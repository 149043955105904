import {
  Answer,
  AnswerType,
  Checklist,
  ChecklistNode,
  ChecklistNodeTreePath,
  ChecklistSection,
  isQuestion,
  isSection,
  Question,
  QuestionContainer
} from "checklists";
import _ from "lodash";

export interface ChecklistState {
  checklist: Checklist;
  reviewedClientId: number | null;
  currentQuestion: Question | null;
  modified: boolean;
  findingInvalidAnswers: boolean;
  isFiltered?: boolean;
}

export interface ChecklistFilters {
  showDeficienciesOnly: boolean;
  showDeficiencyType: "all" | "RD" | "NRD";
  showUnansweredOnly: boolean;
  showWithNotesOnly: boolean;
}

export enum ChecklistActionType {
  Initialize = "Initialize",
  UpdateAnswer = "AnswerQuestion",
  KeyboardAnswerCurrentQuestion = "KeyboardAnswerCurrentQuestion",
  ToggleSectionExpansion = "ToggleSectionExpansion",
  MoveToFirstUnansweredQuestion = "MoveToFirstUnansweredQuestion",
  MarkAllNa = "MarkAllNa",
  UnmarkAllNa = "UnmarkAllNa",
  UpdateFilters = "UpdateFilters",
  UpdateAfterSave = "UpdateAfterSave",
  FindInvalidAnswers = "FindInvalidAnswers",
  ResetFindInvalidAnswers = "ResetFindInvalidAnswers",
  FindQuestion = "FindQuestion"
}

interface InitializeAction {
  type: ChecklistActionType.Initialize;
  checklist: Checklist;
}

interface UpdateAnswerAction {
  type: ChecklistActionType.UpdateAnswer;
  tabSectionId: number;
  question: Question;
  answer: Answer;
  moveToNextQuestion: boolean;
}

interface KeyboardAnswerCurrentQuestionAction {
  type: ChecklistActionType.KeyboardAnswerCurrentQuestion;
  tabSectionId: number;
  answerType: AnswerType;
  moveToNextQuestion: boolean;
  filters: ChecklistFilters;
}

interface ToggleSectionExpansionAction {
  type: ChecklistActionType.ToggleSectionExpansion;
  tabSectionId: number;
  sectionTreePath: ChecklistNodeTreePath;
  sectionExpanded: boolean;
}

interface MoveToFirstUnansweredQuestionAction {
  type: ChecklistActionType.MoveToFirstUnansweredQuestion;
  tabSectionId: number;
}

interface MarkAllNaAction {
  type: ChecklistActionType.MarkAllNa;
  tabSectionId: number;
  sectionTreePath: ChecklistNodeTreePath;
}

interface UnmarkAllNaAction {
  type: ChecklistActionType.UnmarkAllNa;
  tabSectionId: number;
  sectionTreePath: ChecklistNodeTreePath;
}

interface UpdateFiltersAction {
  type: ChecklistActionType.UpdateFilters;
  tabSectionId: number;
  filters: ChecklistFilters;
}

interface UpdateAfterSaveAction {
  type: ChecklistActionType.UpdateAfterSave;
  savedAnswers: Answer[];
}

interface FindInvalidAnswersAction {
  type: ChecklistActionType.FindInvalidAnswers;
}

interface ResetFindInvalidAnswersAction {
  type: ChecklistActionType.ResetFindInvalidAnswers;
  question: Question;
}

interface FindQuestionAction {
  type: ChecklistActionType.FindQuestion;
  question: Question;
  tabSectionId: number;
}

export type ChecklistAction =
  | InitializeAction
  | UpdateAnswerAction
  | KeyboardAnswerCurrentQuestionAction
  | ToggleSectionExpansionAction
  | MoveToFirstUnansweredQuestionAction
  | MarkAllNaAction
  | UnmarkAllNaAction
  | UpdateFiltersAction
  | UpdateAfterSaveAction
  | FindInvalidAnswersAction
  | ResetFindInvalidAnswersAction
  | FindQuestionAction;

export function checklistReducer(state: ChecklistState, action: ChecklistAction): ChecklistState {
  switch (action.type) {
    case ChecklistActionType.Initialize: {
      return { ...state, checklist: action.checklist };
    }

    case ChecklistActionType.UpdateAnswer: {
      return updateStateWithAnswer(state, action.question, action.answer, action.tabSectionId, action.moveToNextQuestion);
    }

    case ChecklistActionType.KeyboardAnswerCurrentQuestion: {
      if (!state.currentQuestion) return state;

      const answer: Answer = {
        isYes: action.answerType === AnswerType.Yes,
        isReportable: action.answerType === AnswerType.Rd,
        isNonReportable: action.answerType === AnswerType.Nrd,
        isNa: action.answerType === AnswerType.Na,
        note: state.currentQuestion.answer?.note ?? null,
        standardParagraphId: null,
        hasCustomDeficiency: false,
        customCpaRef: null,
        customParagraphText: null,
        customRecommendationText: null,
        isSignificant: false,
        requiresRemedialAction: false,
        fileDetails: null,

        modified: true,
        invalid: action.answerType === AnswerType.Rd || action.answerType === AnswerType.Nrd
      };
      return updateStateWithAnswer(state, state.currentQuestion, answer, action.tabSectionId, action.moveToNextQuestion, action.filters);
    }

    case ChecklistActionType.ToggleSectionExpansion: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);
      if (!tabSection) return state;

      const updatedTabSection = updateExpansionOfDescendantSection(tabSection, action.sectionTreePath, 1, !action.sectionExpanded);

      return updateStateWithUpdatedTabSection(state, updatedTabSection);
    }

    case ChecklistActionType.MoveToFirstUnansweredQuestion: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);
      if (!tabSection) return state;

      const firstUnansweredQuestion = findNextQuestion(state.checklist.questionsByTabAndAbsoluteSortOrder[action.tabSectionId], 0, true);
      const tabSectionWithNextQuestionsSectionExpanded = expandSectionsContainingQuestion(tabSection, firstUnansweredQuestion, 0);

      return {
        ...updateStateWithUpdatedTabSection(state, tabSectionWithNextQuestionsSectionExpanded),
        currentQuestion: firstUnansweredQuestion
      };
    }

    case ChecklistActionType.MarkAllNa: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);
      if (!tabSection) return state;

      const [updatedTabSection, questionsWithUpdatedAnswers] = markOrUnmarkTargetSectionAsNa(
        false,
        tabSection,
        state.reviewedClientId,
        action.sectionTreePath,
        false,
        0
      );

      const questionsByTabAndAbsoluteSortOrderUpdatedWithAnswers = {
        ...state.checklist.questionsByTabAndAbsoluteSortOrder,
        [action.tabSectionId]: {
          ...state.checklist.questionsByTabAndAbsoluteSortOrder[action.tabSectionId],
          ...questionsWithUpdatedAnswers
        }
      };

      const lastQuestionInNaSection = _.maxBy(_.toArray(questionsWithUpdatedAnswers), (q) => q.absoluteSortOrder);
      const nextQuestion = findNextQuestion(
        state.checklist.questionsByTabAndAbsoluteSortOrder[action.tabSectionId],
        lastQuestionInNaSection?.absoluteSortOrder ?? 0,
        false
      );
      const tabSectionWithNextQuestionsSectionExpanded = expandSectionsContainingQuestion(
        updatedTabSection as ChecklistSection,
        nextQuestion,
        0
      );
      const stateWithUpdatedTabSection: ChecklistState = updateStateWithUpdatedTabSection(
        state,
        tabSectionWithNextQuestionsSectionExpanded
      );

      return {
        ...stateWithUpdatedTabSection,
        checklist: {
          ...stateWithUpdatedTabSection.checklist,
          questionsByTabAndAbsoluteSortOrder: questionsByTabAndAbsoluteSortOrderUpdatedWithAnswers
        },
        currentQuestion: nextQuestion,
        modified: true
      };
    }

    case ChecklistActionType.UnmarkAllNa: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);
      if (!tabSection) return state;

      const [updatedTabSection, questionsWithUpdatedAnswers] = markOrUnmarkTargetSectionAsNa(
        true,
        tabSection,
        state.reviewedClientId,
        action.sectionTreePath,
        false,
        0
      );

      const stateWithUpdatedTabSection: ChecklistState = updateStateWithUpdatedTabSection(state, updatedTabSection as ChecklistSection);

      const questionsByTabAndAbsoluteSortOrderUpdatedWithAnswers = {
        ...state.checklist.questionsByTabAndAbsoluteSortOrder,
        [action.tabSectionId]: {
          ...state.checklist.questionsByTabAndAbsoluteSortOrder[action.tabSectionId],
          ...questionsWithUpdatedAnswers
        }
      };

      const firstQuestionInUnmarkedSection = _.minBy(_.toArray(questionsWithUpdatedAnswers), (q) => q.absoluteSortOrder);

      return {
        ...stateWithUpdatedTabSection,
        checklist: {
          ...stateWithUpdatedTabSection.checklist,
          questionsByTabAndAbsoluteSortOrder: questionsByTabAndAbsoluteSortOrderUpdatedWithAnswers
        },
        currentQuestion: firstQuestionInUnmarkedSection ?? null,
        modified: true
      };
    }

    case ChecklistActionType.UpdateFilters: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);

      if (!tabSection) return state;

      let updatedCurrentQuestion: Question | null;
      let updatedTabSection: ChecklistSection;

      if (state.currentQuestion) {
        updatedCurrentQuestion = getCurrentOrNextVisibleQuestion(
          state.checklist.questionsByTabAndAbsoluteSortOrder[action.tabSectionId],
          state.currentQuestion.absoluteSortOrder,
          action.filters
        );
        updatedTabSection = expandSectionsContainingQuestion(tabSection!, updatedCurrentQuestion, 0);
      } else {
        updatedCurrentQuestion = state.currentQuestion!;
        updatedTabSection = tabSection!;
      }

      const updatedState = updateStateWithUpdatedTabSection(state, updatedTabSection);

      return {
        ...updatedState,
        isFiltered: action.filters.showDeficienciesOnly || action.filters.showWithNotesOnly,
        currentQuestion: updatedCurrentQuestion,
        checklist: {
          ...updatedState.checklist,
          tabSections: updatedState.checklist.tabSections.map((s) => applyFiltersToQuestionContainer(s, action.filters) as ChecklistSection)
        }
      };
    }

    case ChecklistActionType.UpdateAfterSave: {
      return {
        ...state,
        modified: false,
        checklist: {
          ...state.checklist,
          tabSections: state.checklist.tabSections.map(
            (s) => updateAnswersInQuestionContainerAfterSave(s, action.savedAnswers) as ChecklistSection
          )
        }
      };
    }

    case ChecklistActionType.FindInvalidAnswers: {
      return {
        ...state,
        findingInvalidAnswers: true,
        currentQuestion: null,
        checklist: {
          ...state.checklist,
          tabSections: state.checklist.tabSections.map((s) => expandSectionsWithInvalidAnswers(s))
        }
      };
    }

    case ChecklistActionType.ResetFindInvalidAnswers: {
      return {
        ...state,
        currentQuestion: action.question,
        findingInvalidAnswers: false
      };
    }

    case ChecklistActionType.FindQuestion: {
      const tabSection = state.checklist.tabSections.find((s) => s.id === action.tabSectionId);
      if (!tabSection) return state;

      const tabSectionWithQuestionsSectionExpanded = expandSectionsContainingQuestion(tabSection, action.question, 0);

      return {
        ...updateStateWithUpdatedTabSection(state, tabSectionWithQuestionsSectionExpanded),
        currentQuestion: action.question
      };
    }

    default:
      return state;
  }
}

function updateStateWithUpdatedTabSection(state: ChecklistState, updatedTabSection: ChecklistSection): ChecklistState {
  const updatedTabSections = _.orderBy(
    state.checklist.tabSections.filter((s) => s.id !== updatedTabSection.id).concat(updatedTabSection),
    (s) => s.sortOrder
  );

  return {
    ...state,
    checklist: { ...state.checklist, tabSections: updatedTabSections }
  };
}

function updateStateWithAnswer(
  state: ChecklistState,
  question: Question,
  answer: Answer,
  tabSectionId: number,
  moveToNextQuestion: boolean,
  filters?: ChecklistFilters
): ChecklistState {
  const answerWithReviewedClientId: Answer = { ...answer, reviewedClientId: state.reviewedClientId };
  const tabSection = state.checklist.tabSections.find((s) => s.id === tabSectionId);
  const tabSectionUpdatedWithAnswer = updateQuestionContainerWithAnswer(
    tabSection!,
    question.treePath,
    0,
    answerWithReviewedClientId
  ) as ChecklistSection;

  const questionsByTabAndAbsoluteSortOrderUpdatedWithAnswer = {
    ...state.checklist.questionsByTabAndAbsoluteSortOrder,
    [tabSectionId]: {
      ...state.checklist.questionsByTabAndAbsoluteSortOrder[tabSectionId],
      [question.absoluteSortOrder]: {
        ...state.checklist.questionsByTabAndAbsoluteSortOrder[tabSectionId][question.absoluteSortOrder],
        answer
      }
    }
  };

  let nextQuestion: Question | null;
  let tabSectionWithNextQuestionsSectionExpanded: ChecklistSection;

  if (moveToNextQuestion) {
    nextQuestion = findNextQuestion(
      state.checklist.questionsByTabAndAbsoluteSortOrder[tabSectionId],
      question.absoluteSortOrder,
      false,
      filters
    );
    tabSectionWithNextQuestionsSectionExpanded = expandSectionsContainingQuestion(tabSectionUpdatedWithAnswer, nextQuestion, 0);
  }

  const stateWithUpdatedTabSection: ChecklistState = {
    ...updateStateWithUpdatedTabSection(
      state,
      moveToNextQuestion ? tabSectionWithNextQuestionsSectionExpanded! : tabSectionUpdatedWithAnswer
    )
  };

  const updatedCurrentQuestion = moveToNextQuestion
    ? nextQuestion!
    : state.currentQuestion != null
    ? { ...state.currentQuestion, answer: answer }
    : null;

  return {
    ...stateWithUpdatedTabSection,
    checklist: {
      ...stateWithUpdatedTabSection.checklist,
      questionsByTabAndAbsoluteSortOrder: questionsByTabAndAbsoluteSortOrderUpdatedWithAnswer
    },
    currentQuestion: updatedCurrentQuestion,
    modified: true,
    findingInvalidAnswers: false
  };
}

function updateQuestionContainerWithAnswer(
  questionContainer: QuestionContainer,
  treePath: ChecklistNodeTreePath,
  depth: number,
  answer: Answer
): QuestionContainer {
  const child = questionContainer.children.find((c) => nodeMatches(c, treePath, depth + 1))!;
  const updatedChild = isQuestion(child)
    ? {
        ...child,
        answer: { ...answer, id: child.answer?.id } // Preserve the answer's ID, if it's already been saved
      }
    : updateQuestionContainerWithAnswer(child, treePath, depth + 1, answer);

  const updatedChildren = questionContainer.children
    .filter((c) => c.__typename !== updatedChild?.__typename || c.id !== updatedChild.id)
    .concat(updatedChild);
  const orderedUpdatedChildren = _.orderBy(updatedChildren, (c) => c.sortOrder);

  return { ...questionContainer, children: orderedUpdatedChildren };
}

function updateExpansionOfDescendantSection<T extends QuestionContainer>(
  section: T,
  treePath: ChecklistNodeTreePath,
  depth: number,
  expanded: boolean
): T {
  const childSection = section.children.find((c) => nodeMatches(c, treePath, depth))! as QuestionContainer;

  let updatedChildSection: QuestionContainer;
  const atEndOfPath = !treePath[depth + 1];
  if (atEndOfPath) {
    updatedChildSection = { ...childSection };
    if (isSection(section)) {
      (updatedChildSection as ChecklistSection).expanded = expanded;
    }
  } else {
    updatedChildSection = updateExpansionOfDescendantSection(childSection, treePath, depth + 1, expanded);
  }

  const childIndex = section.children.findIndex((c) => c.id === updatedChildSection.id && c.__typename === updatedChildSection.__typename);
  const updatedChildren = [...section.children];
  updatedChildren.splice(childIndex, 1, updatedChildSection);

  return { ...section, children: updatedChildren };
}

function expandSectionsContainingQuestion(section: ChecklistSection, question: Question | null, depth: number): ChecklistSection {
  if (question === null) return section;

  const childOnPath = section.children.find((c) => nodeMatches(c, question.treePath, depth + 1));

  if (!isSection(childOnPath!)) {
    return { ...section, expanded: true };
  } else {
    const updatedChild = expandSectionsContainingQuestion(childOnPath, question, depth + 1);

    const childIndex = section.children.findIndex((c) => c.id === updatedChild!.id && c.__typename === updatedChild!.__typename);
    const updatedChildren = [...section.children];
    updatedChildren.splice(childIndex, 1, updatedChild);

    return { ...section, expanded: true, children: updatedChildren };
  }
}

function expandSectionsWithInvalidAnswers(section: ChecklistSection): ChecklistSection {
  const updatedChildren = section.children.map((child) => {
    return isSection(child) ? expandSectionsWithInvalidAnswers(child) : child;
  });

  return {
    ...section,
    children: updatedChildren,
    // expand (or collapse) this section if contains questions with invalid answers or a child section that is expanded
    expanded: updatedChildren.some((c) => (isQuestion(c) && c.answer?.invalid) || (isSection(c) && c.expanded)) ? true : section.expanded
  };
}

type ChecklistNodeWithUpdatedQuestions = [ChecklistNode, _.Dictionary<Question>];

function markOrUnmarkTargetSectionAsNa(
  unmarkingAsNa: boolean,
  questionContainer: QuestionContainer,
  reviewedClientId: number | null,
  targetSectionTreePath: ChecklistNodeTreePath,
  insideTargetSection: boolean,
  depth: number
): ChecklistNodeWithUpdatedQuestions {
  // Note: we need to keep track of all the updated answers as we recurse through the tree so that we can update
  // the question dictionary that we use to efficiently find the next question, which is why this function returns a tuple.

  let childrenToUpdate: ChecklistNode[];

  const targetSectionDepth = _.max(_.keys(targetSectionTreePath).map((key) => Number(key)));
  const questionContainerIsTargetSection = nodeMatches(questionContainer, targetSectionTreePath, targetSectionDepth!);

  if (insideTargetSection || questionContainerIsTargetSection) {
    childrenToUpdate = questionContainer.children;
  } else {
    const childToUpdate = questionContainer.children.find((c) => nodeMatches(c, targetSectionTreePath, depth + 1));
    childrenToUpdate = [childToUpdate!];
  }

  const updatedChildrenAndQuestions = childrenToUpdate.map((child) => {
    if (isQuestion(child)) {
      const updatedQuestion =
        insideTargetSection || questionContainerIsTargetSection ? markOrUnmarkQuestionAsNa(child, unmarkingAsNa, reviewedClientId) : child;

      return [
        updatedQuestion as ChecklistNode,
        { [updatedQuestion.absoluteSortOrder]: updatedQuestion }
      ] as ChecklistNodeWithUpdatedQuestions;
    } else {
      const childIsInsideTargetSection = insideTargetSection || questionContainerIsTargetSection;
      return markOrUnmarkTargetSectionAsNa(
        unmarkingAsNa,
        child,
        reviewedClientId,
        targetSectionTreePath,
        childIsInsideTargetSection,
        depth + 1
      );
    }
  });

  const updatedChildren = updatedChildrenAndQuestions.map((updatedChildWithUpdatedQuestions) => updatedChildWithUpdatedQuestions[0]);

  const newChildren = questionContainer.children.filter((c) => updatedChildren.every((uc) => c.id !== uc.id)).concat(updatedChildren);
  const orderedNewChildren = _.orderBy(newChildren, (c) => c.sortOrder);

  const updatedQuestionDictionaries = updatedChildrenAndQuestions.map(
    (updatedChildWithUpdatedQuestions) => updatedChildWithUpdatedQuestions[1]
  );
  const flattenedQuestionDictionary = updatedQuestionDictionaries.reduce((previous, current) => ({ ...previous, ...current }), {});

  if (isSection(questionContainer)) {
    const section = questionContainer;
    const shouldToggleExpansion = insideTargetSection || questionContainerIsTargetSection;

    return [
      {
        ...section,
        children: orderedNewChildren,
        expanded: shouldToggleExpansion ? unmarkingAsNa : section.expanded
      },
      flattenedQuestionDictionary
    ];
  } else {
    return [{ ...questionContainer, children: orderedNewChildren }, flattenedQuestionDictionary];
  }
}

function markOrUnmarkQuestionAsNa(question: Question, unmarkAsNa: boolean, reviewedClientId: number | null): Question {
  return {
    ...question,
    answer: {
      id: question.answer?.id,
      isNa: !unmarkAsNa,
      note: question.answer?.note ?? null, // preserve any note they might have added
      modified: true,

      reviewedClientId: reviewedClientId,
      isYes: false,
      isReportable: false,
      isNonReportable: false,
      standardParagraphId: null,
      hasCustomDeficiency: false,
      customCpaRef: null,
      customParagraphText: null,
      customRecommendationText: null,
      isSignificant: false,
      requiresRemedialAction: false,
      fileDetails: null
    }
  };
}

function applyFiltersToQuestionContainer(questionContainer: QuestionContainer, filters: ChecklistFilters): QuestionContainer {
  let updatedChildren = questionContainer.children.map((c) =>
    isQuestion(c) ? applyFiltersToQuestion(c, filters) : applyFiltersToQuestionContainer(c, filters)
  );

  return {
    ...questionContainer,
    children: updatedChildren,
    hidden: updatedChildren.every((c) => c.hidden)
  };
}

function applyFiltersToQuestion(question: Question, filters: ChecklistFilters): Question {
  const hiddenBecauseAnswered =
    filters.showUnansweredOnly &&
    question.answer &&
    (question.answer.isYes || question.answer.isReportable || question.answer.isNonReportable || question.answer.isNa);
  const hiddenBecauseNoNote = filters.showWithNotesOnly && !question.answer?.note;
  const hiddenBecauseNotDeficiency =
    filters.showDeficienciesOnly && (!question.answer || !(question.answer.isReportable || question.answer.isNonReportable));
  const hiddenBecauseWrongDeficiencyType =
    filters.showDeficienciesOnly &&
    ((filters.showDeficiencyType === "RD" && !question.answer?.isReportable) ||
      (filters.showDeficiencyType === "NRD" && !question.answer?.isNonReportable));

  return {
    ...question,
    hidden: hiddenBecauseAnswered || hiddenBecauseNoNote || hiddenBecauseNotDeficiency || hiddenBecauseWrongDeficiencyType
  };
}

function updateAnswersInQuestionContainerAfterSave(questionContainer: QuestionContainer, savedAnswers: Answer[]): QuestionContainer {
  let updatedChildren = questionContainer.children.map((c) =>
    isQuestion(c) ? updateAnswerAfterSave(c, savedAnswers) : updateAnswersInQuestionContainerAfterSave(c, savedAnswers)
  );

  return {
    ...questionContainer,
    children: updatedChildren
  };
}

function updateAnswerAfterSave(question: Question, savedAnswers: Answer[]): Question {
  const savedAnswerForQuestion = savedAnswers.filter((sa) => sa.questionId === question.id)[0];

  return {
    ...question,
    answer: question.answer ? { ...question.answer, id: savedAnswerForQuestion?.id ?? question.answer.id, modified: false } : undefined
  };
}

export function findNextQuestion(
  questionsByAbsoluteSortOrder: _.Dictionary<Question>,
  afterAbsoluteSortOrder: number,
  unansweredQuestionsOnly: boolean,
  filters?: ChecklistFilters
): Question | null {
  const nextQuestion = questionsByAbsoluteSortOrder[afterAbsoluteSortOrder + 1];

  if (!nextQuestion) return null;

  if (
    (unansweredQuestionsOnly &&
      (nextQuestion.answer?.isYes ||
        nextQuestion.answer?.isReportable ||
        nextQuestion.answer?.isNonReportable ||
        nextQuestion.answer?.isNa)) ||
    (filters && applyFiltersToQuestion(nextQuestion, filters).hidden)
  ) {
    return findNextQuestion(questionsByAbsoluteSortOrder, afterAbsoluteSortOrder + 1, unansweredQuestionsOnly, filters);
  } else {
    return nextQuestion;
  }
}

function getCurrentOrNextVisibleQuestion(
  questionsByAbsoluteSortOrder: _.Dictionary<Question>,
  currentQuestionAbsoluteSortOrder: number,
  filters: ChecklistFilters
): Question | null {
  const question = questionsByAbsoluteSortOrder[currentQuestionAbsoluteSortOrder];

  if (!question) return null;

  return applyFiltersToQuestion(question, filters).hidden
    ? getCurrentOrNextVisibleQuestion(questionsByAbsoluteSortOrder, currentQuestionAbsoluteSortOrder + 1, filters)
    : question;
}

export function nodeMatches(node: ChecklistNode, treePath: ChecklistNodeTreePath, depth: number) {
  return node.id === treePath[depth].id && node.__typename === treePath[depth].__typename;
}
