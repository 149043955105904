import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Checkbox, Grid, Link, Paper, Skeleton, Stack, Typography, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { GridSortCellParams } from "@mui/x-data-grid-pro";
import { ScreenHeader } from "common/ScreenHeader";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  ApproveExemptionLettersMutation,
  Exemption,
  FetchExemptionsWithLettersQuery,
  GenerateExemptionLettersMutation,
  ReleaseExemptionsForApprovalMutation
} from ".";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { MicrosoftWordLink } from "common/MicrosoftWordLink";
import { makeStyles } from "makeStyles";
import { useNotifications } from "notifications";
import { datagridStyles } from "styles/common";
import { standardDateFormat } from "util/formats";
import { useCurrentUser, Permissions } from "users";
import { getRouteForPracticeReview, PracticeReviewTabs } from "../practice-reviews/PracticeReviewScreen";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

interface Props {
  mode: "review" | "approve";
  pprp?: boolean;
}

const ExemptionLettersScreen: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { userHasPermission } = useCurrentUser();
  const emptyFilters = {
    reviewType: "Any"
  };
  const [filters, setFilters] = useState<{
    reviewType: string;
  }>(emptyFilters);

  const [markedExemptionIds, setMarkedExemptionIds] = useState<number[]>([]);

  const exemptionQuery = useQuery<{ exemptionsWithLetters: Exemption[] }>(FetchExemptionsWithLettersQuery, {
    variables: {
      stage: props.mode === "review" ? "ReviewLetters" : "ApproveLetters",
      pprp: props.pprp ?? false
    }
  });

  const exemptions = exemptionQuery.data?.exemptionsWithLetters ?? [];

  const filteredExemptions = exemptions.filter((exemption) => {
    if (filters.reviewType === "Any") {
      return true;
    } else return exemption.practiceReview.reviewType === filters.reviewType;
  });

  const applyFilter = (e: any) => {
    setFilters({ reviewType: e.target.value });
  };

  const [releaseLettersForApprovalMutate, releaseLettersForApprovalMutation] = useMutation<
    { exemption: { releaseLettersForApproval: Exemption[] } },
    { exemptionIds: number[] }
  >(ReleaseExemptionsForApprovalMutation, {
    refetchQueries: [
      { query: FetchExemptionsWithLettersQuery, variables: { stage: "ReviewLetters", pprp: props.pprp ?? false } },
      { query: FetchExemptionsWithLettersQuery, variables: { stage: "ApproveLetters", pprp: props.pprp ?? false } }
    ]
  });

  async function releaseLettersForApproval() {
    const result = await releaseLettersForApprovalMutate({
      variables: {
        exemptionIds: markedExemptionIds
      }
    });

    if ((result.data?.exemption.releaseLettersForApproval?.length ?? 0) > 0) {
      notifications.success("Released exemptions for approval.");
    }
  }

  const [approveLettersMutate, approveLettersMutation] = useMutation<
    { exemption: { approveLetters: Exemption[] } },
    { exemptionIds: number[] }
  >(ApproveExemptionLettersMutation, {
    refetchQueries: [{ query: FetchExemptionsWithLettersQuery, variables: { stage: "ApproveLetters", pprp: props.pprp ?? false } }]
  });

  async function approveLetters() {
    const result = await approveLettersMutate({
      variables: {
        exemptionIds: markedExemptionIds
      }
    });

    if ((result.data?.exemption.approveLetters?.length ?? 0) > 0) {
      notifications.success("Approved exemptions.");
    }
  }

  const [generateLettersMutate, generateLettersMutation] = useMutation<
    { exemption: { generateExemptionLetters: Exemption[] } },
    { exemptionIds: number[] }
  >(GenerateExemptionLettersMutation);

  async function generateLetters() {
    const result = await generateLettersMutate({
      variables: {
        exemptionIds: filteredExemptions.map((e) => e.id)
      }
    });

    if ((result.data?.exemption.generateExemptionLetters?.length ?? 0) > 0) {
      notifications.success(regenerating ? "Regenerated exemption letters." : "Generated exemption letters.");
    }
  }

  const regenerating = exemptions.some((e) => Boolean(e.practiceReview.exemptionLetterUrl));

  return (
    <>
      <Helmet>
        <title>{`${props.mode === "review" ? "Review" : "Approve"} Exemption Letters - PRS Online`}</title>
      </Helmet>
      <ScreenHeader title={`${props.mode === "review" ? "Review" : "Approve"} Exemption Letters`} />
      <Paper sx={{ p: 2 }}>
        <Stack spacing={3}>
          {exemptionQuery.loading && !exemptionQuery.data ? (
            <Grid container direction="column" spacing={3}>
              {[...Array(3)].map((x, index) => (
                <Grid item key={index} xs={12}>
                  <Skeleton variant="rectangular" width="100%" height="10rem" />
                </Grid>
              ))}
            </Grid>
          ) : exemptions.length > 0 ? (
            <DataGridWithHeader
              columns={[
                {
                  field: "prNumber",
                  headerName: "PR No.",
                  width: 80,
                  renderCell: (params) => (
                    <Link
                      to={getRouteForPracticeReview((params.row as Exemption).practiceReview, PracticeReviewTabs.Exemption)}
                      component={RouterLink}>
                      {(params.row as Exemption).practiceReview.prNumber}
                    </Link>
                  ),
                  disableColumnMenu: true,
                  sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
                    param1.api
                      .getRow(param1.id)!
                      .practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
                },
                {
                  field: "firmName",
                  headerName: "Firm",
                  valueGetter: (params) => (params.row as Exemption).practiceReview.firm.name,
                  flex: 2
                },
                {
                  field: "reviewType",
                  headerName: "Type",
                  width: 110,
                  valueGetter: (params) => (params.row as Exemption).practiceReview.reviewType
                },
                {
                  field: "prContact",
                  headerName: "PR Contact",
                  valueGetter: (params) => (params.row as Exemption).practiceReview.contactName,
                  flex: 1
                },
                {
                  field: "prContactEmail",
                  headerName: "Contact Email",
                  valueGetter: (params) => (params.row as Exemption).practiceReview.contactEmail,
                  flex: 1
                },
                {
                  field: "prStartDate",
                  headerName: "PR Date",
                  type: "date",
                  valueGetter: (params) => (params.row as Exemption).practiceReview.startDate,
                  valueFormatter: (params) => DateTime.fromISO(params.value).toFormat(standardDateFormat),
                  width: 90
                },
                {
                  field: "prStatus",
                  headerName: "PR Status",
                  valueGetter: (params) => (params.row as Exemption).practiceReview.status.statusName,
                  width: 140
                },
                {
                  field: "exemptionAnniversary",
                  headerName: "Exemption Anniversary",
                  headerClassName: classes.wrapHeader,
                  valueGetter: (params) => {
                    const exemption = params.row as Exemption;
                    return exemption.startedOn;
                  },
                  valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value as string).toFormat("MMM d") : "--"),
                  width: 100
                },
                {
                  field: "exemptionStartedOn",
                  headerName: "Exemption Started",
                  headerClassName: classes.wrapHeader,
                  valueGetter: (params) => {
                    const exemption = params.row as Exemption;
                    return exemption.startedOn;
                  },
                  valueFormatter: (params) => DateTime.fromISO(params.value).toFormat(standardDateFormat),
                  width: 90
                },
                {
                  field: "notes",
                  headerName: "Exemption Notes",
                  valueGetter: (params) => (params.row as Exemption).notes,
                  flex: 3
                },
                {
                  field: "letterLink",
                  headerName: "Letter",
                  headerAlign: "center",
                  align: "center",
                  width: 60,
                  renderCell: (params) => {
                    const exemption = params.row as Exemption;
                    return exemption.practiceReview.exemptionLetterUrl ? (
                      <MicrosoftWordLink href={exemption.practiceReview.exemptionLetterUrl}>Edit</MicrosoftWordLink>
                    ) : null;
                  }
                },
                {
                  field: "markedReviewed",
                  headerName: "Reviewed",
                  width: 100,
                  align: "center",
                  renderCell: (params) => {
                    const exemption = params.row as Exemption;
                    return (
                      <Checkbox
                        checked={exemption.markedAsReviewed}
                        onClick={() =>
                          markedExemptionIds.indexOf(exemption.id) === -1
                            ? setMarkedExemptionIds(markedExemptionIds.concat(exemption.id))
                            : setMarkedExemptionIds(markedExemptionIds.filter((id) => id !== exemption.id))
                        }
                      />
                    );
                  }
                }
              ]}
              rows={_.orderBy(filteredExemptions, (e) => (e.startedOn ? DateTime.fromISO(e.startedOn) : null))}
              headerActions={
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" color="text.secondary">
                  {!props.pprp && (
                    <>
                      <Typography variant="h4">Filters</Typography>
                      <FormControl margin="none" size="small" sx={{ width: "14em" }}>
                        <InputLabel id="exemption-type-label">Review Type</InputLabel>
                        <Select
                          labelId="exemption-type-label"
                          label="Review Type"
                          value={filters.reviewType}
                          onChange={(e) => applyFilter(e)}>
                          <MenuItem value={"Any"}>Any</MenuItem>
                          <MenuItem value={"Assurance"}>Assurance</MenuItem>
                          <MenuItem value={"Non-assurance"}>Non-Assurance</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}

                  {props.mode === "review" && userHasPermission(Permissions.ExemptionReviewLetter) ? (
                    <LoadingButton
                      onClick={() => generateLetters()}
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={filteredExemptions.length === 0}
                      loading={generateLettersMutation.loading}>
                      {regenerating ? "Regenerate Letters" : "Generate Letters"}
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                </Stack>
              }
              footerActions={
                props.mode === "review" && userHasPermission(Permissions.ExemptionReviewLetter) ? (
                  <LoadingButton
                    onClick={() => releaseLettersForApproval()}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={markedExemptionIds.length === 0}
                    loading={releaseLettersForApprovalMutation.loading}>
                    Release for Approval
                  </LoadingButton>
                ) : props.mode === "approve" && userHasPermission(Permissions.ExemptionConfirm) ? (
                  <LoadingButton
                    onClick={() => approveLetters()}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={markedExemptionIds.length === 0}
                    loading={approveLettersMutation.loading}>
                    Approve
                  </LoadingButton>
                ) : (
                  <></>
                )
              }
              displayWithoutContainer
              noDataMessage="No exemptions."
            />
          ) : (
            <Typography variant="body1">{`No exemption letters to be ${props.mode === "review" ? "reviewed" : "approved"}`}.</Typography>
          )}
        </Stack>
      </Paper>
    </>
  );
};

export default ExemptionLettersScreen;
