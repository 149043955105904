import { useApolloClient, useMutation } from "@apollo/client";
import { AssignedPdCourse, DecisionTypeCode } from "decisions";
import { DocType, PracticeReview } from "practice-reviews";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  FormControlLabel,
  Button,
  DialogContentText,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Checkbox,
  Switch,
  TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CompleteDirectedPdMutation, UpdateDirectedPdCoursesMutation } from "pd-courses/queries";
import { useNotifications } from "notifications";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { DateTime } from "luxon";
import { standardDateFormat } from "util/formats";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { useAttachedDocumentActions } from "./AttachedDocumentActions";
import { useFormik } from "formik";
import { getOpenableUrl } from "util/utilities";
import RichTextEditor from "common/RichTextEditor";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import { optionalScreenWidthLimit } from "styles/theme";

interface Props {
  practiceReview: PracticeReview;
}

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  cardContainer: {
    padding: theme.spacing(3),
    borderLeft: `${theme.spacing(1)} solid transparent`,
    maxWidth: optionalScreenWidthLimit
  },
  courseControlsRow: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: theme.spacing(1)
  },
  exemptCard: {
    borderLeftColor: theme.palette.cpaLightGrey.main,
    color: theme.palette.text.secondary
  },
  completedCard: {
    borderLeftColor: theme.palette.cpaAccentGreen.main
  },
  topicNameAndCodeLabels: {
    color: theme.palette.text.secondary
  },
  exemptSwitchLabel: {
    color: theme.palette.text.primary
  },
  notesEditor: {
    maxWidth: `calc(${optionalScreenWidthLimit} + ${theme.spacing(2)})`
  }
}));

export const DirectedPdTab = (props: Props) => {
  const { classes, cx } = useStyles();
  const { practiceReview } = props;
  const { success } = useNotifications();
  const apolloClient = useApolloClient();
  const { changesSaved, unsavedChanges } = useUnsavedChanges();
  const [completingPd, setCompletingPd] = useState(false);

  const {
    documentsAttached,
    attachingDocumentType,
    setRemovingDocument,
    removeAttachedDocumentMutation,
    removingDocument,
    removeAttachedDocument
  } = useAttachedDocumentActions();
  const fileNameError = `The file you have selected cannot be attached using this function, because its filename contains the text {match}, which is too similar to the reserved name of a document that has a defined purpose in this system.
  This is not allowed because that file could be confused with, or even overwrite or be overwritten by, the other file.

  The most common reason to see this message is that there may be a different area of the review dedicated to uploading the specific type of document, which must be used instead so that the file can play its intended role.

  Please look at the Reports And Questionnaires tab for a spot dedicated to a {match} file.

  If you cannot find such a spot, and/or are certain this file should be attached as Proof of Attendance document instead, please rename the file to not include the text {match} in its filename, then try again.`;

  const proofOfAttendanceDocs = useMemo(
    () =>
      Object.fromEntries(
        practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedPDProofOfAttendance).map((ad) => [ad.id, ad])
      ),
    [practiceReview.attachedDocuments]
  );

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const sortDirectedPdCourses = (assignedCourses: AssignedPdCourse[]) => {
    return assignedCourses.sort(
      (c1, c2) =>
        (c1.topicName ?? c1.pdCourse.topicName ?? "").localeCompare(c2.topicName ?? c2.pdCourse.topicName) ||
        (c1.productCode ?? c1.pdCourse.productCode ?? "").localeCompare(c2.productCode ?? c2.pdCourse.productCode ?? "")
    );
  };

  const decision = practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Committee) || null;

  const [pdCoursesMutate, pdCoursesMutation] = useMutation<
    { pdCourses: { updateDirectedPdCourses: { updatedCourses: AssignedPdCourse[]; notes: string | null } } },
    { practiceReviewId: number; updatedCourses: Partial<AssignedPdCourse>[]; notes: string | null }
  >(UpdateDirectedPdCoursesMutation);

  const [completePdMutate, completePdMutation] = useMutation<{ completeDirectedPd: PracticeReview }, { practiceReviewId: number }>(
    CompleteDirectedPdMutation
  );

  const saveActiveDirectedPdCourses = async (values: AssignedPdCourse[], notes: string | null) => {
    var result = await pdCoursesMutate({
      variables: {
        practiceReviewId: practiceReview.id,
        updatedCourses: values!.map((apd) => ({
          id: apd.id,
          isCompleted: apd.isCompleted,
          isExempt: apd.isExempt,
          exemptReason: apd.exemptReason
        })),
        notes
      }
    });
    if (!result.errors) {
      success("Saved PD courses.");
      await apolloClient.refetchQueries({
        updateCache(cache) {
          cache.modify({
            id: `PracticeReview:${practiceReview.id}`,
            fields: {
              directedPDNotes() {
                return result.data!.pdCourses.updateDirectedPdCourses.notes;
              }
            }
          });
        }
      });
    }
  };

  const sortedPdCourses = useMemo(
    () => sortDirectedPdCourses([...(decision?.assignedPdCourses.filter((c) => c.isDirected) ?? [])]),
    [decision?.assignedPdCourses]
  );

  const readOnly = practiceReview.isDirectedPdCompleted;

  const formik = useFormik({
    initialValues: {
      directedPdCourses: sortedPdCourses,
      notes: practiceReview.directedPDNotes
    },
    onSubmit: async (values, submitProps) => {
      if (readOnly) return;
      var notes = notesContentRetriever.current.getContentAsHtml();
      await saveActiveDirectedPdCourses(values.directedPdCourses ?? [], notes);
      if (!pdCoursesMutation.error) {
        submitProps.resetForm({
          values: { directedPdCourses: values.directedPdCourses, notes }
        });
        changesSaved();
      }
    },
    initialTouched: {
      directedPdCourses: sortedPdCourses.map(() => ({ isCompleted: false, isExempt: false, exemptReason: false })),
      notes: false
    }
  });

  const handleCompletePd = async () => {
    if (formik.dirty) {
      await formik.submitForm();
    }
    await completePdMutate({
      variables: {
        practiceReviewId: practiceReview.id
      }
    });

    setCompletingPd(false);
  };

  // When a file gets attached to a course, don't nuke changes made by the user
  useEffect(() => {
    const newValues = formik.values.directedPdCourses.map((pd, i) => {
      const incomingCourse = decision?.assignedPdCourses.filter((c) => c.id === pd.id)[0];
      let newValue = { ...pd, signedProofOfAttendanceId: incomingCourse?.signedProofOfAttendanceId ?? pd.signedProofOfAttendanceId };
      return newValue;
    });
    formik.setFieldValue("directedPdCourses", newValues);
  }, [decision?.assignedPdCourses]);

  const signedPDDeclaration = practiceReview.attachedDocuments.filter((d) => d.type === DocType.SignedPDDeclaration)[0];
  const allPDFinished =
    !decision?.assignedPdCourses.some((c) => !c.isCompleted && !c.isExempt) ||
    !formik.values.directedPdCourses.some((c) => !c.isCompleted && !c.isExempt);
  const canCompletePD = signedPDDeclaration && allPDFinished;

  return !practiceReview ? (
    <Skeleton width="100%"></Skeleton>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={2} item container>
        <Grid item xs={12}>
          {decision?.directedPdDueDate && (
            <StackedStaticDataDisplay
              label="Date Directed PD to Be Taken By"
              value={DateTime.fromISO(decision.directedPdDueDate).toFormat(standardDateFormat)}
            />
          )}
        </Grid>
        {formik.values.directedPdCourses?.map((directedCourse, index) => (
          <Grid key={directedCourse.id} item xs={12}>
            <Card
              variant="outlined"
              className={cx(
                classes.cardContainer,
                { [classes.exemptCard]: directedCourse.isExempt },
                { [classes.completedCard]: directedCourse.isCompleted }
              )}>
              <Grid container>
                <Grid item container spacing={2}>
                  <Grid xs={12} item>
                    <Typography variant="h3">{directedCourse.name ?? directedCourse.pdCourse.name}</Typography>
                  </Grid>
                  <Grid xs={12} container item spacing={4} alignItems="center" direction="row" className={classes.courseControlsRow}>
                    <Grid item>
                      <Typography variant="subtitle2" className={classes.topicNameAndCodeLabels}>
                        {directedCourse.topicName ?? directedCourse.pdCourse.topicName} |{" "}
                        {directedCourse.productCode ?? directedCourse.pdCourse.productCode}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        disabled={directedCourse.isExempt || readOnly}
                        control={
                          <Checkbox
                            name={`directedPdCourses[${index}].isCompleted`}
                            checked={directedCourse.isCompleted}
                            onChange={(e) => {
                              formik.setFieldTouched(`directedPdCourses[${index}].isCompleted`, true);
                              formik.handleChange(e);
                              unsavedChanges();
                            }}
                          />
                        }
                        label="Complete"
                      />
                      <FormControlLabel
                        disabled={directedCourse.isCompleted || readOnly}
                        className={classes.exemptSwitchLabel}
                        control={
                          <Switch
                            name={`directedPdCourses[${index}].isExempt`}
                            checked={directedCourse.isExempt}
                            onChange={(e) => {
                              formik.setFieldTouched(`directedPdCourses[${index}].isExempt`, true);
                              formik.handleChange(e);
                              unsavedChanges();
                            }}
                          />
                        }
                        label="PD not taken"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {directedCourse.isExempt && (
                  <Grid flexGrow={1} item>
                    {readOnly ? (
                      <>
                        <Typography variant="caption" gutterBottom>
                          Not Taken Reason
                        </Typography>
                        <Typography>{directedCourse.exemptReason}</Typography>
                      </>
                    ) : (
                      <TextField
                        name={`directedPdCourses[${index}].exemptReason`}
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        label={"Not Taken Reason"}
                        error={!directedCourse.exemptReason}
                        helperText={!!directedCourse.exemptReason ? "" : "Not Taken Reason Required"}
                        value={directedCourse.exemptReason}
                        onChange={(e) => {
                          formik.setFieldTouched(`directedPdCourses[${index}].exemptReason`, true);
                          formik.handleChange(e);
                          unsavedChanges();
                        }}
                      />
                    )}
                  </Grid>
                )}
                {!directedCourse.isExempt && (
                  <span>
                    {directedCourse.signedProofOfAttendanceId !== null &&
                    proofOfAttendanceDocs.hasOwnProperty(directedCourse.signedProofOfAttendanceId) ? (
                      <>
                        <Stack alignItems="center" direction="row" spacing={2}>
                          <Link href={getOpenableUrl(proofOfAttendanceDocs[directedCourse.signedProofOfAttendanceId].url)} target="_blank">
                            Proof of Attendance
                          </Link>
                          {!readOnly && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="error"
                              onClick={() => setRemovingDocument(proofOfAttendanceDocs[directedCourse.signedProofOfAttendanceId!])}
                              disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}>
                              Remove
                            </Button>
                          )}
                        </Stack>
                        {proofOfAttendanceDocs[directedCourse.signedProofOfAttendanceId].portalDeclarationDate && (
                          <Typography variant="subtitle1">
                            Submitted on{" "}
                            {DateTime.fromISO(
                              proofOfAttendanceDocs[directedCourse.signedProofOfAttendanceId].portalDeclarationDate!
                            ).toFormat(standardDateFormat)}
                          </Typography>
                        )}
                      </>
                    ) : !readOnly ? (
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <Typography>Proof of Attendance</Typography>
                        <LoadingButton variant="outlined" size="small" component="label" loading={attachingDocumentType !== null}>
                          Attach
                          <input
                            type="file"
                            hidden
                            accept="*/*"
                            onChange={(e) =>
                              documentsAttached(
                                e.target.files!,
                                DocType.SignedPDProofOfAttendance,
                                practiceReview,
                                fileNameError,
                                directedCourse.id
                              )
                            }
                          />
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Typography>No proof of attendance provided</Typography>
                    )}
                  </span>
                )}
              </Grid>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} className={classes.notesEditor}>
          <RichTextEditor
            label="Notes"
            minHeight="6em"
            html={formik.values.notes}
            hideToolbar
            readOnly={formik.isSubmitting || readOnly}
            passContentRetriever={(getContentAsHtml) => {
              notesContentRetriever.current = { getContentAsHtml };
            }}
            reportUnsavedChanges
          />
        </Grid>
        <Grid key="declaration-submit" sx={{ mt: 2 }} item xs={12}>
          {signedPDDeclaration ? (
            <>
              <Stack direction="row" spacing={2}>
                <Link href={getOpenableUrl(signedPDDeclaration.url)} target="_blank">
                  Signed PD Declaration
                </Link>
                {!readOnly && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => setRemovingDocument(signedPDDeclaration)}
                    disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}>
                    Remove
                  </Button>
                )}
              </Stack>
              {signedPDDeclaration.portalDeclarationDate && (
                <Typography variant="subtitle2">
                  Submitted {signedPDDeclaration.portalSigneeName && `by ${signedPDDeclaration.portalSigneeName}`} on{" "}
                  {DateTime.fromISO(signedPDDeclaration.portalDeclarationDate).toFormat(standardDateFormat)}
                </Typography>
              )}
            </>
          ) : (
            <Stack direction="row" spacing={2}>
              <Typography>Signed PD Declaration</Typography>
              <LoadingButton variant="outlined" size="small" component="label" loading={attachingDocumentType !== null}>
                Attach
                <input
                  type="file"
                  hidden
                  accept="*/*"
                  onChange={(e) => documentsAttached(e.target.files!, DocType.SignedPDDeclaration, practiceReview, fileNameError)}
                />
              </LoadingButton>
            </Stack>
          )}
        </Grid>
        <Grid justifyContent="flex-end" container item xs={12} direction="row" spacing={2}>
          {readOnly ? (
            <Grid item>
              <Typography>
                Directed PD completed on {DateTime.fromISO(practiceReview.directedPdCompletedDate!).toFormat(standardDateFormat)}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Tooltip
                  title={
                    !canCompletePD ? (
                      <>
                        {!allPDFinished && "All Directed PD must be marked as complete or not taken with a reason given."}
                        {!allPDFinished && !signedPDDeclaration && <br />}
                        {!signedPDDeclaration && "A signed copy of the PD Declaration form must be attached."}
                      </>
                    ) : (
                      ""
                    )
                  }>
                  <span>
                    <LoadingButton
                      variant="outlined"
                      disabled={!canCompletePD || formik.isSubmitting}
                      onClick={() => setCompletingPd(true)}
                      loading={completePdMutation.loading}>
                      Complete PD
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <LoadingButton
                  variant="outlined"
                  disabled={
                    formik.values.directedPdCourses.some((pd) => pd.isExempt && !pd.exemptReason) ||
                    formik.isSubmitting ||
                    completePdMutation.loading
                  }
                  onClick={formik.submitForm}
                  loading={formik.isSubmitting}>
                  Save
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {removingDocument !== null && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              {`Do you want to remove the ${
                removingDocument?.type === "AdditionalFile" ? `file "${removingDocument?.fileName}"` : removingDocument?.typeFriendlyName
              }?`}
            </DialogContentText>
          }
          title="Remove file?"
          cancel={() => setRemovingDocument(null)}
          confirm={() => removeAttachedDocument(removingDocument, practiceReview.id)}
          loading={removeAttachedDocumentMutation.loading}
        />
      )}
      {completingPd && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              Do you want to complete the directed PD monitoring for this review? This should only be done after verifying the Signed PD
              Declaration form provided by the firm. Once directed PD has been completed, changes cannot be made to the values on this page.
            </DialogContentText>
          }
          title="Complete PD?"
          noDanger
          cancel={() => setCompletingPd(false)}
          confirm={() => handleCompletePd()}
          loading={completePdMutation.loading}
        />
      )}
    </form>
  );
};
