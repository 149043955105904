import { useState } from "react";
import { useNotifications } from "notifications";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons/faFileExcel";
import { AxiosResponse } from "axios";
import { useAxios } from "auth/SecureAxios";
import { useAppConfig } from "util/AppConfig";
import { DateTime } from "luxon";
import fileDownload from "js-file-download";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  prNumbers?: string[];
  isEnded?: boolean;
}

export const ExemptionExportButton: React.FunctionComponent<Props> = (props) => {
  const appConfig = useAppConfig();
  const { fileDownloadAxios } = useAxios();
  const [isExporting, setIsExporting] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const notifications = useNotifications();

  const handleApprove = async () => {
    setIsExporting(true);
    setConfirmModalOpen(false);
    const controller = new AbortController();
    const downloadPromise = fileDownloadAxios.post(
      `${appConfig.apiEndpoint}/api/export-excel/exemption`,
      { prNumbers: props.prNumbers, isEnded: props.isEnded },
      {
        headers: {
          "Content-Type": "application/json"
        },
        signal: controller.signal
      }
    );
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        controller.abort();
        reject(new Error("Request timed out"));
      }, 60 * 2 * 1000); // Set the timeout to 2 minutes
    });
    try {
      const result = (await Promise.race([downloadPromise, timeoutPromise])) as AxiosResponse<
        string | ArrayBuffer | ArrayBufferView | Blob
      >;
      fileDownload(result.data, `Exemption Export - ${DateTime.now().toFormat("yyyy-MM-dd")}.xlsx`);
      setIsExporting(false);
      notifications.info("Downloaded successfully.");
    } catch (error) {
      notifications.error("Download failed. Please try again.");
      setIsExporting(false);
    }
  };

  return (
    <>
      <LoadingButton
        variant="text"
        sx={{ textTransform: "none" }}
        onClick={() => setConfirmModalOpen(true)}
        loading={isExporting}
        loadingPosition="start"
        startIcon={<FontAwesomeIcon icon={faFileExcel} />}>
        Export to Excel
      </LoadingButton>
      <ConfirmationDialog
        open={confirmModalOpen}
        body={<DialogContentText>Do you want to export these Exemptions?</DialogContentText>}
        title="Export Exemptions"
        noDanger
        cancel={() => setConfirmModalOpen(false)}
        confirm={handleApprove}
      />
    </>
  );
};
