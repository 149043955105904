import React, { useEffect, useState } from "react";
import { PRBaseStatusCode, PracticeReview } from "practice-reviews";
import { Stack, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { datagridStyles, staticDataStyles, tableStyles } from "styles/common";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { GridCellParams } from "@mui/x-data-grid-pro";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...tableStyles(theme),
  ...datagridStyles(theme),
  root: {},
  refreshContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto"
  },
  refreshButton: {
    marginLeft: theme.spacing(2)
  },
  labeledField: {
    marginBottom: theme.spacing(2)
  },
  practiceReviewContainer: {
    backgroundColor: theme.palette.grey[50]
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  actionContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end"
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

export const FirmDetails: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  const firm = props.practiceReview.firm;

  return (
    <div className={classes.root}>
      <Stack spacing={5}>
        <Stack direction="row" alignItems="flex-start" spacing={20}>
          <Stack>
            <div className={classes.labeledField}>
              <Typography variant="body1" className={classes.label}>
                Address
              </Typography>
              <Typography variant="body1">
                {firm.address1}
                <br />
                {firm.address2 && (
                  <>
                    {firm.address2}
                    <br />
                  </>
                )}
                {firm.city}
                {firm.province && `, ${firm.province}`}
                <br />
                {firm.country && `${firm.country} `}
                {firm.postalCode}
              </Typography>
            </div>
            {firm.phoneNumber && (
              <div className={classes.labeledField}>
                <Typography variant="body1" className={classes.label}>
                  Phone
                </Typography>
                <Typography variant="body1">{firm.phoneNumber}</Typography>
              </div>
            )}
            {firm.faxNumber && (
              <div className={classes.labeledField}>
                <Typography variant="body1" className={classes.label}>
                  Fax
                </Typography>
                <Typography variant="body1">{firm.faxNumber}</Typography>
              </div>
            )}
          </Stack>

          <Stack>
            <div className={classes.labeledField}>
              <Typography variant="body1" className={classes.label}>
                Practice Locale
              </Typography>
              <Typography variant="body1">{firm.practiceLocale || "--"}</Typography>
            </div>
            <div className={classes.labeledField}>
              <Typography variant="body1" className={classes.label}>
                Zone
              </Typography>
              <Typography variant="body1">{firm.zone || "--"}</Typography>
            </div>
            <div className={classes.labeledField}>
              <Typography variant="body1" className={classes.label}>
                Type
              </Typography>
              <Typography variant="body1">{firm.entityType?.typeShortName ?? "Unknown"}</Typography>
            </div>
            <div className={classes.labeledField}>
              <Typography variant="body1" className={classes.label}>
                CPAB Status
              </Typography>
              <Typography variant="body1">{firm.isCpab ? "Yes" : "No"}</Typography>
            </div>
          </Stack>

          <Stack spacing={2}>
            <StackedStaticDataDisplay
              label="Designated Partner"
              value={
                firm.designatedPartnerFirstName && firm.designatedPartnerLastName
                  ? `${firm.designatedPartnerFirstName} ${firm.designatedPartnerLastName}`
                  : "--"
              }
            />
            <StackedStaticDataDisplay label="Designated Partner Title" value={firm.designatedPartnerTitle ?? "--"} />
            <StackedStaticDataDisplay label="Designated Partner Designation" value={firm.designatedPartnerDesignation ?? "--"} />
          </Stack>

          <Stack spacing={2}>
            <StackedStaticDataDisplay
              label="HO Designated Partner"
              value={
                firm.hoDesignatedPartnerFirstName && firm.hoDesignatedPartnerLastName
                  ? `${firm.hoDesignatedPartnerFirstName} ${firm.hoDesignatedPartnerLastName}`
                  : "--"
              }
            />
            <StackedStaticDataDisplay label="HO Designated Partner Title" value={firm.hoDesignatedPartnerTitle ?? "--"} />
            <StackedStaticDataDisplay label="HO Designated Partner Designation" value={firm.hoDesignatedPartnerDesignation ?? "--"} />
            <StackedStaticDataDisplay
              label="HO Designated Partner Email"
              htmlValue
              value={
                firm.hoDesignatedPartnerEmail
                  ? `<a href="mailto:${firm.hoDesignatedPartnerEmail}">${firm.hoDesignatedPartnerEmail}</a>`
                  : "--"
              }
            />
          </Stack>
        </Stack>

        <Stack>
          {firm.satelliteOffices.length > 0 ? (
            <DataGridWithHeader
              title="Satellite Offices"
              deemphasizeHeader
              displayWithoutContainer
              rows={_.orderBy(firm.satelliteOffices, (fso) => fso.entityNumber)}
              columns={[
                {
                  field: "entityNumber",
                  headerName: "Entity No.",
                  headerClassName: classes.wrapHeader,
                  type: "string",
                  width: 80,
                  cellClassName: classes.number
                },
                {
                  field: "name",
                  headerName: "Name",
                  type: "string",
                  flex: 1
                },
                {
                  field: "address",
                  headerName: "Address",
                  type: "string",
                  width: 200
                },
                {
                  field: "city",
                  headerName: "City",
                  type: "string",
                  width: 160
                },
                {
                  field: "practiceReviewContactName",
                  headerName: "Contact Name",
                  type: "string",
                  width: 160
                },
                {
                  field: "practiceReviewContactPhone",
                  headerName: "Contact Phone",
                  type: "string",
                  width: 130,
                  cellClassName: classes.number
                },
                {
                  field: "practiceReviewContactEmail",
                  headerName: "Contact Email",
                  type: "string",
                  width: 200,
                  renderCell: (params: GridCellParams) => (
                    <a href={`mailto:${params.row.practiceReviewContactEmail}`}>{params.row.practiceReviewContactEmail}</a>
                  )
                },
                {
                  field: "includeWithMainOffice",
                  headerName: "Include with Main",
                  headerClassName: cx(classes.wrapHeader, classes.lastColumnHeader),
                  type: "boolean",
                  width: 80
                }
              ]}
            />
          ) : (
            <>
              <Typography variant="h3">Satellite Offices</Typography>
              <Typography variant="body1">None.</Typography>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
