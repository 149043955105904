import { gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox, TextField } from "@mui/material";

import React from "react";
import { ScreenHeader } from "../common/ScreenHeader";
import CrudTable from "../common/CrudTable";
import { PdCourse, QueryOptions } from "./models";
import { debounce } from "lodash";
import { makeStyles } from "../makeStyles";
import { GridCellParams } from "@mui/x-data-grid-pro";

export const FetchPdCoursesQuery = gql`
  query FetchPdCourses($query: String, $after: Int, $count: Int, $sortOn: String, $sortDescending: Boolean) {
    pdCourses(query: $query, after: $after, count: $count, sortOn: $sortOn, sortDescending: $sortDescending) {
      id
      name
      shortName
      productCode
      isActive
      topicName
      topicShortName
    }
  }
`;

const UpdatePdCourseMutation = gql`
  mutation UpdatePdCourse($pdCourse: PdCourseInput) {
    pdCourses {
      update(pdCourse: $pdCourse) {
        id
        name
        shortName
        productCode
        isActive
        topicName
        topicShortName
      }
    }
  }
`;

type EditablePdCourse = Pick<PdCourse, "id" | "isActive">;

const useStyles = makeStyles()(() => ({
  forceScroll: {
    minHeight: "101vh"
  },
  searchBar: {
    width: "30em"
  }
}));

const pageSize = 60;

const PdCoursesScreen: React.FunctionComponent = (props) => {
  const { classes } = useStyles();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setQueryOptions({
      ...queryOptions,
      after: (newPage - 1) * pageSize
    });
  };

  const [page, setPage] = React.useState<number>(1);
  const [querySearchBarValue, setQuerySearchBarValue] = React.useState<string>("");
  const [queryOptions, setQueryOptions] = React.useState<QueryOptions>({
    query: "",
    after: 0,
    count: pageSize,
    sortOn: "",
    sortDescending: false
  });

  const debouncedSetQuery = debounce((value) => {
    onPageChange(1);
    setQueryOptions({ ...queryOptions, query: value, after: 0 });
  }, 500);

  const updateQuery = (newQuery: string) => {
    setQuerySearchBarValue(newQuery);
    debouncedSetQuery(newQuery);
  };

  const queryData = useQuery<{ pdCourses: PdCourse[] }>(FetchPdCoursesQuery, {
    variables: queryOptions
  });

  const [updateMutation] = useMutation<
    {
      pdCourses: { update: PdCourse };
    },
    { pdCourse: EditablePdCourse }
  >(UpdatePdCourseMutation);

  const onCheckboxChanged = (event: React.ChangeEvent<HTMLElement>, value: boolean, params: GridCellParams) => {
    updateMutation({
      variables: {
        pdCourse: {
          id: params.row.id,
          isActive: value
        }
      },
      optimisticResponse: {
        pdCourses: {
          update: {
            ...(params.row as PdCourse),
            isActive: value
          }
        }
      }
    });
  };

  return (
    <div className={classes.forceScroll}>
      <ScreenHeader title="PD Courses" />
      <CrudTable
        columnDefinitions={[
          {
            field: "name",
            headerName: "Name",
            flex: 40,
            sortable: true
          },
          {
            field: "productCode",
            headerName: "Product Code",
            flex: 10,
            sortable: true
          },
          {
            field: "shortName",
            headerName: "Short Name",
            flex: 30,
            sortable: true
          },
          {
            field: "topicName",
            headerName: "Topic Name",
            flex: 20,
            sortable: true
          },
          {
            field: "topicShortName",
            headerName: "Topic Short Name",
            flex: 15,
            sortable: true
          },
          {
            field: "isActive",
            headerName: "Active",
            renderCell: (params) => <Checkbox checked={params.value as boolean} onChange={(e, v) => onCheckboxChanged(e, v, params)} />,
            flex: 8,
            sortable: true
          }
        ]}
        rows={!queryData.loading ? queryData.data?.pdCourses! : []}
        loading={queryData.loading}
        storageKey="PD Courses"
        sortModel={
          queryOptions.sortOn
            ? [
                {
                  field: queryOptions.sortOn,
                  sort: queryOptions.sortDescending ? "desc" : "asc"
                }
              ]
            : []
        }
        onSortModelChange={(newSortModel) => {
          onPageChange(1);

          if (newSortModel.length === 0 || !newSortModel[0].sort) {
            setQueryOptions({
              ...queryOptions,
              after: 0,
              sortOn: "",
              sortDescending: false
            });

            return;
          }

          setQueryOptions({
            ...queryOptions,
            after: 0,
            sortOn: newSortModel[0].field,
            sortDescending: newSortModel[0].sort === "desc"
          });
        }}
        headerActions={
          <div className={classes.searchBar}>
            <TextField label="Search" value={querySearchBarValue} onChange={(e) => updateQuery(e.target.value)} fullWidth margin="none" />
          </div>
        }
        showPagination={true}
        onPageChange={onPageChange}
        currentPage={page}
        pageSize={pageSize}
      />
    </div>
  );
};

export default PdCoursesScreen;
