import React, { useEffect, useState } from "react";
import { DialogContentText, TextField } from "@mui/material";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";

interface Props {
  open: boolean;
  saving: boolean;
  practiceReviewId: number;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

export const ReturnIncompleteDialog: React.FunctionComponent<Props> = (props) => {
  const [incompleteReason, setIncompleteReason] = useState("");

  useEffect(() => setIncompleteReason(""), [props.open]);

  return (
    <ConfirmationDialog
      open={props.open}
      noDanger
      title="Return incomplete review?"
      body={
        <>
          <DialogContentText>Do you want to return the review to CPAA incomplete? If so, enter the reason below.</DialogContentText>
          <TextField label="Reason" value={incompleteReason} onChange={(e) => setIncompleteReason(e.target.value)} multiline fullWidth />
        </>
      }
      confirm={() => props.onConfirm(incompleteReason)}
      confirmButtonDisabled={incompleteReason.trim() === ""}
      cancel={props.onClose}
      loading={props.saving}
    />
  );
};
