import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SecureRoute } from "auth/SecureRoute";
import { AuthCompletedScreen } from "auth/AuthCompletedScreen";
import { InaScreen } from "inas/InaScreen";
import { PracticeReviewScreen } from "practice-reviews/PracticeReviewScreen";
import { UpcomingReviewNoticesScreen } from "scheduling/UpcomingReviewNoticesScreen";
import { ImportPRs } from "practice-reviews/ImportPrs";
import StatHolidaysScreen from "stat-holidays/StatHolidaysScreen";
import SystemEstimatedTimeRulesScreen from "./system-time-estimates/SystemEstimatedTimeRulesScreen";
import CustomReportsScreen from "custom-reports/CustomReportsScreen";
import TimedEventMarginsScreen from "timed-event-margins/TimedEventMarginsScreen";
import AdjustmentQuestionTemplatesScreen from "adjustment-question-templates/AdjustmentQuestionTemplatesScreen";
import EmailTemplatesScreen from "./email-templates/EmailTemplatesScreen";
import SignOffChecklistItemTemplatesScreen from "sign-off-checklist-items/SignOffChecklistItemTemplatesScreen";
import TimeConversionFormulasScreen from "time-conversion-formulas/TimeConversionFormulasScreen";
import RetentionPoliciesScreen from "retention-policies/RetentionPoliciesScreen";
import PdCoursesScreen from "./pd-courses/PdCoursesScreen";
import WorkflowInasScreen from "workflow-inas/WorkflowInasScreen";
import EngagementTypeTemplateScreen from "./engagement-type-templates/EngagementTypeTemplatesScreen";
import AdvancedPrSearchScreen from "./practice-reviews/AdvancedPrSearchScreen";
import { MasterChecklistScreen } from "master-checklist/MasterChecklistScreen";
import PersonalActivityLogScreen from "personal-activity-log/PersonalActivityLogScreen";
import CommitteeMeetingsScreen from "committee-meetings/CommitteeMeetingsScreen";
import CommitteeMeetingScreen from "committee-meetings/CommitteeMeetingScreen";
import CommitteeMembersScreen from "committee-members/CommitteeMembersScreen";
import AppRolesScreen from "app-roles/AppRolesScreen";
import { Permissions } from "users";
import MotionsScreen from "./motions/MotionsScreen";
import CommitteeDecisionsScreen from "./committee-meetings/CommitteeDecisionsScreen";
import ManageUsersScreen from "users/ManageUsersScreen";
import ExemptionLettersScreen from "exemptions/ExemptionLettersScreen";
import ActiveExemptionsScreen from "exemptions/ActiveExemptionsScreen";
import DecisionAdditionalParagraphsScreen from "decisions/DecisionAdditionalParagraphsScreen";
import DocumentTemplateSandboxScreen from "document-template-sandbox/DocumentTemplateSandboxScreen";
import PprpProgramsScreen from "pprp/PprpProgramsScreen";
import PprpProgramTasksScreen from "pprp/PprpProgramTasksScreen";

export enum AppRoute {
  INAs = "/",
  PracticeReview = "/practice-review/:prNumber/:tab?",
  PracticeReviewClientChecklists = "/practice-review/:prNumber/client-checklists/:reviewedClientId?",
  PracticeReviewCandidateChecklists = "/practice-review/:prNumber/candidate-checklists/:reviewedClientId?",
  PracticeReviewDecision = "/practice-review/:prNumber/decision/:decisionTab?",
  GenerateUpcomingReviewNotices = "/generate-upcoming-review-notices",
  ApproveUpcomingReviewNotices = "/approve-upcoming-review-notices",
  GenerateUpcomingPprpReviewNotices = "/generate-upcoming-pprp-review-notices",
  ApproveUpcomingPprpReviewNotices = "/approve-upcoming-pprp-review-notices",
  CommitteeMeetings = "/committee-meetings",
  CommitteeMeeting = "/committee-meetings/:id",
  CommitteeMembers = "/committee-members",
  PprpCommitteeMembers = "/pprp-committee-members",
  AppRoles = "/app-roles",
  ImportPrs = "/import-prs",
  StatHolidays = "/stat-holidays",
  CustomReports = "/custom-reports",
  TimedEventMargins = "/timed-event-margins",
  Users = "/users",
  AdjustmentQuestionTemplates = "/adjustment-question-templates",
  EmailTemplates = "/email-templates",
  SignOffChecklistItems = "/sign-off-checklist-items",
  TimeConversionFormulas = "/time-conversion-formulas",
  RetentionPolicy = "/retention-policies",
  RetentionPolicies = "/retention-policies",
  PdCourses = "/pd-courses",
  Motions = "/motions",
  DecisionAdditionalParagraphs = "/additional-paragraphs-for-decisions",
  WorkflowINAs = "/workflow-inas",
  ThemeTests = "/theme-tests",
  SystemEstimatedTimeRules = "/system-estimated-time-rules",
  AuthCompleted = "/authCompleted",
  EngagementTypeTemplates = "/engagement-type-templates",
  AdvancedPrSearch = "/advanced-pr-search",
  MasterChecklist = "/master-checklist",
  MyActivityLog = "/my-activity-log",
  EnterCommitteeDecisions = "/enter-committee-decisions",
  ReviewCommitteeDecisions = "/review-committee-decisions",
  EnterPprpCommitteeDecisions = "/enter-pprp-committee-decisions",
  ReviewPprpCommitteeDecisions = "/review-pprp-committee-decisions",
  ReviewExemptionLetters = "/review-exemption-letters",
  ApproveExemptionLetters = "/approve-exemption-letters",
  ReviewPprpExemptionLetters = "/review-pprp-exemption-letters",
  ApprovePprpExemptionLetters = "/approve-pprp-exemption-letters",
  Exemptions = "/active-exemptions",
  DocumentTemplateSandbox = "/document-template-sandbox",
  PprpPrograms = "/programs",
  PprpProgramTasks = "/program-tasks"
}

export const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path={AppRoute.AuthCompleted} component={AuthCompletedScreen} />
      <SecureRoute exact path={AppRoute.INAs} component={InaScreen} />
      <SecureRoute
        exact
        path={[
          AppRoute.PracticeReviewClientChecklists,
          AppRoute.PracticeReviewCandidateChecklists,
          AppRoute.PracticeReviewDecision,
          AppRoute.PracticeReview
        ]}
        component={PracticeReviewScreen}
      />
      <SecureRoute exact path={AppRoute.AdvancedPrSearch} component={AdvancedPrSearchScreen} />
      <SecureRoute exact path={AppRoute.MyActivityLog} component={PersonalActivityLogScreen} />

      <SecureRoute
        exact
        path={AppRoute.ReviewExemptionLetters}
        component={() => <ExemptionLettersScreen mode="review" />}
        permission={Permissions.ExemptionReviewLetter}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveExemptionLetters}
        component={() => <ExemptionLettersScreen mode="approve" />}
        permission={Permissions.ExemptionConfirm}
      />

      <SecureRoute
        exact
        path={AppRoute.GenerateUpcomingReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="generate" />}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveUpcomingReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="approve" />}
        permission={Permissions.AdminTasks}
      />

      <SecureRoute exact path={AppRoute.CommitteeMeetings} component={CommitteeMeetingsScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.CommitteeMeeting} component={CommitteeMeetingScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.EnterCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="enter-committee-decisions" />}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute
        exact
        path={AppRoute.ReviewCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="review-final-reports" />}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.CommitteeMembers} component={CommitteeMembersScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.AppRoles} component={AppRolesScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.ImportPrs} component={ImportPRs} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.StatHolidays} component={StatHolidaysScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.SystemEstimatedTimeRules}
        component={SystemEstimatedTimeRulesScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.CustomReports} component={CustomReportsScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.TimedEventMargins} component={TimedEventMarginsScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.AdjustmentQuestionTemplates}
        component={AdjustmentQuestionTemplatesScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.EmailTemplates} component={EmailTemplatesScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.SignOffChecklistItems}
        component={SignOffChecklistItemTemplatesScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute
        exact
        path={AppRoute.TimeConversionFormulas}
        component={TimeConversionFormulasScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.RetentionPolicies} component={RetentionPoliciesScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.PdCourses} component={PdCoursesScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.Motions} component={MotionsScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.DecisionAdditionalParagraphs}
        component={DecisionAdditionalParagraphsScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.WorkflowINAs} component={WorkflowInasScreen} permission={Permissions.AdminTasks} />
      <SecureRoute
        exact
        path={AppRoute.EngagementTypeTemplates}
        component={EngagementTypeTemplateScreen}
        permission={Permissions.AdminTasks}
      />
      <SecureRoute exact path={AppRoute.MasterChecklist} component={MasterChecklistScreen} permission={Permissions.AdminTasks} />
      <SecureRoute exact path={AppRoute.Users} component={ManageUsersScreen} permission={Permissions.AdminTasks} />

      <SecureRoute exact path={AppRoute.Exemptions} component={ActiveExemptionsScreen} permission={Permissions.AdminTasks} />

      <SecureRoute
        exact
        path={AppRoute.GenerateUpcomingPprpReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="generate" pprp />}
        permission={Permissions.AdminTasksPprp}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveUpcomingPprpReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="approve" pprp />}
        permission={Permissions.AdminTasksPprp}
      />
      <SecureRoute
        exact
        path={AppRoute.ReviewPprpExemptionLetters}
        component={() => <ExemptionLettersScreen mode="review" pprp />}
        permission={Permissions.ExemptionReviewLetter}
      />
      <SecureRoute
        exact
        path={AppRoute.ApprovePprpExemptionLetters}
        component={() => <ExemptionLettersScreen mode="approve" pprp />}
        permission={Permissions.ExemptionConfirm}
      />
      <SecureRoute
        exact
        path={AppRoute.EnterPprpCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="enter-committee-decisions" pprp />}
        permission={Permissions.AdminTasksPprp}
      />
      <SecureRoute
        exact
        path={AppRoute.ReviewPprpCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="review-final-reports" pprp />}
        permission={Permissions.AdminTasksPprp}
      />
      <SecureRoute
        exact
        path={AppRoute.PprpCommitteeMembers}
        component={() => <CommitteeMembersScreen pprp />}
        permission={Permissions.AdminTasksPprp}
      />
      <SecureRoute exact path={AppRoute.PprpPrograms} component={PprpProgramsScreen} permission={Permissions.AdminTasksPprp} />
      <SecureRoute exact path={AppRoute.PprpProgramTasks} component={PprpProgramTasksScreen} permission={Permissions.AdminTasksPprp} />

      <SecureRoute
        exact
        path={AppRoute.DocumentTemplateSandbox}
        component={DocumentTemplateSandboxScreen}
        permission={Permissions.DocumentTemplateSandboxAllow}
      />

      <Redirect to={AppRoute.INAs} />
    </Switch>
  );
};
