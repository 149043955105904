import React, { useEffect, useState } from "react";
import { DialogContentText, TextField } from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";

interface Props {
  open: boolean;
  saving: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

export const ReturnToReviewerDialog: React.FunctionComponent<Props> = (props) => {
  const [returnReason, setReturnReason] = useState("");

  useEffect(() => setReturnReason(""), [props.open]);

  return (
    <ConfirmationDialog
      open={props.open}
      noDanger
      title="Return review to reviewer?"
      body={
        <>
          <DialogContentText>Do you want to return the review to the lead reviewer? If so, enter the reason below.</DialogContentText>
          <TextField label="Reason" value={returnReason} onChange={(e) => setReturnReason(e.target.value)} multiline fullWidth />
        </>
      }
      confirm={() => props.onConfirm(returnReason)}
      confirmButtonDisabled={returnReason.trim() === ""}
      cancel={props.onClose}
      loading={props.saving}
    />
  );
};
