import React, { useState } from "react";
import { PracticeReview, PRBaseStatusCode, Reviewer, SubmitReviewedClientsMutation } from "practice-reviews";
import { Alert, Box, Button, DialogContentText, Stack, Tooltip, Typography } from "@mui/material";

import ReadOnlyNotes from "chrome/ReadOnlyNotes";
import { ReviewerTable } from "./ReviewerTable";
import _ from "lodash";
import { useCurrentUser } from "users";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useMutation } from "@apollo/client";
import { useNotifications } from "../../notifications";
import { ResubmitReviewedClientsDialog } from "./ResubmitReviewedClientsDialog";
import { PrScreenQuery, ReturnReviewMutation, ReturnReviewToReviewerMutation } from "../queries";
import { DecisionTypeCode } from "../../decisions";
import { GetInasForUserQuery } from "../../inas";
import { ReturnIncompleteDialog } from "./ReturnIncompleteDialog";
import { useHistory } from "react-router-dom";
import { AppRoute } from "../../Routes";
import { makeStyles } from "../../makeStyles";
import { FirmChecklistComplete } from "./FirmChecklistComplete";
import { DecisionSummary } from "./DecisionSummary";
import { TimeEntryPreview } from "./TimeEntryPreview";
import { SummaryNotes } from "./SummaryNotes";
import { QuestionnaireSummary } from "./QuestionnaireSummary";
import { SummaryTabHeader } from "./SummaryTabHeader";
import { summaryChecklistStyles } from "../../styles/common";
import { YesNoIcon } from "../../common/YesNoIcon";
import { DeficiencyReportLinkAndActions } from "../report-links/DeficiencyReportLinkAndActions";
import StackedStaticDataDisplay from "../../common/StackedStaticDataDisplay";
import { ReturnToReviewerDialog } from "./ReturnToReviewerDialog";

interface Props {
  practiceReview: PracticeReview;
}

export const SummaryTab: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...summaryChecklistStyles(theme),
    root: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(3)
      }
    }
  }));

  const { classes, theme } = useStyles();
  const notifications = useNotifications();
  const { user, userIsLeadReviewer, userIsOtherReviewer } = useCurrentUser();
  const history = useHistory();

  const pr = props.practiceReview;

  const [confirmingReturnComplete, setConfirmingReturnComplete] = useState(false);
  const [confirmingReturnIncomplete, setConfirmingReturnIncomplete] = useState(false);
  const [confirmingSubmitReviewedClients, setConfirmingSubmitReviewedClients] = useState(false);
  const [confirmingUnsubmitReviewedClients, setConfirmingUnsubmitReviewedClients] = useState(false);
  const [confirmingResubmitReviewedClients, setConfirmingResubmitReviewedClients] = useState(false);
  const [confirmingReturnToReviewer, setConfirmingReturnToReviewer] = useState(false);

  const [returnMutate, returnMutation] = useMutation<
    {
      practiceReview: {
        return: Partial<PracticeReview>;
      };
    },
    {
      practiceReviewId: number;
      complete: boolean;
      reasonReturnedIncomplete?: string;
    }
  >(ReturnReviewMutation, {
    refetchQueries: [{ query: GetInasForUserQuery }]
  });

  const [returnToReviewerMutate, returnToReviewerMutation] = useMutation<
    {
      practiceReview: {
        returnToReviewer: Partial<PracticeReview>;
      };
    },
    {
      practiceReviewId: number;
      reasonForChange: string;
    }
  >(ReturnReviewToReviewerMutation, {
    refetchQueries: [{ query: PrScreenQuery, variables: { prNumber: pr.prNumber } }, { query: GetInasForUserQuery }]
  });

  const [submitReviewedClientsMutate, submitReviewedClientsMutation] = useMutation<
    {
      reviewer: {
        submitReviewedClients: Partial<Reviewer>;
      };
    },
    {
      practiceReviewId: number;
      userId: number;
      submitted: boolean;
    }
  >(SubmitReviewedClientsMutation, { refetchQueries: [{ query: PrScreenQuery, variables: { prNumber: pr.prNumber } }] });

  const reviewers: Reviewer[] = (pr.leadReviewer ? [pr.leadReviewer] : [])
    .concat(_.orderBy(pr.otherReviewers, (r) => r.user.name))
    .map((r) => ({ ...r, clients: pr.reviewedClients.filter((rc) => rc.reviewedByUserId === r.userId) }));
  const reviewerForUser = reviewers.filter((r) => r.userId === user.id)[0];

  const reviewedClientsForUser = pr.reviewedClients.filter((rc) => rc.reviewedByUserId === user.id);
  const userHasCompletedAllReviewedClients = reviewedClientsForUser.every((rc) => !rc.checklistCompletion.someQuestionsIncomplete);

  const userReviewedClientsAreSubmitted = pr.otherReviewers.find((r) => r.userId === user.id)?.reviewedClientsSubmitted;
  const userReviewedClientsHaveBeenUnsubmitted = pr.otherReviewers.find((r) => r.userId === user.id)?.reviewedClientsHaveBeenUnsubmitted;

  const userCanReturnReview = !pr.hasBeenReturned && userIsLeadReviewer(pr);

  const reviewedIfRequiredAndUploaded = (required: boolean, uploadedUrl: string | null, reviewed: boolean) => {
    return !required || !uploadedUrl || uploadedUrl.length === 0 || reviewed;
  };

  const allRelevantQuestionnairesReviewed =
    reviewedIfRequiredAndUploaded(
      pr.requiresSchedulingQuestionnaire,
      pr.schedulingQuestionnaireUrl,
      pr.isSchedulingQuestionnaireReviewedByLead
    ) &&
    reviewedIfRequiredAndUploaded(pr.requiresTaxQuestionnaire, pr.taxQuestionnaireUrl, pr.isTaxQuestionnaireReviewedByLead) &&
    reviewedIfRequiredAndUploaded(
      pr.requiresSelfEvaluationQuestionnaire,
      pr.selfEvaluationQuestionnaireUrl,
      pr.isSelfEvaluationQuestionnaireReviewedByLead
    );

  const userCanReturnReviewComplete =
    userCanReturnReview &&
    allRelevantQuestionnairesReviewed &&
    // Firm checklist complete
    !pr.firmChecklistCompletion.someQuestionsIncomplete &&
    // No incomplete client checklists
    pr.reviewedClients.every((rc) => !rc.checklistCompletion.someQuestionsIncomplete) &&
    // All non-lead reviewers have submitted their files (or have none)
    reviewers.every((r) => r.isLead || r.reviewedClientsSubmitted || r.clients.length === 0) &&
    // There's at least one entered time
    pr.timeEntries.length > 0 &&
    // Reviewer decision is signed off
    pr.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer)?.[0]?.signedOff &&
    // Consolidated deficiency report exists
    pr.deficiencyReportUrl !== null &&
    // PR report exists
    pr.practiceReviewReportUrl !== null;

  const reviewedClientDescriptor = pr.isPprpReview ? "candidate" : "client";
  const capitalizedReviewedClientDescriptor = pr.isPprpReview ? "Candidate" : "Client";

  return (
    <div className={classes.root}>
      <Stack direction="row" spacing={10}>
        <Stack spacing={5} sx={{ flexGrow: 1 }}>
          <QuestionnaireSummary practiceReview={pr} />

          {(userIsLeadReviewer(pr) || !reviewerForUser) && <FirmChecklistComplete practiceReview={pr} />}

          <div>
            <SummaryTabHeader header={`${capitalizedReviewedClientDescriptor} Details and Checklists`} />
            {userIsLeadReviewer(pr) || !reviewerForUser ? (
              <ReviewerTable reviewers={reviewers} />
            ) : (
              <Stack direction="row" spacing={5} alignItems="flex-start">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box sx={{ ml: 0.5 }}>
                    <YesNoIcon yes={reviewerForUser.reviewedClientsSubmitted} />
                  </Box>
                  <Box sx={{ whiteSpace: "nowrap", color: reviewerForUser.clients.length === 0 ? theme.palette.warning.main : undefined }}>
                    {reviewerForUser.clients.length === 0
                      ? "No files"
                      : `${reviewerForUser.clients.filter((client) => !client.checklistCompletion.someQuestionsIncomplete).length} of ${
                          reviewerForUser.clients.length
                        }`}
                  </Box>
                  {!pr.hasBeenReturned && (
                    <span>
                      {!userReviewedClientsAreSubmitted && !userReviewedClientsHaveBeenUnsubmitted ? (
                        <Tooltip
                          title={
                            !userHasCompletedAllReviewedClients ? `Complete all ${reviewedClientDescriptor} checklists.` : "Submit to Lead"
                          }>
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => setConfirmingSubmitReviewedClients(true)}
                              disabled={!userHasCompletedAllReviewedClients}>
                              Submit
                            </Button>
                          </span>
                        </Tooltip>
                      ) : !userReviewedClientsAreSubmitted && userReviewedClientsHaveBeenUnsubmitted ? (
                        <Tooltip
                          title={
                            !userHasCompletedAllReviewedClients
                              ? `Complete all ${reviewedClientDescriptor} checklists.`
                              : "Resubmit to Lead"
                          }>
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => setConfirmingResubmitReviewedClients(true)}
                              disabled={!userHasCompletedAllReviewedClients}>
                              Resubmit
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Withdraw My Submission">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => setConfirmingUnsubmitReviewedClients(true)}>
                            Withdraw
                          </Button>
                        </Tooltip>
                      )}
                    </span>
                  )}
                </Stack>
                {reviewerForUser.resubmissionNotes && (
                  <StackedStaticDataDisplay label="Resubmission Notes" value={reviewerForUser.resubmissionNotes} />
                )}
              </Stack>
            )}
          </div>

          {userIsLeadReviewer(pr) || !reviewerForUser ? (
            <DecisionSummary practiceReview={pr} userIsReviewerOnPr={Boolean(reviewerForUser)} />
          ) : (
            <div>
              <SummaryTabHeader header="Reports" />
              <div className={classes.checklist}>
                <div className={classes.checklistRow}>
                  <div className={classes.checklistItemState}>
                    <YesNoIcon yes={pr.deficiencyReportUrl !== null} />
                  </div>
                  <DeficiencyReportLinkAndActions
                    practiceReview={pr}
                    buttonSize="small"
                    documentClass={classes.checklistItem}
                    actionClass={classes.checklistItemAction}
                    userCanPostToPortal={false}
                  />
                </div>
              </div>
            </div>
          )}

          <TimeEntryPreview practiceReview={pr} userIsNonLeadReviewer={!userIsLeadReviewer(pr) && Boolean(reviewerForUser)} />

          <div>
            <SummaryTabHeader header="Return" />
            <div>
              {userCanReturnReviewComplete && (
                <Button variant="contained" color="primary" onClick={() => setConfirmingReturnComplete(true)}>
                  Return Complete
                </Button>
              )}
              {userCanReturnReview && !userCanReturnReviewComplete && (
                <Button variant="outlined" color="primary" onClick={() => setConfirmingReturnIncomplete(true)}>
                  Return Incomplete
                </Button>
              )}
              {!userCanReturnReview && !pr.hasBeenReturned && (
                <Typography variant="body1">The review has not yet been returned.</Typography>
              )}
              {pr.hasBeenReturned && (
                <div>
                  <Typography variant="body1">
                    <strong>
                      {`Returned ${pr.isReturnedComplete ? "complete" : "incomplete"} by ${pr.leadReviewer?.user.name ?? "unknown"}`}
                    </strong>
                  </Typography>
                  {pr.status.baseStatusCode === PRBaseStatusCode.InProgress && (
                    <Button variant="outlined" color="primary" onClick={() => setConfirmingReturnToReviewer(true)}>
                      Return to Reviewer
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Stack>

        <Stack spacing={5} sx={{ width: "33%" }}>
          <ReadOnlyNotes header="Notes from Estimate Time" value={pr.estimate.notes} plainText={false} height="18em" />

          {(userIsLeadReviewer(pr) || userIsOtherReviewer(pr)) && <SummaryNotes practiceReview={pr} />}
        </Stack>
      </Stack>

      <ConfirmationDialog
        open={confirmingReturnComplete}
        noDanger
        title="Return complete review?"
        body={
          <>
            {reviewers.some((r) => r.clients.length === 0) && (
              <Alert
                color="warning"
                sx={{ mb: theme.spacing(2) }}>{`Some reviewers do not have any ${reviewedClientDescriptor} files assigned: ${reviewers
                .filter((r) => r.clients.length === 0)
                .map((r) => r.user.name)
                .join(", ")}.`}</Alert>
            )}
            <DialogContentText>Do you want to return the review to CPAA?</DialogContentText>
          </>
        }
        confirm={async () => {
          await returnMutate({ variables: { practiceReviewId: pr.id, complete: true } });
          setConfirmingReturnComplete(false);
          notifications.success("Review returned.");
          history.push(AppRoute.INAs);
        }}
        cancel={() => setConfirmingReturnComplete(false)}
        loading={returnMutation.loading}
      />

      <ReturnIncompleteDialog
        open={confirmingReturnIncomplete}
        saving={returnMutation.loading}
        practiceReviewId={pr.id}
        onClose={() => setConfirmingReturnIncomplete(false)}
        onConfirm={async (reason: string) => {
          await returnMutate({
            variables: { practiceReviewId: pr.id, complete: false, reasonReturnedIncomplete: reason }
          });
          setConfirmingReturnIncomplete(false);
          notifications.success("Review returned.");
          history.push(AppRoute.INAs);
        }}
      />

      <ReturnToReviewerDialog
        open={confirmingReturnToReviewer}
        saving={returnToReviewerMutation.loading}
        onClose={() => setConfirmingReturnToReviewer(false)}
        onConfirm={async (reason: string) => {
          await returnToReviewerMutate({
            variables: { practiceReviewId: pr.id, reasonForChange: reason }
          });
          setConfirmingReturnToReviewer(false);
          notifications.success("Review returned to reviewer.");
          history.push(AppRoute.INAs);
        }}
      />

      <ConfirmationDialog
        open={confirmingSubmitReviewedClients}
        noDanger
        title={`Submit ${reviewedClientDescriptor} files?`}
        body={
          <>
            <DialogContentText>
              {`Submit your ${reviewedClientDescriptor} files when you expect no more changes to ${reviewedClientDescriptor} details or checklist answers, and your review content is ready to incorporate into deficiency and review reports.`}
            </DialogContentText>
            <DialogContentText>
              <strong>{`Do you want to submit your ${reviewedClientDescriptor} files to the lead reviewer?`}</strong>
            </DialogContentText>
          </>
        }
        confirm={async () => {
          await submitReviewedClientsMutate({ variables: { practiceReviewId: pr.id, userId: user.id, submitted: true } });
          setConfirmingSubmitReviewedClients(false);

          const clientFileCount = reviewers.find((r) => r.userId === user.id)?.clients.length ?? 0;
          notifications.success(`Submitted ${clientFileCount} file${clientFileCount !== 1 ? "s" : ""} to lead reviewer.`);
        }}
        cancel={() => setConfirmingSubmitReviewedClients(false)}
        loading={submitReviewedClientsMutation.loading}
      />

      <ConfirmationDialog
        open={confirmingUnsubmitReviewedClients}
        noDanger
        title={`Withdraw ${reviewedClientDescriptor} file submission?`}
        body={
          <>
            <DialogContentText>
              {`Withdraw your ${reviewedClientDescriptor} file submission if you need to make additional changes to your ${reviewedClientDescriptor} details or checklists.`}
            </DialogContentText>
            <DialogContentText>
              <strong>Do you want to withdraw your submission?</strong>
            </DialogContentText>
          </>
        }
        confirm={async () => {
          await submitReviewedClientsMutate({ variables: { practiceReviewId: pr.id, userId: user.id, submitted: false } });
          setConfirmingUnsubmitReviewedClients(false);
          notifications.success("Submission withdrawn.");
        }}
        cancel={() => setConfirmingUnsubmitReviewedClients(false)}
        loading={submitReviewedClientsMutation.loading}
      />

      {confirmingResubmitReviewedClients && (
        <ResubmitReviewedClientsDialog
          practiceReviewId={pr.id}
          userId={user.id}
          onClose={() => setConfirmingResubmitReviewedClients(false)}
          clientFilesCount={reviewers.find((r) => r.userId === user.id)?.clients.length ?? 0}
          reviewedClientDescriptor={reviewedClientDescriptor}
        />
      )}
    </div>
  );
};
