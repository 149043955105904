import React, { useState } from "react";
import { DialogContentText, TextField } from "@mui/material";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useMutation } from "@apollo/client";
import { Reviewer, SubmitReviewedClientsMutation } from "..";
import { useNotifications } from "../../notifications";

interface Props {
  practiceReviewId: number;
  userId: number;
  onClose: () => void;
  clientFilesCount: number;
  reviewedClientDescriptor: string;
}

export const ResubmitReviewedClientsDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();

  const [resubmissionNotes, setResubmissionNotes] = useState("");

  const [resubmitMutate, resubmitMutation] = useMutation<
    {
      reviewer: {
        submitReviewedClients: Partial<Reviewer>;
      };
    },
    {
      practiceReviewId: number;
      userId: number;
      submitted: boolean;
      resubmissionNotes: string;
    }
  >(SubmitReviewedClientsMutation);

  async function resubmit() {
    const response = await resubmitMutate({
      variables: { practiceReviewId: props.practiceReviewId, userId: props.userId, submitted: true, resubmissionNotes }
    });

    if (response.data?.reviewer.submitReviewedClients.userId) {
      notifications.success(`Resubmitted ${props.clientFilesCount} file${props.clientFilesCount !== 1 ? "s" : ""} to lead reviewer.`);
    }

    props.onClose();
  }

  return (
    <ConfirmationDialog
      open={true}
      noDanger
      title={`Resubmit ${props.reviewedClientDescriptor} files?`}
      body={
        <>
          <DialogContentText>
            {`Summarize the changes you made to your ${props.reviewedClientDescriptor} files and/or checklists since previously submitting.`}
          </DialogContentText>
          <TextField value={resubmissionNotes} onChange={(e) => setResubmissionNotes(e.target.value)} multiline fullWidth autoFocus />
        </>
      }
      confirm={() => resubmit()}
      confirmButtonText="Resubmit"
      confirmButtonDisabled={resubmissionNotes.trim() === ""}
      cancel={props.onClose}
      loading={resubmitMutation.loading}
    />
  );
};
