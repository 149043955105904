import React, { useRef, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { Formik, Form } from "formik";
import MonitoringHeaderStack from "./MonitoringHeaderStack";
import MonitoringCard from "./MonitoringCard";
import { LoadingButton } from "@mui/lab";
import { SaveMonitoringMutation } from "./queries";
import { useNotifications } from "notifications";
import { DocType, PracticeReview } from "practice-reviews";
import { MonitoringInput } from "./models";
import { makeStyles } from "makeStyles";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import RichTextEditor from "common/RichTextEditor";

const useStyles = makeStyles()({
  headerStack: {
    width: "75%",
    marginTop: "8px"
  }
});

interface Props {
  practiceReview: PracticeReview;
}

export const MonitoringTab: React.FC<Props> = ({ practiceReview }) => {
  const notifications = useNotifications();
  const { classes } = useStyles();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();
  const changeKey = MonitoringTab.name;

  const [saveMonitoring, { loading }] = useMutation(SaveMonitoringMutation, {
    onCompleted: () => {
      notifications.success("Monitoring has been saved.");
      changesSaved(changeKey);
    },
    onError: (error) => {
      notifications.error(error.message || "An error occurred.");
    }
  });

  const markUnsaved = () => {
    unsavedChanges(changeKey);
  };

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const currentMonitoringPeriod = practiceReview.monitorings?.filter((m) => !m.isCompleted)[0];
  const lastCompletedMonitoringDate = practiceReview.monitorings?.filter((m) => m.isCompleted).pop()?.completedDate;
  const monitoringLetters = useMemo(
    () =>
      Object.fromEntries(
        practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.PprpMonitoringCompleteLetter).map((ad) => [ad.id, ad])
      ),
    [practiceReview.attachedDocuments]
  );

  return (
    <Formik
      initialValues={{
        monitoringFrequencyInDays: practiceReview.monitoringFrequencyInDays,
        monitoringOccurrencesRemaining: practiceReview.monitoringOccurrencesRemaining,
        monitorings: practiceReview.monitorings ?? [],
        practiceReviewMonitoringNotes: practiceReview.monitoringNotes
      }}
      enableReinitialize
      onSubmit={(values) => {
        const monitoringToEdit = values.monitorings[values.monitorings.length - 1];
        saveMonitoring({
          variables: {
            practiceReviewId: practiceReview.id,
            monitoringInput: {
              monitoringFrequencyInDays: values.monitoringFrequencyInDays,
              monitoringOccurrencesRemaining: values.monitoringOccurrencesRemaining,
              id: monitoringToEdit?.id,
              isPass: monitoringToEdit?.isPass,
              sendToCommitteeOnFail: monitoringToEdit?.sendToCommitteeOnFail,
              monitoringNotes: monitoringToEdit?.monitoringNotes,
              practiceReviewMonitoringNotes: notesContentRetriever.current?.getContentAsHtml()
            } as MonitoringInput
          }
        });
      }}>
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Stack gap={4}>
            <MonitoringHeaderStack
              direction="row"
              justifyContent="start"
              alignItems="center"
              gap={4}
              monitoringFrequencyInDays={values.monitoringFrequencyInDays}
              monitoringOccurrencesRemaining={values.monitoringOccurrencesRemaining}
              currentMonitoringPeriodDate={currentMonitoringPeriod?.monitoringDate}
              createdDate={practiceReview.completedDate}
              monitoringCompleted={!!practiceReview.monitoringCompleted}
              monitoringCompletedOnDate={lastCompletedMonitoringDate}
              setFieldValue={setFieldValue}
              markUnsaved={markUnsaved}
              className={classes.headerStack}
            />
            <Stack gap={4}>
              {values.monitorings.map((monitoring, index) => (
                <MonitoringCard
                  key={monitoring.id}
                  index={index}
                  monitoring={monitoring}
                  setFieldValue={setFieldValue}
                  markUnsaved={markUnsaved}
                  onChange={handleChange}
                  monitoringCompletedLetter={
                    monitoring.monitoringCompletedLetterId && monitoringLetters.hasOwnProperty(monitoring.monitoringCompletedLetterId)
                      ? monitoringLetters[monitoring.monitoringCompletedLetterId]
                      : undefined
                  }
                  monitoringFailedWorkflowReadOnly={!!practiceReview.monitoringCompleted || index !== values.monitorings.length - 1}
                />
              ))}
            </Stack>
            <RichTextEditor
              label="Notes"
              minHeight="6em"
              html={values.practiceReviewMonitoringNotes}
              passContentRetriever={(getContentAsHtml) => {
                notesContentRetriever.current = { getContentAsHtml };
              }}
              reportUnsavedChanges
              changeKey={changeKey}
              hideToolbar
            />
            <Stack justifyContent="end" direction="row">
              <LoadingButton color="primary" variant="contained" type="submit" loading={loading}>
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
