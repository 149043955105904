import React, { useState } from "react";
import { PracticeReview, PrScreenQuery } from "practice-reviews";
import { Grid, Button, Typography, DialogContentText, Stack, IconButton } from "@mui/material";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { ReviewedClient, ReviewedClientInput } from "../models";
import { actionStyles } from "styles/common";
import _ from "lodash";
import { PartnerGroup } from "./PartnerGroup";
import { Permissions, useCurrentUser } from "users";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import {
  ReviewedClientsListAndDropdownContentsQuery,
  AddReviewedClientMutation,
  EditReviewedClientMutation,
  DeleteReviewedClientMutation
} from "./queries";
import { Skeleton } from "@mui/material";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { useNotifications } from "../../notifications";
import { Notifications } from "notifications/NotificationContext";
import { makeStyles } from "makeStyles";
import { ClientFileForm } from "./ClientFileForm";
import { CandidateForm } from "./CandidateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { ApolloClient, FetchResult } from "@apollo/client";

const remDrawerWidth = 30;

const useStyles = makeStyles()((theme) => ({
  ...actionStyles(theme),
  root: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  addButtonGrid: {
    textAlign: "right",
    padding: theme.spacing(3)
  },
  closeButton: {
    display: "flex",
    alignItems: "flex-start"
  },
  content: {
    alignItems: "flex-start",
    width: `calc(100% - 1px)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
      delay: 50
    })
  },
  contentWithOpen: {
    flexGrow: 0,
    width: `calc(100% - ${remDrawerWidth}rem)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawer: {
    width: 0,
    marginLeft: "-1px", // omg hax
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: "hidden",
    "& form": {
      minWidth: `${remDrawerWidth - 1}rem`,
      padding: theme.spacing(3)
    }
  },
  drawerOpen: {
    width: `${remDrawerWidth}rem`,
    marginBottom: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
      delay: 10
    })
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  deleteWarningReviewedClientName: {
    fontWeight: "bold"
  }
}));

export async function saveRevieweClientEntry(
  reviewedClientInput: ReviewedClientInput,
  revieweClientBeingEdited: ReviewedClient | null,
  editReviewedClient: (reviewedClient: ReviewedClientInput) => Promise<FetchResult<{ reviewedClients: { update: ReviewedClient[] } }>>,
  addReviewedClient: (reviewedClient: ReviewedClientInput) => Promise<FetchResult<{ reviewedClients: { add: ReviewedClient[] } }>>,
  reviewedClientDescriptor: "candidate" | "client",
  reviewerHasSubmittedReviewedClients: boolean,
  notifications: Notifications,
  closeEditPane: () => void,
  practiceReview: PracticeReview,
  apolloClient: ApolloClient<object>,
  userId: number,
  setJustAddedReviewedClient: (value: ReviewedClient | null) => void
) {
  function notifyReviewerTheyMustResubmitReviewedClients() {
    notifications.info(`You will need to resubmit your ${reviewedClientDescriptor} files to the lead reviewer.`, 0);
  }

  if (revieweClientBeingEdited) {
    const result = await editReviewedClient(reviewedClientInput);
    if (result.data?.reviewedClients?.update) {
      notifications.success(`Saved ${reviewedClientDescriptor}.`);

      if (reviewerHasSubmittedReviewedClients) {
        notifyReviewerTheyMustResubmitReviewedClients();
      }

      closeEditPane();
      return true;
    } else return false;
  } else {
    var existingIds = practiceReview.reviewedClients.map((m) => m.id);
    const result = await addReviewedClient(reviewedClientInput);
    const addedFile = result.data?.reviewedClients?.add?.find((f) => !existingIds.includes(f.id));
    if (addedFile) {
      if (reviewerHasSubmittedReviewedClients) {
        notifyReviewerTheyMustResubmitReviewedClients();
      }

      closeEditPane();

      // Add the new file into the cached practice review so that it immediately appears on the screen.
      const prCacheId = `PracticeReview:${practiceReview.id}`;
      apolloClient.cache.modify({
        id: prCacheId,
        fields: {
          reviewedClients(existingClients: ReviewedClient[]) {
            return existingClients.concat([addedFile]);
          }
        }
      });

      if (addedFile.reviewedByUserId === userId) {
        setJustAddedReviewedClient(addedFile);
      }

      return true;
    } else return false;
  }
}

interface Props {
  practiceReview: PracticeReview;
}

export const ReviewedClients: React.FunctionComponent<Props> = (props) => {
  const { practiceReview } = props;
  const pprp = practiceReview.isPprpReview;

  const { user, userIsLeadReviewer } = useCurrentUser();
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();
  const notifications = useNotifications();
  const apolloClient = useApolloClient();

  const [reviewedClientBeingEdited, setReviewedClientBeingEdited] = useState<ReviewedClient | null>(null);
  const [addingReviewedClient, setAddingReviewedClient] = useState<boolean>(false);
  const [confirmingDeleteFile, setConfirmingDeleteFile] = useState<ReviewedClient | null>(null);
  const [justAddedReviewedClient, setJustAddedReviewedClient] = useState<ReviewedClient | null>(null);

  const { userHasPermission } = useCurrentUser();
  const canEdit = !practiceReview.hasBeenReturned || userHasPermission(Permissions.ReturnedPrUpdate);

  const editPaneOpen = () =>
    reviewedClientBeingEdited !== null || addingReviewedClient || (!mainQueryResult.loading && reviewedClientsGroupedByPartner?.isEmpty());

  const mainQueryResult = useQuery<{ practiceReviewById: PracticeReview }>(ReviewedClientsListAndDropdownContentsQuery, {
    variables: {
      id: practiceReview.id
    }
  });
  const reviewedClients = mainQueryResult.data?.practiceReviewById.reviewedClients ?? [];
  const reviewedClientsGroupedByPartner = _(reviewedClients).groupBy((c) => c.partnerName);

  const engagementTypes = mainQueryResult.data?.practiceReviewById.engagementTypes ?? [];
  const pprpEngagementTypeId = engagementTypes.filter((et) => et.name === "PPRP")[0]?.id;

  const latestFirmPartners = mainQueryResult.data?.practiceReviewById.firm.latestPartners ?? [];

  const [addReviewedClient, { loading: loadingAdd }] = useMutation<
    { reviewedClients: { add: ReviewedClient[] } },
    { reviewedClient: ReviewedClientInput }
  >(AddReviewedClientMutation, {
    refetchQueries: [
      {
        query: ReviewedClientsListAndDropdownContentsQuery,
        variables: {
          id: practiceReview.id
        }
      },
      {
        query: PrScreenQuery,
        variables: {
          prNumber: practiceReview.prNumber
        }
      }
    ]
  });

  const reviewedClientClicked = (reviewedClient: ReviewedClient) => {
    setReviewedClientBeingEdited(reviewedClient);
  };

  const markerClicked = (reviewedClient: ReviewedClient) => {
    const url = getRouteForPracticeReview(
      practiceReview,
      practiceReview.isPprpReview ? PracticeReviewTabs.CandidateChecklists : PracticeReviewTabs.ClientChecklists,
      reviewedClient
    );
    history.push(url);
  };

  const closeEditPane = () => {
    setAddingReviewedClient(false);
    setReviewedClientBeingEdited(null);
    changesSaved();
  };

  const goToJustAddedReviewedClient = (reviewedClient: ReviewedClient) => {
    const url = getRouteForPracticeReview(
      practiceReview,
      pprp ? PracticeReviewTabs.CandidateChecklists : PracticeReviewTabs.ClientChecklists,
      reviewedClient
    );
    setJustAddedReviewedClient(null);
    history.push(url);
  };

  const deleteClicked = async (reviewedClient: ReviewedClient) => {
    if (confirmingDeleteFile !== reviewedClient) {
      setConfirmingDeleteFile(reviewedClient);
    } else {
      await deleteReviewedClient({
        variables: {
          id: reviewedClient.id
        }
      });
      closeEditPane();
      setConfirmingDeleteFile(null);
    }
  };

  const [editReviewedClient, { loading: loadingEdit }] = useMutation<
    { reviewedClients: { update: ReviewedClient[] } },
    { reviewedClient: ReviewedClientInput }
  >(EditReviewedClientMutation, {
    refetchQueries: [
      {
        query: PrScreenQuery,
        variables: {
          prNumber: practiceReview.prNumber
        }
      }
    ]
  });

  const [deleteReviewedClient, { loading: loadingDelete }] = useMutation<{ reviewedClients: { delete: ReviewedClient } }, { id: number }>(
    DeleteReviewedClientMutation,
    {
      refetchQueries: [
        {
          query: PrScreenQuery,
          variables: {
            prNumber: practiceReview.prNumber
          }
        }
      ]
    }
  );

  async function saveReviewedClient(reviewedClientInput: ReviewedClientInput) {
    return await saveRevieweClientEntry(
      reviewedClientInput,
      reviewedClientBeingEdited,
      (reviewedClient) => editReviewedClient({ variables: { reviewedClient: reviewedClient } }),
      (reviewedClient) => addReviewedClient({ variables: { reviewedClient: reviewedClient } }),
      reviewedClientDescriptor,
      reviewerHasSubmittedReviewedClients,
      notifications,
      closeEditPane,
      props.practiceReview,
      apolloClient,
      user.id,
      setJustAddedReviewedClient
    );
  }

  const availablePartners: string[] = _.orderBy(
    _.union(
      latestFirmPartners?.map((m) => m.name),
      reviewedClients?.map((r) => r.partnerName)
    ),
    (x) => x.toLowerCase()
  );

  const reviewers = (props.practiceReview.leadReviewer ? [props.practiceReview.leadReviewer] : []).concat(
    _.orderBy(props.practiceReview.otherReviewers, (r) => r.user.name)
  );

  const reviewerHasSubmittedReviewedClients =
    !userIsLeadReviewer(props.practiceReview) &&
    reviewedClientBeingEdited?.reviewedByUserId === user.id &&
    props.practiceReview.otherReviewers.filter((r) => r.userId === user.id)?.[0].reviewedClientsSubmitted;

  const userCanDeleteReviewedClientBeingEdited =
    reviewedClientBeingEdited?.reviewedByUserId === user.id || userIsLeadReviewer(props.practiceReview);

  function userCanViewReviewedClient(reviewedClient: ReviewedClient) {
    const userHasPermissionToViewWholePr = props.practiceReview.isPprpReview
      ? userHasPermission(Permissions.PprpProgramReviewViewAll)
      : userHasPermission(Permissions.PracticeReviewViewAll);
    return userIsLeadReviewer(props.practiceReview) || reviewedClient.reviewedByUserId === user.id || userHasPermissionToViewWholePr;
  }

  const reviewedClientDescriptor = pprp ? "candidate" : "client";
  const capitalizedReviewedClientDescriptor = pprp ? "Candidate" : "Client";

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.content, {
          [classes.contentWithOpen]: editPaneOpen() && canEdit
        })}>
        <div className={classes.addButtonGrid}>
          {!editPaneOpen() && !mainQueryResult.loading && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setAddingReviewedClient(true)}
              disabled={editPaneOpen()}
              hidden={!canEdit}>
              {`Add New ${capitalizedReviewedClientDescriptor}`}
            </Button>
          )}
        </div>
        <Grid container rowSpacing={5} columnSpacing={5}>
          {mainQueryResult.loading ? ( // show skeleton when loading
            [...Array(4)].map((x, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton variant="rectangular" width="100%" height="10rem" />
              </Grid>
            ))
          ) : reviewedClientsGroupedByPartner.isEmpty() ? ( // show empty text when no review files
            <Grid item>
              <Typography variant="h3" sx={{ pl: 5 }}>
                {pprp ? "No candidates." : "No client files."}
              </Typography>
            </Grid>
          ) : (
            // show list of review files
            reviewedClientsGroupedByPartner
              .map((reviewedClients, partner) => (
                <Grid item xs={12} md={6} key={partner}>
                  <PartnerGroup
                    pprp={pprp}
                    partnerName={partner}
                    reviewedClients={reviewedClients}
                    selectedReviewedClientId={reviewedClientBeingEdited?.id}
                    reviewedClientClicked={reviewedClientClicked}
                    markerClicked={markerClicked}
                    userCanViewReviewedClient={userCanViewReviewedClient}
                  />
                </Grid>
              ))
              .value()
          )}
        </Grid>
      </div>
      <div
        className={cx(classes.drawer, {
          [classes.drawerOpen]: editPaneOpen() && canEdit
        })}>
        <Stack direction="row" justifyContent="space-between" sx={{ pt: 3, pl: 3, pr: 3 }}>
          <Typography variant="h3" className={classes.sectionHeader}>
            {`${reviewedClientBeingEdited ? "Edit" : "New"} ${capitalizedReviewedClientDescriptor} Details${
              reviewedClientBeingEdited?.refNum ? ` (${reviewedClientBeingEdited.refNum})` : ""
            }${reviewedClientBeingEdited?.candidate?.idNo ? ` (${reviewedClientBeingEdited.candidate.idNo})` : ""}`}
          </Typography>
          {!reviewedClientsGroupedByPartner?.isEmpty() && ( // can't close the dialog if there are no files to show
            <Typography variant="h3" className={classes.closeButton}>
              <IconButton size="small" onClick={() => closeEditPane()} title="Cancel">
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Typography>
          )}
        </Stack>
        {pprp ? (
          <CandidateForm
            reviewedClientBeingEdited={reviewedClientBeingEdited}
            practiceReview={props.practiceReview}
            cancel={!reviewedClientsGroupedByPartner?.isEmpty() ? closeEditPane : null}
            canEdit={canEdit}
            delete={deleteClicked}
            engagementTypes={engagementTypes}
            loadingAdd={loadingAdd}
            loadingEdit={loadingEdit}
            loadingDelete={loadingDelete}
            reviewers={reviewers}
            saveReviewedClient={saveReviewedClient}
            unsavedChanges={unsavedChanges}
            userCanDeleteReviewedClientBeingEdited={userCanDeleteReviewedClientBeingEdited}
            pprpEngagementTypeId={pprpEngagementTypeId}
          />
        ) : (
          <ClientFileForm
            reviewedClientBeingEdited={reviewedClientBeingEdited}
            practiceReview={props.practiceReview}
            availablePartners={availablePartners}
            cancel={!reviewedClientsGroupedByPartner?.isEmpty() ? closeEditPane : null}
            canEdit={canEdit}
            delete={deleteClicked}
            engagementTypes={engagementTypes}
            loadingAdd={loadingAdd}
            loadingEdit={loadingEdit}
            loadingDelete={loadingDelete}
            reviewers={reviewers}
            saveReviewedClient={saveReviewedClient}
            unsavedChanges={unsavedChanges}
            userCanDeleteReviewedClientBeingEdited={userCanDeleteReviewedClientBeingEdited}
          />
        )}
      </div>
      {confirmingDeleteFile && (
        <ConfirmationDialog
          open={true}
          body={
            <>
              <DialogContentText className={classes.deleteWarningReviewedClientName}>
                <span>{confirmingDeleteFile?.name}</span>
                <span>{confirmingDeleteFile?.refNum && ` (${confirmingDeleteFile.refNum})`}</span>
              </DialogContentText>
              <br />
              {`Are you sure you want to delete this ${reviewedClientDescriptor} file and its checklist?`}
            </>
          }
          title={`Delete ${capitalizedReviewedClientDescriptor}?`}
          cancel={() => setConfirmingDeleteFile(null)}
          confirm={() => deleteClicked(confirmingDeleteFile)}
        />
      )}

      {justAddedReviewedClient && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              {`${capitalizedReviewedClientDescriptor} file created. Would you like to go straight to the ${reviewedClientDescriptor} checklist for ${justAddedReviewedClient.name}?`}
            </DialogContentText>
          }
          title={`Go to new ${reviewedClientDescriptor} checklist?`}
          noDanger
          cancel={() => setJustAddedReviewedClient(null)}
          cancelButtonText="No"
          confirm={() => goToJustAddedReviewedClient(justAddedReviewedClient)}
          confirmButtonText="Yes"
        />
      )}
    </div>
  );
};
