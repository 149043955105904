import React from "react";
import { Autocomplete, AutocompleteRenderGroupParams, Typography } from "@mui/material";
import { TCustomControl, TToolbarComponentProps } from "mui-rte";
import { EditorState, Modifier } from "draft-js";
import { EnhancedMergeField, HTMLMergeFields, MergeFieldType, MergeFieldBehavior } from "./models";
import { TextField } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "../../makeStyles";
import { theme } from "styles/theme";
import { getAsposeMergeFieldTextForInsert, getMergeFieldTypeDisplayText } from "./utilities";

const fetchHtmlMergeFieldsQuery = gql`
  query FetchHtmlMergeFields {
    htmlMergeFields {
      htmlMergeReviewSingleFields
      htmlMergeReviewChildFields
      htmlMergeReviewRepeatedValueWrappers
      htmlMergeReviewShowHideWrappers
      htmlMergeMeetingFields
      htmlMergeProgramTaskFields
      decisionLettersDataFields {
        id
        field
        type
        description
        nestUnder
      }
      committeeMeetingDataFields {
        id
        field
        type
        description
        nestUnder
      }
      programReviewDataFields {
        id
        field
        type
        description
        nestUnder
      }
      htmlMergeFieldOpener
      htmlMergeFieldCloser
      asposeMergeFieldOpener
      asposeMergeFieldCloser
    }
  }
`;

interface InsertFieldComponentProps extends TToolbarComponentProps {
  setInsertedField: (newField: string | null) => void;
  mergeFieldType: MergeFieldType;
  currentIdNestLevel?: string;
}

const useStyles = makeStyles()(() => ({
  insertFieldComponent: {
    width: "400px",
    display: "inline-flex",
    marginLeft: "auto",
    "& .MuiInputBase-input, & .MuiFormLabel-root": {
      fontSize: "small"
    }
  },
  listbox: {},
  enhancedFieldOption: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    paddingBottom: "10px !important",
    border: "1px solid #CCC",
    marginTop: "-1px",
    "&.MuiAutocomplete-option.Mui-focused": {
      "&.Field": {
        backgroundColor: "#F0FFF0"
      },
      "&.RichTextHtml": {
        backgroundColor: "#F0FFF0"
      },

      "&.Boolean": {
        backgroundColor: "#FFF0FF"
      },

      "&.List": {
        backgroundColor: "#F0F0FF"
      },

      "&.Document": {
        backgroundColor: "#fff3eb"
      }
    }
  },
  fieldName: {
    lineHeight: "1",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  fieldType: {
    fontSize: "small",
    fontStyle: "italic",
    paddingLeft: "24px",
    paddingRight: "16px",
    ".List &": {
      color: "#001564"
    },
    ".Field &": {
      color: "#004000"
    },
    ".RichTextHtml &": {
      color: "#004000"
    },
    ".Boolean &": {
      color: "purple"
    },
    ".Document &": {
      color: "#ab4400"
    }
  },
  fieldDescription: {
    fontSize: "0.75rem",
    fontWeight: "lighter",
    lineHeight: "1",
    paddingLeft: "24px",
    paddingRight: "16px"
  },
  nestedUnder: {
    paddingLeft: "0px",
    marginLeft: "15px",
    borderLeft: "1px solid #CCC"
  },
  notRelevant: {
    backgroundColor: "#f3f3f3",
    color: "gray",
    fontSize: "small",
    border: "1px solid #DDD"
  }
}));

const InsertFieldComponent: React.FunctionComponent<InsertFieldComponentProps> = (props) => {
  // The closest thing to documentation mui-rte has is this: https://github.com/niuware/mui-rte/blob/b7f70ed7175f15dbb2ee5aba854883312bf01a2d/examples/custom-controls/index.tsx
  const { classes, cx } = useStyles();

  const queryData = useQuery<{ htmlMergeFields: HTMLMergeFields }>(fetchHtmlMergeFieldsQuery);
  const mergeFields = queryData.data?.htmlMergeFields;

  const mergeFieldValues = React.useMemo(
    () =>
      queryData.loading
        ? []
        : props.mergeFieldType === MergeFieldType.DecisionLetter
        ? Object.values([...mergeFields!.decisionLettersDataFields]).sort()
        : props.mergeFieldType === MergeFieldType.MeetingMinutes
        ? Object.values([...mergeFields!.committeeMeetingDataFields]).sort()
        : props.mergeFieldType === MergeFieldType.ProgramReview
        ? Object.values([...mergeFields!.programReviewDataFields]).sort()
        : props.mergeFieldType === MergeFieldType.HtmlForMeeting
        ? Object.values([...mergeFields!.htmlMergeMeetingFields]).sort()
        : props.mergeFieldType === MergeFieldType.HtmlForProgramTask
        ? Object.values([...mergeFields!.htmlMergeProgramTaskFields]).sort()
        : Object.values([
            ...mergeFields!.htmlMergeReviewSingleFields,
            ...mergeFields!.htmlMergeReviewChildFields,
            ...mergeFields!.htmlMergeReviewRepeatedValueWrappers,
            ...mergeFields!.htmlMergeReviewShowHideWrappers
          ]).sort(),
    [mergeFields]
  );

  const insertText = (option: any, event: React.MouseEvent<Element, MouseEvent>) => {
    if (!option) {
      return;
    }

    let text: string;
    if (
      props.mergeFieldType == MergeFieldType.MeetingMinutes ||
      props.mergeFieldType === MergeFieldType.DecisionLetter ||
      props.mergeFieldType === MergeFieldType.ProgramReview
    ) {
      text = getAsposeMergeFieldTextForInsert(option);
    } else {
      text = `${mergeFields!.htmlMergeFieldOpener}${option}${mergeFields!.htmlMergeFieldCloser}`;
    }

    props.setInsertedField(text);

    props.onMouseDown(event); // This invokes the callback (the onClick method in TCustomControl), which is what writes to the editor
  };

  const getMargin = (option: any) => {
    if (!option?.id) {
      return "0px";
    }
    return `${(String(option.id).split(".").length - 1) * 20}px`;
  };

  const isAtCurrentLevel = (option: any) => {
    if (!option?.id) {
      return true;
    }

    const optionId = String(option.id);

    if (option.id == option.field && !props.currentIdNestLevel) {
      return true;
    }

    const path = optionId.substring(0, optionId.length - (option.field.length + 1));

    return path.toLowerCase() == props.currentIdNestLevel?.toLowerCase();
  };

  return queryData.loading ? null : (
    <Autocomplete
      className={classes.insertFieldComponent}
      classes={{
        listbox:
          props.mergeFieldType === MergeFieldType.MeetingMinutes ||
          props.mergeFieldType === MergeFieldType.DecisionLetter ||
          props.mergeFieldType === MergeFieldType.ProgramReview
            ? classes.listbox
            : undefined
      }}
      getOptionLabel={(option: any) => option.field ?? option}
      options={mergeFieldValues as any}
      isOptionEqualToValue={(option: any, value) => (option?.id ?? option) === value}
      disableClearable
      groupBy={(option: any) => option.nestUnder}
      renderGroup={(params: AutocompleteRenderGroupParams) => params.children}
      renderOption={(liProps: React.HTMLAttributes<HTMLLIElement>, option: any) => (
        <li
          {...liProps}
          key={option.id ?? option}
          className={cx("MuiAutocomplete-option", option.type, {
            [classes.enhancedFieldOption]: Boolean(option.id),
            [classes.notRelevant]: !isAtCurrentLevel(option)
          })}
          onClick={(e) => insertText(option, e)}
          style={{ marginLeft: getMargin(option) }}>
          {option.id ? (
            <div>
              <div className={classes.fieldName}>{option.field}</div>
              {option.type && <div className={cx(classes.fieldType, option.type)}>{getMergeFieldTypeDisplayText(option.type)}</div>}
              {option.description && <div className={classes.fieldDescription}>{option.description}</div>}
            </div>
          ) : (
            option
          )}
        </li>
      )}
      renderInput={(params) => <TextField {...params} placeholder="Insert Field" margin="none" />}
      value={props.currentIdNestLevel}
    />
  );
};

class InputHtmlState {
  insertedField: string | null;

  constructor() {
    this.insertedField = null;
  }
}

const getInputHtmlMergeFieldControl: (
  mergeFieldType: MergeFieldType | undefined,
  currentIdNestLevel: string | undefined
) => TCustomControl = (mergeFieldType: MergeFieldType | undefined, currentIdNestLevel: string | undefined) => {
  const state = new InputHtmlState();

  return {
    name: "insert-field",
    component: (controlProps: any) => (
      <InsertFieldComponent
        {...controlProps}
        mergeFieldType={mergeFieldType}
        currentIdNestLevel={currentIdNestLevel}
        setInsertedField={(newField: string) => (state.insertedField = newField)}
      />
    ),
    type: "callback",
    onClick: (_editorState: EditorState, name: string, _anchor: HTMLElement | null) => {
      if (!state.insertedField) {
        return _editorState;
      }

      const newContentState = Modifier.insertText(_editorState.getCurrentContent(), _editorState.getSelection(), state.insertedField);

      return EditorState.push(_editorState, newContentState, "insert-characters");
    }
  };
};

export default getInputHtmlMergeFieldControl;
