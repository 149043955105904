import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { FetchPprpProgramByIdQuery, RemoveAttachedDocumentFromPprpProgramMutation } from ".";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { PprpProgram, PprpProgramMember } from "./models";
import { AttachedDocument, DocType } from "../practice-reviews";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useNotifications } from "../notifications";
import _ from "lodash";
import { makeStyles } from "../makeStyles";
import { staticDataStyles, datagridStyles, documentListStyles } from "styles/common";
import { useAppConfig } from "../util/AppConfig";
import { useAxios } from "../auth/SecureAxios";
import { formatDate } from "../util/formats";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LoadingButton } from "@mui/lab";
import { AxiosResponse } from "axios";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { getOpenableUrl } from "../util/utilities";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...datagridStyles(theme),
  ...documentListStyles(theme)
}));

interface Props {
  handleClose: () => void;
  programId: number;
}

const PprpProgramDialog = (props: Props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const apolloClient = useApolloClient();
  const { secureAxios } = useAxios();

  const [attachingDocumentType, setAttachingDocumentType] = useState<DocType | null>(null);
  const [removingDocument, setRemovingDocument] = useState<AttachedDocument | null>(null);

  const programQuery = useQuery<
    {
      pprpProgramById: PprpProgram;
    },
    {
      id: number;
    }
  >(FetchPprpProgramByIdQuery, { variables: { id: props.programId }, fetchPolicy: "cache-and-network" });
  const program = programQuery.data?.pprpProgramById;

  async function documentsAttached(documents: FileList, documentType: DocType) {
    setAttachingDocumentType(documentType);

    const formData = new FormData();
    formData.append("PprpProgramId", program!.id.toString());
    formData.append("DocumentTypeCode", documentType.toString());
    formData.append("Document", documents[0]);

    let postResult: AxiosResponse<PprpProgram>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/pprp-program-document/upload`;
    try {
      postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      }
    } catch (e: any) {
      setAttachingDocumentType(null);
      notifications.serverError(e.message);
      return;
    }

    if (postResult?.status !== 200) {
      notifications.serverError(new Error(postResult?.statusText));
    } else {
      const updatedProgram: PprpProgram = postResult.data;
      refreshProgramInCache(updatedProgram);
      notifications.success(`Attached document${documents.length > 1 ? "s" : ""}.`);
    }

    setAttachingDocumentType(null);
  }

  const [removeAttachedDocumentMutate, removeAttachedDocumentMutation] = useMutation<
    { pprpProgram: { removeAttachedDocument: PprpProgram } },
    { programId: number; attachedDocumentId: number }
  >(RemoveAttachedDocumentFromPprpProgramMutation);

  async function removeAttachedDocument(document: AttachedDocument) {
    const result = await removeAttachedDocumentMutate({
      variables: { programId: program!.id, attachedDocumentId: document.id }
    });

    if (result.data?.pprpProgram.removeAttachedDocument?.id) {
      refreshProgramInCache(result.data.pprpProgram.removeAttachedDocument);
      notifications.success("Removed document.");
    }

    setRemovingDocument(null);
  }

  async function refreshProgramInCache(updatedProgram: PprpProgram) {
    const cacheId = `PprpProgram:${updatedProgram.id}`;

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.modify({
          id: cacheId,
          fields: {
            attachedDocuments(_, { INVALIDATE }) {
              return INVALIDATE;
            }
          }
        });
      }
    });
  }

  const DocumentLinkAndActions: React.FunctionComponent<{
    name: string;
    documentType: DocType;
    excelDocument?: boolean;
  }> = (componentProps) => {
    const { documentType, name, excelDocument } = componentProps;
    const document = program?.attachedDocuments.filter((d) => d.type === documentType)[0] ?? null;

    return (
      <div className={classes.documentRow}>
        <div className={classes.documentRow}>
          {document === null ? (
            <Typography variant="body1" className={classes.missingForm}>
              {name}
            </Typography>
          ) : (
            <Link href={getOpenableUrl(document.url)} target="_blank">
              <Typography variant="body1">{name}</Typography>
            </Link>
          )}
        </div>
        <div className={classes.documentAction}>
          {!document ? (
            <LoadingButton
              variant="outlined"
              size="small"
              component="label"
              loading={attachingDocumentType === documentType}
              disabled={attachingDocumentType !== null}>
              Attach
              <input
                type="file"
                hidden
                accept={excelDocument ? ".xlsx" : ".docx"}
                onChange={(e) => documentsAttached(e.target.files!, documentType)}
              />
            </LoadingButton>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => setRemovingDocument(document!)}
              disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
              className="removeButton">
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  };

  const orderedProgramMembers = program
    ? _.orderBy(
        program.members,
        [(m) => m.isPrimaryProgramLeader, (m) => m.isSecondaryProgramLeader, (m) => m.lastName, (m) => m.firstName],
        ["desc", "desc", "asc", "asc"]
      )
    : [];

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="body" maxWidth="lg">
      <ClosableDialogTitle onClose={props.handleClose}>Pre-approved Program</ClosableDialogTitle>
      {program ? (
        <DialogContent>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={1}>
              <StackedStaticDataDisplay label="Entity No." value={program.programEntityNumber.toString()} />
            </Grid>
            <Grid item xs={4}>
              <StackedStaticDataDisplay label="Name" value={program.name} />
            </Grid>
            <Grid item xs={5}>
              <StackedStaticDataDisplay label="Path" value={program.programPath ?? "--"} />
            </Grid>
            <Grid item xs={2}>
              <StackedStaticDataDisplay label="Approval Date" value={formatDate(program.approvalDate)} />
            </Grid>

            <Grid item xs={4}>
              <StackedStaticDataDisplay label="Restrictions" value={program.restrictions ?? "--"} />
            </Grid>
            <Grid item xs={4}>
              <StackedStaticDataDisplay label="Conditions" value={program.conditions ?? "--"} />
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Typography variant="body1" className={classes.label} gutterBottom>
                  Program Address
                </Typography>
                <Typography variant="body2">{program.address1}</Typography>
                {program.address2 && <Typography variant="body2">{program.address2}</Typography>}
                {program.city && program.province && <Typography variant="body2">{`${program.city}, ${program.province}`}</Typography>}
                <Typography variant="body2">{program.postalCode}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Firm
              </Typography>
              <Card variant="outlined">
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={1}>
                      <StackedStaticDataDisplay label="Entity No." value={program.firm.entityNumber.toString()} />
                    </Grid>
                    <Grid item xs={8}>
                      <StackedStaticDataDisplay label="Name" value={program.firm.name} />
                    </Grid>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay label="Type" value={program.firm.entityType?.typeShortName ?? "Unknown"} />
                    </Grid>
                    <Grid item xs={9}>
                      <StackedStaticDataDisplay label="Status" value={`${program.firm.firmStatus}, ${program.firm.subStatus}`} />
                    </Grid>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay label="CPAB" value={program.firm.isCpab ? "Yes" : "No"} />
                    </Grid>
                    <Grid item xs={3}>
                      <Stack>
                        <Typography variant="body1" className={classes.label} gutterBottom>
                          Address
                        </Typography>
                        <Typography variant="body2">{program.address1}</Typography>
                        {program.firm.address2 && <Typography variant="body2">{program.firm.address2}</Typography>}
                        {program.firm.city && program.firm.province && (
                          <Typography variant="body2">{`${program.firm.city}, ${program.firm.province}`}</Typography>
                        )}
                        <Typography variant="body2">{program.firm.postalCode}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <StackedStaticDataDisplay label="Zone" value={program.firm.zone ?? "--"} />
                    </Grid>
                    <Grid item xs={2}>
                      <StackedStaticDataDisplay label="Practice Locale" value={program.firm.practiceLocale ?? "--"} />
                    </Grid>
                    <Grid item xs={2}>
                      <StackedStaticDataDisplay label="Phone" value={program.firm.phoneNumber ?? "--"} />
                    </Grid>
                    <Grid item xs={2}>
                      <StackedStaticDataDisplay label="Fax" value={program.firm.faxNumber ?? "--"} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                PERT Candidate Details
              </Typography>
              <Card variant="outlined">
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay label="Total" value={program.pertCandidateTotal?.toString() ?? "--"} />
                    </Grid>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay label="Total per PERT" value={program.pertCandidateTotalPerPert?.toString() ?? "--"} />
                    </Grid>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay label="Approval Limit" value={program.pertCandidateApprovalLimit?.toString() ?? "--"} />
                    </Grid>
                    <Grid item xs={3}>
                      <StackedStaticDataDisplay
                        label="No. per Location"
                        value={program.pertCandidateCountPerLocation?.toString() ?? "--"}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="h4" gutterBottom>
                  Members
                </Typography>
                <DataGridPro
                  autoHeight
                  hideFooter
                  disableColumnMenu
                  disableSelectionOnClick
                  rows={orderedProgramMembers}
                  columns={[
                    {
                      field: "memberEntityNumber",
                      headerName: "Entity No.",
                      width: 80
                    },
                    {
                      field: "name",
                      headerName: "Name",
                      flex: 1
                    },
                    {
                      field: "designation",
                      headerName: "Designation",
                      width: 100
                    },
                    {
                      field: "title",
                      headerName: "Title",
                      width: 80
                    },
                    {
                      field: "email",
                      headerName: "Email",
                      flex: 1
                    },
                    {
                      field: "program leader",
                      headerName: "Program Leader",
                      valueGetter: (params: { row: PprpProgramMember }) =>
                        params.row.isPrimaryProgramLeader ? "Primary" : params.row.isSecondaryProgramLeader ? "Secondary" : "",
                      width: 120
                    },
                    {
                      field: "isProgramManager",
                      type: "boolean",
                      headerName: "Program Manager",
                      cellClassName: classes.hideBooleanXsInCell,
                      width: 140
                    }
                  ]}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="h4">Program Documents</Typography>
                <div className={classes.documentList}>
                  <DocumentLinkAndActions name="Executive Summary" documentType={DocType.PprpProgramExecutiveSummary} />
                  <DocumentLinkAndActions name="Competency Map" documentType={DocType.PprpProgramCompetencyMap} excelDocument />
                  <DocumentLinkAndActions
                    name="Summary of Chargeable Hours"
                    documentType={DocType.PprpProgramSummaryOfChargeableHours}
                    excelDocument
                  />
                  <DocumentLinkAndActions name="Memo to Registrar" documentType={DocType.PprpProgramMemoToRegistrar} />
                  <DocumentLinkAndActions name="Senior Commitment Letter" documentType={DocType.PprpProgramSeniorCommitmentLetter} />
                </div>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <CircularProgress sx={{ m: 3 }} />
      )}
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>

      {removingDocument !== null && (
        <ConfirmationDialog
          open={true}
          body={<DialogContentText>{`Do you want to remove the ${removingDocument?.typeFriendlyName}?`}</DialogContentText>}
          title="Remove file?"
          cancel={() => setRemovingDocument(null)}
          confirm={() => removeAttachedDocument(removingDocument)}
          loading={removeAttachedDocumentMutation.loading}
        />
      )}
    </Dialog>
  );
};

export default PprpProgramDialog;
