import { gql } from "@apollo/client";

export const UpdateDirectedPdCoursesMutation = gql`
  mutation UpdateDirectedPdCoursesMutation($practiceReviewId: Int!, $updatedCourses: [AssignedPdInput!]!, $notes: String) {
    pdCourses {
      updateDirectedPdCourses(practiceReviewId: $practiceReviewId, updatedCourses: $updatedCourses, notes: $notes) {
        updatedCourses {
          id
          isDirected
          isCompleted
          isExempt
          exemptReason
          name
          productCode
          topicName
          proofOfAttendanceFormId
          signedProofOfAttendanceId
        }
        notes
      }
    }
  }
`;

export const CompleteDirectedPdMutation = gql`
  mutation CompleteDirectedPdMutation($practiceReviewId: Int!) {
    pdCourses {
      completeDirectedPd(practiceReviewId: $practiceReviewId) {
        id
        isDirectedPdCompleted
        directedPdCompletedDate
        directedPdCompletedByUser {
          id
          name
        }
      }
    }
  }
`;
