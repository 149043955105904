import { gql } from "@apollo/client";

export const SaveMonitoringMutation = gql`
  mutation SaveMonitoring($practiceReviewId: Int!, $monitoringInput: MonitoringInput!) {
    monitoring {
      save(practiceReviewId: $practiceReviewId, monitoringInput: $monitoringInput) {
        id
        isCompleted
        isPass
        isReleased
        sendToCommitteeOnFail
        monitoringNotes
        createdDate
        completedDate
        completedByUser {
          id
          name
        }
        practiceReview {
          id
          monitoringFrequencyInDays
          monitoringOccurrencesRemaining
          monitoringNotes
        }
      }
    }
  }
`;

export const GenerateMonitoringCompleteLetterMutation = gql`
  mutation GenerateMonitoringCompleteLetter($monitoringId: Int!) {
    monitoring {
      generateLetter(monitoringId: $monitoringId) {
        id
        monitorings {
          id
          monitoringCompletedLetterId
          letterGeneratedDate
        }
        attachedDocuments {
          id
          type
          url
        }
      }
    }
  }
`;

export const ToggleMonitoringCompleteLetterReleasedMutation = gql`
  mutation ToggleMonitoringCompleteLetterReleased($monitoringId: Int!) {
    monitoring {
      toggleLetterReleased(monitoringId: $monitoringId) {
        id
        isReleased
      }
    }
  }
`;
