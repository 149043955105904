import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { formatDate, formatTime } from "../../util/formats";
import { LoadingButton } from "@mui/lab";

interface Props {
  isReschedule: boolean;
  isEstimateFinalized: boolean;
  startDate: string;
  endDate: string;

  open: boolean;
  handleClose: () => void;
  save: (onCompletedMessage: string) => Promise<void>;
  loading: boolean;
  changeKey?: string;
}

const FinalizeScheduleDialog: React.FunctionComponent<Props> = (props) => (
  <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} scroll="paper">
    <ClosableDialogTitle onClose={props.handleClose}>{props.isReschedule ? "Reschedule?" : "Finalize schedule?"}</ClosableDialogTitle>
    <DialogContent>
      <DialogContentText>
        {`The review will start on ${formatDate(props.startDate)} at ${formatTime(props.startDate)} and will finish on
          ${formatDate(props.endDate)}.`}
      </DialogContentText>
      <DialogContentText>
        {props.isReschedule
          ? "Do you want to update the review's scheduled dates and notify the firm of the new dates?"
          : "Do you want to complete Schedule Review INAs and notify the firm of the scheduled review dates?"}
      </DialogContentText>
      {!props.isEstimateFinalized && (
        <Alert severity="warning">
          The estimate for this review has not yet been finalized. This action will also mark the estimate as final and close any Estimate
          Time INAs for this review.
        </Alert>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} disabled={props.loading}>
        Cancel
      </Button>
      <LoadingButton
        color="primary"
        variant="contained"
        loading={props.loading}
        onClick={async () => {
          await props.save("Schedule finalized.");
          props.handleClose();
        }}>
        {props.isReschedule ? "Reschedule" : "Finalize"}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default FinalizeScheduleDialog;
