import { User } from "users";

export interface Monitoring {
  id: number;
  practiceReviewId: number;
  isPass: boolean | null;
  isReleased: boolean;
  sendToCommitteeOnFail: boolean;
  monitoringNotes: string | null;
  monitoringDate: string;
  isCompleted: boolean;
  completedByUser: User | null;
  completedDate: string | null;
  monitoringCompletedLetterId: number | null;
  letterGeneratedDate: string | null;
}

export interface MonitoringInput {
  id?: number;
  isPass?: boolean;
  sendToCommitteeOnFail?: boolean;
  monitoringNotes?: string;
  practiceReviewMonitoringNotes?: string;

  monitoringFrequencyInDays?: number;
  monitoringOccurrencesRemaining?: number;
}

export enum FrequencyOptions {
  Annually = 365,
  Semiannually = 180,
  Quarterly = 90
}
