import { gql, Reference, useMutation } from "@apollo/client";
import { TimeEntry } from "../practice-reviews";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { DialogContentText } from "@mui/material";

const DeleteTimeEntryMutation = gql`
  mutation DeleteTimeEntry($timeEntryId: Int!) {
    timeEntries {
      delete(timeEntryId: $timeEntryId) {
        id
        enteredDate
        enteredHours
        user {
          id
          cammsEntityNumber
          name
        }
        practiceReview {
          id
          actualTimeToDate
        }
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  entry: TimeEntry;
  practiceReviewId: number;
}

const DeleteTimeEntryDialog = (props: Props) => {
  const notifications = useNotifications();
  const [mutate, { loading: deleting }] = useMutation<{ timeEntries: { delete: TimeEntry } }, { timeEntryId: number }>(
    DeleteTimeEntryMutation,
    {
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify(data?.timeEntries.delete.practiceReview as any),
          fields: {
            actualTimeToDate() {
              return data?.timeEntries.delete.practiceReview.actualTimeToDate;
            },
            timeEntries(existingTimeEntries: Reference[], { readField }) {
              return [...existingTimeEntries.filter((ref) => readField("id", ref) !== data?.timeEntries.delete.id)];
            }
          }
        });
      }
    }
  );

  return (
    <>
      <ConfirmationDialog
        open={true}
        title="Delete Time Entry?"
        body={<DialogContentText>Are you sure you want to delete the time entry?</DialogContentText>}
        confirmButtonText="Delete"
        confirm={async () => {
          await mutate({
            variables: { timeEntryId: props.entry.id }
          });
          notifications.success("Entry deleted.");
          props.handleClose();
        }}
        loading={deleting}
        cancel={props.handleClose}
      />
    </>
  );
};

export default DeleteTimeEntryDialog;
