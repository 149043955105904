import React, { createContext, useContext, useEffect, useState } from "react";
import { useAxios } from "auth/SecureAxios";
import { LoadingScreen } from "chrome/LoadingScreen";

export enum ThemeCode {
  LOCAL = "LOCAL",
  DEV = "DEV",
  UAT = "UAT",
  PROD = "PROD"
}

export interface IAppConfig {
  themeCode: ThemeCode;
  apiEndpoint: string;
  graphQLEndpoint: string;
  showOriginalExceptionMessages: boolean;
  showStackTraces: boolean;
  showBrowserConsoleDebugLogs: boolean;
  appInsightsKey: string;

  azureAdClientId: string;
  azureAdAuthority: string;
  azureAdScopes: Array<string>;

  isDevTestResetEnabled: boolean;
  sharepointDocumentTemplateLibraryUrl: string;
  muiDataGridProLicenseKey: string;
  azureSignalRConnectionString: string;

  version: string;

  useChecklistAutoSave: boolean;
}

export const AppConfigContext = createContext<IAppConfig | null>(null);

export const AppConfigProvider = (props: { children: React.ReactNode }) => {
  var [appConfig, setAppConfig] = useState<IAppConfig | null>(null);
  var [isLoading, setIsLoading] = useState<boolean>(true);
  var [error, setError] = useState<any>(null);
  const { insecureAxios } = useAxios();

  useEffect(() => {
    insecureAxios
      .get<IAppConfig>("/appConfig")
      .then((result) => {
        setAppConfig(result.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [insecureAxios]);

  if (isLoading) {
    return <LoadingScreen mode="init" />;
  } else if (appConfig) {
    return <AppConfigContext.Provider value={appConfig}>{props.children}</AppConfigContext.Provider>;
  } else {
    console.log(error);
    return <LoadingScreen mode="init" />;
  }
};

export function useAppConfig() {
  const context = useContext(AppConfigContext);

  return {
    ...context
  };
}
