import React, { useCallback, useEffect, useRef, useState } from "react";
import MUIRichTextEditor, { TCustomControl, TMUIRichTextEditorStyles } from "mui-rte";
import { ContentState, EditorState, convertToRaw, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { ThemeProvider, StyledEngineProvider, Typography, Box, Button, IconButton, FormHelperText, Tooltip } from "@mui/material";
import getInputHtmlMergeFieldControl from "./HtmlMergeFields/GetInputHtmlMergeFieldControl";
import { smallTextFieldPadding } from "../styles/theme";
import _ from "lodash";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { staticDataStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import { MergeFieldType } from "./HtmlMergeFields/models";
import { faSquareFull } from "@fortawesome/free-solid-svg-icons/faSquareFull";
import { faBold } from "@fortawesome/free-solid-svg-icons/faBold";
import { faItalic } from "@fortawesome/free-solid-svg-icons/faItalic";
import { faUnderline } from "@fortawesome/free-solid-svg-icons/faUnderline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateCleanContentStateFromHtml } from "./draftJsPreserveTabs";

const useStyles = makeStyles<Props>()((theme) => ({
  ...staticDataStyles(theme),
  fieldTag: {
    border: "1px dotted green",
    backgroundColor: "#F0FFF0",
    padding: "0px 2px",
    whiteSpace: "nowrap" as "nowrap"
  },
  fieldFormatClause: {
    fontStyle: "italic",
    color: "#888"
  },
  docTag: {
    border: "1px dotted #833C0B",
    backgroundColor: "#FBE4D5",
    padding: "0px 2px",
    whiteSpace: "nowrap" as "nowrap"
  },
  markupTag: {
    fontSize: "small",
    padding: "0px 2px",
    margin: "1px",
    whiteSpace: "nowrap" as "nowrap"
  },
  varTag: {
    border: "1px solid #CCC",
    borderRadius: "3px",
    backgroundColor: "#FAFAFA",
    color: "gray"
  },
  ifTag: {
    borderColor: "purple",
    backgroundColor: "#fff4ff",
    color: "purple"
  },
  elseTag: {
    borderWidth: "1px",
    borderStyle: "solid none solid none"
  },
  foreachTag: {
    borderColor: "#2a44a5",
    backgroundColor: "#eaf4ff",
    color: "#001564"
  },
  openTag: {
    borderWidth: "1px",
    borderStyle: "solid none solid solid",
    borderRadius: "5px 0px 0px 5px"
  },
  closeTag: {
    borderWidth: "1px",
    borderStyle: "solid solid solid none",
    borderRadius: "0px 5px 5px 0px"
  }
}));

interface Props {
  label?: string;
  html: string | null;
  passContentRetriever: (
    getContentAsHtml: () => string | null,
    getNewStateWithTextInserted?: (textToInsert: string) => string | null
  ) => void;
  hideToolbar?: boolean;
  readOnly?: boolean;
  className?: string;
  showMergeFields?: boolean;
  mergeFieldType?: MergeFieldType;
  minHeight?: string;
  reportUnsavedChanges?: boolean;
  changeKey?: string;
  onChange?: () => void;
  required?: boolean;
  fullHeight?: boolean;
  redaction?: boolean;
  templateMarkup?: boolean;
  onRedact?: () => void;
  error?: string;
  addNormalMargin?: boolean;
}

const RichTextEditor: React.FunctionComponent<Props> = (props) => {
  const { classes, theme: appTheme, cx } = useStyles(props);

  const { unsavedChanges, unsavedChangesExist } = useUnsavedChanges();
  const unsavedChangesExistForChangeKey = unsavedChangesExist(props.changeKey);

  const editorState = useRef<EditorState | null>(null);
  const originalHtml = useRef("");
  const [rawContent, setRawContent] = useState("");
  const [cookieTrail, setCookieTrail] = useState("trail");

  const lineThroughStyle = { textDecoration: "line-through" };
  const lineThroughRenderConfig = { element: "span", style: lineThroughStyle }; // This specific style is picked up by Aspose.Words's HTML conversion

  const FieldTagsDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={classes.fieldTag}>
        {props.children}
      </span>
    );
  };
  const FieldFormatClauseDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={classes.fieldFormatClause}>
        {props.children}
      </span>
    );
  };

  const OpenIfDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.ifTag} ${classes.openTag}`}>
        {props.children}
      </span>
    );
  };

  const CloseIfDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.ifTag} ${classes.closeTag}`}>
        {props.children}
      </span>
    );
  };

  const ElseDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.ifTag} ${classes.elseTag}`}>
        {props.children}
      </span>
    );
  };

  const OpenForeachDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.foreachTag} ${classes.openTag}`}>
        {props.children}
      </span>
    );
  };

  const CloseForeachDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.foreachTag} ${classes.closeTag}`}>
        {props.children}
      </span>
    );
  };

  const VarTagDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.varTag}`}>
        {props.children}
      </span>
    );
  };

  const DocTagDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={classes.docTag}>
        {props.children}
      </span>
    );
  };

  const VarTagOpenDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.varTag} ${classes.openTag}`}>
        {props.children}
      </span>
    );
  };

  const VarTagCloseDecorator = (props: any) => {
    return (
      <span spellCheck="false" className={`${classes.markupTag} ${classes.varTag} ${classes.closeTag}`}>
        {props.children}
      </span>
    );
  };

  useEffect(() => {
    editorState.current = null;

    const originalContentState = generateCleanContentStateFromHtml(props.html);

    const originalRawContent = JSON.stringify(convertToRaw(originalContentState));
    setRawContent(originalRawContent);

    const html = getHtmlFromContentState(originalContentState);
    originalHtml.current = html;
  }, [props.html]);

  function getHtmlFromContentState(contentState: ContentState) {
    const currentStateHTML = stateToHTML(contentState, {
      inlineStyles: {
        REDACTED: lineThroughRenderConfig,
        INLINEREDACTED: lineThroughRenderConfig,
        STRIKETHROUGH: lineThroughRenderConfig,

        // TODO: This is not a solution, and might need to come out when we DO fix this properly; see #69526 for lots of detail!
        HIGHLIGHT: { element: "span", style: { backgroundColor: "yellow" } }
      }
    });
    return currentStateHTML.replace(/‘|’/g, "'").replace(/“|”/g, '"');
  }

  function getContentAsHtml() {
    const currentContent = editorState.current ? editorState.current.getCurrentContent() : ContentState.createFromText("");
    return currentContent.isEmpty() ? null : getHtmlFromContentState(currentContent);
  }

  function getNewHtmlWithTextInserted(textToInsert: string): string | null {
    const newContentState = Modifier.insertText(
      editorState.current!.getCurrentContent(),
      editorState.current!.getSelection(),
      textToInsert
    );
    return getHtmlFromContentState(newContentState);
  }

  useEffect(
    () =>
      props.passContentRetriever(
        () => getContentAsHtml(),
        (textToInsert) => getNewHtmlWithTextInserted(textToInsert)
      ),
    [editorState]
  );

  const previousHtml = useRef<string | null>(null);

  const determineCaretPositionWithinForeaches = (state: EditorState) => {
    const selection = state.getSelection();
    const contentBlocks = state.getCurrentContent().getBlocksAsArray();
    let contentBeforeCaret = "";
    for (let i = 0; i < contentBlocks.length; i++) {
      const contentBlock = contentBlocks[i];
      if (contentBlock.getKey() === selection.getFocusKey()) {
        const blockText = contentBlock.getText();
        contentBeforeCaret += blockText.substring(0, selection.getFocusOffset());
      } else {
        contentBeforeCaret += contentBlock.getText();
      }
    }
    const foreachOpens = Array.from(contentBeforeCaret.matchAll(/<<foreach \[in ([^\]]+)\]>>/gim)).map((match) => {
      return {
        index: match.index,
        field: match[1] as string | undefined
      };
    });
    const foreachCloses = Array.from(contentBeforeCaret.matchAll(/<<\/foreach>>/gim)).map((match) => {
      return {
        index: match.index,
        field: undefined as string | undefined
      };
    });
    const foreachesInOrder = _.orderBy(_.union(foreachOpens, foreachCloses), ["index"]);
    const currentTrail: (string | undefined)[] = [];
    foreachesInOrder.forEach((entry) => {
      if (entry.field) {
        currentTrail.push(entry.field);
      } else {
        currentTrail.pop();
      }
    });
    setCookieTrail(currentTrail.join("."));
  };

  const reportUnsavedChanges = (state: EditorState) => {
    if (!props.reportUnsavedChanges || unsavedChangesExistForChangeKey) return; // skip the expensive conversion if we already know there are unsaved changes

    const currentHtml = getHtmlFromContentState(state.getCurrentContent());

    if (previousHtml.current === null) {
      previousHtml.current = currentHtml;
      return;
    }

    if (currentHtml.replace("\n", "") !== originalHtml.current.replace("\n", "") && currentHtml !== previousHtml.current) {
      unsavedChanges(props.changeKey);
    }

    previousHtml.current = currentHtml;
  };

  const doOnChangeStateUpdateChecks = useCallback(
    (state: EditorState) => {
      determineCaretPositionWithinForeaches(state);
      reportUnsavedChanges(state);

      if (props.onChange) {
        props.onChange();
      }
    },
    [unsavedChangesExistForChangeKey, unsavedChanges, props.changeKey, props.reportUnsavedChanges]
  );

  const controls = [
    "title",
    "bold",
    "italic",
    "underline",
    "highlight",
    "link",
    "numberList",
    "bulletList",
    ...(props.redaction ? ["redact"] : [])
  ];
  const inlineToolbarControls = ["bold", "italic", "underline", "highlight"];

  const customControls: TCustomControl[] = [];

  if (props.showMergeFields) {
    const mergeFieldControl = getInputHtmlMergeFieldControl(props.mergeFieldType, cookieTrail);
    controls.push(mergeFieldControl.name);
    customControls.push(mergeFieldControl);
  }

  if (props.redaction) {
    controls.push("redacted");
    customControls.push({
      name: "redacted",
      type: "inline",
      component: (componentProps) => (
        <Button
          id={componentProps.id}
          startIcon={<FontAwesomeIcon icon={faSquareFull} />}
          onMouseDown={(e) => {
            componentProps.onMouseDown(e);
            if (props.onRedact) {
              props.onRedact();
            }
          }}
          color={componentProps.active ? "primary" : "inherit"}
          disabled={componentProps.disabled}
          sx={{ ml: "auto" }}>
          Redact
        </Button>
      ),
      inlineStyle: lineThroughStyle
    });

    inlineToolbarControls.push("inlineredacted");
    customControls.push({
      name: "inlineredacted",
      type: "inline",
      component: (componentProps) => (
        <IconButton
          id={componentProps.id}
          onMouseDown={(e) => {
            componentProps.onMouseDown(e);
            if (props.onRedact) {
              props.onRedact();
            }
          }}
          color={componentProps.active ? "primary" : "inherit"}
          disabled={componentProps.disabled}>
          <FontAwesomeIcon icon={faSquareFull} />
        </IconButton>
      ),
      inlineStyle: lineThroughStyle
    });
  }

  const decorators = props.templateMarkup
    ? [
        // decorator set for Aspose merge fields on the Motions and Additional Comments screens
        {
          component: FieldTagsDecorator,
          regex: /<<\[[a-zA-Z0-9 \.]+\](:["a-zA-Z ,]+)?( \-html)?>>/gim
        },
        {
          // doesn't currently do anything because decorators don't nest, but it could in the future
          component: FieldFormatClauseDecorator,
          regex: /(?<=\]: ?")[^"]+(?=">>)/gim
        },
        {
          component: VarTagDecorator,
          regex: /<<var \[[^\]]+\]>>/gim
        },
        {
          component: OpenIfDecorator,
          regex: /<<if \[[^\]]+\]>>/gim
        },
        {
          component: CloseIfDecorator,
          regex: /<<\/if>>/gim
        },
        {
          component: ElseDecorator,
          regex: /<<else>>/gim
        },
        {
          component: OpenForeachDecorator,
          regex: /<<foreach \[[^\]]+\]>>/gim
        },
        {
          component: CloseForeachDecorator,
          regex: /<<\/foreach>>/gim
        },
        {
          component: DocTagDecorator,
          regex: /<<doc \[[^\]]+\][^>]*>>/gim
        },
        {
          component: VarTagDecorator, // catch-all for any other tags
          regex: /<<[^\>]*>>/gim
        },
        {
          component: VarTagOpenDecorator, // catch-all for \n's because multi-line stuff isn't how mui-rte works
          regex: /<<[^\>]*/gim
        },
        {
          component: VarTagCloseDecorator, // catch-all for \n's because multi-line stuff isn't how mui-rte works
          regex: /[^\[]*\]?>>/gim
        }
      ]
    : props.showMergeFields
    ? [
        // decorator set for the Email Templates screen
        {
          component: FieldTagsDecorator,
          regex: /\[\[[a-zA-Z0-9]+\]\]/gim
        },
        {
          component: OpenIfDecorator,
          regex: /\[\[OptionalBlockStart:[a-zA-Z0-9]+\]\]/gim
        },
        {
          component: CloseIfDecorator,
          regex: /\[\[OptionalBlockEnd:[a-zA-Z0-9]+\]\]/gim
        },
        {
          component: OpenForeachDecorator,
          regex: /\[\[TableStart:[a-zA-Z0-9]+\]\]/gim
        },
        {
          component: CloseForeachDecorator,
          regex: /\[\[TableEnd:[a-zA-Z0-9]+\]\]/gim
        }
      ]
    : [];

  // If the toolbar is showing, we need to account for the offset so the clickable area is accurate
  const minHeight = props.hideToolbar ? props.minHeight ?? "46px" : `calc(${props.minHeight ?? "100%"} - 53.5px)`;
  const rteTheme: TMUIRichTextEditorStyles = {
    overrides: {
      MUIRichTextEditor: {
        root: {
          border: props.readOnly ? "none" : `1px solid ${props.error ? appTheme.palette.error.main : appTheme.palette.border}`,
          borderRadius: appTheme.shape.borderRadius,
          flex: 1
        },
        container: {
          margin: 0,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative"
        },
        editor: {
          padding: 0,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: minHeight
        },
        editorContainer: {
          margin: 0,
          padding: 0,
          flex: 1,
          display: "flex !important",
          flexDirection: "column",
          cursor: props.readOnly ? "default" : "text",
          "& .DraftEditor-root, & .DraftEditor-editorContainer, & .public-DraftEditor-content": {
            height: "100%",
            flex: 1
          },
          "& .DraftEditor-editorContainer, & .public-DraftEditor-content": {
            minHeight: minHeight
          },
          "& .public-DraftEditor-content": {
            padding: props.readOnly ? 0 : smallTextFieldPadding
          }
        },
        toolbar: {
          padding: appTheme.spacing(1),
          borderBottom: `1px solid ${appTheme.palette.border}`,
          display: "flex"
        },
        placeHolder: {
          padding: smallTextFieldPadding,
          position: "absolute",
          width: "100%",
          minHeight: minHeight,
          top: props.hideToolbar ? "0" : "53.5px"
        }
      }
    }
  };
  Object.assign(appTheme, rteTheme);

  // MUI-rte doesn't include handlePastedText in the type definition for these props despite draft-js including support for it.
  // Because of this, we have to trick typescript into letting us pass it in by unwrapping an object with it as part of the
  // props for the editor. If the module ever adds it to the type definition, we can get rid of this hack.
  let draftEditorProps = {
    draftEditorProps: {
      spellCheck: true,
      // Including this stops draft-js from nuking spaces leading whitespace which might be used for formatting
      handlePastedText: (text: string, pastedHtml: string | null, state: EditorState) => {
        let pastedContent: ContentState;
        pastedContent = !pastedHtml ? ContentState.createFromText(text) : generateCleanContentStateFromHtml(pastedHtml);
        let newContent = Modifier.replaceWithFragment(state.getCurrentContent(), state.getSelection(), pastedContent.getBlockMap());
        editorState.current = EditorState.createWithContent(newContent);
        setRawContent(JSON.stringify(convertToRaw(editorState.current.getCurrentContent())));
        doOnChangeStateUpdateChecks(editorState.current);
        return true;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: props.fullHeight ? "100%" : undefined,
        mt: props.addNormalMargin ? 2 : undefined,
        mb: props.addNormalMargin ? 1 : undefined
      }}>
      {props.label && (
        <Typography variant="body1" className={cx(classes.label, { [classes.errorLabel]: Boolean(props.error) })} sx={{ mb: 0.5 }}>
          {`${props.label}${props.required ? "*" : ""}`}
          {!props.readOnly && props.hideToolbar && (
            <Tooltip title="Rich text">
              <Box
                sx={{
                  display: "inline",
                  ml: 1,
                  position: "relative",
                  top: "-0.5em",
                  fontSize: "0.6em",
                  "& > :not(:first-child)": { ml: 0.25 }
                }}>
                <FontAwesomeIcon icon={faBold} />
                <FontAwesomeIcon icon={faItalic} />
                <FontAwesomeIcon icon={faUnderline} />
              </Box>
            </Tooltip>
          )}
        </Typography>
      )}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }} className={props.className}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={rteTheme}>
            <MUIRichTextEditor
              keyCommands={[
                {
                  key: 13,
                  name: "ctrl+enter", // ideally it'd be Shift+Enter, but mui-rte keyCommands only do Ctrl.
                  callback: (state) => {
                    return RichUtils.insertSoftNewline(state);
                  }
                }
              ]}
              defaultValue={rawContent}
              onChange={(state) => {
                editorState.current = state;
                doOnChangeStateUpdateChecks(state);
              }}
              controls={controls}
              toolbarButtonSize="small"
              toolbar={!props.hideToolbar}
              inlineToolbar
              inlineToolbarControls={inlineToolbarControls}
              readOnly={props.readOnly}
              customControls={customControls}
              decorators={decorators}
              {...draftEditorProps} // Unwrapping the props here allows us to pass in props not in MUI-rte's type def
            />
            {props.error && <FormHelperText error>{props.error}</FormHelperText>}
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
    </Box>
  );
};

export default RichTextEditor;
