import React, { useState } from "react";
import { Ina } from "./models";
import { GridColDef } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { InaPanel } from "./InaPanel";
import { makeStyles } from "../makeStyles";
import { datagridStyles, inaPanelStyles, tableStyles } from "styles/common";
import { dataGridDateValueFormatter } from "../util/formats";
import { Button } from "@mui/material";
import EditPprpProgramTaskDialog from "../pprp/EditPprpProgramTaskDialog";

const useStyles = makeStyles<Props>()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme)
}));

interface Props {
  title: string;
  inas: Ina[];
  getNavigationRoute?: (ina: Ina) => string;
  getFixedNavigationRoute?: () => string;
  className?: string;
  completable?: boolean;
  hideSubmissionDateColumn?: boolean;
  hideSubmittedByColumn?: boolean;
  showProgramLocationColumn?: boolean;
  getCompletionBlockingReason?: (ina: Ina) => string | null;
  extraColumns?: GridColDef[];
  headerActions?: (selectedInas: Ina[]) => React.ReactElement;
  extraActions?: (ina: Ina) => React.ReactElement;
  extraActionsWidth?: number;
  getRowDetails?: (ina: Ina) => React.ReactElement;
  getDetailPanelHeight?: (ina: Ina) => number;
  showProgramEntityNumber?: boolean;
}

export const PprpProgramTaskInaPanel: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const [editingTaskId, setEditingTaskId] = useState<number | null>(null);

  const columns: GridColDef<Ina>[] = [
    {
      field: "taskNo",
      headerName: "Task No.",
      width: 80,
      cellClassName: ({ row }) =>
        cx(classes.defaultLeftBorder, {
          [classes.new]: row.isNew,
          [classes.semiNew]: row.isSemiNew && !row.isNew
        }),
      valueGetter: ({ row }) => row.programTask!.id
    },
    {
      field: "entityNo",
      headerName: `${props.showProgramEntityNumber ? "Program" : "Firm"} Entity No.`,
      headerClassName: classes.wrapHeader,
      width: 90,
      valueGetter: (params: { row: Ina }) =>
        props.showProgramEntityNumber
          ? params.row.programTask!.pprpProgram?.programEntityNumber ?? "--"
          : params.row.programTask!.pprpProgram?.firm.entityNumber ?? params.row.programTask!.firm?.entityNumber ?? "--"
    },
    {
      field: "firmName",
      headerName: "Firm Name",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) =>
        row.programTask!.pprpProgram?.firm.name ??
        row.programTask!.firm?.name ??
        row.programTask?.applyForNewProviderFirmName ??
        row.programTask?.applyForNewProviderPprpName
    },
    {
      field: "programPath",
      headerName: "Program Path",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.programTask!.pprpProgram?.programPath ?? "--"
    },
    {
      field: "programLocation",
      headerName: "Program Location",
      headerClassName: classes.wrapHeader,
      flex: 1,
      hide: !props.showProgramLocationColumn,
      valueGetter: ({ row }) =>
        `${row.programTask!.pprpProgram?.address1}, ${
          row.programTask!.pprpProgram?.address2 ? row.programTask!.pprpProgram?.address2 + ", " : ""
        } ${row.programTask!.pprpProgram?.city}`
    },
    {
      field: "taskType",
      headerName: "Task Type",
      flex: 0.5,
      valueGetter: ({ row }) => row.programTask!.type.typeName
    },
    {
      field: "submittedBy",
      headerName: "Submitted by",
      headerClassName: classes.wrapHeader,
      width: 160,
      hide: props.hideSubmittedByColumn,
      valueGetter: ({ row }) => row.programTask!.submittedBy
    },
    {
      field: "submissionDate",
      headerName: "Submission Date",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 100,
      hide: props.hideSubmissionDateColumn,
      valueGetter: ({ row }) => row.programTask!.submissionDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "taskStatus",
      headerName: "Task Status",
      width: 100,
      valueGetter: ({ row }) => _.startCase(row.programTask!.status)
    }
  ];

  return (
    <>
      <InaPanel
        columns={columns}
        title={props.title}
        inas={props.inas}
        getNavigationRoute={props.getNavigationRoute}
        getFixedNavigationRoute={props.getFixedNavigationRoute}
        className={props.className}
        completable={props.completable}
        getCompletionBlockingReason={props.getCompletionBlockingReason}
        headerActions={props.headerActions}
        extraActions={(ina) => (
          <>
            {props.extraActions ? props.extraActions(ina) : null}
            <Button
              variant="outlined"
              size="small"
              className={classes.actionTextButton}
              onClick={() => setEditingTaskId(ina.programTask!.id)}>
              View Task
            </Button>
          </>
        )}
        actionsWidth={(props.extraActionsWidth ?? 0) + (props.completable ? 70 : 0) + 150}
        getRowDetails={props.getRowDetails}
        getDetailPanelHeight={props.getDetailPanelHeight}
        defaultSortModel={[
          {
            field: "pprpProgramTask.submissionDate",
            sort: "asc"
          }
        ]}
        itemType="task"
      />
      {editingTaskId && <EditPprpProgramTaskDialog taskId={editingTaskId} handleClose={() => setEditingTaskId(null)} />}
    </>
  );
};
