import { OperationVariables, QueryResult } from "@apollo/client";
import { Dialog, DialogContent, DialogActions, Button, Typography, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AxiosResponse } from "axios";
import { useAxios } from "auth/SecureAxios";
import { QuestionContainerTemplate } from "checklists";
import { useNotifications } from "notifications";

import React, { FormEvent, useState } from "react";
import { useAppConfig } from "util/AppConfig";

interface Props {
  onClose: () => void;
  pprp: boolean;
  masterChecklistQuery: QueryResult<{ masterChecklistQuestionContainers: QuestionContainerTemplate[] }, OperationVariables>;
}

export const MasterChecklistImportWarningDialog: React.FunctionComponent<Props> = (props) => {
  const { secureAxios } = useAxios();
  const appConfig = useAppConfig();
  const [isLoading, setIsLoading] = useState(false);
  const notifications = useNotifications();

  const importMasterChecklist = async (e: FormEvent<HTMLInputElement>) => {
    try {
      if (e.currentTarget && e.currentTarget.files && e.currentTarget.files[0]) {
        let currentFile = e.currentTarget.files[0];
        let formData = new FormData();
        formData.append("files", currentFile);

        setIsLoading(true);
        let postResult: AxiosResponse<{ successful: number; total: number; error: string | null }>;
        const postRequestEndpoint = `${appConfig.apiEndpoint}/api/master-checklist/import?pprp=${props.pprp.toString()}`;
        postResult = await secureAxios.post(postRequestEndpoint, formData);
        if (postResult === undefined || postResult?.status === 401) {
          postResult = await secureAxios.post(postRequestEndpoint, formData);
        }

        if (postResult?.status === 200) {
          if (postResult.data.error) {
            notifications.error(postResult.data.error);
          } else {
            await props.masterChecklistQuery.refetch();
            notifications.success(`Successfully merged ${postResult.data.successful} of ${postResult.data.total} sheets.`);
          }
        } else {
          throw Error(JSON.stringify(postResult?.data));
        }
        setIsLoading(false);
      }
    } catch (err: any) {
      if (appConfig.showOriginalExceptionMessages) {
        notifications.error(err);
      } else {
        notifications.error("A server error occurred.");
      }
      setIsLoading(false);
    }
    props.onClose();
  };
  return (
    <Dialog
      open={true}
      onClose={() => {
        props.onClose();
      }}>
      <DialogTitle>
        <Typography variant="h3">Import Master Checklist</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Importing the Master Checklist deletes and replaces all sections and questions. Are you sure you would like to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={() => {
            props.onClose();
          }}>
          Cancel
        </Button>
        <label htmlFor="contained-button-upload">
          <input hidden id="contained-button-upload" type="file" onInput={(e) => importMasterChecklist(e)} />
          <LoadingButton loading={isLoading} component="span" variant="contained" color="primary">
            Continue
          </LoadingButton>
        </label>
      </DialogActions>
    </Dialog>
  );
};
