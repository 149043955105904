import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";

interface MonitoringFailedWorkflowSelectorProps {
  sendToComitteeOnFail: boolean;
  index: number;
  onChange: (value: boolean) => void;
  readOnly: boolean;
}

const MonitoringFailedWorkflowSelector: React.FC<MonitoringFailedWorkflowSelectorProps> = (props) => {
  const { sendToComitteeOnFail, index, onChange, readOnly } = props;
  return (
    <div>
      <Typography>Monitoring Failed Remediation</Typography>
      <ReadOnlyWrapper readOnly={readOnly} title="">
        <RadioGroup
          name={`monitorings[${index}].sendToComitteeOnFail`}
          value={sendToComitteeOnFail}
          onChange={(e) => onChange(e.target.value === "true")}>
          <FormControlLabel label="Cancel monitoring and send to Registrations Committee" control={<Radio />} value={true} />
          <FormControlLabel label="Continue monitoring" control={<Radio />} value={false} />
        </RadioGroup>
      </ReadOnlyWrapper>
    </div>
  );
};

export default MonitoringFailedWorkflowSelector;
