import { useMutation, useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import _ from "lodash";
import React, { useState } from "react";
import CrudTable from "../common/CrudTable";
import { Helmet } from "react-helmet";
import { CommitteeMember, FetchCommitteeMembersQuery, DeleteCommitteeMemberMutation } from ".";
import { datagridStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import EditMemberDialog from "./EditMemberDialog";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";
import { dataGridDateValueFormatter } from "util/formats";
import { GridCellParams, GridSortModel } from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

interface Props {
  pprp?: boolean;
}

const CommitteeMembersScreen: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "isActive", sort: "desc" },
    { field: "firstName", sort: "asc" }
  ]);

  const membersQuery = useQuery<{ committeeMembers: CommitteeMember[] }, { isPprp: boolean; includeInactive: boolean }>(
    FetchCommitteeMembersQuery,
    {
      variables: { isPprp: props.pprp ?? false, includeInactive: true }
    }
  );

  const [deleteMemberMutate, deleteMemberMutation] = useMutation<{ committeeMembers: { delete: CommitteeMember } }, { memberId: number }>(
    DeleteCommitteeMemberMutation,
    { refetchQueries: [{ query: FetchCommitteeMembersQuery, variables: { isPprp: props.pprp ?? false } }] }
  );

  const members = membersQuery.data?.committeeMembers ? _.orderBy(membersQuery.data.committeeMembers, (cm) => cm.name) : [];

  return (
    <>
      <Helmet>
        <title>Committee Members - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Committee Members" />
      <CrudTable
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        rows={members}
        loading={membersQuery.loading}
        columnDefinitions={[
          {
            field: "firstName",
            headerName: "First Name",
            flex: 1
          },
          {
            field: "lastName",
            headerName: "Last Name",
            flex: 1
          },
          {
            field: "designation",
            headerName: "Designation",
            flex: 2
          },
          {
            field: "startDate",
            type: "date",
            headerName: "Start Date",
            flex: 1,
            valueFormatter: dataGridDateValueFormatter
          },
          {
            field: "endDate",
            type: "date",
            headerName: "End Date",
            flex: 1,
            valueFormatter: dataGridDateValueFormatter
          },
          {
            field: "isChair",
            type: "boolean",
            headerName: "Committee Chair",
            cellClassName: (params) =>
              cx({
                [classes.hideBooleanXsInCell]: !(params.row as CommitteeMember).isChair
              }),
            width: 150
          },
          ...(props.pprp
            ? [
                {
                  field: "isRegistrar",
                  type: "boolean",
                  headerName: "Registrar",
                  cellClassName: (params: GridCellParams<any, any, any>) =>
                    cx({
                      [classes.hideBooleanXsInCell]: !(params.row as CommitteeMember).isRegistrar
                    }),
                  width: 150
                }
              ]
            : []),
          {
            field: "isActive",
            type: "boolean",
            headerName: "Active",
            cellClassName: (params) =>
              cx({
                [classes.hideBooleanXsInCell]: !(params.row as CommitteeMember).isActive
              })
          }
        ]}
        storageKey="Committee Members"
        noDataMessage="No committee members."
        renderAddDialog={(rdprops) => (
          <EditMemberDialog title="Add Committee Member" handleClose={rdprops.onClose} confirmButtonText="Add" pprp={props.pprp} />
        )}
        renderEditDialog={(id, rdprops) => {
          return (
            <EditMemberDialog
              member={members.find((m) => m.id === id)}
              title="Edit Committee Member"
              handleClose={rdprops.onClose}
              confirmButtonText="Save"
              pprp={props.pprp}
            />
          );
        }}
        disableDeleteReason={(m) => (m.isActive ? "Cannot delete active committee members." : "")}
        renderDeleteDialog={(id, rdprops) => (
          <ConfirmationDialog
            title="Delete Committee Member"
            open={true}
            body={<DialogContentText>Are you sure you want to remove {members.find((m) => m.id === id)?.name}?</DialogContentText>}
            cancel={rdprops.onClose}
            loading={deleteMemberMutation.loading}
            confirm={() => {
              deleteMemberMutate({ variables: { memberId: id } });
              rdprops.onClose();
            }}
            confirmButtonText="Delete"
          />
        )}
      />
    </>
  );
};

export default CommitteeMembersScreen;
