import React from "react";
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import {
  SignOffChecklistItemTemplate,
  SignOffChecklistItemTemplateInput,
  SignOffChecklistItemType as SignOffChecklistItemType,
  SignOffChecklistItemTypeCode as SignOffChecklistItemTypeCode
} from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { Select as FmuiSelect, TextField as FmuiTextField } from "formik-mui";
import { SignOffChecklistItemTemplatesQuery } from "./SignOffChecklistItemTemplatesScreen";
import { LoadingButton } from "@mui/lab";

const AddSignOffChecklistItemTemplateMutation = gql`
  mutation AddSignOffChecklistItemTemplate($item: SignOffChecklistItemTemplateInput!) {
    signOffChecklistItemTemplates {
      add(item: $item) {
        id
        questionText
      }
    }
  }
`;

const EditSignOffChecklistItemTemplateMutation = gql`
  mutation EditSignOffChecklistItemTemplate($item: SignOffChecklistItemTemplateInput!) {
    signOffChecklistItemTemplates {
      update(item: $item) {
        id
        questionText
        signOffChecklistItemType {
          id
          name
        }
        sortOrder
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  checklistItemTemplate?: SignOffChecklistItemTemplate;
  signOffChecklistItemTypeCode: SignOffChecklistItemTypeCode;
  signOffChecklistItemTypes: SignOffChecklistItemType[];
}

const EditSignOffChecklistItemTemplateDialog = (props: Props) => {
  const validationSchema = Yup.object().shape({
    questionText: Yup.string().required("Enter the item text."),
    signOffChecklistItemTypeCode: Yup.string().required("Select a checklist type.")
  });

  const [addSignOffItem, { loading: loadingAdd }] = useMutation<
    { signOffChecklistItemTemplates: { add: SignOffChecklistItemTemplate } },
    { item: SignOffChecklistItemTemplateInput }
  >(AddSignOffChecklistItemTemplateMutation, {
    refetchQueries: [{ query: SignOffChecklistItemTemplatesQuery }]
  });

  const [editSignOffItems, { loading: loadingEdit }] = useMutation<
    { signOffChecklistItemTemplates: { update: SignOffChecklistItemTemplate } },
    { item: SignOffChecklistItemTemplateInput }
  >(EditSignOffChecklistItemTemplateMutation);

  const mutate = Boolean(props.checklistItemTemplate?.id) ? editSignOffItems : addSignOffItem;
  const loading = Boolean(props.checklistItemTemplate?.id) ? loadingEdit : loadingAdd;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          questionText: props.checklistItemTemplate?.questionText ?? "",
          signOffChecklistItemTypeCode: props.signOffChecklistItemTypeCode
        }}
        onSubmit={async (values) => {
          const item: SignOffChecklistItemTemplateInput = {
            id: props.checklistItemTemplate?.id,
            signOffChecklistItemTypeId: props.signOffChecklistItemTypes.find((ct) => ct.typeCode === values.signOffChecklistItemTypeCode)!
              .id,
            questionText: values.questionText
          };
          await mutate({
            variables: { item }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>Sign-off items appear on checklists for their specified group when completing a Practice Review.</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormikField component={FmuiTextField} name="questionText" label="Question Text" fullWidth multiline required />
                </Grid>
                <Grid item xs={8}>
                  {/* The FmuiSelect component doesn't render its own label so an InputLabel is required */}
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="signOffChecklistItemTypeCode-label" shrink required>
                      Sign-off Checklist Item Type
                    </InputLabel>
                    <FormikField
                      component={FmuiSelect}
                      name="signOffChecklistItemTypeCode"
                      labelId="signOffChecklistItemTypeCode-label"
                      // This is needed for the input to place a notch in the top border for the label
                      label="Sign-off Checklist Item Type *"
                      margin="dense"
                      required>
                      {props.signOffChecklistItemTypes.map((checklistType) => (
                        <MenuItem value={checklistType.typeCode} key={checklistType.id}>
                          {checklistType.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditSignOffChecklistItemTemplateDialog;
