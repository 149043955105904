import { gql } from "@apollo/client";

const ReviewedClientDetailFieldsFragment = gql`
  fragment ReviewedClientDetailFields on ReviewedClient {
    id
    practiceReviewId
    name
    partnerName
    refNum
    reviewedByUserId
    engagementTypeId
    engagementType {
      id
      name
    }
    clientFile {
      businessNature
      fiscalYearEnd
      cpabReview
      competitiveBid
      isHoursNotFees
      feesOrHoursAmount
      materiality
      assets
      liabilities
      revenue
      netIncomeBeforeTaxes
      reportDate
    }
    candidate {
      idNo
      perEffectiveDate
      monthsExperience
      mentorReviews
      requiredNoOfReviews
      rotation1
      rotation1Duration
      rotation2
      rotation2Duration
      rotation3
      rotation3Duration
      rotation4
      rotation4Duration
      reasonableAuditChargeableHours
      reasonableReviewChargeableHours
      reasonableOtherAssuranceChargeableHours
      reasonableCompilationChargeableHours
      reasonableTaxationChargeableHours
      reasonableOtherChargeableHours
      additionalComments
    }
  }
`;

export const AddReviewedClientMutation = gql`
  ${ReviewedClientDetailFieldsFragment}
  mutation AddReviewedClient($reviewedClient: ReviewedClientInput!) {
    reviewedClients {
      add(reviewedClient: $reviewedClient) {
        ...ReviewedClientDetailFields
      }
    }
  }
`;

export const EditReviewedClientMutation = gql`
  ${ReviewedClientDetailFieldsFragment}
  mutation EditReviewedClient($reviewedClient: ReviewedClientInput!) {
    reviewedClients {
      update(reviewedClient: $reviewedClient) {
        ...ReviewedClientDetailFields
      }
    }
  }
`;

export const DeleteReviewedClientMutation = gql`
  mutation DeleteReviewedClient($id: Int) {
    reviewedClients {
      delete(id: $id) {
        id
      }
    }
  }
`;

const ReviewedClientListExtraDisplayFieldsFragment = gql`
  fragment ReviewedClientListExtraDisplayFields on ReviewedClient {
    engagementType {
      id
      name
    }
    reviewer {
      id
      name
    }
    checklistCompletion {
      someQuestionsIncomplete
      someQuestionsComplete
      reportableDeficienciesExist
      nonReportableDeficienciesExist
      empty
    }
  }
`;

const EngagementTypeSelectFieldsFragment = gql`
  fragment EngagementTypeSelectFields on EngagementType {
    id
    name
    engagementGroup {
      id
      name
      sortOrder
    }
  }
`;

export const ReviewedClientsListAndDropdownContentsQuery = gql`
  ${ReviewedClientDetailFieldsFragment}
  ${ReviewedClientListExtraDisplayFieldsFragment}
  ${EngagementTypeSelectFieldsFragment}
  query FetchReviewedClients($id: Int!) {
    practiceReviewById(id: $id) {
      id
      reviewedClients {
        ...ReviewedClientDetailFields
        ...ReviewedClientListExtraDisplayFields
      }
      engagementTypes {
        ...EngagementTypeSelectFields
      }
      firm {
        latestPartners {
          name
        }
      }
    }
  }
`;
