import { gql, useMutation, useQuery } from "@apollo/client";
import { DialogContentText, Link, Typography, Stack } from "@mui/material";
import React from "react";
import { Firm, HistoryItem, PracticeReview } from ".";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import CrudTable from "../common/CrudTable";
import EditHistoricalPrDialog from "./EditHistoricalPrDialog";
import { DataGridHistoryItem, isDecision, isHistoricalPr, PRBaseStatusCode } from "./models";
import { makeStyles } from "../makeStyles";
import PrLink from "../common/PrLink";
import { GridSortItem } from "@mui/x-data-grid-pro";
import { PprpProgram } from "../pprp";
import { getOpenableUrl } from "../util/utilities";

const DeletePrHistoryMutation = gql`
  mutation DeleteHistoricalPr($id: Int) {
    historicalPrs {
      delete(id: $id) {
        id
      }
    }
  }
`;

const PracticeReviewDetailsFragment = gql`
  fragment PracticeReviewDetails on PracticeReview {
    confidentialClientListUrl
    schedulingQuestionnaireUrl
    decisionLetterUrl
    selfEvaluationQuestionnaireUrl
    practiceReviewReportUrl
    finalReportPackageUrl
    sharePointUrl
    reviewYear
    prNumber
    isPprpReview
    id
    status {
      id
      baseStatusCode
      statusName
    }
    mostAuthoritativeDecision {
      id
      isComply
      nonComplyFinancial
      nonComplyAudit
      nonComplyReview
      nonComplyQualityManagement
      nonComplyTax
      nonComplyCompilation
      nonComplyOther
      nonComplyOtherSpecified
      complianceZone {
        name
        zoneCode
      }
    }
  }
`;

export const FetchFirmHistoryItemsQuery = gql`
  query FetchFirmHistoryItems($firmId: Int!) {
    firmById(id: $firmId) {
      practiceReviews {
        ...PracticeReviewDetails
      }
      historicalPrs {
        id
        reviewYear
        prNumber
        isComply
        nonComplyFinancial
        nonComplyAudit
        nonComplyReview
        nonComplyQualityManagement
        nonComplyTax
        nonComplyCompilation
        nonComplyOther
        nonComplyOtherSpecified
        otherComments
      }
    }
  }
  ${PracticeReviewDetailsFragment}
`;

export const FetchProgramHistoryItemsQuery = gql`
  query FetchProgramHistoryItems($programId: Int!) {
    pprpProgramById(id: $programId) {
      practiceReviews {
        ...PracticeReviewDetails
      }
    }
  }
  ${PracticeReviewDetailsFragment}
`;

const useStyles = makeStyles()((theme) => ({
  comply: {
    color: theme.palette.success.main
  },
  nonComply: {
    color: theme.palette.error.main
  },
  rowDetails: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.common.white}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    position: "relative",
    top: "-1px"
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

const getAreasOfNonCompliance = (decision: HistoryItem | null | undefined) => {
  if (decision) {
    const areas = [];

    if (decision.nonComplyAudit) {
      areas.push("Audit");
    }

    if (decision.nonComplyFinancial) {
      areas.push("Financial");
    }

    if (decision.nonComplyQualityManagement) {
      areas.push("Quality Management");
    }

    if (decision.nonComplyReview) {
      areas.push("Review");
    }

    if (decision.nonComplyTax) {
      areas.push("Tax");
    }

    if (decision.nonComplyCompilation) {
      areas.push("Compilation");
    }

    if (decision.nonComplyOther || decision.nonComplyOtherSpecified) {
      areas.push(decision.nonComplyOtherSpecified ?? "Other");
    }

    return areas;
  }

  return [];
};

const ReviewHistory: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  const [deleteMutation, { loading: deleting }] = useMutation<{ statutoryHolidays: { delete: boolean } }, { id: number }>(
    DeletePrHistoryMutation,
    {
      refetchQueries: [{ query: FetchFirmHistoryItemsQuery, variables: { firmId: props.practiceReview.firm.id } }]
    }
  );

  const fetchFirmHistoryItems = useQuery<{ firmById: Firm }>(FetchFirmHistoryItemsQuery, {
    variables: {
      firmId: props.practiceReview.firm.id
    },
    skip: props.practiceReview.isPprpReview
  });

  const fetchProgramHistoryItems = useQuery<{ pprpProgramById: PprpProgram }>(FetchProgramHistoryItemsQuery, {
    variables: {
      programId: props.practiceReview.pprpProgram?.id ?? 0
    },
    skip: !props.practiceReview.isPprpReview || !props.practiceReview.pprpProgram
  });

  const prs = props.practiceReview.isPprpReview
    ? fetchProgramHistoryItems.data?.pprpProgramById.practiceReviews ?? []
    : (fetchFirmHistoryItems.data?.firmById.practiceReviews ?? []).filter((pr) => !pr.isPprpReview); // Do not show PPRP reviews in a PAF review's history
  const historicalPrs = (props.practiceReview.isPprpReview ? [] : fetchFirmHistoryItems.data?.firmById.historicalPrs) ?? [];
  const historyItems = React.useMemo(
    () =>
      [
        ...prs
          .filter(
            (pr) =>
              pr.mostAuthoritativeDecision &&
              pr.prNumber !== props.practiceReview.prNumber &&
              pr.status.baseStatusCode !== PRBaseStatusCode.Removed
          )
          .map((pr) => ({
            ...pr.mostAuthoritativeDecision,
            _gridId: `decision-${pr.mostAuthoritativeDecision!.id}`,
            practiceReview: pr
          })),
        ...historicalPrs.map((h) => ({ ...h, _gridId: `historicalPr-${h.id}` }))
      ] as DataGridHistoryItem[],
    [prs, historicalPrs]
  );

  const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
    {
      field: "reviewYear",
      sort: "desc"
    },
    {
      field: "prNumber",
      sort: "desc"
    }
  ]);
  return (
    <CrudTable
      title="History"
      addButtonText="Add Historical PR"
      noDataMessage="No previous practice reviews."
      loading={fetchFirmHistoryItems.loading}
      columnDefinitions={[
        {
          field: "reviewYear",
          headerName: "Year",
          sortable: true,
          valueGetter: (params) => {
            const row = params.row as HistoryItem;
            if (isDecision(row)) {
              return row.practiceReview!.reviewYear;
            } else if (isHistoricalPr(row)) {
              return row.reviewYear;
            }
          },
          flex: 2
        },
        {
          field: "prNumber",
          headerName: "PR No.",
          valueGetter: (params) => {
            const row = params.row as HistoryItem;
            if (isDecision(row)) {
              return row.practiceReview!.prNumber;
            } else if (isHistoricalPr(row)) {
              return row.prNumber;
            }
          },
          renderCell: (params) => {
            const row = params.row as HistoryItem;
            return isDecision(row) ? <PrLink practiceReview={row.practiceReview!} /> : <>{params.value}</>;
          },
          sortable: true,
          flex: 2
        },
        {
          field: "assessment",
          headerName: "Assessment",
          valueGetter: (params) => params.row.isComply,
          renderCell: (params) => {
            const row = params.row as HistoryItem;
            const complianceZone = isDecision(row) ? row.complianceZone : null;

            return (
              <span title={complianceZone?.name}>
                {params.value ? (
                  <span className={classes.comply}>Comply {complianceZone?.zoneCode}</span>
                ) : (
                  <span className={classes.nonComply}>Non-comply {complianceZone?.zoneCode}</span>
                )}
              </span>
            );
          },
          sortable: false,
          flex: 2
        },
        {
          field: "areasOfNonCompliance",
          headerName: "Areas of Non-compliance",
          valueGetter: (params) => {
            const areas = getAreasOfNonCompliance(params.row as HistoryItem);

            return areas?.length ? areas.join(", ") : "None";
          },
          sortable: true,
          flex: 5
        }
      ]}
      rows={historyItems}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      displayWithoutContainer
      disableSelectionOnClick
      renderEditDialog={(id, dialogProps) => {
        const historyItem = historyItems.find((h) => h._gridId === id);

        if (!historyItem || !isHistoricalPr(historyItem)) {
          return;
        }

        return (
          <EditHistoricalPrDialog
            handleClose={dialogProps.onClose}
            title="Edit Historical PR"
            {...historyItem}
            id={historyItem.id}
            firmId={props.practiceReview.firm.id}
          />
        );
      }}
      renderAddDialog={(dialogProps) => (
        <EditHistoricalPrDialog handleClose={dialogProps.onClose} title="Add Historical PR" firmId={props.practiceReview.firm.id} />
      )}
      renderDeleteDialog={(id, dialogProps) => {
        const historyItem = historyItems.find((h) => h._gridId === id);

        if (!historyItem || !isHistoricalPr(historyItem)) {
          return;
        }

        return (
          <ConfirmationDialog
            open={true}
            title="Delete Historical PR"
            body={
              <DialogContentText>
                {`Are you sure you want to delete the history item with PR Number: ${historyItem.prNumber}?`}
              </DialogContentText>
            }
            noDanger={false}
            confirm={() => deleteMutation({ variables: { id: historyItem.id! } }).then(dialogProps.onClose)}
            cancel={dialogProps.onClose}
            loading={deleting}
          />
        );
      }}
      disableEditReason={(row) => (!isHistoricalPr(row as HistoryItem) ? "This item was system-generated" : undefined)}
      disableDeleteReason={(row) => (!isHistoricalPr(row as HistoryItem) ? "This item was system-generated" : undefined)}
      getDetailPanelHeight={() => 116}
      getRowId={(r) => r._gridId}
    />
  );
};

export default ReviewHistory;
