import { gql, useQuery } from "@apollo/client";
import React from "react";
import CrudTable from "../common/CrudTable";
import EditEmailTemplateDialog from "./EditEmailTemplateDialog";
import { EmailTemplate } from "./models";
import { Helmet } from "react-helmet";
import { ScreenHeader } from "../common/ScreenHeader";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";

const FetchEmailTemplatesQuery = gql`
  query FetchEmailTemplates {
    emailTemplates {
      id
      templateName
      emailSubject
      emailBodyHtml
      isMeetingBased
      isProgramTaskBased
      hasSeparatePprpTemplate
      pprpEmailSubject
      pprpEmailBodyHtml
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const EmailTemplatesScreen: React.FunctionComponent = (props) => {
  const { classes } = useStyles();

  const query = useQuery<{ emailTemplates: EmailTemplate[] }>(FetchEmailTemplatesQuery);

  return (
    <>
      <Helmet>
        <title>Email Templates - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Email Templates" />
      <CrudTable
        columnDefinitions={[
          {
            field: "templateName",
            headerName: "Name",
            flex: 10,
            sortable: true
          },
          {
            field: "emailSubject",
            headerName: "Subject Template",
            flex: 30,
            sortable: false
          },
          {
            field: "hasSeparatePprpTemplate",
            headerName: "Separate PPRP Template",
            headerClassName: classes.wrapHeader,
            cellClassName: classes.hideBooleanXsInCell,
            type: "boolean",
            width: 120,
            sortable: true
          }
        ]}
        rows={query.data?.emailTemplates ?? []}
        loading={query.loading}
        storageKey="Email Templates"
        renderEditDialog={(id, dialogProps) => {
          const emailTemplate = query.data?.emailTemplates.find((t) => t.id === id);

          if (!emailTemplate) {
            return null;
          }

          return <EditEmailTemplateDialog id={id} emailTemplate={emailTemplate} handleClose={dialogProps.onClose} />;
        }}
      />
    </>
  );
};

export default EmailTemplatesScreen;
