import { useApolloClient, useMutation } from "@apollo/client";
import { useAxios } from "auth/SecureAxios";
import { AxiosResponse } from "axios";
import { forEach } from "lodash";
import { useNotifications } from "notifications";
import {
  AttachedDocument,
  DocType,
  PracticeReview,
  RemoveAttachedDocumentFromPracticeReviewMutation,
  UploadDocumentResponse
} from "practice-reviews";
import { useState } from "react";
import { useAppConfig } from "util/AppConfig";

export function checkFileNameResemblesReservedDocumentName(filename: string, errorMessage: string) {
  const reservedDocumentNames = [
    "PPRP Report",
    "Checklist Notes Report",
    "Committee Meeting Minutes",
    "Confidential Client List",
    "PD Declaration Form",
    "Decision Letter",
    "Deficiency Report",
    "Signed Deficiency Report",
    "Director's Presentation Format",
    "Firm Activity Log with Full Detail",
    "Firm Response Original",
    "Firm Response Redacted",
    "Schedule Notification Letter",
    "Office Listing Report",
    "Office Listing Report With Firm Names",
    "Practice Review Report",
    "Signed Draft PR Report",
    "Presentation Format",
    "Scheduling Question",
    "Standard Motions Summary",
    "Standard Motions Summary With Firm Names",
    "Student Question",
    "Tax Question",
    "Tax Management Question",
    "PPRP Self-Evaluation Question",
    "Exemption Letter",
    "Signed Exemption Letter",
    "Confirm PD Declaration"
  ];

  const documentNamesThisFilenameResembles = reservedDocumentNames
    .map((n) => n.toLowerCase())
    .filter((n) => filename.toLowerCase().indexOf(n) !== -1);
  if (!documentNamesThisFilenameResembles?.length) {
    return null;
  }
  const totalTerms = documentNamesThisFilenameResembles.length;
  var prohibitedTextString = documentNamesThisFilenameResembles.reduce(function (prevVal, currVal, idx) {
    const quotedString = `"${currVal}"`;

    return idx === 0
      ? quotedString
      : totalTerms > 2 && idx < totalTerms - 1
      ? prevVal + ", " + quotedString
      : prevVal + " or " + quotedString;
  }, "");
  return errorMessage.replaceAll("{match}", prohibitedTextString);
}

export function validateFileNameForSharePoint(filename: string) {
  let error: string | null = null;

  if (filename.length >= 128) {
    error = "The name is too long. File names must be under 128 characters.";
  }

  if (filename.indexOf("..") !== -1) {
    error = "The name contains consecutive '.' characters. Only one period in a row is allowed.";
  }

  if (filename.slice(0, 1) === ".") {
    error = "The name starts with a '.' character, which is not allowed.";
  }

  if (filename.slice(filename.length - 1, filename.length) === ".") {
    error = "The name ends with a '.' character, which is not allowed.";
  }

  if (/["#%&*:<>?\\/{|}~]/.test(filename)) {
    error = "The name contains a character that is not allowed.";
  }

  return error ? `The file could not be attached because its file name was invalid: ${error}` : null;
}

export const useAttachedDocumentActions = () => {
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const apolloClient = useApolloClient();
  const { secureAxios } = useAxios();

  const [attachingDocumentType, setAttachingDocumentType] = useState<DocType | null>(null);
  const [removingDocument, setRemovingDocument] = useState<AttachedDocument | null>(null);

  const [removeAttachedDocumentMutate, removeAttachedDocumentMutation] = useMutation<
    { practiceReview: { removeAttachedDocument: PracticeReview } },
    { practiceReviewId: number; attachedDocumentId: number; documentType: DocType }
  >(RemoveAttachedDocumentFromPracticeReviewMutation);

  async function updatePracticeReviewInCache(updatedPr: UploadDocumentResponse) {
    const cacheId = `PracticeReview:${updatedPr.id}`;

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.modify({
          id: cacheId,
          fields: {
            isMissingSchedulingQuestionnaire() {
              return updatedPr.isMissingSchedulingQuestionnaire;
            },
            schedulingQuestionnaireUrl() {
              return updatedPr.schedulingQuestionnaireUrl;
            },
            isMissingTaxQuestionnaire() {
              return updatedPr.isMissingTaxQuestionnaire;
            },
            taxQuestionnaireUrl() {
              return updatedPr.taxQuestionnaireUrl;
            },
            isMissingConfidentialClientList() {
              return updatedPr.isMissingConfidentialClientList;
            },
            confidentialClientListUrl() {
              return updatedPr.confidentialClientListUrl;
            },
            isMissingSelfEvaluationQuestionnaire() {
              return updatedPr.isMissingSelfEvaluationQuestionnaire;
            },
            selfEvaluationQuestionnaireUrl() {
              return updatedPr.selfEvaluationQuestionnaireUrl;
            },
            attachedDocuments() {
              return updatedPr.attachedDocuments.map((ad) => ({ ...ad, __typename: "AttachedDocument" }));
            }
          }
        });
        if (updatedPr.assignedPd) {
          const assignedPdCacheId = `AssignedPdCourse:${updatedPr.assignedPd.id}`;
          cache.modify({
            id: assignedPdCacheId,
            fields: {
              signedProofOfAttendanceId() {
                return updatedPr.assignedPd!.signedProofOfAttendanceId;
              }
            }
          });
        }
      }
    });
  }

  async function documentsAttached(
    documents: FileList,
    documentType: DocType,
    practiceReview: PracticeReview,
    fileNameError: string,
    assignedPdId?: number
  ) {
    if (documentType === DocType.AdditionalFile) {
      let validationErrorExists = false;

      forEach(documents, (document) => {
        if (practiceReview.attachedDocuments.some((ad) => ad.fileName === document.name)) {
          notifications.error(`The practice review already has a file named ${document.name}.`);
          validationErrorExists = true;
        }

        var invalidSharePointFileNameErrorMessage = validateFileNameForSharePoint(document.name);
        if (invalidSharePointFileNameErrorMessage !== null) {
          notifications.error(invalidSharePointFileNameErrorMessage);
          validationErrorExists = true;
        }

        const fileNameResemblesReservedDocumentName = checkFileNameResemblesReservedDocumentName(document.name, fileNameError);
        if (fileNameResemblesReservedDocumentName) {
          notifications.error(fileNameResemblesReservedDocumentName);
          validationErrorExists = true;
        }
      });

      if (validationErrorExists) {
        return;
      }
    }

    setAttachingDocumentType(documentType);

    const formData = new FormData();
    formData.append("PracticeReviewId", practiceReview.id.toString());
    formData.append("DocumentTypeCode", documentType.toString());

    if (assignedPdId) {
      formData.append("AssignedPdId", assignedPdId.toString());
    }

    forEach(documents, (document) => {
      formData.append("Documents", document);
    });

    let postResult: AxiosResponse<UploadDocumentResponse>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/practice-review-document/upload`;
    try {
      postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      }
    } catch (e: any) {
      setAttachingDocumentType(null);
      notifications.serverError(e.message);
      return;
    }

    if (postResult?.status !== 200) {
      notifications.serverError(new Error(postResult?.statusText));
    } else {
      const updatedPr: UploadDocumentResponse = postResult.data;
      await updatePracticeReviewInCache(updatedPr);
      notifications.success(`Attached document${documents.length > 1 ? "s" : ""}.`);
    }

    setAttachingDocumentType(null);
  }

  async function removeAttachedDocument(document: AttachedDocument, practiceReviewId: number) {
    const result = await removeAttachedDocumentMutate({
      variables: { practiceReviewId: practiceReviewId, attachedDocumentId: document.id, documentType: document.type }
    });

    if (result.data?.practiceReview.removeAttachedDocument?.id) {
      await updatePracticeReviewInCache(result.data.practiceReview.removeAttachedDocument);
      notifications.success("Removed document.");
    }

    setRemovingDocument(null);
  }

  return {
    documentsAttached,
    attachingDocumentType,
    removeAttachedDocument,
    removingDocument,
    setRemovingDocument,
    removeAttachedDocumentMutation
  };
};
