import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Form as FormikForm, Field as FormikField, Formik } from "formik";
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
  CancelPprpProgramTaskMutation,
  CreateNewProviderApplicationTaskMutation,
  FetchPprpProgramTaskByIdQuery,
  FetchPprpProgramTasksQuery,
  PprpProgramTask,
  RemoveAttachedDocumentFromPprpProgramTaskMutation,
  SavePprpProgramTaskMutation
} from ".";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { formatDate } from "../util/formats";
import { getHtmlForField, getOpenableUrl } from "../util/utilities";
import { InaTypeCode } from "../inas";
import RichTextEditor from "common/RichTextEditor";
import { PprpProgramTaskInput, PprpProgramTaskRegistrarDecision, PprpProgramTaskTypeCode } from "./models";
import { TextField as FmuiTextField } from "formik-mui";
import { DateTime } from "luxon";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";
import { DocType } from "../practice-reviews";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { useNotifications } from "../notifications";
import _ from "lodash";
import { makeStyles } from "../makeStyles";
import { staticDataStyles } from "styles/common";
import { useAppConfig } from "../util/AppConfig";
import { useAxios } from "../auth/SecureAxios";
import { AxiosResponse } from "axios";
import * as Yup from "yup";
import { ConfirmationDialog } from "../common/ConfirmationDialog";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  signOffChecklistItem: {
    display: "flex",
    alignItems: "flex-start",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1)
    },
    "& .MuiCheckbox-root": {
      position: "relative",
      top: theme.spacing(-0.4),
      padding: 0,
      marginRight: theme.spacing(1)
    },
    "& .MuiTypography-root": {
      lineHeight: 1.25
    }
  }
}));

interface Props {
  handleClose: () => void;
  taskId: number | null;
}

interface FormValues {
  internalNotes: string | null;
  notesToRegistrar: string | null;
  registrarDecision: PprpProgramTaskRegistrarDecision | null;
  registrarDecisionDate: DateTime | null;
  registrarNotes: string | null;
  renewalChecklistItems: { id: number; sortOrder: number; isChecked: boolean }[];
  applyForProgramLeaderFirstName: string;
  applyForProgramLeaderLastName: string;
  applyForProgramLeaderEmail: string;
  applyForNewProviderFirmName: string | null;
  applyForNewProviderPprpName: string | null;
  applyForNewProviderLeaderFirstName: string | null;
  applyForNewProviderLeaderLastName: string | null;
  applyForNewProviderLeaderEmail: string | null;
  // Request Form is only used for vlidation errors on new program applications
  requestForm: null;
}

const EditPprpProgramTaskDialog = (props: Props) => {
  const { classes, theme } = useStyles();
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const apolloClient = useApolloClient();
  const { secureAxios } = useAxios();

  const [documentsToAttach, setDocumentsToAttach] = useState<{ file: File; type: DocType }[]>([]);
  const [documentTypesToRemove, setDocumentTypesToRemove] = useState<DocType[]>([]);

  const [attachingDocuments, setAttachingDocuments] = useState(false);
  const [confirmingCancelTask, setConfirmingCancelTask] = useState(false);

  const [queryTask, taskQuery] = useLazyQuery<
    {
      pprpProgramTaskById: PprpProgramTask;
    },
    {
      id: number;
    }
  >(FetchPprpProgramTaskByIdQuery);
  const task = taskQuery.data?.pprpProgramTaskById;
  useEffect(() => {
    if (props.taskId) {
      queryTask({ variables: { id: props.taskId }, fetchPolicy: "cache-and-network" });
    }
  }, [props.taskId, queryTask]);

  const internalNotesContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const notesToRegistrarContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const registrarNotesContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const initialValues: FormValues = task
    ? {
        internalNotes: task.internalNotes,
        notesToRegistrar: task.notesToRegistrar,
        registrarDecision: task.registrarDecision,
        registrarDecisionDate: task.registrarDecisionDate ? DateTime.fromISO(task.registrarDecisionDate) : null,
        registrarNotes: task.registrarNotes,
        renewalChecklistItems: task.renewalChecklistItems.map((item) => ({
          id: item.id,
          sortOrder: item.sortOrder,
          isChecked: item.isChecked
        })),
        applyForProgramLeaderFirstName: task.applyForProgramLeaderFirstName ?? "",
        applyForProgramLeaderLastName: task.applyForProgramLeaderLastName ?? "",
        applyForProgramLeaderEmail: task.applyForProgramLeaderEmail ?? "",
        applyForNewProviderFirmName: task.applyForNewProviderFirmName,
        applyForNewProviderPprpName: task.applyForNewProviderPprpName,
        applyForNewProviderLeaderFirstName: task.applyForNewProviderLeaderFirstName,
        applyForNewProviderLeaderLastName: task.applyForNewProviderLeaderLastName,
        applyForNewProviderLeaderEmail: task.applyForNewProviderLeaderEmail,
        requestForm: null
      }
    : {
        internalNotes: null,
        notesToRegistrar: null,
        registrarDecision: null,
        registrarDecisionDate: null,
        registrarNotes: null,
        renewalChecklistItems: [],
        applyForProgramLeaderFirstName: "",
        applyForProgramLeaderLastName: "",
        applyForProgramLeaderEmail: "",
        applyForNewProviderFirmName: null,
        applyForNewProviderPprpName: null,
        applyForNewProviderLeaderFirstName: null,
        applyForNewProviderLeaderLastName: null,
        applyForNewProviderLeaderEmail: null,
        requestForm: null
      };

  const validationSchema = Yup.object().shape({
    applyForProgramLeaderEmail: Yup.string().email("Enter a valid email address."),
    applyForNewProviderLeaderEmail: Yup.string().email("Enter a valid email address.").nullable(),
    requestForm: Yup.mixed().test({
      name: "attached",
      message: "Request Form must be attached.",
      test: () => !!task || documentsToAttach.some((f) => f.type === DocType.PprpProgramTaskNewApplicationForm)
    })
  });

  const [saveMutate, saveMutation] = useMutation<
    {
      pprpProgramTask: { save: PprpProgramTask };
    },
    { task: PprpProgramTaskInput }
  >(SavePprpProgramTaskMutation);

  const [createMutate, createMutation] = useMutation<
    {
      pprpProgramTask: { createNewProviderApplication: PprpProgramTask };
    },
    { task: PprpProgramTaskInput }
  >(CreateNewProviderApplicationTaskMutation);

  const [cancelTaskMutate, cancelTaskMutation] = useMutation<
    {
      pprpProgramTasks: { cancel: PprpProgramTask };
    },
    { taskId: number }
  >(CancelPprpProgramTaskMutation);

  async function cancelTask() {
    const mutationResult = await cancelTaskMutate({
      variables: { taskId: props.taskId! },
      refetchQueries: [{ query: FetchPprpProgramTasksQuery, variables: { includeComplete: false } }]
    });

    if (!mutationResult.errors || mutationResult.errors.length === 0) {
      setConfirmingCancelTask(false);
      notifications.success("Task cancelled.");
      props.handleClose();
    }
  }

  const [removeAttachedDocumentMutate, removeAttachedDocumentsMutation] = useMutation<
    { pprpProgramTask: { removeAttachedDocument: PprpProgramTask } },
    { taskId: number; attachedDocumentId: number }
  >(RemoveAttachedDocumentFromPprpProgramTaskMutation);

  async function save(values: FormValues) {
    let taskInput: PprpProgramTaskInput = {
      id: task?.id ?? null,
      internalNotes: getHtmlForField(internalNotesContentRetriever),
      notesToRegistrar: getHtmlForField(notesToRegistrarContentRetriever),
      registrarDecisionCode: values.registrarDecision,
      registrarDecisionDate: values.registrarDecisionDate ? values.registrarDecisionDate.toISODate() : null,
      registrarNotes: getHtmlForField(registrarNotesContentRetriever),
      renewalChecklistItems: values.renewalChecklistItems.map((v) => ({ id: v.id, isChecked: v.isChecked })),
      applyForProgramLeaderFirstName: values.applyForProgramLeaderFirstName.trim() === "" ? null : values.applyForProgramLeaderFirstName,
      applyForProgramLeaderLastName: values.applyForProgramLeaderLastName.trim() === "" ? null : values.applyForProgramLeaderLastName,
      applyForProgramLeaderEmail: values.applyForProgramLeaderEmail.trim() === "" ? null : values.applyForProgramLeaderEmail,
      applyForNewProviderFirmName:
        values.applyForNewProviderFirmName === null || values.applyForNewProviderFirmName.trim() === ""
          ? null
          : values.applyForNewProviderFirmName,
      applyForNewProviderPprpName:
        values.applyForNewProviderPprpName === null || values.applyForNewProviderPprpName.trim() === ""
          ? null
          : values.applyForNewProviderPprpName,
      applyForNewProviderLeaderFirstName:
        values.applyForNewProviderLeaderFirstName === null || values.applyForNewProviderLeaderFirstName.trim() === ""
          ? null
          : values.applyForNewProviderLeaderFirstName,
      applyForNewProviderLeaderLastName:
        values.applyForNewProviderLeaderLastName === null || values.applyForNewProviderLeaderLastName.trim() === ""
          ? null
          : values.applyForNewProviderLeaderLastName,
      applyForNewProviderLeaderEmail:
        values.applyForNewProviderLeaderEmail === null || values.applyForNewProviderLeaderEmail.trim() === ""
          ? null
          : values.applyForNewProviderLeaderEmail
    };

    const mutationResult = props.taskId
      ? await saveMutate({
          variables: { task: taskInput }
        })
      : await createMutate({
          variables: { task: taskInput },
          refetchQueries: [{ query: FetchPprpProgramTasksQuery, variables: { includeComplete: false } }]
        });

    if (mutationResult.errors && mutationResult.errors.length > 0) {
      return;
    }

    const taskId =
      task?.id ??
      (mutationResult.data?.pprpProgramTask as { createNewProviderApplication: PprpProgramTask }).createNewProviderApplication.id;

    const attachDocumentsFormData = new FormData();
    attachDocumentsFormData.append("PprpProgramTaskId", taskId.toString());

    if (task) {
      documentTypesToRemove.forEach(async (documentType) => {
        const document = task.attachedDocuments.filter((d) => d.type === documentType)[0];
        const mutationResult = await removeAttachedDocumentMutate({
          variables: { taskId: task.id, attachedDocumentId: document.id }
        });

        if (mutationResult.errors && mutationResult.errors.length > 0) {
          return;
        }
      });
    }

    if (documentsToAttach.length > 0) {
      setAttachingDocuments(true);

      documentsToAttach.forEach((d) => {
        attachDocumentsFormData.append("Documents", d.file);
        attachDocumentsFormData.append("DocumentTypeCodes", d.type);
      });

      let postResult: AxiosResponse<PprpProgramTask>;
      const postRequestEndpoint = `${appConfig.apiEndpoint}/api/pprp-program-document/upload-program-task-documents`;
      try {
        postResult = await secureAxios.post(postRequestEndpoint, attachDocumentsFormData, {});
        if (postResult === undefined || postResult?.status === 401) {
          postResult = await secureAxios.post(postRequestEndpoint, attachDocumentsFormData, {});
        }
      } catch (e: any) {
        notifications.serverError(e.message);
        return;
      }

      if (postResult?.status !== 200) {
        notifications.serverError(new Error(postResult?.statusText));
        return;
      }

      setAttachingDocuments(false);
    }

    if (taskId) {
      refreshProgramTaskInCache(taskId);
    }
    notifications.success("Saved task.");
    props.handleClose();
  }

  async function refreshProgramTaskInCache(taskId: number) {
    const cacheId = `PprpProgramTask:${taskId}`;

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.modify({
          id: cacheId,
          fields: {
            registrarDecision(_, { INVALIDATE }) {
              return INVALIDATE;
            },
            registrarDecisionDate(_, { INVALIDATE }) {
              return INVALIDATE;
            },
            attachedDocuments(_, { INVALIDATE }) {
              return INVALIDATE;
            }
          }
        });
      }
    });
  }

  const DocumentLink: React.FunctionComponent<{
    documentType: DocType;
    documentName: string;
  }> = (componentProps) => {
    const document = task?.attachedDocuments.filter((d) => d.type === componentProps.documentType)[0];
    const removingDocument = documentTypesToRemove.indexOf(componentProps.documentType) !== -1;

    return document && !removingDocument ? (
      <Link href={getOpenableUrl(document.url)} target="_blank">
        <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
          {componentProps.documentName}
        </Typography>
      </Link>
    ) : (
      <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
        {componentProps.documentName}
      </Typography>
    );
  };

  const ReplaceableDocumentLink: React.FunctionComponent<{
    documentType: DocType;
    documentName: string;
    error?: string;
  }> = (componentProps) => {
    const document = task?.attachedDocuments.filter((d) => d.type === componentProps.documentType)[0];
    const documentToAttach = documentsToAttach.filter((d) => d.type === componentProps.documentType)[0];
    const removingDocument = documentTypesToRemove.indexOf(componentProps.documentType) !== -1;

    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <DocumentLink documentName={componentProps.documentName} documentType={componentProps.documentType} />
        {!document ? (
          !documentToAttach ? (
            <Stack direction="row" alignItems="center" gap={2}>
              <Button variant="outlined" size="small" component="label">
                Attach
                <input
                  type="file"
                  hidden
                  accept="*/*"
                  onChange={(e) =>
                    setDocumentsToAttach([...documentsToAttach, { file: e.target.files![0], type: componentProps.documentType }])
                  }
                />
              </Button>
              {componentProps.error && (
                <Typography variant="body2" color="red">
                  {componentProps.error}
                </Typography>
              )}
            </Stack>
          ) : (
            <Stack direction="row" gap={2}>
              <Stack>
                <Typography variant="body2">Attaching:</Typography>
                <Typography variant="body2">{documentToAttach.file.name}</Typography>
              </Stack>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => setDocumentsToAttach(documentsToAttach.filter((d) => d.type !== componentProps.documentType))}
                  className="removeButton">
                  Clear
                </Button>
              </Box>
            </Stack>
          )
        ) : removingDocument ? (
          <Stack>
            {!documentToAttach ? (
              <Stack direction="row" alignItems="center" gap={2}>
                <Stack>
                  <Typography variant="body2">Removing:</Typography>
                  <Typography variant="body2">{document.fileName}</Typography>
                </Stack>
                <Button variant="outlined" size="small" component="label">
                  Attach
                  <input
                    type="file"
                    hidden
                    accept="*/*"
                    onChange={(e) =>
                      setDocumentsToAttach([...documentsToAttach, { file: e.target.files![0], type: componentProps.documentType }])
                    }
                  />
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center" gap={2}>
                <Stack>
                  <Typography variant="body2">Attaching:</Typography>
                  <Typography variant="body2">{documentToAttach.file.name}</Typography>
                </Stack>

                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => setDocumentsToAttach(documentsToAttach.filter((d) => d.type !== componentProps.documentType))}
                  className="removeButton">
                  Clear
                </Button>
              </Stack>
            )}
          </Stack>
        ) : (
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => setDocumentTypesToRemove([...documentTypesToRemove, componentProps.documentType])}
            className="removeButton">
            Remove
          </Button>
        )}
      </Stack>
    );
  };

  const reviewApplicationIna = task?.inas.filter((ina) => ina.type.typeCode === InaTypeCode.PprpReviewApplication)[0];
  const waitingForRegistrarDecisionIna = task?.inas.filter((ina) => ina.type.typeCode === InaTypeCode.PprpWaitingForRegistrarDecision)[0];
  const enterProgramTaskDecisionIna = task?.inas.filter((ina) => ina.type.typeCode === InaTypeCode.PprpEnterProgramTaskDecision)[0];
  const waitingForSignedDecisionLetterIna = task?.inas.filter(
    (ina) => ina.type.typeCode === InaTypeCode.PprpWaitingForSignedDecisionLetter
  )[0];
  const signedDecisionLetterReceivedIna = task?.inas.filter((ina) => ina.type.typeCode === InaTypeCode.PprpSignedDecisionLetterReceived)[0];

  const additionalDocuments = task?.attachedDocuments.filter((d) => d.type === DocType.AdditionalFile) ?? [];

  let formType: DocType;
  switch (task?.type.typeCode) {
    case PprpProgramTaskTypeCode.ChangeProgramLeader:
      formType = DocType.PprpProgramTaskChangeProgramLeaderForm;
      break;

    case PprpProgramTaskTypeCode.ChangeProgramManager:
      formType = DocType.PprpProgramTaskChangeProgramManagerForm;
      break;

    case PprpProgramTaskTypeCode.ApplyForProgram:
      formType = DocType.PprpProgramTaskPprInformationGatheringForm;
      break;

    case PprpProgramTaskTypeCode.ModifyProgram:
      formType = DocType.PprpProgramTaskModifyProgramForm;
      break;

    case PprpProgramTaskTypeCode.DeregisterProgram:
      formType = DocType.PprpProgramTaskDeregisterProgramForm;
      break;

    case PprpProgramTaskTypeCode.AnnualRenewal:
      formType = DocType.PprpProgramRenewalRequestForm;
      break;

    case PprpProgramTaskTypeCode.ApplyForNewProvider:
      formType = DocType.PprpProgramTaskNewApplicationForm;
      break;

    default:
      formType = DocType.AdditionalFile;
      break;
  }
  if (!task) {
    formType = DocType.PprpProgramTaskNewApplicationForm;
  }

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="body" maxWidth="lg">
      <ClosableDialogTitle onClose={props.handleClose}>{task ? "Edit" : "Create"} Program Task</ClosableDialogTitle>
      {task || props.taskId === null ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={save}>
          {(formikProps) => (
            <FormikForm>
              <DialogContent>
                <Grid container columnSpacing={3} rowSpacing={5}>
                  {task && (
                    <Grid item xs={1}>
                      <StackedStaticDataDisplay label="Task No." value={task.id.toString()} />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <StackedStaticDataDisplay label="Task Type" value={task?.type.typeName ?? "New Provider Application"} />
                  </Grid>
                  <Grid item xs={3}>
                    {task && (
                      <StackedStaticDataDisplay
                        label="Submitted by"
                        value={
                          task?.type.typeCode !== PprpProgramTaskTypeCode.AnnualRenewal || task?.annualRenewalResponseReceived
                            ? `${task?.submittedBy} on ${formatDate(task?.submissionDate)}`
                            : "Not submitted"
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {task && <StackedStaticDataDisplay label="Status" value={_.startCase(task.status)} />}
                  </Grid>
                  <Grid item xs={2}>
                    {task && (
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="outlined" color="error" onClick={() => setConfirmingCancelTask(true)}>
                          Cancel Task
                        </Button>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} container columnSpacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Pre-approved Program
                      </Typography>
                    </Grid>
                    {!task || task.type.typeCode === PprpProgramTaskTypeCode.ApplyForNewProvider ? (
                      <>
                        <Grid item container columnSpacing={3} xs={12}>
                          <Grid item xs={5}>
                            <FormikField
                              component={FmuiTextField}
                              name="applyForNewProviderFirmName"
                              label="Firm Name"
                              fullWidth
                              InputLabelProps={{ shrink: formikProps.values.applyForNewProviderFirmName }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormikField
                              component={FmuiTextField}
                              name="applyForNewProviderPprpName"
                              label="PPRP Name"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: formikProps.values.applyForNewProviderPprpName }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container columnSpacing={3} xs={12}>
                          <Grid item xs={3}>
                            <FormikField
                              component={FmuiTextField}
                              name="applyForNewProviderLeaderFirstName"
                              label="Program Leader First Name"
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormikField
                              component={FmuiTextField}
                              name="applyForNewProviderLeaderLastName"
                              label="Program Leader Last Name"
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormikField
                              component={FmuiTextField}
                              name="applyForNewProviderLeaderEmail"
                              label="Program Leader Email"
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={3}>
                          <StackedStaticDataDisplay
                            label="Program Number and Path"
                            value={`${task.pprpProgram?.programEntityNumber.toString()} - ${_.startCase(
                              task.pprpProgram?.programPath || "--"
                            )}`}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <StackedStaticDataDisplay
                            label="Firm Number and Name"
                            value={`${task.pprpProgram?.firm.entityNumber ?? task.firm!.entityNumber} - ${
                              task.pprpProgram?.firm.name ?? task.firm!.name
                            }`}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <StackedStaticDataDisplay
                            label="Program Leader - Primary"
                            value={task.pprpProgram?.members.filter((m) => m.isPrimaryProgramLeader)[0]?.name ?? "--"}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <StackedStaticDataDisplay
                            label="Program Leader - Secondary"
                            value={task.pprpProgram?.members.filter((m) => m.isSecondaryProgramLeader)[0]?.name ?? "--"}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {task?.type.typeCode === PprpProgramTaskTypeCode.ApplyForProgram && (
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Program Leader
                      </Typography>
                      <Grid container columnSpacing={5}>
                        <Grid item xs={3}>
                          <FormikField component={FmuiTextField} name="applyForProgramLeaderFirstName" label="First Name" fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                          <FormikField component={FmuiTextField} name="applyForProgramLeaderLastName" label="Last Name" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikField component={FmuiTextField} name="applyForProgramLeaderEmail" label="Email" fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {task?.type.typeCode === PprpProgramTaskTypeCode.ChangeProgramLeader && (
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Changes to Program Leaders
                      </Typography>
                      <Stack direction="row" spacing={5}>
                        <Stack>
                          <Typography className={classes.label}>Primary Leader</Typography>
                          <Stack>
                            <Typography>{`${task.primaryLeaderChange.current.name}${
                              task.primaryLeaderChange.resigning ? " (resigning)" : ""
                            }`}</Typography>
                            {task.primaryLeaderChange.replacement && (
                              <Typography>{`${task.primaryLeaderChange.replacement.name} (replacement)`}</Typography>
                            )}
                          </Stack>
                        </Stack>
                        {task.secondaryLeaderChange && (
                          <Stack>
                            <Typography className={classes.label}>Secondary Leader</Typography>
                            <Stack>
                              {task.secondaryLeaderChange.current && (
                                <Typography>{`${task.secondaryLeaderChange.current.name}${
                                  task.secondaryLeaderChange.resigning ? " (resigning)" : ""
                                }`}</Typography>
                              )}
                              {task.secondaryLeaderChange.replacement && (
                                <Typography>{`${task.secondaryLeaderChange.replacement.name} (${
                                  task.secondaryLeaderChange.current ? "replacement" : "new"
                                })`}</Typography>
                              )}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  )}

                  {task?.type.typeCode === PprpProgramTaskTypeCode.AnnualRenewal && (
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Checklist
                      </Typography>
                      <Stack>
                        {task.renewalChecklistItems.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                title={item.questionText}
                                checked={formikProps.values.renewalChecklistItems.filter((i) => i.id === item.id)[0].isChecked}
                                onClick={() => {
                                  const formItem = formikProps.values.renewalChecklistItems.filter((i) => i.id === item.id)[0];
                                  formikProps.setFieldValue(
                                    "renewalChecklistItems",
                                    _.orderBy(
                                      [
                                        ...formikProps.values.renewalChecklistItems.filter((i) => i.id !== formItem.id),
                                        { ...formItem, isChecked: !formItem.isChecked }
                                      ],
                                      (i) => i.sortOrder
                                    )
                                  );
                                }}
                              />
                            }
                            label={item.questionText}
                          />
                        ))}
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <RichTextEditor
                      label="Internal Notes"
                      minHeight="20em"
                      html={formikProps.values.internalNotes}
                      passContentRetriever={(getContentAsHtml) => {
                        internalNotesContentRetriever.current = { getContentAsHtml };
                      }}
                    />
                  </Grid>

                  {!task?.type.skipRegistrar && (
                    <Grid item xs={6}>
                      <Stack>
                        <Typography variant="h4">Timeline</Typography>
                        {task && (
                          <Typography variant="body2" gutterBottom sx={{ color: theme.palette.text.secondary }}>{`Updated ${formatDate(
                            task.lastStatusChange
                          )}`}</Typography>
                        )}
                      </Stack>
                      <Timeline
                        position="right"
                        sx={{
                          [`& li::before`]: {
                            display: "none"
                          },
                          m: 0
                        }}>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color={reviewApplicationIna?.isComplete ? "primary" : undefined} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Review Application</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color={waitingForRegistrarDecisionIna?.isComplete ? "primary" : undefined} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Waiting for Registrar Decision</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color={enterProgramTaskDecisionIna?.isComplete ? "primary" : undefined} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Enter Program Task Decision</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color={waitingForSignedDecisionLetterIna?.isComplete ? "primary" : undefined} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Waiting for Signed Decision Letter</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color={signedDecisionLetterReceivedIna?.isComplete ? "primary" : undefined} />
                          </TimelineSeparator>
                          <TimelineContent>Signed Decision Letter Received</TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  )}

                  {task && (
                    <>
                      <Grid
                        item
                        xs={12}
                        container
                        columnSpacing={3}
                        rowSpacing={task.type.skipRegistrar ? 0 : 1}
                        sx={{ paddingTop: `${theme.spacing(2)}px !important` }}>
                        <Grid item xs={12}>
                          <Typography variant="h4" gutterBottom={!task.type.skipRegistrar}>
                            {task.type.skipRegistrar ? "Decision" : "Registrar"}
                          </Typography>
                        </Grid>

                        {!task.type.skipRegistrar && (
                          <>
                            <Grid item xs={6}>
                              <RichTextEditor
                                label="Notes to Registrar"
                                minHeight="8em"
                                html={formikProps.values.notesToRegistrar}
                                passContentRetriever={(getContentAsHtml) => {
                                  notesToRegistrarContentRetriever.current = { getContentAsHtml };
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <RichTextEditor
                                label="Registrar Notes"
                                minHeight="8em"
                                html={formikProps.values.registrarNotes}
                                passContentRetriever={(getContentAsHtml) => {
                                  registrarNotesContentRetriever.current = { getContentAsHtml };
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={6}>
                          <FormikField
                            component={FmuiTextField}
                            select
                            name="registrarDecision"
                            label={task.type.skipRegistrar ? "Decision" : "Registrar Decision"}
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              formikProps.setFieldValue("registrarDecision", e.target.value);
                              formikProps.setFieldValue("registrarDecisionDate", DateTime.now().startOf("day"));
                            }}>
                            <MenuItem value={PprpProgramTaskRegistrarDecision.Approved}>Approved</MenuItem>
                            <MenuItem value={PprpProgramTaskRegistrarDecision.Declined}>Declined</MenuItem>
                          </FormikField>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikField
                            component={PrsDatePickerField}
                            name="registrarDecisionDate"
                            label={task.type.skipRegistrar ? "Decision Date" : "Registrar Decision Date"}
                            fullWidth
                            allowNonWorkingDays
                            maxDate={DateTime.local()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} container columnSpacing={3}>
                    <Grid item xs={4}>
                      <Typography variant="h4" gutterBottom>
                        Documents
                      </Typography>
                      <Stack spacing={1}>
                        <ReplaceableDocumentLink
                          documentType={formType}
                          error={formikProps.errors.requestForm}
                          documentName="Request Form"
                        />
                        {!task?.type.skipRegistrar && (
                          <>
                            <ReplaceableDocumentLink documentType={DocType.PprpProgramTaskDecisionLetter} documentName="Decision Letter" />
                            {task?.type.typeCode !== PprpProgramTaskTypeCode.ApplyForNewProvider ? (
                              <DocumentLink
                                documentType={DocType.PprpProgramTaskSignedDecisionLetter}
                                documentName="Decision Letter - Signed"
                              />
                            ) : (
                              <>
                                <ReplaceableDocumentLink
                                  documentType={DocType.PprpProramTaskLetterOfAgreement}
                                  documentName="Letter of Agreement"
                                />
                                <ReplaceableDocumentLink
                                  documentType={DocType.PprpProgramTaskSignedLetterOfAgreement}
                                  documentName="Letter of Agreement - Signed"
                                />
                              </>
                            )}
                          </>
                        )}
                      </Stack>
                    </Grid>
                    {(!task ||
                      task.type.typeCode === PprpProgramTaskTypeCode.ApplyForProgram ||
                      task.type.typeCode === PprpProgramTaskTypeCode.ApplyForNewProvider) && (
                      <Grid item xs={4}>
                        <Typography variant="h4" gutterBottom>
                          New Program Documents
                        </Typography>
                        <Stack spacing={1}>
                          <ReplaceableDocumentLink documentType={DocType.PprpProgramExecutiveSummary} documentName="Executive Summary" />
                          <ReplaceableDocumentLink documentType={DocType.PprpProgramCompetencyMap} documentName="Competency Map" />
                          <ReplaceableDocumentLink
                            documentType={DocType.PprpProgramSummaryOfChargeableHours}
                            documentName="Summary of Chargeable Hours"
                          />
                          <ReplaceableDocumentLink documentType={DocType.PprpProgramMemoToRegistrar} documentName="Memo to Registrar" />
                          <ReplaceableDocumentLink
                            documentType={DocType.PprpProgramSeniorCommitmentLetter}
                            documentName="Senior Commitment Letter"
                          />
                        </Stack>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <Typography variant="h4" gutterBottom>
                        Additional Documents
                      </Typography>
                      <Stack>
                        {additionalDocuments.length > 0 ? (
                          additionalDocuments.map((d) => (
                            <Link key={d.id} href={d.url} target="_blank">
                              <Typography variant="body1">{d.fileName}</Typography>
                            </Link>
                          ))
                        ) : (
                          <Typography>None</Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={saveMutation.loading || createMutation.loading || attachingDocuments || removeAttachedDocumentsMutation.loading}
                  onClick={() => formikProps.submitForm()}>
                  Save
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      ) : (
        <CircularProgress sx={{ m: 3 }} />
      )}
      {confirmingCancelTask && (
        <ConfirmationDialog
          open={true}
          loading={cancelTaskMutation.loading}
          body={<DialogContentText>Do you want to cancel this task?</DialogContentText>}
          title="Cancel task?"
          cancel={() => setConfirmingCancelTask(false)}
          confirm={cancelTask}
        />
      )}
    </Dialog>
  );
};

export default EditPprpProgramTaskDialog;
