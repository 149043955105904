import React, { useEffect, useMemo, useState } from "react";
import { Typography, Paper, IconButton, Collapse, LinearProgress, Tooltip, Box, alpha } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { datagridStyles, expanderStyles, inaPanelStyles } from "styles/common";
import DataGridPagination from "./DataGridPagination";
import { makeStyles } from "../makeStyles";
import {
  DataGridPro,
  GridActionsColDef,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridDensity,
  GridRowId,
  GridRowIdGetter,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import _ from "lodash";
import { useCollapsibleGrids } from "util/CollapsibleProvider";

import { Ina, HighlightRowType } from "inas";

interface NonCollapsibleDataGridWithHeaderProps {
  title?: string;
  subtitle?: string;
  columns: (GridColDef | GridActionsColDef)[];
  rows: GridRowsProp;
  headerActions?: React.ReactNode;
  footerActions?: React.ReactNode;
  className?: string;
  headerHeight?: number;
  enableColumnMenu?: boolean;
  disableSelectionOnClick?: boolean;
  density?: GridDensity;
  sortModel?: GridSortModel;
  noDataMessage?: string;
  loading?: boolean;
  pageSize?: number;
  onSortModelChange?: (newSortModel: GridSortModel) => void;
  onPageChange?: (page: number) => void;
  showPagination?: boolean;
  currentPage?: number;
  displayWithoutContainer?: boolean;
  deemphasizeHeader?: boolean;
  getRowId?: GridRowIdGetter;
  checkboxSelection?: boolean;
  selectionModel?: GridRowId[];
  onSelectionModelChange?: (newSelectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  isRowSelectable?: (params: GridRowParams) => boolean;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactElement | null | undefined;
  getDetailPanelHeight?: (params: GridRowParams) => number;
  initialState?: GridInitialStatePro;
  maxHeight?: number;
  stickyHeader?: boolean;
  getLastClickedRow?: HighlightRowType | null;
}

type CollapsibleDataGridWithHeaderProps = NonCollapsibleDataGridWithHeaderProps & {
  /**
   * When setting this option it is required to set `storageKey` to a unique string.
   * It's used as a key to persist the collapse state for this
   * table in local storage.
   */
  collapsible: boolean;
  storageKey: string;
};

type DataGridWithHeaderProps = NonCollapsibleDataGridWithHeaderProps | CollapsibleDataGridWithHeaderProps;

function isCollapsible(param: DataGridWithHeaderProps): param is CollapsibleDataGridWithHeaderProps {
  return param.hasOwnProperty("collapsible");
}

function getHeaderHeight(props: DataGridWithHeaderProps) {
  // In order for wrapped column headers not to look cramped, we want the header height to be the standard 56px
  // unless the density is set to "comfortable".
  return props.headerHeight ?? props.density === "comfortable" ? 72 : 56;
}

function getRowHeight(props: DataGridWithHeaderProps) {
  if (!props.density || props.density === "standard") {
    return 52;
  } else return props.density === "compact" ? 36 : 67;
}

const useStyles = makeStyles<DataGridWithHeaderProps>()((theme, props) => {
  const headerHeight = getHeaderHeight(props);
  const hasHeader = props.title || props.headerActions;

  return {
    ...datagridStyles(theme, headerHeight),
    ...inaPanelStyles(theme),
    ...expanderStyles(theme),
    header: {
      display: "flex",
      alignItems: "baseline",
      padding: !props.displayWithoutContainer && hasHeader ? theme.spacing(2) : undefined,
      color: props.deemphasizeHeader ? undefined : theme.palette.primary.main,
      ...(props.stickyHeader && {
        minHeight: headerHeight,
        position: "sticky",
        // This is the height of the app bar
        top: 156,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.mobileStepper - 1
      }),
      "& h2": {
        fontWeight: 500
      }
    },
    headerActions: {
      marginLeft: "auto",
      alignSelf: "center",
      display: "flex",
      "& > *": {
        marginLeft: theme.spacing(3)
      }
    },
    highlightRow: {
      backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity * 2)
    },
    footer: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderTop: `1px solid ${theme.palette.border}`
    },
    footerActions: {
      marginLeft: "auto"
    },
    dataGrid: {
      marginTop: props.displayWithoutContainer ? theme.spacing(1) : hasHeader ? theme.spacing(-1) : 0,
      border: !props.displayWithoutContainer ? "none" : undefined,
      ...(props.stickyHeader && {
        overflow: "visible",
        "& .MuiDataGrid-main": {
          overflow: "visible",
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0 !important"
          }
        },
        "& .MuiDataGrid-columnHeaders": {
          position: "sticky",
          backgroundColor: theme.palette.background.paper,
          top: 156 + headerHeight,
          zIndex: theme.zIndex.mobileStepper - 2
        }
      }),
      "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
        borderBottom: "none"
      },
      "&.MuiDataGrid-root": {
        border: !props.displayWithoutContainer ? "none" : undefined
      }
    },
    emptyDataGrid: {
      "& .MuiDataGrid-windowContainer": {
        display: "none"
      },
      "& .MuiDataGrid-virtualScroller": {
        marginTop: `0px !important`
      },
      "& .MuiDataGrid-virtualScrollerContent": {
        height: `calc(${headerHeight}px + ${Math.max(props.rows.length, 1) * getRowHeight(props)}px) !important`
      },
      "& .MuiDataGrid-main > div:first-child": {
        top: `${headerHeight}px !important`
      }
    },
    subtitle: {
      marginLeft: theme.spacing(5),
      color: theme.palette.text.secondary
    },
    rowDetailsRoot: {
      transform: "translate(0px, -1px)"
    },
    rowDetailsContainer: {
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
    },
    rowDetails: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // These colors are picked out from the DataGrid, and I don't think they're exposed on the theme
      backgroundColor: "rgba(0, 111, 186, 0.08)",
      border: "1px solid rgba(224, 224, 224, 1)",
      borderTop: "none"
    }
  };
});

export const DataGridWithHeader: React.FunctionComponent<DataGridWithHeaderProps> = (props) => {
  const { cx } = useStyles(props);
  const colDefs = React.useMemo(
    () =>
      props.columns.map((c) =>
        c.type !== "boolean"
          ? {
              ...c,
              renderCell: c.renderCell
                ? c.renderCell
                : (params: GridCellParams) => (
                    <Tooltip title={params.formattedValue && params.formattedValue !== "--" ? params.formattedValue : ""}>
                      <span>{params.formattedValue}</span>
                    </Tooltip>
                  )
            }
          : c
      ),
    [props.columns]
  );

  return !props.displayWithoutContainer ? (
    <Paper className={cx(props.className)}>
      {isCollapsible(props) ? (
        <CollapsingDataGridWithHeader {...props} columns={colDefs} />
      ) : (
        <NonCollapsingDataGridWithHeader {...props} columns={colDefs} />
      )}
    </Paper>
  ) : (
    <div className={cx(props.className)}>
      {isCollapsible(props) ? (
        <CollapsingDataGridWithHeader {...props} columns={colDefs} />
      ) : (
        <NonCollapsingDataGridWithHeader {...props} columns={colDefs} />
      )}
    </div>
  );
};

const NonCollapsingDataGridWithHeader: React.FunctionComponent<DataGridWithHeaderProps> = (props) => {
  const { classes } = useStyles(props);

  return (
    <>
      <div className={classes.header}>
        {props.title && <Typography variant={props.deemphasizeHeader ? "h3" : "h2"}>{props.title}</Typography>}
        {props.subtitle && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        )}
        {props.headerActions && <div className={classes.headerActions}>{props.headerActions}</div>}
      </div>
      <InnerDataGridWrapper {...props} />
    </>
  );
};

const CollapsingDataGridWithHeader: React.FunctionComponent<CollapsibleDataGridWithHeaderProps> = (props) => {
  const { classes, cx } = useStyles(props);

  const { states, setExpanded } = useCollapsibleGrids();
  useEffect(() => {
    if (!states.hasOwnProperty(props.storageKey)) {
      setExpanded(props.storageKey, true);
    }
  }, [props.storageKey, setExpanded, states]);
  const expanded = useMemo(() => states[props.storageKey] ?? true, [props.storageKey, states]);

  return (
    <>
      <div className={classes.header}>
        {props.collapsible && (
          <IconButton size="small" onClick={() => setExpanded(props.storageKey, !expanded)} className={classes.expander}>
            <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
          </IconButton>
        )}
        {props.title && <Typography variant={props.deemphasizeHeader ? "h3" : "h2"}>{props.title}</Typography>}
        {props.subtitle && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        )}
        {props.headerActions && expanded && <div className={classes.headerActions}>{props.headerActions}</div>}
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <InnerDataGridWrapper {...props} />
      </Collapse>
    </>
  );
};

const InnerDataGridWrapper: React.FunctionComponent<DataGridWithHeaderProps> = (props) => {
  const { classes, cx } = useStyles(props);
  const hasFooter = Boolean(props.footerActions) || props.showPagination;

  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);

  const ActionFooter: React.FunctionComponent = () => (
    <div className={classes.footer}>
      <div className={classes.footerActions}>{props.footerActions}</div>
    </div>
  );

  // Add a class to the last visible column
  const indexOfLastVisibleColumn = _.findLastIndex(props.columns, (c) => !c.hide);
  const columns =
    indexOfLastVisibleColumn >= 0
      ? [
          ...props.columns.slice(0, indexOfLastVisibleColumn),
          {
            ...props.columns[indexOfLastVisibleColumn],
            headerClassName: cx(props.columns[indexOfLastVisibleColumn].headerClassName as string, classes.lastColumnHeader)
          },
          ...props.columns.slice(indexOfLastVisibleColumn + 1, props.columns.length)
        ]
      : props.columns;

  const headerHeight = getHeaderHeight(props);

  // We need to set a height on the data grid container so that the expand animation works correctly. These
  // number could change if we changed, say, the body font size.
  // Header height + (row height * rows) + footer height(optional) - margin adjustment.
  const numberOfVisibleRows = props.pageSize ? Math.min(props.rows.length, props.pageSize) : props.rows.length;
  const rowHeight = getRowHeight(props);

  const apiRef = useGridApiRef();
  const totalRowDetailsHeight = props.getDetailPanelHeight
    ? expandedRowIds
        .map((rowId) => props.getDetailPanelHeight!({ row: apiRef.current.getRow(rowId) } as GridRowParams))
        .reduce((prev, curr) => prev + curr, 0)
    : 0;
  const bodyHeight = props.loading || props.rows.length === 0 ? rowHeight : rowHeight * numberOfVisibleRows + totalRowDetailsHeight;
  const footerHeight = hasFooter ? 48 : 0;
  const desiredHeight = headerHeight + bodyHeight + footerHeight;
  const contentHeight = props.maxHeight ? Math.min(desiredHeight, props.maxHeight) : desiredHeight;

  const CustomExpandIcon = () => <FontAwesomeIcon icon={faChevronDown} />;
  const CustomCollapseIcon = () => <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: true })} />;

  const addCustomRowClassName = ({ id, row }: GridRowParams<Ina>) => {
    let classNames = "";

    const isLastClickedPr =
      id === props.getLastClickedRow?.rowId &&
      props.getLastClickedRow?.hasNavigated === true &&
      row.type.typeCode === props.getLastClickedRow.rowTypeCode;

    if (isLastClickedPr) {
      classNames += `${classes.highlightRow} `; // Replace with your actual class name
    }
    //if (/* Add more classes w/ conditions as needed */) {
    //  classNames += `${classes.someOtherClass} `;
    //}
    return classNames.trim();
  };

  return (
    <Box sx={{ height: contentHeight }}>
      <DataGridPro
        apiRef={apiRef}
        className={cx(classes.dataGrid, classes.datagridRoot, { [classes.emptyDataGrid]: props.rows.length === 0 })}
        pageSize={props.pageSize}
        rows={props.rows}
        columns={columns}
        autoHeight={!Boolean(props.maxHeight)}
        disableSelectionOnClick={props.disableSelectionOnClick}
        hideFooter={!hasFooter}
        disableColumnMenu={!props.enableColumnMenu}
        density={props.density || "standard"}
        loading={props.loading}
        onDetailPanelExpandedRowIdsChange={(newIds: GridRowId[]) => setExpandedRowIds(newIds)}
        components={{
          Footer: () => (
            <div>
              {props.footerActions && <ActionFooter />}
              {props.showPagination && !props.loading && (
                <DataGridPagination page={props.currentPage!} onPageChange={props.onPageChange!} />
              )}
            </div>
          ),
          LoadingOverlay: () => <LinearProgress />,
          NoRowsOverlay: () => (
            <div className={classes.noDataMessage}>
              {/* The no data message can appear when DataGrid is still building its internal state, causing it to flash on large tables */}
              {props.rows.length === 0 ? props.noDataMessage : ""}
            </div>
          ),
          DetailPanelExpandIcon: CustomExpandIcon,
          DetailPanelCollapseIcon: CustomCollapseIcon
        }}
        sortModel={props.sortModel}
        onSortModelChange={props.onSortModelChange}
        hideFooterPagination={!props.showPagination}
        getRowId={props.getRowId}
        checkboxSelection={props.checkboxSelection}
        selectionModel={props.selectionModel}
        onSelectionModelChange={props.onSelectionModelChange}
        isRowSelectable={props.isRowSelectable}
        getDetailPanelContent={props.getDetailPanelContent}
        getDetailPanelHeight={props.getDetailPanelHeight ?? (() => 56)}
        initialState={props.initialState}
        getRowClassName={addCustomRowClassName}
      />
    </Box>
  );
};
