import React, { useEffect } from "react";
import { ReviewedClient } from "practice-reviews";
import { gql, useLazyQuery } from "@apollo/client";
import { PrChecklist } from "./PrChecklist";
import {
  ChecklistSectionFieldsFragment,
  QuestionHeaderFieldsFragment,
  QuestionFieldsFragment,
  AnswerFieldsFragment,
  QuestionContainerFieldsFragment
} from "./queries";
import { QuestionContainer } from "checklists";
import { LevelOfAdjustment } from "../level-of-adjustment";

const ReviewedClientChecklistQuery = gql`
  ${QuestionContainerFieldsFragment}
  ${QuestionFieldsFragment}
  ${AnswerFieldsFragment}
  ${ChecklistSectionFieldsFragment}
  ${QuestionHeaderFieldsFragment}
  query FetchReviewedClientChecklist($practiceReviewId: Int!, $engagementTypeId: Int!, $reviewedClientId: Int!) {
    reviewedClientChecklistQuestionContainers(practiceReviewId: $practiceReviewId, engagementTypeId: $engagementTypeId) {
      ...QuestionContainerFields
      questions {
        ...QuestionFields
        answer(reviewedClientId: $reviewedClientId) {
          ...AnswerFields
        }
      }
      ...ChecklistSectionFields
      ...QuestionHeaderFields
    }
  }
`;

interface Props {
  practiceReviewId: number;
  prNumber: string;
  levelOfAdjustment: LevelOfAdjustment;
  reviewedClient: ReviewedClient;
  reviewerHasSubmittedReviewedClients?: boolean;
  checklistActionsVerticalOffset?: number;
}

export const ReviewedClientChecklist: React.FunctionComponent<Props> = (props) => {
  const [runChecklistQuery, checklistQuery] = useLazyQuery<
    { reviewedClientChecklistQuestionContainers: QuestionContainer[] },
    { practiceReviewId: number; engagementTypeId: number; reviewedClientId: number }
  >(ReviewedClientChecklistQuery, {
    fetchPolicy: "network-only",
    variables: {
      practiceReviewId: props.practiceReviewId,
      engagementTypeId: props.reviewedClient.engagementType.id,
      reviewedClientId: props.reviewedClient.id
    }
  });
  const checklistQuestionContainers = checklistQuery.data?.reviewedClientChecklistQuestionContainers ?? [];

  useEffect(() => {
    const awaitedRunChecklistQuery = async () => await runChecklistQuery();
    awaitedRunChecklistQuery();
  }, [runChecklistQuery]);

  return (
    <PrChecklist
      checklistQuestionContainers={checklistQuestionContainers}
      practiceReviewId={props.practiceReviewId}
      prNumber={props.prNumber}
      levelOfAdjustment={props.levelOfAdjustment}
      reviewedClientId={props.reviewedClient.id}
      checklistLoading={checklistQuery.loading}
      reviewerHasSubmittedReviewedClients={props.reviewerHasSubmittedReviewedClients}
      showLevelOfAdjustment={false}
      checklistActionsVerticalOffset={props.checklistActionsVerticalOffset}
    />
  );
};
