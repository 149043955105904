import { Button, Card, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Grid, Radio } from "@mui/material";
import { Formik, Form as FormikForm, Field as FormikField, FormikProps } from "formik";
import { TextField as FmuiTextField, Checkbox as FmuiCheckbox, RadioGroup as FmuiRadioGroup } from "formik-mui";
import React from "react";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { HistoricalPr } from "./models";
import * as Yup from "yup";
import { Validations } from "../common/validations/common-yup-validations";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import { FetchFirmHistoryItemsQuery } from "./ReviewHistory";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

const AddPrHistoryMutation = gql`
  mutation AddHistoricalPr($historicalPr: HistoricalPrInput) {
    historicalPrs {
      add(historicalPr: $historicalPr) {
        id
      }
    }
  }
`;

const EditPrHistoryMutation = gql`
  mutation EditHistoricalPr($historicalPr: HistoricalPrInput) {
    historicalPrs {
      update(historicalPr: $historicalPr) {
        id
        reviewYear
        prNumber
        isComply
        nonComplyFinancial
        nonComplyAudit
        nonComplyReview
        nonComplyQualityManagement
        nonComplyTax
        nonComplyCompilation
        nonComplyOther
        nonComplyOtherSpecified
        otherComments
      }
    }
  }
`;

const PrNumberIsInUseQuery = gql`
  query GetPrByPrNumberQuery($prNumber: String!) {
    prNumberIsInUse(prNumber: $prNumber)
  }
`;

interface EditableHistoricalPr extends Partial<HistoricalPr> {
  id?: number;
}

interface Props extends EditableHistoricalPr {
  handleClose: () => void;
  title: string;
  firmId: number;
}

const useStyles = makeStyles()((theme) => ({
  mainBody: {
    height: "35em",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    paddingTop: theme.spacing(1)
  },
  centeredItems: {
    alignItems: "center"
  },
  spaceAbove: {
    marginTop: theme.spacing(2)
  },
  spaceLeft: {
    marginLeft: theme.spacing(1)
  },
  flushLeft: {
    paddingLeft: 0
  },
  otherComments: {
    marginTop: "auto",
    maxHeight: "5em"
  },
  denseGridContainer: {
    marginBottom: theme.spacing(1),
    "& .MuiGrid-item": {
      height: "1.9em"
    }
  },
  indentGridContainer: {
    "& .MuiGrid-item": {
      marginLeft: theme.spacing(4)
    }
  },
  paddedSides: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  cardInterior: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  nonComplyOther: {
    marginBottom: theme.spacing(3)
  }
}));

const convertBooleanToFormikFormat = (value?: boolean | null) => {
  if (value === undefined) {
    return undefined;
  }

  return `${value}`;
};

const EditHistoricalPrDialog: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  const isEditing = props.id !== undefined;
  const validationSchema = Yup.object().shape({
    reviewYear: Validations.requiredNonNegativeInteger(),
    prNumber: Validations.requiredPrNumber()
  });

  const [currentPrNumber, setCurrentPrNumber] = React.useState<string | undefined>();
  const [prNumberInUse, setPrNumberInUse] = React.useState<boolean>(false);

  const [addPrHistory, { loading: loadingAdd }] = useMutation<
    { historicalPrs: { add: EditableHistoricalPr } },
    { historicalPr: EditableHistoricalPr }
  >(AddPrHistoryMutation, {
    refetchQueries: [{ query: FetchFirmHistoryItemsQuery, variables: { firmId: props.firmId } }]
  });
  const [editPrHistory, { loading: loadingEdit }] = useMutation<
    { historicalPrs: { update: EditableHistoricalPr } },
    { historicalPr: EditableHistoricalPr }
  >(EditPrHistoryMutation);

  const [runPrNumberQuery, { loading: loadingPrNumberQuery, data: prNumberQueryData, variables: prNumberQueryVariables }] = useLazyQuery<{
    prNumberIsInUse: boolean;
  }>(PrNumberIsInUseQuery, {
    variables: {
      prNumber: ""
    }
  });

  const debouncedCheckIfPrNumberIsUsed = _.debounce(async (prNumber: string) => {
    await runPrNumberQuery({
      variables: {
        prNumber
      }
    });
  }, 200);

  function getPrNumberHelperText<T extends { prNumber: string | undefined }>(formikProps: FormikProps<T>) {
    if (formikProps.touched.prNumber) {
      return prNumberInUse ? `PR Number ${currentPrNumber} is in use.` : formikProps.errors.prNumber;
    }
  }

  React.useEffect(() => {
    setPrNumberInUse(
      (prNumberQueryData?.prNumberIsInUse &&
        prNumberQueryVariables?.prNumber !== props.prNumber &&
        prNumberQueryVariables?.prNumber === currentPrNumber) ??
        false
    );
  }, [prNumberQueryData]);

  const mutate = isEditing ? editPrHistory : addPrHistory;
  const loading = loadingAdd || loadingEdit;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="body" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          id: props.id,
          firmId: props.firmId,
          reviewYear: props.reviewYear,
          prNumber: props.prNumber,
          isComply: convertBooleanToFormikFormat(props.isComply) ?? undefined,
          nonComplyFinancial: props.nonComplyFinancial ?? false,
          nonComplyAudit: props.nonComplyAudit ?? false,
          nonComplyReview: props.nonComplyReview ?? false,
          nonComplyQualityManagement: props.nonComplyQualityManagement ?? false,
          nonComplyTax: props.nonComplyTax ?? false,
          nonComplyCompilation: props.nonComplyCompilation ?? false,
          nonComplyOther: props.nonComplyOther ?? false,
          nonComplyOtherSpecified: props.nonComplyOtherSpecified,
          otherComments: props.otherComments
        }}
        onSubmit={async (values: any) => {
          const historicalPr: EditableHistoricalPr = {
            ...values,
            reviewYear: Number(values.reviewYear),
            isComply: values.isComply === "true",
            nonComplyOtherSpecified: values.nonComplyOther ? values.nonComplyOtherSpecified : undefined
          };
          await mutate({
            variables: { historicalPr }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <div className={classes.mainBody}>
                <Grid container direction="row">
                  <Grid container xs={8} spacing={2}>
                    <Grid item xs={12}>
                      <FormikField component={FmuiTextField} name="reviewYear" label="Review Year" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField
                        component={FmuiTextField}
                        name="prNumber"
                        label="PR Number"
                        fullWidth
                        required
                        onKeyUp={(e: any) => {
                          setCurrentPrNumber(e.target.value);
                          debouncedCheckIfPrNumberIsUsed(e.target.value);
                        }}
                        helperText={getPrNumberHelperText(formikProps)}
                        error={(prNumberInUse || formikProps.errors.prNumber) && formikProps.touched.prNumber}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} spacing={2} className={cx(classes.denseGridContainer, classes.spaceAbove)}>
                    <Grid item xs={12}>
                      <FormLabel>Assessment</FormLabel>
                    </Grid>
                    <Grid container xs={10} className={cx(classes.spaceLeft, classes.paddedSides)}>
                      <FormikField component={FmuiRadioGroup} name="isComply">
                        <Grid item>
                          <FormControlLabel value="true" control={<Radio />} label="Comply" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value="false" control={<Radio />} label="Non-comply" />
                        </Grid>
                      </FormikField>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} spacing={2} className={cx(classes.denseGridContainer, classes.spaceAbove)}>
                    <Card variant="outlined" className={classes.spaceLeft}>
                      <div className={classes.cardInterior}>
                        <FormLabel>Areas of Non-compliance</FormLabel>
                        <Grid container xs={12}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyFinancial" />}
                              label="Financial Statements"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyAudit" />}
                              label="Audit"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyReview" />}
                              label="Review"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyQualityManagement" />}
                              label="Quality Management"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyTax" />}
                              label="Tax"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyCompilation" />}
                              label="Compilation"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<FormikField component={FmuiCheckbox} type="checkbox" name="nonComplyOther" />}
                              label="Other"
                            />
                          </Grid>
                          {formikProps.values.nonComplyOther && (
                            <Grid item xs={12} className={classes.nonComplyOther}>
                              <br />
                              <FormikField component={FmuiTextField} name="nonComplyOtherSpecified" label="Reason" fullWidth />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.otherComments}>
                <FormikField component={FmuiTextField} name="otherComments" label="Other Comments" fullWidth multiline maxRows={3} />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={loading || loadingPrNumberQuery}
                onClick={() => formikProps.submitForm()}
                disabled={prNumberInUse}>
                Save
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditHistoricalPrDialog;
