import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { IconButton, InputAdornment } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";

// Using this numbered Textfield component for more clickable buttons than the browser's native input.
type NumberedTextfieldProps = TextFieldProps & {
  value: number;
  onChange: (newValue: number) => void;
};

const NumberedTextfield: React.FC<NumberedTextfieldProps> = ({ value, onChange, inputProps = {}, ...rest }) => {
  const handleIncrement = () => {
    const newValue = Math.min((value || 0) + 1, inputProps.max ? Number(inputProps.max) : Infinity);
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = Math.max((value || 0) - 1, inputProps.min ? Number(inputProps.min) : -Infinity);
    onChange(newValue);
  };

  return (
    <TextField
      {...rest}
      type="text"
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleDecrement} size={"small"} aria-label="decrease value">
              <FontAwesomeIcon icon={faMinus} />
            </IconButton>
            <IconButton onClick={handleIncrement} size={"small"} aria-label="increase value">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </InputAdornment>
        ),
        ...rest.InputProps
      }}
    />
  );
};

export default NumberedTextfield;
