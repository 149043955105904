import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReviewedClientChecklist } from "checklists/ReviewedClientChecklist";
import CollapseToggle from "common/CollapseToggle";
import InlineStaticDataDisplay from "common/InlineStaticDataDisplay";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { makeStyles } from "makeStyles";
import { PracticeReview, ReviewedClient } from "practice-reviews";
import { expanderStyles } from "styles/common";
import { Permissions, useCurrentUser } from "users";
import { formatCurrency, formatDate } from "../util/formats";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import { getPrScreenHeaderHeight } from "practice-reviews/PrScreenHeader";
import { useDimensions } from "react-dimensions-hook";
import _ from "lodash";
import { YesNoIcon } from "../common/YesNoIcon";

const useStyles = makeStyles()((theme) => ({
  ...expanderStyles(theme),
  root: {
    paddingTop: theme.spacing(3)
  },
  fileSelectorContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignItems: "center"
  },
  partnerName: {
    color: theme.palette.text.secondary
  },
  fileDetailsWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    background: theme.palette.common.white
  },
  stickyFileDetailsWrapper: {
    position: "sticky",
    top: getPrScreenHeaderHeight(theme),
    zIndex: 2 // above MUI floating labels
  },
  detailsHeader: {
    display: "flex",
    alignItems: "center"
  },
  detailItems: {
    marginTop: theme.spacing(1),
    display: "grid",
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(3),
    justifyContent: "start"
  },
  clientDetailItems: {
    gridTemplateColumns: "minmax(6em, auto) minmax(6em, auto) minmax(6em, auto) minmax(6em, auto) repeat(auto-fill, 12ch)"
  },
  firstRow: {
    display: "contents",
    "& > *": {
      gridRow: "1/1"
    }
  },
  secondRow: {
    display: "contents",
    "& > *": {
      gridRow: "2/2"
    }
  },
  thirdRow: {
    display: "contents",
    "& > *": {
      gridRow: "3/3"
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
  reviewedClientId?: number;
}

export const ReviewedClientChecklists: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();
  const history = useHistory();
  const { user, userIsLeadReviewer, userHasPermission } = useCurrentUser();

  const pprp = props.practiceReview.isPprpReview;

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const { ref: reviewedClientDetailsRef, dimensions: reviewedClientDetailsDimensions } = useDimensions();

  const [selectedReviewedClient, setSelectedReviewedClient] = useState<ReviewedClient | null>(null);
  useEffect(
    () => setSelectedReviewedClient(props.practiceReview.reviewedClients.find((rc) => rc.id === props.reviewedClientId) ?? null),
    [props.reviewedClientId, props.practiceReview.reviewedClients]
  );

  const reviewerHasSubmittedReviewedClients =
    !userIsLeadReviewer(props.practiceReview) &&
    props.practiceReview.reviewedClients.filter((rc) => rc.id === props.reviewedClientId)?.[0]?.reviewedByUserId === user.id &&
    props.practiceReview.otherReviewers.filter((r) => r.userId === user.id)?.[0]?.reviewedClientsSubmitted;

  const capitalizedReviewedClientDescriptor = props.practiceReview.isPprpReview ? "Candidate" : "Client";

  return (
    <div className={classes.root}>
      <Grid container className={classes.fileSelectorContainer} spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <Autocomplete
            options={_.orderBy(
              userIsLeadReviewer(props.practiceReview) ||
                (!props.practiceReview.isPprpReview && userHasPermission(Permissions.PracticeReviewViewAll)) ||
                (props.practiceReview.isPprpReview && userHasPermission(Permissions.PprpProgramReviewViewAll))
                ? props.practiceReview.reviewedClients
                : props.practiceReview.reviewedClients.filter((c) => c.reviewedByUserId === user.id),
              (rc) => rc.name
            )}
            getOptionLabel={(rc) => `${rc.name}${rc.refNum ? ` (${rc.refNum})` : ""}`}
            value={selectedReviewedClient}
            onChange={(e, newReviewedClient) => {
              if (newReviewedClient) {
                history.replace(
                  getRouteForPracticeReview(
                    props.practiceReview,
                    props.practiceReview.isPprpReview ? PracticeReviewTabs.CandidateChecklists : PracticeReviewTabs.ClientChecklists,
                    newReviewedClient
                  )
                );
              }
            }}
            renderInput={(params) => <TextField {...params} label={capitalizedReviewedClientDescriptor} margin="none" />}
          />
        </Grid>
        {selectedReviewedClient && (
          <Grid item xs={12} lg={4} xl={3}>
            <InlineStaticDataDisplay label={pprp ? "Mentor" : "Partner"} value={selectedReviewedClient.partnerName} />
          </Grid>
        )}
      </Grid>

      {selectedReviewedClient && (
        <Box
          ref={reviewedClientDetailsRef}
          className={cx(classes.fileDetailsWrapper, { [classes.stickyFileDetailsWrapper]: detailsExpanded })}>
          <Card variant="outlined">
            <CardContent sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, ":last-child": { paddingBottom: 1 } }}>
              <div className={classes.detailsHeader}>
                <CollapseToggle
                  size="small"
                  className={classes.expander}
                  expanded={detailsExpanded}
                  toggle={() => setDetailsExpanded(!detailsExpanded)}
                />
                <Typography variant="h3" onClick={() => setDetailsExpanded(!detailsExpanded)}>
                  {`${capitalizedReviewedClientDescriptor} Details: ${selectedReviewedClient.name}`}
                </Typography>
              </div>
              <Collapse in={detailsExpanded} timeout="auto" unmountOnExit>
                {pprp ? (
                  <Stack direction="row" spacing={10}>
                    <div className={classes.detailItems}>
                      <div className={classes.firstRow}>
                        <StackedStaticDataDisplay label="ID No." nowrap value={selectedReviewedClient.candidate!.idNo} />
                        <StackedStaticDataDisplay
                          label="PER Effective Date"
                          value={formatDate(selectedReviewedClient.candidate!.perEffectiveDate)}
                        />
                        <StackedStaticDataDisplay
                          label="Mentor Reviews"
                          nowrap
                          value={selectedReviewedClient.candidate!.mentorReviews?.toString() ?? "--"}
                        />
                        <StackedStaticDataDisplay label="Rotation 1" nowrap value={selectedReviewedClient.candidate!.rotation1 ?? "--"} />
                        <StackedStaticDataDisplay label="Rotation 2" nowrap value={selectedReviewedClient.candidate!.rotation2 ?? "--"} />
                        <StackedStaticDataDisplay label="Rotation 3" nowrap value={selectedReviewedClient.candidate!.rotation3 ?? "--"} />
                        <StackedStaticDataDisplay label="Rotation 4" nowrap value={selectedReviewedClient.candidate!.rotation4 ?? "--"} />
                      </div>
                      <div className={classes.secondRow}>
                        <StackedStaticDataDisplay
                          label="Months of Experience"
                          nowrap
                          value={selectedReviewedClient.candidate!.monthsExperience?.toString() ?? "--"}
                        />
                        <div />
                        <StackedStaticDataDisplay
                          label="Required Mentor Reviews"
                          nowrap
                          value={selectedReviewedClient.candidate!.requiredNoOfReviews?.toString() ?? "--"}
                        />
                        <StackedStaticDataDisplay
                          label="Duration in Rotation 1"
                          nowrap
                          value={
                            selectedReviewedClient.candidate!.rotation1Duration
                              ? `${selectedReviewedClient.candidate!.rotation1Duration} months`
                              : "--"
                          }
                        />
                        <StackedStaticDataDisplay
                          label="Duration in Rotation 2"
                          nowrap
                          value={
                            selectedReviewedClient.candidate!.rotation2Duration
                              ? `${selectedReviewedClient.candidate!.rotation2Duration} months`
                              : "--"
                          }
                        />
                        <StackedStaticDataDisplay
                          label="Duration in Rotation 3"
                          nowrap
                          value={
                            selectedReviewedClient.candidate!.rotation3Duration
                              ? `${selectedReviewedClient.candidate!.rotation3Duration} months`
                              : "--"
                          }
                        />
                        <StackedStaticDataDisplay
                          label="Duration in Rotation 4"
                          nowrap
                          value={
                            selectedReviewedClient.candidate!.rotation4Duration
                              ? `${selectedReviewedClient.candidate!.rotation4Duration} months`
                              : "--"
                          }
                        />
                      </div>
                    </div>
                    <Table size="small" sx={{ flexGrow: 0, flexShrink: 0, width: "auto", "& .MuiCheckbox-root": { padding: 0 } }}>
                      <TableHead>
                        <TableRow sx={{ "& .MuiTableCell-head": { fontSize: theme.typography.body1.fontSize } }}>
                          <TableCell>Chargeable Hours</TableCell>
                          <TableCell align="center">Reasonable</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Audit</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableAuditChargeableHours} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Review</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableReviewChargeableHours} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other Assurance</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableOtherAssuranceChargeableHours} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Compilation</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableCompilationChargeableHours} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Taxation</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableTaxationChargeableHours} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other</TableCell>
                          <TableCell align="center">
                            <YesNoIcon yes={selectedReviewedClient.candidate!.reasonableOtherChargeableHours} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Stack>
                ) : (
                  <div className={cx(classes.detailItems, classes.clientDetailItems)}>
                    <StackedStaticDataDisplay label="Nature of Business" nowrap value={selectedReviewedClient.clientFile!.businessNature} />
                    <StackedStaticDataDisplay
                      label="Fiscal Year End"
                      nowrap
                      value={formatDate(selectedReviewedClient.clientFile!.fiscalYearEnd)}
                    />
                    <StackedStaticDataDisplay
                      label="Report Date"
                      value={
                        selectedReviewedClient.clientFile!.reportDate ? formatDate(selectedReviewedClient.clientFile!.reportDate) : "--"
                      }
                    />
                    <StackedStaticDataDisplay label="Engagement Type" nowrap value={selectedReviewedClient.engagementType?.name ?? "--"} />
                    {selectedReviewedClient.clientFile!.isHoursNotFees ? (
                      <StackedStaticDataDisplay
                        label="Hours"
                        value={selectedReviewedClient.clientFile!.feesOrHoursAmount?.toString() ?? "--"}
                      />
                    ) : (
                      <StackedStaticDataDisplay
                        label="Fees"
                        nowrap
                        value={
                          selectedReviewedClient.clientFile!.feesOrHoursAmount !== undefined &&
                          selectedReviewedClient.clientFile!.feesOrHoursAmount !== null
                            ? formatCurrency(selectedReviewedClient.clientFile!.feesOrHoursAmount)!
                            : "--"
                        }
                      />
                    )}
                    <StackedStaticDataDisplay
                      label="Materiality"
                      nowrap
                      value={formatCurrency(selectedReviewedClient.clientFile!.materiality) ?? "--"}
                    />
                    <StackedStaticDataDisplay
                      label="Assets"
                      nowrap
                      value={formatCurrency(selectedReviewedClient.clientFile!.assets) ?? "--"}
                    />
                    <StackedStaticDataDisplay
                      label="Liabilities"
                      nowrap
                      value={formatCurrency(selectedReviewedClient.clientFile!.liabilities) ?? "--"}
                    />
                    <StackedStaticDataDisplay
                      label="Revenue"
                      nowrap
                      value={formatCurrency(selectedReviewedClient.clientFile!.revenue) ?? "--"}
                    />
                    <StackedStaticDataDisplay
                      label="Net Income"
                      value={formatCurrency(selectedReviewedClient.clientFile!.netIncomeBeforeTaxes) ?? "--"}
                    />
                  </div>
                )}
              </Collapse>
            </CardContent>
          </Card>
        </Box>
      )}

      {selectedReviewedClient && (
        <ReviewedClientChecklist
          key={selectedReviewedClient.id}
          practiceReviewId={props.practiceReview.id}
          prNumber={props.practiceReview.prNumber}
          levelOfAdjustment={props.practiceReview.levelOfAdjustment}
          reviewedClient={selectedReviewedClient}
          reviewerHasSubmittedReviewedClients={reviewerHasSubmittedReviewedClients}
          checklistActionsVerticalOffset={detailsExpanded ? reviewedClientDetailsDimensions.height : 0}
        />
      )}
    </div>
  );
};
