import { FC } from "react";
import { Stack, StackProps, Select, MenuItem, FormControl, InputLabel, InputAdornment, TextField } from "@mui/material";
import { FrequencyOptions } from "./models";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { DateTime } from "luxon";
import EventIcon from "@mui/icons-material/Event";
import { formatDate } from "util/formats";
import { makeStyles } from "makeStyles";
import NumberedTextfield from "common/NumberedTextfield";
const useStyles = makeStyles()({
  stackItemWidth: {
    width: "25%",
    margin: 0
  },
  dateWidth: {
    width: "16em"
  }
});

export interface MonitoringHeaderStackProps extends Omit<StackProps, "onChange"> {
  monitoringFrequencyInDays: number | null;
  monitoringOccurrencesRemaining: number | null;
  currentMonitoringPeriodDate?: string;
  monitoringCompleted: boolean;
  monitoringCompletedOnDate?: string | null;
  createdDate: string | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  markUnsaved: () => void;
}

const MAX_FREQUENCY_INPUT = 10;

export const MonitoringHeaderStack: FC<MonitoringHeaderStackProps> = ({
  monitoringFrequencyInDays,
  monitoringOccurrencesRemaining,
  currentMonitoringPeriodDate,
  monitoringCompleted,
  monitoringCompletedOnDate,
  createdDate,
  setFieldValue,
  markUnsaved,
  ...stackProps
}) => {
  const { classes } = useStyles();

  const endDate =
    currentMonitoringPeriodDate && monitoringFrequencyInDays !== null && monitoringOccurrencesRemaining !== null
      ? DateTime.fromISO(currentMonitoringPeriodDate).plus({ days: monitoringFrequencyInDays * monitoringOccurrencesRemaining })
      : null;

  return (
    <Stack {...stackProps}>
      <StackedStaticDataDisplay label="Monitoring Started On" value={formatDate(createdDate)} sx={{ width: "8em" }} />
      {monitoringCompleted ? (
        <>
          <StackedStaticDataDisplay label="Monitoring Completed On" value={formatDate(monitoringCompletedOnDate ?? null)} />
        </>
      ) : (
        <>
          <FormControl className={classes.stackItemWidth}>
            <InputLabel>Frequency</InputLabel>
            <Select
              value={monitoringFrequencyInDays}
              onChange={(e) => {
                setFieldValue("monitoringFrequencyInDays", e.target.value);
                markUnsaved();
              }}
              label="Frequency">
              {Object.keys(FrequencyOptions)
                .filter((key) => isNaN(Number(key)))
                .map((key) => (
                  <MenuItem key={key} value={FrequencyOptions[key as keyof typeof FrequencyOptions]}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <NumberedTextfield
            label="Occurrences Remaining"
            value={monitoringOccurrencesRemaining ?? 0}
            onChange={(newValue) => {
              setFieldValue("monitoringOccurrencesRemaining", newValue);
              markUnsaved();
            }}
            inputProps={{ min: "0", max: `${MAX_FREQUENCY_INPUT}` }}
            className={classes.stackItemWidth}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Tentative Monitoring End Date"
            disabled
            value={formatDate(endDate?.toISO() ?? null)}
            className={classes.stackItemWidth}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EventIcon />
                </InputAdornment>
              )
            }}
          />
        </>
      )}
    </Stack>
  );
};

export default MonitoringHeaderStack;
