export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  cammsEntityNumber: number | null;
  designation: string | null;
  userPrincipalName?: string | null;
  permissions: Permissions[];
  groups: string[];
}

export interface UserInput {
  id: number;
  cammsEntityNumber: number | null;
  designation: string | null;
}
export interface UserRole {
  id: number;
  name: string;
}

export enum Permissions {
  PracticeReviewViewAll = "PracticeReview.View.All",
  PprpProgramReviewViewAll = "PPRPProgramReview.View.All",
  PrChangeStatus = "PR.Change.Status",
  PrRemove = "PR.Remove",
  PrChangeType = "PR.Change.Type",
  PrChangeMeeting = "PR.Change.Meeting",
  PrUpdateFiles = "PR.Update.Files",
  PrUpdatePrNotes = "PR.Update.PrNotes",
  PrUpdateEstimateAndClients = "PR.Update.EstimateAndClients",
  CammsRefreshFirm = "CAMMS.Refresh.Firm",
  CammsViewAndGenerateInvoice = "CAMMS.ViewAndGenerate.Invoice",
  InaViewOthers = "INA.View.Others",
  ActivityLogUpdate = "Activity.Update",
  ReportsView = "Reports.View",
  ViewSchedulingAndReviewers = "SchedulingAndReviewers.View",
  UpdateScheduling = "Scheduling.Update",
  UpdateReviewers = "Reviewers.Update",
  ViewNotesFromNoticeProcess = "NoticeProcess.Notes.View",
  TimeEntryEnterForAnyReviewer = "TimeEntries.Enter.AnyReviewer",
  AdminTasks = "Admin.Tasks",
  AdminTasksPprp = "Admin.Tasks.PPRP",
  ReturnedPrUpdate = "ReturnedPR.Update",
  PrManagerDecisionView = "PR.ManagerDecision.View",
  PrManagerDecisionUpdate = "PR.ManagerDecision.Update",
  PrManagerDecisionSignOff = "PR.ManagerDecision.SignOff",
  PrDirectorDecisionView = "PR.DirectorDecision.View",
  PrDirectorDecisionUpdate = "PR.DirectorDecision.Update",
  PrDirectorDecisionSignOff = "PR.DirectorDecision.SignOff",
  MeetingBinderGenerate = "MeetingBinder.Generate",
  MeetingBinderAssemble = "MeetingBinder.Assemble",
  DocumentTemplateSandboxAllow = "DocumentTemplateSandbox.Allow",
  ExemptionSetPendingStatus = "Exemption.SetPendingStatus",
  ExemptionReviewLetter = "Exemption.ReviewLetter",
  ExemptionConfirm = "Exemption.Confirm",
  PracticeReviewViewIncreasedRisk = "Pr.IncreasedRisk.View"
}

export enum LocalStorageKeys {
  LastClickedRow = "LastClickedRow"
}
