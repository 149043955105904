import React from "react";
import { Ina, HighlightRowType } from "./models";
import { Link, Tooltip } from "@mui/material";
import { dataGridDateValueFormatter, sortDateStringByMonthOnly } from "util/formats";
import { datagridStyles, inaPanelStyles, tableStyles } from "styles/common";
import { makeStyles } from "../makeStyles";
import { PrScreenQuery } from "../practice-reviews";
import { GridColDef, GridRowId, GridSortCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { InaPanel } from "./InaPanel";
import { DateTime } from "luxon";

const useStyles = makeStyles<Props>()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme)
}));

interface Props {
  title: string;
  inas: Ina[];
  getNavigationRoute?: (ina: Ina) => string;
  getFixedNavigationRoute?: () => string;
  className?: string;
  completable?: boolean;
  showOverdue?: boolean;
  showMissingFormsColumn?: boolean;
  showScheduledColumn?: boolean;
  showReviewLocationColumn?: boolean;
  showSystemEstimateColumn?: boolean;
  showFinalEstimateColumn?: boolean;
  showLeadReviewerColumn?: boolean;
  showOtherReviewersColumn?: boolean;
  showPhaseColumn?: boolean;
  showIncreasedRiskColumn?: boolean;
  showDecisionColumn?: boolean;
  showMeetingColumn?: boolean;
  showRequiresResponseColumn?: boolean;
  showExemptionStartDateColumn?: boolean;
  isPerformReview?: boolean;
  extraColumns?: GridColDef[];
  headerActions?: (selectedInas: Ina[]) => React.ReactElement;
  extraActions?: (ina: Ina) => React.ReactElement;
  actionsWidth?: number;
  getRowDetails?: (ina: Ina) => React.ReactElement;
  getDetailPanelHeight?: (ina: Ina) => number;
  getLastClickedRow?: HighlightRowType | null;
  navigateTo?: (event: React.MouseEvent<HTMLElement>, ina: Ina, id: GridRowId, location: string) => void;
  defaultSortModel?: GridSortModel;
}

export const PracticeReviewInaPanel: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const columns: GridColDef<Ina>[] = [
    {
      field: "practiceReview.prNumber",
      headerName: "PR No.",
      width: 70,
      valueGetter: ({ row }) => row.practiceReview!.prNumber,
      cellClassName: ({ row }) =>
        cx(classes.defaultLeftBorder, {
          [classes.new]: row.isNew,
          [classes.semiNew]: row.isSemiNew && !row.isNew,
          [classes.overdue]: props.showOverdue && row.isOverdue
        }),
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getRow(param1.id)!.practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
    },
    {
      field: "practiceReview.firm.entityNumber",
      headerName: "Entity No.",
      headerClassName: classes.wrapHeader,
      width: 60,
      valueGetter: ({ row }) =>
        row.practiceReview!.pprpProgram ? row.practiceReview!.pprpProgram.programEntityNumber : row.practiceReview!.firm.entityNumber,
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) => {
        const pr1 = param1.api.getRow(param1.id)!.practiceReview!;
        const entityNumber1 = pr1.pprpProgram ? pr1.pprpProgram.programEntityNumber : pr1.firm.entityNumber;
        const pr2 = param1.api.getRow(param2.id)!.practiceReview!;
        const entityNumber2 = pr2.pprpProgram ? pr2.pprpProgram.programEntityNumber : pr2.firm.entityNumber;

        return entityNumber1 - entityNumber2;
      }
    },
    {
      field: "practiceReview.firm.name",
      headerName: "Firm Name",
      flex: 2,
      width: 300,
      renderCell: ({ row, id }) => {
        const firmOrProgramName = row.practiceReview!.pprpProgram ? row.practiceReview!.pprpProgram.name : row.practiceReview!.firm.name;
        return props.getNavigationRoute ? (
          <Tooltip title={firmOrProgramName}>
            <Link
              component="button"
              onClick={(event) => {
                props.navigateTo?.(event, row, id, props.getNavigationRoute!(row));
              }}>
              {firmOrProgramName}
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title={firmOrProgramName}>
            <div>{firmOrProgramName}</div>
          </Tooltip>
        );
      },
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getRow(param1.id)!.practiceReview.firm.name.localeCompare(param2.api.getRow(param2.id)!.practiceReview.firm.name)
    },
    {
      field: "practiceReview.pprpProgram.programPath",
      headerName: "Program Path",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.practiceReview!.pprpProgram?.programPath ?? "--",
      hide: props.inas.every((ina) => ina.practiceReview!.pprpProgram === null)
    }
  ];

  columns.push(...(props.extraColumns ?? []));

  const suffixColumns: GridColDef<Ina>[] = [
    {
      field: "practiceReview.exemption.startedOn",
      headerName: "Exemption Started",
      headerClassName: classes.wrapHeader,
      width: 100,
      valueGetter: ({ row }) => row.practiceReview!.exemption?.startedOn,
      valueFormatter: dataGridDateValueFormatter,
      hide: !props.showExemptionStartDateColumn
    },
    {
      field: "practiceReview.exemption.anniversaryMonth",
      headerName: "Anniv. Month",
      headerClassName: `${classes.wrapHeader} ${classes.tightSortIconHeader}`,
      type: "date",
      sortComparator: sortDateStringByMonthOnly,
      valueGetter: ({ row }) => row.practiceReview!.exemption?.startedOn,
      valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat("MMM") : "--"),
      width: 70,
      hide: !props.showExemptionStartDateColumn
    },
    {
      field: "practiceReview.mostAuthoritativeDecision.isComply",
      headerName: "Decision",
      width: 90,
      valueGetter: ({ row }) =>
        row.practiceReview!.mostAuthoritativeDecision
          ? row.practiceReview!.mostAuthoritativeDecision!.isComply
            ? "Comply"
            : "Non-comply"
          : "--",
      cellClassName: ({ row }) =>
        cx({
          [classes.comply]: row.practiceReview!.mostAuthoritativeDecision?.isComply === true,
          [classes.nonComply]: row.practiceReview!.mostAuthoritativeDecision?.isComply === false
        }),
      hide: !props.showDecisionColumn
    },
    {
      field: "practiceReview.committeeMeeting",
      headerName: "Committee Meeting",
      headerClassName: classes.wrapHeader,
      width: 200,
      valueGetter: ({ row }) => row.practiceReview!.committeeMeeting?.name ?? "--",
      hide: !props.showMeetingColumn
    },
    {
      field: "practiceReview.requiresResponse",
      headerName: "Requires Response",
      headerClassName: classes.wrapHeader,
      headerAlign: "center",
      type: "boolean",
      width: 100,
      valueGetter: ({ row }) => row.practiceReview!.requiresResponse,
      hide: !props.showRequiresResponseColumn
    },
    {
      field: "practiceReview.hasIncreasedRisk",
      headerName: "Increased Risk",
      headerClassName: classes.wrapHeader,
      cellClassName: cx(classes.hideBooleanXsInCell, classes.dangerCheck),
      type: "boolean",
      width: 100,
      valueGetter: ({ row }) => row.practiceReview!.hasIncreasedRisk,
      hide: !props.showIncreasedRiskColumn || props.inas.every((ina) => !ina.practiceReview!.hasIncreasedRisk)
    },
    {
      field: "practiceReview.estimate.totalSystemEstimate",
      headerName: "System Estimate (h)",
      headerAlign: "right",
      headerClassName: classes.wrapHeader,
      cellClassName: classes.number,
      type: "number",
      width: 110,
      valueGetter: ({ row }) => row.practiceReview!.estimate.totalSystemEstimate.toFixed(2),
      hide: !props.showSystemEstimateColumn
    },
    {
      field: "practiceReview.estimate.finalEstimate",
      headerName: "Final Estimate (h)",
      headerAlign: "right",
      headerClassName: classes.wrapHeader,
      width: 110,
      type: "number",
      cellClassName: classes.number,
      valueGetter: ({ row }) => row.practiceReview!.estimate.finalEstimate.toFixed(2),
      hide: !props.showFinalEstimateColumn
    },
    {
      field: "missingForms",
      headerName: "Missing Forms",
      type: "string",
      width: 300,
      valueGetter: ({ row }) => {
        const practiceReview = row.practiceReview!;
        const missingForms: string[] = [];

        if (practiceReview.isMissingSchedulingQuestionnaire) missingForms.push("Scheduling Q.");
        if (practiceReview.isMissingTaxQuestionnaire) missingForms.push("Tax Q.");
        if (practiceReview.isMissingConfidentialClientList) missingForms.push("Client List");
        if (practiceReview.isMissingSelfEvaluationQuestionnaire) missingForms.push("Self-evaluation Q.");

        return missingForms.length > 0 ? missingForms.join(", ") : "--";
      },
      hide: !props.showMissingFormsColumn
    },
    {
      field: "practiceReview.datesHaveBeenConfirmed",
      headerName: "Scheduled?",
      type: "boolean",
      width: 110,
      valueGetter: ({ row }) => row.practiceReview!.datesHaveBeenConfirmed,
      hide: !props.showScheduledColumn
    },
    {
      field: "practiceReview.firm.city",
      headerName: "Review Location",
      headerClassName: classes.wrapHeader,
      width: 120,
      valueGetter: ({ row }) => row.practiceReview!.firm.city,
      hide: !props.showReviewLocationColumn
    },
    {
      field: "practiceReview.phase.name",
      headerName: "Phase",
      width: 150,
      valueGetter: ({ row }) => row.practiceReview!.phase.name,
      hide: !props.showPhaseColumn
    },
    {
      field: "practiceReview.leadReviewer.name",
      headerName: "Lead Reviewer",
      width: 140,
      valueGetter: ({ row }) => row.practiceReview!.leadReviewer?.user.name ?? "--",
      hide: !props.showLeadReviewerColumn
    },
    {
      field: "practiceReview.otherReviewers",
      valueGetter: ({ row }) => {
        const pr = row.practiceReview!;
        return pr.otherReviewers.length > 0
          ? _.orderBy(pr.otherReviewers, (r) => r.user.name)
              .map((r) => r.user.name)
              .join(", ")
          : "--";
      },
      headerName: "Other Reviewers",
      width: 200,
      hide: !props.showOtherReviewersColumn
    },
    {
      field: "practiceReview.startDate",
      headerName: "Review Date",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 100,
      valueGetter: ({ row }) => row.practiceReview!.startDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "practiceReview.reviewType",
      headerName: "Type",
      width: 110,
      valueGetter: ({ row }) => row.practiceReview!.reviewType
    },
    { field: "priority", headerName: "Priority", width: 70, hide: props.isPerformReview }
  ];
  columns.push(...suffixColumns);
  //Swap column positions for Perform Reviews
  if (props.isPerformReview) {
    const dueDateIndex = columns.findIndex((element) => element.field === "practiceReview.dueDate");
    const startDateIndex = columns.findIndex((element) => element.field === "practiceReview.startDate");
    [columns[dueDateIndex], columns[startDateIndex]] = [columns[startDateIndex], columns[dueDateIndex]];
  }
  return (
    <InaPanel
      columns={columns}
      title={props.title}
      inas={props.inas}
      getNavigationRoute={props.getNavigationRoute}
      getFixedNavigationRoute={props.getFixedNavigationRoute}
      className={props.className}
      completable={props.completable}
      showOverdue={props.showOverdue}
      headerActions={props.headerActions}
      extraActions={props.extraActions}
      actionsWidth={props.actionsWidth}
      getRowDetails={props.getRowDetails}
      getDetailPanelHeight={props.getDetailPanelHeight}
      extraRefetchQueries={[(ina) => ({ query: PrScreenQuery, variables: { prNumber: ina.practiceReview!.prNumber } })]}
      getLastClickedRow={props.getLastClickedRow}
      navigateTo={props.navigateTo}
      defaultSortModel={
        props.defaultSortModel
          ? props.defaultSortModel
          : props.showExemptionStartDateColumn
          ? [
              {
                field: "practiceReview.exemption.anniversaryMonth",
                sort: "asc"
              }
            ]
          : [
              {
                field: "practiceReview.startDate",
                sort: "asc"
              }
            ]
      }
    />
  );
};
