import { Alert, Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, MenuItem, Stack } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { PracticeReview } from "practice-reviews";
import React from "react";
import { CommitteeMeeting } from "./models";
import { Formik, Field as FormikField, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { useMutation, useQuery } from "@apollo/client";
import { useNotifications } from "notifications";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { AvailableMeetingsQuery, ChangePrCommitteeMeetingMutation, FetchCommitteeMeetingsQuery, FetchUnassignedPrsQuery } from ".";
import _ from "lodash";
import PrList from "../inas/PrList";
import { Checkbox as FmuiCheckbox } from "formik-mui";
import { meetingStyles } from "styles/common";

const useStyles = makeStyles()((theme) => ({
  ...meetingStyles(theme)
}));

interface Props {
  practiceReviews: PracticeReview[];
  onClose: () => void;
}

export const ChangeMeetingDialog: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();

  interface FormValues {
    committeeMeetingId: number | null;
    notifyFirm: boolean;
  }

  const initialFormValues: FormValues = {
    committeeMeetingId: props.practiceReviews[0]?.committeeMeeting?.id ?? null,
    notifyFirm: false
  };

  const availableMeetingsQuery = useQuery<{ availableMeetings: CommitteeMeeting[] }>(AvailableMeetingsQuery);
  const availableMeetings = availableMeetingsQuery.data?.availableMeetings ?? [];

  const noPrsArePprp = props.practiceReviews.every((pr) => !pr.isPprpReview);
  const noPrsArePaf = props.practiceReviews.every((pr) => pr.isPprpReview);
  const meetingsEligibleForPrType = availableMeetings.filter((m) => (m.isPprp || noPrsArePprp) && (!m.isPprp || noPrsArePaf));

  const [changeMeetingMutate, changeMeetingMutation] = useMutation<
    { practiceReview: { changeMeeting: PracticeReview[] } },
    { practiceReviewIds: number[]; newCommitteeMeetingId: number; notifyFirm: boolean }
  >(ChangePrCommitteeMeetingMutation, {
    refetchQueries: [{ query: AvailableMeetingsQuery }, { query: FetchCommitteeMeetingsQuery }, { query: FetchUnassignedPrsQuery }]
  });

  async function changeMeeting(values: FormValues, actions: FormikHelpers<FormValues>) {
    const result = await changeMeetingMutate({
      variables: {
        practiceReviewIds: props.practiceReviews.map((pr) => pr.id),
        newCommitteeMeetingId: values.committeeMeetingId!,
        notifyFirm: values.notifyFirm
      }
    });

    if ((result.data?.practiceReview.changeMeeting?.length ?? 0) > 0) {
      notifications.success(`Assigned PR${props.practiceReviews.length > 0 ? "s" : ""} to meeting.`);
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  const validationSchema = Yup.object({
    committeeMeetingId: Yup.number().nullable().required("Select a PRC meeting.")
  });

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={changeMeeting}>
        {(formikProps) => {
          const selectedMeeting = availableMeetings.find((m) => m.id === formikProps.values.committeeMeetingId);

          return (
            <>
              <ClosableDialogTitle onClose={props.onClose}>
                {props.practiceReviews[0]?.committeeMeeting ? "Change Committee Meeting" : "Assign to Committee Meeting"}
              </ClosableDialogTitle>
              <DialogContent>
                <Stack spacing={2}>
                  {meetingsEligibleForPrType.length === 0 && (
                    <Alert color="warning">{`There are no available meetings that ${
                      props.practiceReviews.length > 1 ? "these reviews" : "this review"
                    } can be assigned to.`}</Alert>
                  )}

                  <PrList prs={props.practiceReviews} />

                  <FormikField
                    component={FmuiTextField}
                    select
                    name="committeeMeetingId"
                    label="Committee Meeting"
                    fullWidth
                    disabled={formikProps.isSubmitting}
                    error={formikProps.touched.committeeMeetingId && Boolean(formikProps.errors.committeeMeetingId)}
                    helperText={formikProps.touched.committeeMeetingId && formikProps.errors.committeeMeetingId}>
                    {_.orderBy(meetingsEligibleForPrType, (m) => m.meetingDate).map((meeting) => (
                      <MenuItem key={meeting.id} value={meeting.id}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          {meeting.name}
                          <span
                            className={cx(classes.meetingCapacity, {
                              [classes.meetingOverCapacity]: meeting.assignedReviews.length >= meeting.reviewQuota
                            })}>{`${meeting.assignedReviews.length}/${meeting.reviewQuota}`}</span>
                        </Box>
                      </MenuItem>
                    ))}
                  </FormikField>

                  {selectedMeeting &&
                    selectedMeeting.id !== props.practiceReviews[0].committeeMeeting?.id &&
                    selectedMeeting.assignedReviews.length >= selectedMeeting.reviewQuota && (
                      <Alert color="warning">This meeting is full.</Alert>
                    )}

                  <FormControlLabel
                    control={<FormikField component={FmuiCheckbox} type="checkbox" name="notifyFirm" />}
                    label={`Notify firm${props.practiceReviews.length === 1 ? "" : "s"} of meeting change`}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => props.onClose()} disabled={changeMeetingMutation.loading}>
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={() => formikProps.submitForm()}
                  disabled={!formikProps.isValid}
                  loading={changeMeetingMutation.loading}>
                  OK
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
