import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  Stack
} from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import * as Yup from "yup";
import { Field as FormikField, Formik, FormikHelpers } from "formik";
import { DateTime } from "luxon";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { RadioGroup, TextField as FmuiTextField } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import { Validations } from "../common/validations/common-yup-validations";
import { useMutation } from "@apollo/client";
import {
  AddCommitteeMeetingMutation,
  AvailableMeetingsQuery,
  CommitteeMeeting,
  CommitteeMeetingInput,
  FetchCommitteeMeetingsQuery
} from ".";
import { useNotifications } from "../notifications";
import { makeStyles } from "../makeStyles";
import _ from "lodash";
import { PracticeReview } from "../practice-reviews";
import { GetInasForUserQuery } from "../inas";
import { useCalendar } from "../util/CalendarProvider";
import { staticDataStyles } from "styles/common";
import { AppRoute } from "../Routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme)
}));

interface Props {
  handleClose: (prsToReassign?: PracticeReview[]) => void;
}

interface FormValues {
  meetingDate: DateTime | null;
  location: string;
  isPprp: "true" | "false" | null;
  reviewQuota: number;
  subcommitteeDate: DateTime | null;
}

const AddMeetingDialog = (props: Props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { configureForBusinessDaysMath } = useCalendar();
  const history = useHistory();

  const [addMeetingMutate, addMeetingMutation] = useMutation<
    { committeeMeeting: { add: CommitteeMeeting } },
    { meeting: CommitteeMeetingInput }
  >(AddCommitteeMeetingMutation, {
    refetchQueries: [
      { query: FetchCommitteeMeetingsQuery, variables: { excludeFuture: false, pastPeriod: null } },
      { query: GetInasForUserQuery },
      { query: AvailableMeetingsQuery }
    ]
  });

  async function addMeeting(values: FormValues, actions: FormikHelpers<FormValues>) {
    const meetingInput = getMeetingInputFromFormValues(values);

    const result = await addMeetingMutate({
      variables: { meeting: meetingInput }
    });

    actions.setSubmitting(false);
    props.handleClose();

    if (result.data?.committeeMeeting?.add.id) {
      notifications.success("Added meeting.");
      history.push(AppRoute.CommitteeMeeting.replace(":id", result.data.committeeMeeting.add.id.toString()));
    }
  }

  function getMeetingInputFromFormValues(values: FormValues): CommitteeMeetingInput {
    return {
      meetingDate: values.meetingDate!.toISODate(),
      location: values.location,
      isPprp: values.isPprp === "true",
      reviewQuota: Number(values.reviewQuota),
      subcommitteeDate: values.isPprp === "false" ? values.subcommitteeDate?.toISODate() ?? null : null
    };
  }

  const validationSchema = Yup.object({
    meetingDate: Validations.requiredDate(),
    location: Validations.requiredText(),
    isPprp: Yup.boolean().nullable().required("Select whether the meeting is for practice reviews or PPRP reviews."),
    reviewQuota: Validations.requiredPositiveInteger()
  });

  const initialValues: FormValues = {
    meetingDate: null,
    location: "",
    isPprp: null,
    reviewQuota: 60,
    subcommitteeDate: null
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>Add Committee Meeting</ClosableDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          await addMeeting(values, actions);

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <>
            <DialogContent>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel className={classes.label}>Meeting Type</FormLabel>
                  <FormikField component={RadioGroup} name="isPprp" required>
                    <FormControlLabel value={"false"} control={<Radio />} label="Practice Reviews" />
                    <FormControlLabel value={"true"} control={<Radio />} label="PPRP Reviews" />
                  </FormikField>
                  {formikProps.touched.isPprp && formikProps.errors.isPprp && (
                    <FormHelperText error>{formikProps.errors.isPprp}</FormHelperText>
                  )}
                </FormControl>
                <FormikField
                  component={PrsDatePickerField}
                  name="meetingDate"
                  label="Date"
                  fullWidth
                  required
                  minDate={DateTime.local()}
                  onChange={(newDate: DateTime | null) => {
                    formikProps.setValues({
                      ...formikProps.values,
                      meetingDate: newDate,
                      subcommitteeDate: newDate ? configureForBusinessDaysMath(newDate).minusBusiness({ days: 2 }) : null
                    });
                  }}
                />
                <FormikField component={FmuiTextField} name="location" label="Location" fullWidth required />
                <FormikField component={FmuiTextField} name="reviewQuota" label="Maximum to Assign" fullWidth required />
                {formikProps.values.isPprp === "false" && (
                  <FormikField
                    component={PrsDatePickerField}
                    name="subcommitteeDate"
                    label="Subcommittee Meeting Date"
                    fullWidth
                    minDate={DateTime.local()}
                  />
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.handleClose()} disabled={addMeetingMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={addMeetingMutation.loading}
                onClick={() => formikProps.submitForm()}>
                Add
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddMeetingDialog;
