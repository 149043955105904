import React, { memo, useEffect, useState } from "react";
import { Button, DialogContentText, IconButton, InputBase, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment as fasComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faComment as farComment } from "@fortawesome/free-regular-svg-icons/faComment";
import { AnswerType } from "checklists";
import RichTooltip from "common/RichTooltip";
import { DeleteButton } from "common/CrudTable";
import { answerButtonsStyles } from "styles/common";
import { makeStyles } from "makeStyles";
import { ConfirmationDialog } from "../common/ConfirmationDialog";

const useStyles = makeStyles<Props>()((theme, props) => ({
  ...answerButtonsStyles(theme),
  buttons: {
    flexShrink: 0,
    display: "flex",
    visibility: props.hidden ? "hidden" : undefined,
    height: props.hidden ? 0 : "unset"
  },
  fourButtonContainer: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr"
  },
  noteButton: {
    marginRight: theme.spacing(1),
    height: "100%"
  },
  editorTooltipContent: {
    display: "flex",
    alignItems: "center",
    "& > :first-child": {
      flexGrow: 1
    },
    "& > :last-child": {
      marginLeft: theme.spacing(1)
    }
  },
  noteField: {
    width: "20em"
  },
  hidden: {
    visibility: "hidden"
  },
  clearNoteButton: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    height: `calc(1rem + ${theme.spacing(2)})`,
    width: `calc(1rem + ${theme.spacing(2)})`
  },
  readonly: {
    cursor: "default"
  }
}));

interface Props {
  answerType?: AnswerType | null;
  note?: string | null;
  answerQuestion?: (answerType: AnswerType) => void;
  saveNote?: (note: string | null) => void;
  hidden: boolean;
  readonly?: boolean;
  disabled?: boolean;
}

const AnswerDisplay: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const [note, setNote] = useState("");
  useEffect(() => setNote(props.note ?? ""), [props.note]);

  const [notePopoverOpen, setNotePopoverOpen] = useState(false);
  const [confirmingDeleteNote, setConfirmingDeleteNote] = useState(false);

  const isYes = props.answerType === AnswerType.Yes;
  const isRd = props.answerType === AnswerType.Rd;
  const isNrd = props.answerType === AnswerType.Nrd;
  const isNa = props.answerType === AnswerType.Na;

  function closeNotePopover(deleting?: boolean) {
    setNotePopoverOpen(false);
    props.saveNote!(note.trim() === "" || deleting ? null : note);
  }

  return (
    <div className={classes.buttons}>
      <RichTooltip
        placement="left"
        arrow
        open={notePopoverOpen}
        content={
          <div className={classes.editorTooltipContent}>
            <InputBase className={classes.noteField} multiline autoFocus value={note} onChange={(e) => setNote(e.target.value)} />
            <DeleteButton
              className={cx({ [classes.hidden]: !Boolean(note) })}
              onClick={() => {
                setConfirmingDeleteNote(true);
              }}
            />
          </div>
        }
        onClose={() => closeNotePopover()}>
        <Tooltip title={props.note ?? ""} placement="left">
          <IconButton
            className={classes.noteButton}
            color={props.note ? "primary" : undefined}
            disabled={props.disabled}
            onClick={(e) => {
              setNotePopoverOpen(!notePopoverOpen);
              if (document.activeElement) {
                (document.activeElement as HTMLElement).blur(); // Set focus back to body for keyboard answering
              }
            }}>
            <FontAwesomeIcon icon={props.note ? fasComment : farComment} />
          </IconButton>
        </Tooltip>
      </RichTooltip>

      <div className={cx(classes.buttonContainer, classes.fourButtonContainer)}>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isYes,
            [classes.selectedAnswerButton]: isYes,
            [classes.yes]: isYes,
            [classes.readonly]: props.readonly
          })}
          disableElevation
          variant={isYes ? "contained" : "outlined"}
          disabled={props.disabled}
          onClick={(e) => {
            if (!props.readonly) {
              props.answerQuestion!(AnswerType.Yes);
              (e.target as HTMLButtonElement).blur(); // So that keyboard answering works without clicking outside the button
            }
          }}>
          YES
        </Button>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isRd,
            [classes.selectedAnswerButton]: isRd,
            [classes.rd]: isRd,
            [classes.readonly]: props.readonly
          })}
          disableElevation
          variant={isRd ? "contained" : "outlined"}
          disabled={props.disabled}
          onClick={(e) => {
            if (!props.readonly) {
              props.answerQuestion!(AnswerType.Rd);
              (e.target as HTMLButtonElement).blur();
            }
          }}>
          RD
        </Button>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isNrd,
            [classes.selectedAnswerButton]: isNrd,
            [classes.nrd]: isNrd,
            [classes.readonly]: props.readonly
          })}
          disableElevation
          variant={isNrd ? "contained" : "outlined"}
          disabled={props.disabled}
          onClick={(e) => {
            if (!props.readonly) {
              props.answerQuestion!(AnswerType.Nrd);
              (e.target as HTMLButtonElement).blur();
            }
          }}>
          NRD
        </Button>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isNa,
            [classes.selectedAnswerButton]: isNa,
            [classes.na]: isNa,
            [classes.readonly]: props.readonly
          })}
          disableElevation
          variant={isNa ? "contained" : "outlined"}
          disabled={props.disabled}
          onClick={(e) => {
            if (!props.readonly) {
              props.answerQuestion!(AnswerType.Na);
              (e.target as HTMLButtonElement).blur();
            }
          }}>
          N/A
        </Button>
      </div>

      {confirmingDeleteNote && (
        <ConfirmationDialog
          open={true}
          body={<DialogContentText>Remove note?</DialogContentText>}
          title="Remove note?"
          cancel={() => setConfirmingDeleteNote(false)}
          confirm={() => {
            setNote("");
            closeNotePopover(true);
            setConfirmingDeleteNote(false);
          }}
        />
      )}
    </div>
  );
};

const MemoizedAnswerDisplay = memo(AnswerDisplay);
export { MemoizedAnswerDisplay as AnswerDisplay };
