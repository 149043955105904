import { gql } from "@apollo/client";

export const GetInasForUserQuery = gql`
  query GetInasForCurrentUser($userId: Int) {
    inas(userId: $userId) {
      id
      priority
      createdDate
      dueDate
      isNew
      isSemiNew
      isOverdue
      type {
        id
        typeCode
        friendlyName
      }
      assignedToUser {
        id
        name
      }
      practiceReview {
        id
        prNumber
        prStage
        hasIncreasedRisk
        firm {
          id
          name
          entityNumber
          address1
          address2
          city
        }
        phase {
          id
          name
        }
        attachedDocuments {
          id
          type
        }
        contactName
        contactPhone
        contactEmail
        contactEmailUri
        datesHaveBeenConfirmed
        startDate
        endDate
        completedDate
        reviewType
        isPprpReview
        isMissingSchedulingQuestionnaire
        isMissingTaxQuestionnaire
        isMissingConfidentialClientList
        isMissingSelfEvaluationQuestionnaire
        estimate {
          id
          totalSystemEstimate
          finalEstimate
        }
        leadReviewer {
          userId
          practiceReviewId
          user {
            id
            name
          }
        }
        otherReviewers {
          practiceReviewId
          userId
          user {
            id
            name
          }
        }
        mostRecentRescheduleReason
        held {
          heldBy
          reason
          date
        }
        mostAuthoritativeDecision {
          id
          isComply
          directedPdDueDate
          assignedPdCourses {
            id
            isDirected
            isCompleted
            isExempt
            pdCourse {
              id
              name
            }
          }
        }
        committeeMeeting {
          id
          name
          meetingDate
        }
        requiresResponse
        exemption {
          id
          startedOn
          status
          newPracticeReviewForFirm {
            id
            prNumber
          }
        }
        pprpProgram {
          id
          programEntityNumber
          name
          programPath
          address1
          address2
          city
        }

        monitoringOccurrencesRemaining
        monitoringFrequencyInDays
        monitoringCompleted
        lastCompletedMonitoring {
          id
          isCompleted
          completedDate
          completedByUser {
            id
            name
          }
          isPass
        }
        latestMonitoring {
          id
          monitoringDate
          completedDate
          completedByUser {
            id
            name
          }
        }
      }
      programTask {
        id
        type {
          typeCode
          typeName
          skipRegistrar
        }
        submittedBy
        submissionDate
        status
        registrarDecision
        registrarDecisionDate
        applyForProgramLeaderFirstName
        applyForProgramLeaderLastName
        applyForProgramLeaderEmail
        applyForNewProviderPprpName
        applyForNewProviderFirmName
        pprpProgram {
          id
          name
          programPath
          programEntityNumber
          address1
          address2
          city
          firm {
            id
            entityNumber
            name
          }
        }
        firm {
          id
          entityNumber
          name
        }
        attachedDocuments {
          id
          type
        }
      }
      activity {
        id
        activityName
        outcomeOrComments
        assignedByUser {
          id
          name
        }
      }
    }
  }
`;

export const CompleteInaMutation = gql`
  mutation CompleteIna($inaId: Int!, $inaTypeCode: InaTypeCode!) {
    ina {
      complete(inaId: $inaId, inaTypeCode: $inaTypeCode)
    }
  }
`;

export const CompleteMultipleInasMutation = gql`
  mutation CompleteMultipleInas($inaIds: [Int!]!, $inaTypeCode: InaTypeCode!) {
    ina {
      completeMultiple(inaIds: $inaIds, inaTypeCode: $inaTypeCode)
    }
  }
`;

export const ReassignInasMutation = gql`
  mutation ReassignInas($inaIds: [Int!]!, $userId: Int!, $priority: InaPriority!, $isRetain: Boolean!) {
    ina {
      reassign(inaIds: $inaIds, userId: $userId, priority: $priority, isRetain: $isRetain) {
        id
        priority
        assignedToUser {
          id
          name
        }
      }
    }
  }
`;

export const SetPriorityOfInasMutation = gql`
  mutation SetPriorityOfInas($inaIds: [Int!]!, $priority: InaPriority!) {
    ina {
      setPriority(inaIds: $inaIds, priority: $priority) {
        id
        priority
      }
    }
  }
`;
