import React from "react";
import { PracticeReview, PRBaseStatusCode, PRPhaseCode } from "practice-reviews";
import { Grid, Alert } from "@mui/material";
import { FirmPartnerTable } from "./FirmPartnerTable";
import { ClienteleProfileTable } from "./ClienteleProfileTable";
import { EstimateTimeTable } from "./EstimateTimeTable";
import { FirmProfileTable } from "./FirmProfileTable";
import { QuestionnaireList } from "./QuestionnaireList";
import { useCurrentUser, Permissions } from "users";

import { ClientsToReview } from "./ClientsToReview";
import { makeStyles } from "makeStyles";

interface Props {
  practiceReview: PracticeReview;
}

export const EstimateTime: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    root: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(5)
      }
    },
    column: {
      overflowX: "hidden"
    },
    centerColumn: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(5)
      }
    }
  }));
  const { userHasPermission } = useCurrentUser();

  const { classes, cx } = useStyles();
  const prIsOnHold = props.practiceReview.status.baseStatusCode === PRBaseStatusCode.Hold;
  const prIsInSchedulingPhase = props.practiceReview.phase.id === PRPhaseCode.Scheduling;

  const estimateIsVisible = userHasPermission(Permissions.PrUpdateEstimateAndClients);
  return (
    <div className={classes.root}>
      {prIsOnHold && <Alert severity="warning">The PR is on hold, so changes are not allowed.</Alert>}
      {(props.practiceReview.isMissingSchedulingQuestionnaire ||
        props.practiceReview.isMissingTaxQuestionnaire ||
        props.practiceReview.isMissingConfidentialClientList ||
        props.practiceReview.isMissingSelfEvaluationQuestionnaire) && (
        <Alert severity="warning">Some questionnaires and/or forms have not been received.</Alert>
      )}
      <FirmPartnerTable partners={props.practiceReview.firm.latestPartners} estimate={props.practiceReview.estimate} />
      <Grid container columnSpacing={5}>
        <Grid item xs={12} lg={estimateIsVisible ? 4 : 6} className={classes.column}>
          <ClienteleProfileTable clienteleProfile={props.practiceReview.firm.latestClienteleProfile} />
        </Grid>
        <Grid item xs={12} lg={estimateIsVisible ? 4 : 6} className={cx(classes.column, classes.centerColumn)}>
          <FirmProfileTable
            firmId={props.practiceReview.firm.id}
            reviewYear={props.practiceReview.reviewYear}
            firmProfile={props.practiceReview.firmProfileForReviewYear}
            practiceReviewId={props.practiceReview.id}
            disableInputs={prIsOnHold}
          />
          <QuestionnaireList practiceReview={props.practiceReview} />
          {props.practiceReview.isDeskReview && (
            <ClientsToReview
              practiceReviewId={props.practiceReview.id}
              clientsToReview={props.practiceReview.clientsToReview}
              disableInputs={prIsOnHold}
            />
          )}
        </Grid>
        {estimateIsVisible && (
          <Grid item xs={12} lg={4} className={classes.column}>
            <EstimateTimeTable
              practiceReviewId={props.practiceReview.id}
              prNumber={props.practiceReview.prNumber}
              estimate={props.practiceReview.estimate}
              currentReviewActualTime={props.practiceReview.actualTimeToDate}
              previousReviewActualTime={props.practiceReview.previousReview?.actualTimeToDate ?? null}
              previousReviewDate={props.practiceReview.previousReview?.startDate ?? null}
              disableInputs={!prIsInSchedulingPhase || prIsOnHold}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
