import { SignOffChecklistItem } from "../decisions";
import { Ina } from "../inas";
import { AttachedDocument, Firm, PracticeReview } from "../practice-reviews";

export interface PprpProgram {
  id: number;
  programEntityNumber: number;
  name: string;
  status: string | null;
  subStatus: string | null;
  approvalDate: string | null;
  programPath: string | null;
  restrictions: string | null;
  conditions: string | null;
  positionsApproved: number | null;

  address1: string | null;
  address2: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;

  pertCandidateTotal: number | null;
  pertCandidateTotalPerPert: number | null;
  pertCandidateApprovalLimit: number | null;
  pertCandidateCountPerLocation: number | null;
  pertCandidateDifference: number | null;

  members: PprpProgramMember[];
  attachedDocuments: AttachedDocument[];
  firm: Firm;
  practiceReviews: PracticeReview[];
}

export interface PprpProgramDetails {
  technicalCompetencyApprovedDepth: string | null;
  technicalCompetencyApprovedCore: string | null;

  approvalLimitComments: string | null;

  firmProfileCurrentApprovalLimit: number | null;
  firmProfileCurrentStudentsInProgram: number | null;
  firmProfileMaxStudentSupportBySch: number | null;
}

export interface PprpProgramMember {
  id: number;
  memberEntityNumber: number;
  name: string;
  firstName: string;
  lastName: string;
  title: string | null;
  designation: string | null;
  isPrimaryProgramLeader: boolean;
  isSecondaryProgramLeader: boolean;
  isProgramManager: boolean;
  hasSigningAuthority: boolean;
}

export enum PprpProgramTaskTypeCode {
  ModifyProgram = "ModifyProgram",
  ChangeProgramLeader = "ChangeProgramLeader",
  ChangeProgramManager = "ChangeProgramManager",
  DeregisterProgram = "DeregisterProgram",
  ApplyForProgram = "ApplyForProgram",
  AnnualRenewal = "AnnualRenewal",
  ApplyForNewProvider = "ApplyForNewProvider"
}

export enum PprpProgramTaskStatus {
  New = "New",
  InProgress = "InProgress",
  Complete = "Complete",
  Cancelled = "Cancelled"
}

export enum PprpProgramTaskRegistrarDecision {
  Approved = "Approved",
  Declined = "Declined"
}

export interface PprpProgramTaskType {
  typeCode: PprpProgramTaskTypeCode;
  typeName: string;
  skipRegistrar: boolean;
}

export interface PprpProgramTask {
  id: number;
  type: PprpProgramTaskType;
  status: PprpProgramTaskStatus;
  lastStatusChange: string;
  submissionDate: string;
  submittedBy: string;
  internalNotes: string | null;
  notesToRegistrar: string | null;
  registrarDecision: PprpProgramTaskRegistrarDecision | null;
  registrarDecisionDate: string | null;
  registrarNotes: string | null;

  annualRenewalResponseReceived: boolean;

  pprpProgram: PprpProgram | null;
  firm: Firm | null;
  inas: Ina[];
  attachedDocuments: AttachedDocument[];
  renewalChecklistItems: SignOffChecklistItem[];

  primaryLeaderChange: LeaderChange;
  secondaryLeaderChange: LeaderChange;

  applyForProgramLeaderFirstName: string | null;
  applyForProgramLeaderLastName: string | null;
  applyForProgramLeaderEmail: string | null;

  applyForNewProviderFirmName: string | null;
  applyForNewProviderPprpName: string | null;
  applyForNewProviderLeaderFirstName: string | null;
  applyForNewProviderLeaderLastName: string | null;
  applyForNewProviderLeaderEmail: string | null;
}

export interface PprpProgramTaskInput {
  id: number | null;
  internalNotes: string | null;
  notesToRegistrar: string | null;
  registrarDecisionCode: PprpProgramTaskRegistrarDecision | null;
  registrarDecisionDate: string | null;
  registrarNotes: string | null;
  renewalChecklistItems: { id: number; isChecked: boolean }[];
  applyForProgramLeaderFirstName: string | null;
  applyForProgramLeaderLastName: string | null;
  applyForProgramLeaderEmail: string | null;
  applyForNewProviderFirmName: string | null;
  applyForNewProviderPprpName: string | null;
  applyForNewProviderLeaderFirstName: string | null;
  applyForNewProviderLeaderLastName: string | null;
  applyForNewProviderLeaderEmail: string | null;
}

export interface LeaderChange {
  current: PprpProgramMember;
  resigning: boolean;
  replacement: PprpProgramMember | null;
}
