import { useQuery, gql } from "@apollo/client";
import { User, Permissions } from "users";
import { PracticeReview, Reviewer } from "../practice-reviews";

export interface CurrentUser {
  userLoading: boolean;
  user: User;
  userIsLeadReviewer: (practiceReview?: PracticeReview) => boolean;
  userIsOtherReviewer: (practiceReview: PracticeReview) => boolean;
  userHasPermission: (permission: Permissions) => boolean;
}

export function useCurrentUser(): CurrentUser {
  const query = useQuery<{
    currentUser: User;
  }>(
    gql`
      query GetCurrentUser {
        currentUser {
          id
          name
          firstName
          lastName
          designation
          cammsEntityNumber
          email
          permissions
          groups
        }
      }
    `
  );

  const defaultUser: User = {
    id: 0,
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    cammsEntityNumber: null,
    permissions: [],
    groups: []
  };
  const user = query.data?.currentUser ?? defaultUser;

  function userIsOtherReviewer(practiceReview: PracticeReview) {
    return practiceReview.otherReviewers.some((reviewer: Reviewer) => reviewer.userId === user.id);
  }

  function userIsLeadReviewer(practiceReview?: PracticeReview) {
    return practiceReview?.leadReviewer?.user.id === user.id;
  }

  function userHasPermission(permission: Permissions) {
    return user.permissions.indexOf(permission) !== -1;
  }

  return {
    userLoading: query.loading,
    user: user,
    userIsLeadReviewer,
    userIsOtherReviewer,
    userHasPermission
  };
}
