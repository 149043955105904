import { gql } from "@apollo/client";

export const AllUsersQuery = gql`
  query FetchUsers {
    users {
      id
      name
      email
      firstName
      lastName
      designation
      cammsEntityNumber
      userPrincipalName
      groups
    }
  }
`;

export const EditUserMutation = gql`
  mutation EditUser($user: UserInput!) {
    user {
      edit(user: $user) {
        id
        designation
        cammsEntityNumber
      }
    }
  }
`;

export const UsersWhoseInasMayBeViewedByOthersQuery = gql`
  query FetchUsersWhoseInasMayBeViewedByOthers {
    usersWhoseInasMayBeViewedByOthers {
      id
      name
    }
  }
`;

export const UsersWhoMayHaveInasReassignedToThemQuery = gql`
  query FetchUsersWhoMayHaveInasReassignedToThem {
    usersWhoMayHaveInasReassignedToThem {
      id
      name
    }
  }
`;
