import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { Alert, DialogContentText, Stack } from "@mui/material";
import { BinderJob, CommitteeMeeting } from "./models";
import { FetchBinderJobByMeetingIdQuery, FetchBinderJobsQuery, GenerateBinderMutation } from ".";

interface Props {
  onClose: () => void;
  committeeMeeting: CommitteeMeeting;
}

const ConfirmGenerateBinderDocumentsDialog = (props: Props) => {
  const notifications = useNotifications();
  const [generateMutate, generateMutation] = useMutation<{ committeeMeeting: { generateBinder: BinderJob } }, { meetingId: number }>(
    GenerateBinderMutation,
    {
      refetchQueries: [
        { query: FetchBinderJobsQuery },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.committeeMeeting.id } }
      ]
    }
  );

  return (
    <>
      <ConfirmationDialog
        noDanger
        open={true}
        title={props.committeeMeeting.binderGenerated ? "Regenerate Binder Documents and Tabs" : "Generate Binder Documents and Tabs"}
        body={
          props.committeeMeeting.binderGenerated ? (
            <Stack spacing={2}>
              <Alert color="warning">Caution: the documents for this meeting binder have already been generated.</Alert>
              <DialogContentText>
                This action will reset the tab numbers and regenerate each document. Any manual changes that have been made to the office
                listing, the motion summary, or the presentation format documents since the binder documents were last generated will no
                longer be the current version and will not appear in the assembled binder.
              </DialogContentText>
              <DialogContentText>
                You should only proceed if zones or motions have changed, impacting the tab numbers/sort order.
              </DialogContentText>
              <DialogContentText>
                (To simply update the binder to reflect manual changes in the documents that comprise it, instead of regenerating them, use
                the "Assemble Binder" option instead.)
              </DialogContentText>
              <DialogContentText>Are you sure you want to regenerate the binder documents?</DialogContentText>
            </Stack>
          ) : (
            <>
              <DialogContentText>{`Generate the binder documents for "${props.committeeMeeting.name}"? This will:`}</DialogContentText>
              <DialogContentText>
                <ul>
                  <li>Assign tab numbers to all reviews on the meeting</li>
                  <li>
                    {props.committeeMeeting.isPprp
                      ? "Create an Office Listing document and save it"
                      : "Create new Agenda, Office Listing, and Standard Motion Summary documents and save them"}{" "}
                    to the meeting's library folder in SharePoint
                  </li>
                  <li>
                    Create new {props.committeeMeeting.isPprp ? "Program Review Summary" : "Presentation Format"} documents for each review
                    and save them to the reviews' libraries in SharePoint
                  </li>
                </ul>
              </DialogContentText>
            </>
          )
        }
        confirm={async () => {
          await generateMutate({
            variables: { meetingId: props.committeeMeeting.id }
          });

          props.onClose();
        }}
        loading={generateMutation.loading}
        cancel={props.onClose}
      />
    </>
  );
};

export default ConfirmGenerateBinderDocumentsDialog;
