import { PracticeReview } from "practice-reviews";
import { Activity } from "../activities/models";
import { PprpProgramTask } from "../pprp";
import { User } from "../users";
import { GridRowId } from "@mui/x-data-grid-pro";

export enum InaPriority {
  High = "High",
  Normal = "Normal",
  Low = "Low"
}

export enum InaTypeCode {
  ReviewOnHold = "ReviewOnHold",

  GenerateNotices = "GenerateNotices",
  ApproveNotices = "ApproveNotices",
  ProcessUnsentNotices = "ProcessUnsentNotices",
  WaitingForUnsentNotices = "WaitingForUnsentNotices",
  NoticesToPrintAndMail = "NoticesToPrintAndMail",
  WaitingForForms = "WaitingForForms",
  SendClientFileRequestNotification = "SendClientFileRequestNotification",
  ClientFilesNotReceived = "ClientFilesNotReceived",
  FormsMissingFirstReminder = "FormsMissingFirstReminder",
  FormsMissingSecondReminder = "FormsMissingSecondReminder",
  ReviewForms = "ReviewForms",
  EstimateTime = "EstimateTime",
  RecheckTime = "RecheckTime",
  ScheduleReview = "ScheduleReview",
  RescheduleReview = "RescheduleReview",
  ReviewSubmittedFiles = "ReviewSubmittedFiles",
  ReviewSubmittedTaskFiles = "ReviewSubmittedTaskFiles",
  ReviewSubmittedPdDocuments = "ReviewSubmittedPdDocuments",
  WaitingForReviewPeriod = "WaitingForReviewPeriod",
  WaitingForReviewerDownload = "WaitingForReviewerDownload",
  PerformReview = "PerformReview",
  PRReturnedIncomplete = "PRReturnedIncomplete",
  PRNotReturned = "PRNotReturned",

  SendReviewerEvaluation = "SendReviewerEvaluation",
  PrepareDraftReport = "PrepareDraftReport",
  CreatePendingInvoice = "CreatePendingInvoice",
  EnterManagerDecision = "EnterManagerDecision",
  DiscussDecisionChangeWithReviewer = "DiscussDecisionChangeWithReviewer",
  WaitingForFirmToBeToldOfDecisionChange = "WaitingForFirmToBeToldOfDecisionChange",
  EnterDirectorDecision = "EnterDirectorDecision",
  SendDraftReportsToFirm = "SendDraftReportsToFirm",
  PRsToPrintAndMail = "PRsToPrintAndMail",
  WaitingForFirmResponse = "WaitingForFirmResponse",
  RedactFirmResponse = "RedactFirmResponse",
  ReviewFirmResponse = "ReviewFirmResponse",
  AssignToCommitteeMeeting = "AssignToCommitteeMeeting",

  WaitingForCommitteeMeeting = "WaitingForCommitteeMeeting",
  PrepareForCommitteeMeeting = "PrepareForCommitteeMeeting",
  ViewAndPrintCommitteeBinder = "ViewAndPrintCommitteeBinder",
  ViewAndPrintDirectorCommitteeMeetingMaterials = "ViewAndPrintDirectorCommitteeMeetingMaterials",
  WaitingToEnterCommitteeDecisions = "WaitingToEnterCommitteeDecisions",
  EnterCommitteeDecision = "EnterCommitteeDecision",
  ReviewFinalReports = "ReviewFinalReports",
  SendFinalReports = "SendFinalReports",
  PDWaitingForDeclaration = "PDWaitingForDeclaration",
  PDFollowUp = "PDFollowUp",
  SendPDCompletionNotification = "SendPDCompletionNotification",

  ScheduleCommitteeMeetings = "ScheduleCommitteeMeetings",
  FirmStatusChanged = "FirmStatusChanged",
  FirmAddressChanged = "FirmAddressChanged",

  ReviewDraftExemptionLetter = "ReviewDraftExemptionLetter",
  ApproveExemptionLetterAndSendNotification = "ApproveExemptionLetterAndSendNotification",
  WaitingForExemptionResponse = "WaitingForExemptionResponse",
  ConfirmAcknowledgedExemption = "ConfirmAcknowledgedExemption",
  ReviewPrWithActiveExemption = "ReviewPrWithActiveExemption",

  PprpReviewApplication = "PprpReviewApplication",
  PprpEnterProgramTaskDecision = "PprpEnterProgramTaskDecision",
  PprpWaitingForRegistrarDecision = "PprpWaitingForRegistrarDecision",
  PprpWaitingForSignedDecisionLetter = "PprpWaitingForSignedDecisionLetter",
  PprpSignedDecisionLetterReceived = "PprpSignedDecisionLetterReceived",
  PprpSendAnnualProgramRenewalNotice = "PprpSendAnnualProgramRenewalNotice",
  PprpWaitingForProgramRenewalResponse = "PprpWaitingForProgramRenewalResponse",
  PprpMonitoringRequired = "PprpMonitoringRequired",
  PprpMonitoringCompleted = "PprpMonitoringCompleted",
  PprpSendBackToCommittee = "PprpSendBackToCommittee",

  CustomActivity = "CustomActivity"
}

export interface InaType {
  id: number;
  typeCode: InaTypeCode;
  friendlyName: string;
}

export interface Ina {
  id: number;
  type: InaType;
  createdDate: string;
  dueDate: string | null;
  priority: InaPriority;
  practiceReview: PracticeReview | null;
  programTask: PprpProgramTask | null;
  isNew: boolean;
  isSemiNew: boolean;
  isComplete: boolean;
  isOverdue: boolean;
  inaBatch: InaBatch;
  assignedToUser: User;
  activity: Activity | null;
}

export interface InaBatch {
  id: number;
  assignedTo: string;
}

export interface HighlightRowType {
  rowId?: GridRowId | undefined;
  rowTypeCode?: string | undefined;
  hasNavigated?: boolean | undefined;
}
