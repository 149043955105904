import React, { useRef, useState } from "react";
import {
  AttachedDocument,
  CreateRedactedCopyOfFirmResponseMutation,
  DocType,
  PracticeReview,
  PrScreenQuery,
  RemoveAttachedDocumentFromPracticeReviewMutation
} from "practice-reviews";
import { makeStyles } from "../makeStyles";
import { Formik, Field as FormikField } from "formik";
import { DateTime } from "luxon";
import * as Yup from "yup";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { Box, Button, DialogContentText, FormControlLabel, Link, Radio, Stack, Tooltip, Typography } from "@mui/material";
import { Checkbox as FmuiCheckbox } from "formik-mui";
import RichTextEditor from "../common/RichTextEditor";
import { useApolloClient, useMutation } from "@apollo/client";
import { FirmResponse, FirmResponseInput } from "./models";
import { getOpenableUrl, htmlHasContent } from "../util/utilities";
import { useNotifications } from "../notifications";
import { SaveFirmResponseMutation } from "./queries";
import { LoadingButton } from "@mui/lab";
import { Validations } from "../common/validations/common-yup-validations";
import { AxiosResponse } from "axios";
import { useAxios } from "../auth/SecureAxios";
import { useAppConfig } from "../util/AppConfig";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { AcrobatLink } from "../common/AcrobatLink";

const useStyles = makeStyles()((theme) => ({}));

interface Props {
  practiceReview: PracticeReview;
}

interface FormValues {
  dateReceived: DateTime | null;
  firmAcknowledgesReports: boolean;
  responseType: "html" | "attached" | "none" | null;
  noFirmResponse: boolean;
  responseHtml: string | null;
  isRedacted: boolean;
}

export const FirmResponseTab: React.FunctionComponent<Props> = (props) => {
  const { theme } = useStyles();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();
  const notifications = useNotifications();
  const apolloClient = useApolloClient();

  const [attachingDocumentType, setAttachingDocumentType] = useState<DocType | null>(null);
  const [removingDocument, setRemovingDocument] = useState<AttachedDocument | null>(null);

  const firmResponse = props.practiceReview.firmResponse;
  const hasHtmlResponse = htmlHasContent(firmResponse?.responseHtml ?? null);
  const originalResponseDocumentAttached = props.practiceReview.attachedDocuments.some((ad) => ad.type === DocType.FirmResponseOriginal);
  const redactedResponseDocumentAttached = props.practiceReview.attachedDocuments.some((ad) => ad.type === DocType.FirmResponseRedacted);
  const responseDocumentsAttached = originalResponseDocumentAttached || redactedResponseDocumentAttached;
  const hasResponse = hasHtmlResponse || responseDocumentsAttached;

  const responseHtmlContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const [saveMutate, saveMutation] = useMutation<
    { firmResponse: { save: FirmResponse } },
    {
      firmResponse: FirmResponseInput;
    }
  >(SaveFirmResponseMutation);

  async function saveFirmResponse(values: FormValues) {
    let responseHtml = responseHtmlContentRetriever.current.getContentAsHtml();
    if (!htmlHasContent(responseHtml)) {
      responseHtml = null;

      if (values.responseType === "html") {
        values.isRedacted = false;
      }
    }

    const firmResponseInput: FirmResponseInput = {
      id: firmResponse?.id,
      practiceReviewId: props.practiceReview.id,
      dateReceived: (values.dateReceived ?? DateTime.now()).toISODate(),
      firmAcknowledgesReports: values.firmAcknowledgesReports,
      noFirmResponse: values.responseType == "none",
      responseHtml: values.responseType == "html" ? responseHtml : null,
      isRedacted: values.isRedacted
    };

    const mutationResponse = await saveMutate({
      variables: {
        firmResponse: firmResponseInput
      }
    });

    const savedFirmResponse = mutationResponse.data?.firmResponse.save;
    if (savedFirmResponse?.id) {
      changesSaved();

      const prCacheId = `PracticeReview:${props.practiceReview.id}`;

      apolloClient.cache.modify({
        id: prCacheId,
        fields: {
          firmResponse(existing: FirmResponse | null) {
            return savedFirmResponse;
          }
        }
      });

      notifications.success("Firm response saved.");
    }
  }

  const [removeAttachedDocumentMutate, removeAttachedDocumentMutation] = useMutation<
    { practiceReview: { removeAttachedDocument: PracticeReview } },
    { practiceReviewId: number; attachedDocumentId: number; documentType: DocType }
  >(RemoveAttachedDocumentFromPracticeReviewMutation, {
    refetchQueries: [{ query: PrScreenQuery, variables: { prNumber: props.practiceReview.prNumber } }]
  });

  async function removeAttachedDocument(document: AttachedDocument) {
    const result = await removeAttachedDocumentMutate({
      variables: { practiceReviewId: props.practiceReview.id, attachedDocumentId: document.id, documentType: document.type }
    });

    if (result.data?.practiceReview.removeAttachedDocument?.id) {
      notifications.success("Removed document.");
    }

    setRemovingDocument(null);
  }

  const [createRedactedCopyMutate, createRedactedCopyMutation] = useMutation<
    { practiceReview: { createRedactedCopyOfFirmResponse: PracticeReview } },
    { practiceReviewId: number }
  >(CreateRedactedCopyOfFirmResponseMutation);

  async function createAndOpenRedactedCopy() {
    const result = await createRedactedCopyMutate({
      variables: { practiceReviewId: props.practiceReview.id }
    });

    const mutatedPr = result.data?.practiceReview.createRedactedCopyOfFirmResponse;
    if (mutatedPr?.id) {
      const redactedCopy = mutatedPr.attachedDocuments.find((ad) => ad.type === DocType.FirmResponseRedacted);
      if (redactedCopy) {
        window.open(`acrobat:${redactedCopy.url}`);
      }
    }

    setRemovingDocument(null);
  }

  const ResponseDocumentLinkAndActions: React.FunctionComponent<{
    name: string;
    documentType: DocType;
    hasEnteredResponse: boolean;
    includeRedactButton?: boolean;
  }> = (componentProps) => {
    const { name, documentType } = componentProps;
    const { secureAxios } = useAxios();
    const appConfig = useAppConfig();
    const apolloClient = useApolloClient();

    const document = props.practiceReview.attachedDocuments.find((ad) => ad.type === documentType);

    async function documentAttached(documents: FileList, documentType: DocType) {
      if (documentType === DocType.FirmResponseOriginal && !documents[0].name.toLowerCase().endsWith(".docx")) {
        notifications.error("Original firm responses must be a Word .docx file.");
        return;
      }

      setAttachingDocumentType(documentType);

      const formData = new FormData();

      formData.append("PracticeReviewId", props.practiceReview.id.toString());
      formData.append("DocumentTypeCode", documentType.toString());
      formData.append("Documents", documents[0]);

      const extension = /\.[^\.]+$/.exec(documents[0].name)?.[0] ?? "";
      formData.append(
        "FileName",
        `Firm Response ${documentType === DocType.FirmResponseOriginal ? "Original" : "Redacted"} ${
          props.practiceReview.prNumber
        }${extension}`
      );

      let postResult: AxiosResponse<PracticeReview>;
      const postRequestEndpoint = `${appConfig.apiEndpoint}/api/practice-review-document/upload`;
      try {
        postResult = await secureAxios.post(postRequestEndpoint, formData, {});
        if (postResult === undefined || postResult?.status === 401) {
          postResult = await secureAxios.post(postRequestEndpoint, formData, {});
        }
      } catch (e: any) {
        setAttachingDocumentType(null);
        notifications.serverError(e.response?.data ?? e.message);
        return;
      }

      if (postResult?.status !== 200) {
        notifications.serverError(new Error(postResult?.statusText));
      } else {
        const updatedPr: PracticeReview = postResult.data;
        const attachedDocument = updatedPr.attachedDocuments.find((ad) => ad.typeAsString === documentType)!;
        attachedDocument.type = documentType;

        const prCacheId = `PracticeReview:${updatedPr.id}`;

        // Modifying the cache here will not give the component everything it needs to update, but it will trigger a refetch
        // of the main PR query.
        apolloClient.cache.modify({
          id: prCacheId,
          fields: {
            attachedDocuments() {
              return updatedPr.attachedDocuments.map((ad) => ({ ...ad, __typename: "AttachedDocument" }));
            }
          }
        });

        notifications.success("Attached document.");
      }

      setAttachingDocumentType(null);
    }

    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <div>
          {!document ? (
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {name}
            </Typography>
          ) : (
            <Link href={getOpenableUrl(document.url)} target="_blank">
              <Typography variant="body1">{name}</Typography>
            </Link>
          )}
        </div>
        <div>
          {!document ? (
            <Stack direction="row" spacing={2} alignItems="center">
              {componentProps.includeRedactButton && (
                <>
                  <Tooltip
                    title={
                      componentProps.hasEnteredResponse
                        ? "Remove the entered response before attaching a response document."
                        : documentType === DocType.FirmResponseRedacted && !originalResponseDocumentAttached
                        ? "Attach the firm's original response first."
                        : ""
                    }>
                    <span>
                      <LoadingButton
                        size="small"
                        component="label"
                        loading={createRedactedCopyMutation.loading}
                        variant="outlined"
                        color="primary"
                        disabled={
                          componentProps.hasEnteredResponse ||
                          (documentType === DocType.FirmResponseRedacted && !originalResponseDocumentAttached)
                        }
                        onClick={() => createAndOpenRedactedCopy()}>
                        Redact Original
                      </LoadingButton>
                    </span>
                  </Tooltip>

                  <Typography>OR</Typography>
                </>
              )}

              <Tooltip title={componentProps.hasEnteredResponse ? "Remove the entered response before attaching a response document." : ""}>
                <span>
                  <LoadingButton
                    size="small"
                    component="label"
                    loading={attachingDocumentType === documentType}
                    variant="outlined"
                    disabled={componentProps.hasEnteredResponse}>
                    Upload
                    <input type="file" hidden accept=".docx,.pdf" onChange={(e) => documentAttached(e.target.files!, documentType)} />
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {componentProps.includeRedactButton && (
                <Button variant="outlined" size="small" onClick={() => window.open(`acrobat:${document.url}`)}>
                  Edit
                </Button>
              )}
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => setRemovingDocument(document!)}
                disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
                className="removeButton">
                Remove
              </Button>
            </Stack>
          )}
        </div>
      </Stack>
    );
  };

  const initialFormValues: FormValues = {
    dateReceived: firmResponse?.dateReceived ? DateTime.fromISO(firmResponse?.dateReceived) : null,
    firmAcknowledgesReports: firmResponse?.firmAcknowledgesReports ?? false,
    responseType: hasHtmlResponse ? "html" : responseDocumentsAttached ? "attached" : firmResponse?.noFirmResponse ? "none" : null,
    noFirmResponse: firmResponse?.noFirmResponse ?? false,
    responseHtml: firmResponse?.responseHtml ?? null,
    isRedacted: firmResponse?.isRedacted ?? false
  };

  const validationSchema = Yup.object({
    dateReceived: Validations.requiredDate("Enter the date received.")
  });

  return (
    <Stack spacing={3} sx={{ height: "100%", maxWidth: "60rem" }}>
      <Formik initialValues={initialFormValues} onSubmit={(values) => saveFirmResponse(values)} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <>
              <Stack direction="row" spacing={5} alignItems="flex-start">
                <FormikField
                  component={PrsDatePickerField}
                  name="dateReceived"
                  label="Date Received"
                  maxDate={DateTime.local()}
                  margin="none"
                  allowNonWorkingDays
                  onBlur={() => {
                    formikProps.setFieldTouched("dateReceived", true);
                  }}
                  onChange={(newValue: DateTime) => {
                    formikProps.setFieldValue("dateReceived", newValue);
                    unsavedChanges();
                  }}
                />

                <FormControlLabel
                  control={
                    <FormikField
                      component={FmuiCheckbox}
                      type="checkbox"
                      name="firmAcknowledgesReports"
                      onChange={(e: React.ChangeEvent<any>) => {
                        formikProps.setFieldValue("firmAcknowledgesReports", e.target.value !== "true");
                        unsavedChanges();
                      }}
                    />
                  }
                  label="Firm acknowledges reports have been received"
                />
              </Stack>

              <Stack spacing={1}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Tooltip title={originalResponseDocumentAttached ? "Remove the attached response first." : ""}>
                    <Box sx={{ alignSelf: "flex-start" }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={formikProps.values.responseType === "html"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (formikProps.values.responseType === "none") {
                                formikProps.setFieldValue("dateReceived", null);
                              }
                              formikProps.setFieldValue("responseType", "html");

                              unsavedChanges();
                            }}
                          />
                        }
                        label="Enter the firm response"
                        disabled={originalResponseDocumentAttached}
                      />
                    </Box>
                  </Tooltip>

                  {formikProps.values.responseType === "html" && (
                    <Stack spacing={2}>
                      <Box sx={{ minHeight: "16em" }}>
                        <RichTextEditor
                          fullHeight
                          html={formikProps.values.responseHtml}
                          passContentRetriever={(getContentAsHtml) => {
                            responseHtmlContentRetriever.current = { getContentAsHtml };
                          }}
                          reportUnsavedChanges
                          redaction
                          onRedact={() => formikProps.setFieldValue("isRedacted", true)}
                        />
                      </Box>
                      {props.practiceReview.attachedDocuments.some((ad) => ad.type === DocType.FirmResponseRedacted) && (
                        <AcrobatLink
                          href={props.practiceReview.attachedDocuments.find((ad) => ad.type === DocType.FirmResponseRedacted)!.url}>
                          Redacted Response
                        </AcrobatLink>
                      )}
                    </Stack>
                  )}
                </Box>

                <div>
                  <Tooltip title={hasHtmlResponse ? "Remove the entered response first." : ""}>
                    <span>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={formikProps.values.responseType === "attached"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (formikProps.values.responseType === "none") {
                                formikProps.setFieldValue("dateReceived", null);
                              }
                              formikProps.setFieldValue("responseType", "attached");
                              unsavedChanges();
                            }}
                          />
                        }
                        label="Attach the firm response document"
                        disabled={hasHtmlResponse}
                      />
                    </span>
                  </Tooltip>

                  {formikProps.values.responseType === "attached" && (
                    <>
                      <Stack direction="row" spacing={5}>
                        <ResponseDocumentLinkAndActions
                          name="Original Response"
                          documentType={DocType.FirmResponseOriginal}
                          hasEnteredResponse={hasHtmlResponse}
                        />
                        <ResponseDocumentLinkAndActions
                          name="Redacted Response"
                          documentType={DocType.FirmResponseRedacted}
                          hasEnteredResponse={hasHtmlResponse}
                          includeRedactButton
                        />
                      </Stack>

                      {removingDocument !== null && (
                        <ConfirmationDialog
                          open={true}
                          body={<DialogContentText>{`Do you want to remove the ${removingDocument?.typeFriendlyName}?`}</DialogContentText>}
                          title="Remove file?"
                          cancel={() => setRemovingDocument(null)}
                          confirm={() => removeAttachedDocument(removingDocument)}
                          loading={removeAttachedDocumentMutation.loading}
                        />
                      )}
                    </>
                  )}
                </div>

                <div>
                  <Tooltip title={hasResponse ? "Remove the previous response first." : ""}>
                    <span>
                      <FormControlLabel
                        disabled={hasResponse}
                        control={
                          <Radio
                            checked={formikProps.values.responseType === "none"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              formikProps.setFieldValue("responseType", "none");
                              formikProps.setFieldValue("noFirmResponse", e.target.value !== "true");
                              unsavedChanges();
                            }}
                          />
                        }
                        label="No firm response is being provided"
                      />
                    </span>
                  </Tooltip>
                </div>
              </Stack>

              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton color="primary" variant="outlined" loading={saveMutation.loading} onClick={() => formikProps.submitForm()}>
                  Save
                </LoadingButton>
              </Stack>
            </>
          );
        }}
      </Formik>
    </Stack>
  );
};
