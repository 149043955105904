import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRowIdGetter, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import React, { useState } from "react";
import { DataGridWithHeader } from "../common/DataGridWithHeader";
import { makeStyles } from "../makeStyles";

export interface RenderDialogProps {
  onClose: () => void;
}

const useStyles = makeStyles()((theme) => ({
  tableActions: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  }
}));

export const DeleteButton = (props: { disabledReason?: string; onClick: () => void; className?: string }) => (
  <Tooltip title={props.disabledReason ?? ""} className={props.className} placement="left">
    <span>
      <IconButton size="small" color="error" disabled={Boolean(props.disabledReason)} onClick={props.onClick}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </IconButton>
    </span>
  </Tooltip>
);

export const EditButton = (props: { disabledReason?: string; onClick: () => void; className?: string }) => (
  <Tooltip title={props.disabledReason ?? ""} className={props.className} placement="left">
    <span>
      <IconButton color="primary" size="small" disabled={props.disabledReason !== undefined} onClick={props.onClick}>
        <FontAwesomeIcon icon={faEdit} />
      </IconButton>
    </span>
  </Tooltip>
);

export interface Props<T> {
  columnDefinitions: GridColDef[];
  sortModel?: GridSortModel;
  rows: T[];
  title?: string;
  /**
   * When setting this option it is recommened to set `storageKey` to a unique string.
   * `storageKey` is used as a key to persist the collapsed/expanded state for this
   * table in local storage
   */
  collapsible?: boolean;
  storageKey?: string;
  noDataMessage?: string;
  renderAddDialog?: (props: RenderDialogProps) => React.ReactNode;
  renderEditDialog?: (id: any, props: RenderDialogProps) => React.ReactNode;
  renderDeleteDialog?: (id: any, props: RenderDialogProps) => React.ReactNode;
  headerHeight?: number;
  headerActions?: React.ReactNode;
  loading?: boolean;
  pageSize?: number;
  onSortModelChange?: (newSortModel: GridSortModel) => void;
  onPageChange?: (page: number) => void;
  showPagination?: boolean;
  currentPage?: number;
  disableAddReason?: string;
  disableEditReason?: (params: T) => string | undefined;
  disableDeleteReason?: (params: T) => string | undefined;
  removeDisabledActions?: boolean;
  displayWithoutContainer?: boolean;
  disableSelectionOnClick?: boolean;
  getRowId?: GridRowIdGetter;
  addButtonText?: string;
  extraRowActions?: (row: any) => React.ReactElement;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactElement | null | undefined;
  getDetailPanelHeight?: (params: GridRowParams) => number;
  initialState?: GridInitialStatePro;
  maxHeight?: number;
}

function CrudTable<T>(props: Props<T>) {
  const { classes } = useStyles();
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const [editingId, setEditingId] = useState<any | null>(null);
  const [deletingId, setDeletingId] = useState<any | null>(null);

  const addButtonText = props.addButtonText ?? "Add New";

  const onAddButtonClicked = () => {
    setAddDialogOpen(true);
  };

  const onAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const onEditDialogClose = () => {
    setEditingId(null);
  };

  const onDeleteDialogClose = () => {
    setDeletingId(null);
  };

  const shouldRenderAddDialog = () => addDialogOpen && props.renderAddDialog;
  const shouldRenderEditDialog = () => editingId !== null && props.renderEditDialog;
  const shouldRenderDeleteDialog = () => deletingId !== null && props.renderDeleteDialog;

  const columns: GridColDef[] = [...props.columnDefinitions];

  if (props.renderEditDialog || props.renderDeleteDialog || props.extraRowActions) {
    columns.push({
      field: "Actions",
      renderCell: (params) => {
        const editDisabledReason = props.disableEditReason?.(params.row as T);
        const deleteDisabledReason = props.disableDeleteReason?.(params.row as T);
        const editDisabled = editDisabledReason !== undefined;
        const deleteDisabled = deleteDisabledReason !== undefined;

        return (
          <>
            {props.extraRowActions && props.extraRowActions(params.row)}
            {props.renderEditDialog && !(editDisabled && props.removeDisabledActions) && (
              <EditButton
                disabledReason={editDisabledReason}
                onClick={() => setEditingId(params.id as number)}
                className={classes.tableActions}
              />
            )}
            {props.renderDeleteDialog && !(deleteDisabled && props.removeDisabledActions) && (
              <DeleteButton
                disabledReason={deleteDisabledReason}
                onClick={() => setDeletingId(params.id)}
                className={classes.tableActions}
              />
            )}
          </>
        );
      },
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: props.extraRowActions ? 150 : 100
    });
  }

  const noDataMessage = props.noDataMessage ?? "No items.";

  return (
    <>
      <DataGridWithHeader
        title={props.title}
        loading={props.loading}
        pageSize={props.pageSize}
        headerHeight={props.headerHeight}
        headerActions={
          props.headerActions || props.renderAddDialog ? (
            <>
              {props.headerActions}
              {props.renderAddDialog && (
                <Tooltip title={props.disableAddReason ?? ""}>
                  <Button disabled={props.disableAddReason !== undefined} variant="outlined" color="primary" onClick={onAddButtonClicked}>
                    {addButtonText}
                  </Button>
                </Tooltip>
              )}
            </>
          ) : undefined
        }
        storageKey={props.storageKey}
        columns={columns}
        rows={props.rows}
        noDataMessage={noDataMessage}
        sortModel={props.sortModel}
        onSortModelChange={props.onSortModelChange}
        onPageChange={props.onPageChange}
        showPagination={props.showPagination}
        currentPage={props.currentPage}
        collapsible={props.collapsible}
        displayWithoutContainer={props.displayWithoutContainer}
        disableSelectionOnClick={props.disableSelectionOnClick}
        getRowId={props.getRowId}
        getDetailPanelContent={props.getDetailPanelContent}
        getDetailPanelHeight={props.getDetailPanelHeight}
        initialState={props.initialState}
        maxHeight={props.maxHeight}
      />
      {shouldRenderAddDialog() &&
        props.renderAddDialog?.({
          onClose: onAddDialogClose
        })}
      {shouldRenderEditDialog() &&
        props.renderEditDialog?.(editingId!, {
          onClose: onEditDialogClose
        })}
      {shouldRenderDeleteDialog() &&
        props.renderDeleteDialog?.(deletingId!, {
          onClose: onDeleteDialogClose
        })}
    </>
  );
}

export default CrudTable;
