import { gql } from "@apollo/client";

export const FetchCommitteeMeetingsQuery = gql`
  query FetchCommitteeMeetings($excludeFuture: Boolean, $pastPeriod: Int) {
    committeeMeetings(excludeFuture: $excludeFuture, pastPeriod: $pastPeriod) {
      id
      meetingDate
      location
      isPprp
      reviewQuota
      isOpen
      hasBeenClosed
      status

      assignedReviews {
        id
        reviewType
        mostAuthoritativeDecision {
          complianceZone {
            id
            zoneCode
          }
        }
      }

      sharePointUrl
    }
  }
`;

const BinderJobDetailsFragment = gql`
  fragment BinderJobDetails on QueueJob {
    id
    committeeMeetingId
    isComplete
    isDismissed
    dateCreated
    dateCompleted
    queueJobTypeCode
    committeeMeeting {
      meetingDate
      location
      sharePointUrl
    }
    progress {
      id
      stepsCompleted
      totalSteps
      stepsNotStarted
      stepsRunning
      stepsWithErrors
      allStepsComplete
      progressMessage
      resultData
      errorMessages
      lastUpdated
    }
  }
`;

export const FetchBinderJobsQuery = gql`
  query FetchBinderJobs {
    binderJobs {
      ...BinderJobDetails
    }
  }
  ${BinderJobDetailsFragment}
`;

export const FetchBinderJobByMeetingIdQuery = gql`
  query FetchBinderJobByMeetingId($meetingId: Int!) {
    binderJobByMeetingId(meetingId: $meetingId) {
      ...BinderJobDetails
    }
  }
  ${BinderJobDetailsFragment}
`;

export const DismissBinderJobMutation = gql`
  mutation DismissBinderJob($queueJobId: Int!) {
    queueJob {
      dismiss(queueJobId: $queueJobId) {
        id
      }
    }
  }
`;

export const RequeueBinderJobMutation = gql`
  mutation RequeueBinderJob($queueJobId: Int!) {
    queueJob {
      requeue(queueJobId: $queueJobId) {
        id
        progress {
          id
          stepsCompleted
          totalSteps
          stepsNotStarted
          stepsRunning
          stepsWithErrors
          allStepsComplete
          progressMessage
          resultData
          errorMessages
          lastUpdated
        }
      }
    }
  }
`;

export const FetchCommitteeMeetingByIdQuery = gql`
  query FetchCommitteeMeetingById($meetingId: Int!) {
    committeeMeetingById(id: $meetingId) {
      id
      meetingDate
      location
      name
      isPprp
      reviewQuota
      isOpen
      hasBeenClosed
      status

      recordingSecretary
      observer1
      observer2
      observer3
      observer4
      subcommitteeDate
      subcommitteeMeetings {
        id
        time
        members {
          id
          name
        }
      }

      isOpen
      hasBeenClosed
      binderGenerated

      sharePointUrl

      assembledBinders {
        fileName
      }
    }
  }
`;

export const FetchCommitteeMeetingPrsQuery = gql`
  query FetchCommitteeMeetingPrs($meetingId: Int!, $meetingDate: Date!) {
    committeeMeetingById(id: $meetingId) {
      id
      assignedReviews {
        id
        isPprpReview
        committeeTab
        prNumber
        firm {
          id
          name
          isCpab
        }
        startDate
        committeeMeetingIsConfirmed
        hasRedactedFirmResponse
        reviewType
        mostAuthoritativeDecision {
          isComply
          complianceZone {
            id
            zoneCode
          }
          motionStandard {
            id
            motionCode
            sortOrder
          }
        }
        tabledFromPrevious(meetingDate: $meetingDate) {
          committeeMeetingId
          practiceReviewId
          reason
        }
      }
    }
  }
`;

export const AvailableMeetingsQuery = gql`
  query GetAvailableCommitteeMeetings {
    availableMeetings {
      id
      meetingDate
      name
      isPprp
      reviewQuota
      assignedReviews {
        id
      }
    }
  }
`;

export const ChangePrCommitteeMeetingMutation = gql`
  mutation ChangePrCommitteeMeeting($practiceReviewIds: [Int!]!, $newCommitteeMeetingId: Int!, $notifyFirm: Boolean!) {
    practiceReview {
      changeMeeting(practiceReviewIds: $practiceReviewIds, newCommitteeMeetingId: $newCommitteeMeetingId, notifyFirm: $notifyFirm) {
        id
        committeeMeeting {
          id
          name
          isOpen
        }
      }
    }
  }
`;

export const AddCommitteeMeetingMutation = gql`
  mutation AddCommitteeMeeting($meeting: CommitteeMeetingInput!) {
    committeeMeeting {
      add(meeting: $meeting) {
        id
        meetingDate
        location
        name
        isPprp
        subcommitteeDate
      }
    }
  }
`;

export const SaveCommitteeMeetingMutation = gql`
  mutation EditCommitteeMeeting($meeting: CommitteeMeetingInput!) {
    committeeMeeting {
      edit(meeting: $meeting) {
        id
        meetingDate
        location
        name
        reviewQuota
        isOpen
        hasBeenClosed
        status

        recordingSecretary
        observer1
        observer2
        observer3
        observer4

        subcommitteeDate
        subcommitteeMeetings {
          id
          time
          members {
            id
            name
          }
        }
      }
    }
  }
`;

export const DeleteCommitteeMeetingMutation = gql`
  mutation DeleteCommitteeMeeting($meetingId: Int!) {
    committeeMeeting {
      delete(meetingId: $meetingId) {
        id
      }
    }
  }
`;

export const ToggleCommitteeMeetingClosedMutation = gql`
  mutation ToggleCommitteeMeetingClosed($meetingId: Int!) {
    committeeMeeting {
      toggleClosed(meetingId: $meetingId) {
        id
        isOpen
        hasBeenClosed
        status
      }
    }
  }
`;

export const GenerateBinderMutation = gql`
  mutation GenerateBinder($meetingId: Int!) {
    committeeMeeting {
      generateBinder(meetingId: $meetingId) {
        id
      }
    }
  }
`;

export const AssembleBinderMutation = gql`
  mutation AssembleBinder($meetingId: Int!) {
    committeeMeeting {
      assembleBinder(meetingId: $meetingId) {
        id
      }
    }
  }
`;

export const GenerateCommitteeMeetingMinutesMutation = gql`
  mutation GenerateCommitteeMeetingMinutes($meetingId: Int!, $attendingMemberIds: [Int!]!, $regretsMemberIds: [Int!]!) {
    committeeMeeting {
      generateMinutes(meetingId: $meetingId, attendingMemberIds: $attendingMemberIds, regretsMemberIds: $regretsMemberIds) {
        id
        minutesGenerated
        minutesUrl
      }
    }
  }
`;

export const ReleaseFinalReportsForDirectorReviewMutation = gql`
  mutation ReleaseFinalReportsForDirectorReview($meetingId: Int!) {
    committeeMeeting {
      releaseFinalReportsForDirectorReview(meetingId: $meetingId)
    }
  }
`;

export const FetchUnassignedPrsQuery = gql`
  query FetchUnassignedPrs {
    unassignedPrs {
      id
      prNumber
      isPprpReview
      firm {
        id
        name
        isCpab
      }
      startDate
      committeeMeetingIsConfirmed
      hasRedactedFirmResponse
      reviewType
      mostAuthoritativeDecision {
        isComply
        complianceZone {
          id
          zoneCode
        }
        motionStandard {
          id
          motionCode
          sortOrder
        }
      }
    }
  }
`;

const PrsNeedingCommitteeDecisionsEnteredFragment = gql`
  fragment PrsNeedingCommitteeDecisionsEnteredFields on PracticeReview {
    id
    committeeTab
    prNumber
    isPprpReview
    hasDecisionLetter
    practiceReviewReportUrl
    isFinalReportReviewedByCoordinator
    isFinalReportReviewedByDirector
    pdDeclarationFormUrl
    pdProofOfAttendanceFormsUrl
    finalReportPackageUrl
    sharePointUrl
    status {
      id
      baseStatusCode
      statusName
    }
    firm {
      id
      name
      entityNumber
    }
    mostAuthoritativeDecision {
      decisionType {
        id
        typeCode
      }
      isComply
      followUpDate
      assignedPdCourses {
        id
        isDirected
      }
      directedPdDueDate
      motionStandard {
        id
        motionCode
        shortDescription
      }
      motionPprp {
        id
        motionCode
        shortDescription
      }
      motionPd {
        id
        motionCode
        shortDescription
      }
      motionCpab {
        id
        motionCode
        shortDescription
      }
      motionCic {
        id
        motionCode
        shortDescription
      }
    }
  }
`;

export const GetMeetingsForReviewQuery = gql`
  query GetMeetingsForReview($forCoordinator: Boolean!, $pprp: Boolean!) {
    committeeMeetingsForReview(forCoordinator: $forCoordinator, pprp: $pprp) {
      id
      name
      meetingDate
      decisionLettersGenerated
      minutesGenerated
      minutesUrl
      isPprp
    }
  }
`;

export const FetchCommitteeMeetingToEnterDecisionsQuery = gql`
  query FetchCommitteeMeetingToEnterDecisions($meetingId: Int!, $meetingDate: Date!) {
    committeeMeetingById(id: $meetingId) {
      id
      assignedReviews {
        ...PrsNeedingCommitteeDecisionsEnteredFields
        decisionLetterUrl
        tabledFromPrevious(meetingDate: $meetingDate) {
          committeeMeetingId
          practiceReviewId
          reason
        }
      }
      tabledReviews {
        practiceReviewId
        committeeMeetingId
        practiceReview {
          ...PrsNeedingCommitteeDecisionsEnteredFields
          decisionLetterUrl(tabled: true)
          tabledToNext(meetingId: $meetingId) {
            committeeMeetingId
            practiceReviewId
            tab
            reason
            isTabledLetterReviewedByCoordinator
            isTabledLetterReviewedByDirector
            decisionTabledLetterHasBeenSent
          }
        }
      }
    }
  }
  ${PrsNeedingCommitteeDecisionsEnteredFragment}
`;

export const ToggleFinalReportReviewedMutation = gql`
  mutation ToggleFinalReportReviewed($practiceReviewId: Int!, $role: CommitteeDecisionReviewRole!) {
    practiceReview {
      toggleFinalReportReviewed(practiceReviewId: $practiceReviewId, role: $role) {
        id
        isFinalReportReviewedByCoordinator
        isFinalReportReviewedByDirector
      }
    }
  }
`;

export const ToggleTabledLetterReviewedMutation = gql`
  mutation ToggleTabledLetterReviewed($committeeMeetingId: Int!, $practiceReviewId: Int!, $role: CommitteeDecisionReviewRole!) {
    committeeMeetingTabledReview {
      toggleTabledLetterReviewed(committeeMeetingId: $committeeMeetingId, practiceReviewId: $practiceReviewId, role: $role) {
        committeeMeetingId
        practiceReviewId
        isTabledLetterReviewedByCoordinator
        isTabledLetterReviewedByDirector
      }
    }
  }
`;

export const GenerateDecisionLettersMutation = gql`
  mutation GenerateDecisionLetters($meetingId: Int!) {
    committeeMeeting {
      generateDecisionLetters(meetingId: $meetingId) {
        id
        decisionLettersGenerated
        minutesGenerated
        assignedReviews {
          ...PrsNeedingCommitteeDecisionsEnteredFields
          decisionLetterUrl
          pdDeclarationFormUrl
          pdProofOfAttendanceFormsUrl
        }
        tabledReviews {
          practiceReviewId
          committeeMeetingId
          practiceReview {
            ...PrsNeedingCommitteeDecisionsEnteredFields
            decisionLetterUrl(tabled: true)
          }
        }
      }
    }
  }
  ${PrsNeedingCommitteeDecisionsEnteredFragment}
`;
