import { EnhancedMergeField } from "common/HtmlMergeFields/models";

export enum GeneratedDocTypeCode {
  ChecklistNotesReport = "ChecklistNotesReport",
  DeficiencyReport = "DeficiencyReport",
  DirectorPresentationFormat = "DirectorPresentationFormat",
  PracticeReviewReport = "PracticeReviewReport",
  PresentationFormat = "PresentationFormat",

  CommitteeMeetingAgenda = "CommitteeMeetingAgenda",
  CommitteeMeetingMinutes = "CommitteeMeetingMinutes",
  StandardMotionsSummary = "StandardMotionsSummary",
  StandardMotionsWithNamesSummary = "StandardMotionsWithNamesSummary",

  OfficeListingReport = "OfficeListingReport",
  OfficeListingReportWithFirm = "OfficeListingReportWithFirm",

  PDDeclarationForm = "PDDeclarationForm",
  PDProofOfAttendanceForm = "PDProofOfAttendanceForm",
  DecisionComplyLetter = "DecisionComplyLetter",
  DecisionNonComplyLetter = "DecisionNonComplyLetter",
  DecisionTabledLetter = "DecisionTabledLetter",
  DecisionPprpLetter = "DecisionPprpLetter",
  ExemptionLetter = "ExemptionLetter"
}

export interface GeneratedDocumentType {
  typeCode: GeneratedDocTypeCode;
  friendlyName: string;
  templateFileName?: string;
}

export interface ReflectedDocGenDataSetInfo {
  dataSetName: string;
  dataSetType: string;
  documentTypes: GeneratedDocumentType[];
  fieldList?: EnhancedMergeField[];
}
