import { createContext, ReactNode, useContext } from "react";
import useLocalStorage from "./useLocalStorage";

interface StoredCollapsibleState {
  [key: string]: boolean;
}

interface CollapsibleProviderState {
  states: StoredCollapsibleState;
  expandAll: () => void;
  collapseAll: () => void;
  setExpanded: (key: string, expanded: boolean) => void;
}

export const CollapsibleContext = createContext<CollapsibleProviderState>({
  states: {},
  expandAll: () => {},
  collapseAll: () => {},
  setExpanded: (key, expanded) => {}
});

export const CollapsibleProvider = (props: { screen: string; children: ReactNode }) => {
  const [states, setStates] = useLocalStorage<StoredCollapsibleState>(`CollapsibleStates:${props.screen}`, {});

  const expandAll = () => {
    let newStates = { ...states };
    Object.keys(newStates).forEach((k) => (newStates[k] = true));
    setStates(newStates);
  };

  const collapseAll = () => {
    let newStates = { ...states };
    Object.keys(newStates).forEach((k) => (newStates[k] = false));
    setStates(newStates);
  };

  const setExpanded = (key: string, expanded: boolean) => {
    let newStates = { ...states };
    newStates[key] = expanded;
    setStates(newStates);
  };

  return (
    <CollapsibleContext.Provider
      value={{
        states: states,
        expandAll,
        collapseAll,
        setExpanded
      }}>
      {props.children}
    </CollapsibleContext.Provider>
  );
};

export function useCollapsibleGrids() {
  return useContext(CollapsibleContext);
}
