import { FC } from "react";
import { CardProps, Typography, Stack, Link, Switch, FormControlLabel } from "@mui/material";
import { YesNoIcon } from "common/YesNoIcon";
import { LoadingButton } from "@mui/lab";
import { AttachedDocument, PracticeReview } from "practice-reviews";
import { getOpenableUrl } from "util/utilities";
import { useMutation } from "@apollo/client";
import { GenerateMonitoringCompleteLetterMutation, ToggleMonitoringCompleteLetterReleasedMutation } from "./queries";
import { useNotifications } from "notifications";
import { Monitoring } from "./models";
import { formatDate } from "util/formats";

export interface MonitoringCompleteNotificationCardProps extends CardProps {
  monitoringCompletedLetter?: AttachedDocument;
  monitoringId: number;
  isReleased: boolean;
  letterGeneratedDate: string | null;
}

export const MonitoringCompleteNotificationCard: FC<MonitoringCompleteNotificationCardProps> = ({ ...cardProps }) => {
  const { monitoringId, monitoringCompletedLetter, isReleased, letterGeneratedDate } = cardProps;
  const notifications = useNotifications();

  const [generateMutate, generateMutation] = useMutation<
    {
      monitoring: {
        generateLetter: Partial<PracticeReview>;
      };
    },
    {
      monitoringId: number;
    }
  >(GenerateMonitoringCompleteLetterMutation);
  const [toggleReleaseMutate, releaseMutation] = useMutation<
    {
      monitoring: {
        toggleLetterReleased: Partial<Monitoring>;
      };
    },
    {
      monitoringId: number;
    }
  >(ToggleMonitoringCompleteLetterReleasedMutation);

  const generateLetter = async () => {
    const result = await generateMutate({ variables: { monitoringId } });

    if (result.errors) {
      notifications.error(result.errors);
    } else {
      notifications.success("Letter generated.");
    }
  };

  const toggleLetterReleased = async () => {
    const result = await toggleReleaseMutate({ variables: { monitoringId } });

    if (result.errors) {
      notifications.error(result.errors);
    } else {
      if (result.data?.monitoring.toggleLetterReleased.isReleased) {
        notifications.success("Letter released.");
      } else {
        notifications.success("Letter removed from portal.");
      }
    }
  };

  return (
    <div>
      <Typography variant="h4">Monitoring Complete Notification</Typography>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ pt: 2 }}>
        <YesNoIcon yes={!!monitoringCompletedLetter} />

        {monitoringCompletedLetter ? (
          <Link href={getOpenableUrl(monitoringCompletedLetter.url)} target="_blank">
            Monitoring Completed Letter
          </Link>
        ) : (
          <Typography variant="body1">Monitoring Completed Letter</Typography>
        )}
        <Stack direction="row" spacing={2} sx={{ flexGrow: 1, pl: 3 }} alignItems="center">
          <LoadingButton variant="outlined" color="primary" loading={generateMutation.loading} onClick={generateLetter}>
            {monitoringCompletedLetter ? "Regenerate" : "Generate"}
          </LoadingButton>
          {letterGeneratedDate && <Typography variant="body2">Generated {formatDate(letterGeneratedDate)}</Typography>}
          {monitoringCompletedLetter && (
            <FormControlLabel
              control={<Switch checked={isReleased} onChange={toggleLetterReleased} disabled={releaseMutation.loading} />}
              label="Release to portal"
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default MonitoringCompleteNotificationCard;
