import React, { useRef } from "react";
import { makeStyles } from "../makeStyles";
import { PracticeReview } from "practice-reviews";
import { Box, Card, CardContent, Grid, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { standardDateFormat } from "../util/formats";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "notifications";
import { DateTime } from "luxon";
import { staticDataStyles, tableStyles } from "../styles/common";
import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import RichTextEditor from "../common/RichTextEditor";
import FixedPlacesNumberFormat from "../util/FixedPlacesNumberFormat";
import { getHtmlForField } from "../util/utilities";
import { PprpProgramDetails, SavePprpProgramDetailsMutation } from ".";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...tableStyles(theme),
  inputCell: {
    "& input": {
      fontSize: "0.875rem"
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

interface FormValues {
  technicalCompetencyApprovedDepth: string | null;
  technicalCompetencyApprovedCore: string | null;
  approvalLimitComments: string | null;
  firmProfileCurrentApprovalLimit: number | null;
  firmProfileCurrentStudentsInProgram: number | null;
  firmProfileMaxStudentSupportBySch: number | null;
}

export const ProgramDetailsTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved, setSaveFunction } = useUnsavedChanges();

  const program = props.practiceReview.pprpProgram!;
  const programDetails = props.practiceReview.pprpProgramDetails;

  const approvalLimitCommentsContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const [saveMutate, saveMutation] = useMutation<
    { practiceReview: { savePprpProgramDetails: PracticeReview } },
    { practiceReviewId: number; programDetails: PprpProgramDetails }
  >(SavePprpProgramDetailsMutation);

  async function save(values: FormValues) {
    const programDetailsInput: PprpProgramDetails = {
      technicalCompetencyApprovedDepth: values.technicalCompetencyApprovedDepth,
      technicalCompetencyApprovedCore: values.technicalCompetencyApprovedCore,
      approvalLimitComments: getHtmlForField(approvalLimitCommentsContentRetriever),
      firmProfileCurrentApprovalLimit: values.firmProfileCurrentApprovalLimit,
      firmProfileCurrentStudentsInProgram: values.firmProfileCurrentStudentsInProgram,
      firmProfileMaxStudentSupportBySch: values.firmProfileMaxStudentSupportBySch
    };

    const response = await saveMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        programDetails: programDetailsInput
      }
    });

    const savedPr = response.data?.practiceReview.savePprpProgramDetails;
    if (savedPr?.id) {
      changesSaved();
      notifications.success("Program details saved.");

      return true;
    }

    return false;
  }

  const primaryProgramLeader = program.members.filter((m) => m.isPrimaryProgramLeader)?.[0];
  const secondaryProgramLeader = program.members.filter((m) => m.isSecondaryProgramLeader)?.[0];
  const programManager = program.members.filter((m) => m.isProgramManager)?.[0];
  const signingAuthorities = program.members.filter(
    (m) => !m.isPrimaryProgramLeader && !m.isSecondaryProgramLeader && !m.isProgramManager && m.hasSigningAuthority
  );

  const initialFormValues: FormValues = {
    technicalCompetencyApprovedDepth: programDetails?.technicalCompetencyApprovedDepth ?? null,
    technicalCompetencyApprovedCore: programDetails?.technicalCompetencyApprovedCore ?? null,
    approvalLimitComments: programDetails?.approvalLimitComments ?? null,
    firmProfileCurrentApprovalLimit: programDetails?.firmProfileCurrentApprovalLimit ?? null,
    firmProfileCurrentStudentsInProgram: programDetails?.firmProfileCurrentStudentsInProgram ?? null,
    firmProfileMaxStudentSupportBySch: programDetails?.firmProfileMaxStudentSupportBySch ?? null
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={save}>
      {(formikProps) => {
        setSaveFunction(() => save(formikProps.values));

        function getFirmProfileInputCell(fieldName: string) {
          return (
            <TableCell align="right" className={classes.inputCell} sx={{ width: "8em" }}>
              <FormikField
                component={FmuiTextField}
                name={fieldName}
                margin="none"
                InputProps={{
                  inputComponent: FixedPlacesNumberFormat,
                  inputProps: { places: 0 },
                  className: classes.number,
                  placeholder: "0"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue(fieldName, getIntValueFromString(e.target.value));
                  unsavedChanges();
                }}
              />
            </TableCell>
          );
        }

        function getIntValueFromString(value: string) {
          const digitsOnly = value.replace(/\D/, "");
          const parsedIntValue = parseInt(digitsOnly);
          return isNaN(parsedIntValue) ? null : parsedIntValue;
        }

        return (
          <FormikForm>
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} lg={8}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h3" gutterBottom>
                      PPRP Background
                    </Typography>

                    <Grid container rowSpacing={5}>
                      <Grid item xs={12} container columnSpacing={3}>
                        <Grid item xs={6}>
                          <Stack spacing={2}>
                            <StackedStaticDataDisplay
                              label="Approval Date"
                              value={program.approvalDate ? DateTime.fromISO(program.approvalDate).toFormat(standardDateFormat) : "--"}
                            />

                            <div>
                              <Typography variant="body1" className={classes.label}>
                                Program Address
                              </Typography>
                              <Typography variant="body1">
                                {program.address1}
                                <br />
                                {program.address2 && (
                                  <>
                                    {program.address2}
                                    <br />
                                  </>
                                )}
                                {program.city}
                                {program.province && `, ${program.province}`}
                                <br />
                                {program.country && `${program.country} `}
                                {program.postalCode}
                              </Typography>
                            </div>

                            <StackedStaticDataDisplay
                              label="Firm/PPR"
                              value={`${props.practiceReview.firm.entityNumber.toString()} ${props.practiceReview.firm.name}`}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={2}>
                            <StackedStaticDataDisplay label="Program Leader - Primary" value={primaryProgramLeader?.name ?? "--"} />
                            <StackedStaticDataDisplay label="Program Leader - Secondary" value={secondaryProgramLeader?.name ?? "--"} />
                            <StackedStaticDataDisplay label="Program Manager" value={programManager?.name ?? "--"} />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container columnSpacing={3}>
                        <Grid item xs={6}>
                          <StackedStaticDataDisplay label="Restrictions" value={program.restrictions ?? "--"} />
                        </Grid>
                        <Grid item xs={6}>
                          <StackedStaticDataDisplay label="Conditions" value={program.conditions ?? "--"} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container columnSpacing={3}>
                        <Grid item xs={6}>
                          <Stack>
                            <Typography variant="h4" gutterBottom>
                              Technical Competency
                            </Typography>
                            <Box sx={{ maxWidth: "30em" }}>
                              <FormikField
                                component={FmuiTextField}
                                name="technicalCompetencyApprovedDepth"
                                label="Approved Depth"
                                fullWidth
                              />
                              <FormikField
                                component={FmuiTextField}
                                name="technicalCompetencyApprovedCore"
                                label="Approved Core"
                                fullWidth
                              />
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h4">Program Profile ({props.practiceReview.reviewYear})</Typography>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" align="right">
                                  Current Approval Limit
                                </TableCell>
                                {getFirmProfileInputCell("firmProfileCurrentApprovalLimit")}
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" align="right">
                                  Current Students in Program
                                </TableCell>
                                {getFirmProfileInputCell("firmProfileCurrentStudentsInProgram")}
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" align="right">
                                  Max Students Supported by SCH
                                </TableCell>
                                {getFirmProfileInputCell("firmProfileMaxStudentSupportBySch")}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <RichTextEditor
                          label="Approval Limit Comments"
                          minHeight="8em"
                          html={formikProps.values.approvalLimitComments}
                          passContentRetriever={(getContentAsHtml) => {
                            approvalLimitCommentsContentRetriever.current = { getContentAsHtml };
                          }}
                          reportUnsavedChanges
                          hideToolbar
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Stack alignItems="flex-end">
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={async () => {
                              await formikProps.submitForm();
                            }}
                            loading={saveMutation.loading}>
                            Save
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
