import { useQuery } from "@apollo/client";
import { FormControlLabel, IconButton, Paper, Stack, Switch, Typography } from "@mui/material";
import { ScreenHeader } from "common/ScreenHeader";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { FetchPprpProgramsQuery, PprpProgram, PprpProgramTask } from ".";
import { formatDate } from "../util/formats";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PprpProgramDialog from "./PprpProgramDialog";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const PprpProgramsScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();

  const [showInactive, setShowInactive] = useState(false);
  const [editingProgramId, setEditingProgramId] = useState<number | null>(null);

  const programQuery = useQuery<{ pprpPrograms: PprpProgram[] }>(FetchPprpProgramsQuery, { variables: { includeInactive: showInactive } });
  const programs = programQuery.data?.pprpPrograms ?? [];

  return (
    <>
      <Helmet>
        <title>Pre-approved Programs - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Pre-approved Programs" />

      <Paper sx={{ p: 2 }}>
        <Stack spacing={3}>
          <DataGridWithHeader
            maxHeight={800}
            loading={programQuery.loading}
            disableSelectionOnClick
            enableColumnMenu
            headerActions={
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3} sx={{ width: "100%" }}>
                <Typography variant="subtitle1" color="text.secondary">
                  {programs.length}
                  <Typography display={"inline"} sx={{ fontSize: "0.875rem" }}>
                    &nbsp;Programs
                  </Typography>
                </Typography>

                <FormControlLabel
                  control={<Switch checked={showInactive} onClick={() => setShowInactive(!showInactive)} />}
                  label="Inactive"
                />
              </Stack>
            }
            columns={[
              {
                field: "firmEntityNo",
                headerName: "Firm Entity No.",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgram }) => params.row.firm.entityNumber,
                width: 100
              },
              {
                field: "firmName",
                headerName: "Firm Name",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgram }) => params.row.firm.name,
                flex: 2
              },
              {
                field: "programEntityNumber",
                headerName: "Program No.",
                headerClassName: classes.wrapHeader,
                width: 80
              },
              {
                field: "name",
                headerName: "Program Name",
                flex: 1
              },
              {
                field: "programPath",
                headerName: "Program Path",
                width: 150
              },
              {
                field: "approvalDate",
                headerName: "Approval Date",
                valueFormatter: (params: { value: string }) => formatDate(params.value),
                width: 120
              },
              {
                field: "firmType",
                headerName: "Firm Type",
                valueGetter: (params: { row: PprpProgram }) => params.row.firm.entityType?.typeShortName ?? "Unknown",
                width: 100
              },
              {
                field: "firmStatus",
                headerName: "Firm Status",
                valueGetter: (params: { row: PprpProgram }) =>
                  `${params.row.firm.firmStatus ?? "--"}${params.row.firm.subStatus ? `, ${params.row.firm.subStatus}` : ""}`,
                filterable: false,
                width: 180
              },
              {
                field: "actions",
                headerName: "",
                renderCell: (params: { row: PprpProgramTask }) => (
                  <IconButton color="primary" size="small" title="Edit" onClick={() => setEditingProgramId(params.row.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                ),
                width: 40
              }
            ]}
            rows={programs}
            displayWithoutContainer
            noDataMessage="No programs."
          />
        </Stack>
      </Paper>

      {editingProgramId && <PprpProgramDialog programId={editingProgramId} handleClose={() => setEditingProgramId(null)} />}
    </>
  );
};

export default PprpProgramsScreen;
