import { useQuery } from "@apollo/client";
import { Button, FormControlLabel, IconButton, Paper, Stack, Switch } from "@mui/material";
import { ScreenHeader } from "common/ScreenHeader";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { FetchPprpProgramTasksQuery, PprpProgramTask } from ".";
import { formatDate } from "../util/formats";
import _ from "lodash";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditPprpProgramTaskDialog from "./EditPprpProgramTaskDialog";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const PprpProgramTasksScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();

  const [editingTaskId, setEditingTaskId] = useState<number | null>(null);
  const [newApplication, setNewApplication] = useState(false);

  const emptyFilters = { includeComplete: false };
  const [filters, setFilters] = useState<{
    includeComplete: boolean;
  }>(emptyFilters);

  const taskQuery = useQuery<{ pprpProgramTasks: PprpProgramTask[] }, { includeComplete: boolean }>(FetchPprpProgramTasksQuery, {
    variables: { includeComplete: filters.includeComplete }
  });
  const tasks = taskQuery.data?.pprpProgramTasks ?? [];

  return (
    <>
      <Helmet>
        <title>Pre-approved Program Tasks - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Pre-approved Program Tasks" />

      <Paper sx={{ p: 2 }}>
        <Stack spacing={3}>
          <DataGridWithHeader
            loading={taskQuery.loading}
            disableSelectionOnClick
            enableColumnMenu
            maxHeight={800}
            headerActions={
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" onClick={() => setNewApplication(true)}>
                  New Provider Application
                </Button>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.includeComplete}
                      onClick={() => setFilters({ ...filters, includeComplete: !filters.includeComplete })}
                    />
                  }
                  label="Show Complete Tasks"
                />
              </Stack>
            }
            columns={[
              {
                field: "id",
                headerName: "Task No.",
                headerClassName: classes.wrapHeader,
                width: 60
              },
              {
                field: "firmEntityNo",
                headerName: "Firm Entity No.",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgramTask }) =>
                  params.row.pprpProgram?.firm.entityNumber ?? params.row.firm?.entityNumber ?? "--",
                width: 100
              },
              {
                field: "firmName",
                headerName: "Firm Name",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgramTask }) =>
                  params.row.pprpProgram?.firm.name ??
                  params.row.firm?.name ??
                  params.row.applyForNewProviderFirmName ??
                  params.row.applyForNewProviderPprpName ??
                  "--",
                width: 200
              },
              {
                field: "programPath",
                headerName: "Program Path",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgramTask }) => params.row.pprpProgram?.programPath,
                width: 180
              },
              {
                field: "type",
                headerName: "Task Type",
                valueGetter: (params: { row: PprpProgramTask }) => params.row.type.typeName,
                width: 180
              },
              {
                field: "submissionDate",
                headerName: "Submission Date",
                valueFormatter: (params: { value: string }) => formatDate(params.value),
                width: 120
              },
              {
                field: "status",
                headerName: "Status",
                valueGetter: (params: { row: PprpProgramTask }) => _.startCase(params.row.status),
                width: 120
              },
              {
                field: "lastStatusChange",
                headerName: "Last Status Change",
                valueFormatter: (params: { value: string }) => formatDate(params.value),
                width: 140
              },
              {
                field: "currentIna",
                headerName: "Current INA",
                valueGetter: (params: { row: PprpProgramTask }) => {
                  const incompleteInas = params.row.inas.filter((ina) => !ina.isComplete);
                  const sortedIncompleteInas = _.orderBy(incompleteInas, (ina) => ina.type.id);
                  return sortedIncompleteInas[0]?.type.friendlyName ?? "--";
                },
                width: 200
              },
              {
                field: "programName",
                headerName: "Program",
                valueGetter: (params: { row: PprpProgramTask }) => params.row.pprpProgram?.name ?? "--",
                width: 150
              },
              {
                field: "programLeader",
                headerName: "Primary Program Leader",
                headerClassName: classes.wrapHeader,
                valueGetter: (params: { row: PprpProgramTask }) =>
                  params.row.pprpProgram?.members.filter((m) => m.isPrimaryProgramLeader)[0]?.name ?? "--",
                width: 150
              },
              {
                field: "registrarDecision",
                headerName: "Decision",
                valueGetter: (params: { row: PprpProgramTask }) =>
                  params.row.registrarDecision ? _.startCase(params.row.registrarDecision) : "--",
                width: 100
              },
              {
                field: "actions",
                headerName: "",
                renderCell: (params: { row: PprpProgramTask }) => (
                  <IconButton color="primary" size="small" title="Edit" onClick={() => setEditingTaskId(params.row.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                ),
                width: 40
              }
            ]}
            rows={tasks}
            displayWithoutContainer
            noDataMessage="No active tasks."
          />
        </Stack>
      </Paper>

      {editingTaskId && <EditPprpProgramTaskDialog taskId={editingTaskId} handleClose={() => setEditingTaskId(null)} />}
      {newApplication && <EditPprpProgramTaskDialog taskId={null} handleClose={() => setNewApplication(false)} />}
    </>
  );
};

export default PprpProgramTasksScreen;
