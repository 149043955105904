import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  MenuItem,
  Link,
  Avatar,
  Tooltip,
  Menu,
  IconButton,
  Autocomplete,
  TextField,
  Divider,
  Theme,
  Stack
} from "@mui/material";
import { lighten } from "@mui/material/styles";

import React, { useEffect, useState } from "react";
import { Link as RouterLink, Route, useHistory } from "react-router-dom";
import { AppRoute } from "Routes";
import { useMsal } from "@azure/msal-react";
import { useCurrentUser, User, Permissions, UsersWhoseInasMayBeViewedByOthersQuery } from "users";
import { useAppConfig } from "util/AppConfig";
import { ResetTestDataDialog } from "test-infra/ResetTestDataDialog";
import NavSubMenu from "./NavSubMenu";
import GoToPrField from "practice-reviews/GoToPrField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { makeStyles } from "../makeStyles";
import { useQuery } from "@apollo/client";
import { useInasUser } from "../InasUserProvider";
import _ from "lodash";
import { CustomReport, FetchCustomReportsQuery } from "../custom-reports";
import { versionIndicatorStyles } from "styles/common";

export function getMainBarHeight(theme: Theme) {
  return theme.spacing(12);
}

const useStyles = makeStyles()((theme) => {
  const mainBarHeight = getMainBarHeight(theme);
  return {
    appBar: {
      transition: "unset",
      boxShadow: "unset"
    },
    mainBar: {
      height: mainBarHeight,
      backgroundColor: theme.palette.common.white,
      borderWidth: "6px 0 0 0",
      borderStyle: "solid",
      borderImageSlice: "1",
      borderImageSource: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    },
    grow: {
      flexGrow: 1
    },
    logoBg: {
      padding: theme.spacing(0.5, 0.5, 0.5, 0),
      margin: theme.spacing(0, 1.5, 0, 0),
      height: mainBarHeight,
      backgroundColor: "transparent",
      "& img": {
        maxWidth: "100%",
        height: "100%"
      }
    },
    homeLink: {
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
      color: "inherit",
      textDecoration: "inherit"
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      marginRight: theme.spacing(5)
    },
    versionIndicator: {
      ...versionIndicatorStyles(theme).versionIndicator,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(5),
      marginBottom: "-15px"
    },
    tabs: {
      display: "flex",
      height: "100%"
    },
    tab: {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      color: theme.palette.primary.main,
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      cursor: "pointer",
      "&.selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
      },
      "&:hover:not(.selected)": {
        backgroundColor: lighten(theme.palette.primary.main, 0.9)
      }
    },
    tabPanel: {
      height: theme.spacing(7.5),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    navButton: {
      color: theme.palette.common.white,
      borderRadius: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontFamily: theme.typography.fontFamily,
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark
      }
    },
    mainButton: {
      // this is to style them like <Button> is but let them be <a> tags
      textTransform: "uppercase",
      lineHeight: 1.75,
      minWidth: "64px",
      whiteSpace: "normal",
      textAlign: "center",
      fontSize: "0.9375rem"
    },
    userInfo: {
      fontWeight: 500
    },
    goToPrField: {
      marginLeft: "auto",
      marginRight: theme.spacing(3)
    },
    initialsAvatar: {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main
    },
    viewOtherUsersInasSelector: {
      width: "20em",
      "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.common.white
      }
    },
    reportMenuButton: {
      textTransform: "unset"
    }
  };
});

enum Tabs {
  Main,
  AdminTasks,
  Reports
}

interface SiteNavItemProps {
  title: string;
  path?: AppRoute | string;
  isHref?: true;
  condition?: any;
  className?: string;
}

const SiteNavItem: React.FunctionComponent<SiteNavItemProps> = (props) => {
  const { classes } = useStyles();
  return (
    <MenuItem
      key={props.title}
      className={`${classes.navButton} ${props.className}`}
      component={props.isHref ? Link : RouterLink}
      {...{
        to: props.isHref ? undefined : props.path,
        href: props.isHref ? props.path : undefined,
        target: props.isHref ? "_blank" : undefined
      }}>
      {props.title}
    </MenuItem>
  );
};

interface SiteNavCategoryProps {
  title: string;
}
const SiteNavCategory: React.FunctionComponent<SiteNavCategoryProps> = (props) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const buttonRef = React.useRef<any>();
  return (
    <>
      <Button className={classes.navButton} size="large" onClick={() => setOpen(true)} ref={buttonRef}>
        {props.title}
        <Box sx={{ ml: 1 }}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Box>
      </Button>
      {props.children && (
        <NavSubMenu anchor={buttonRef} open={open} onClose={() => setOpen(false)}>
          {props.children}
        </NavSubMenu>
      )}
    </>
  );
};

const SiteAppBar: React.FunctionComponent = () => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { instance: msalInstance } = useMsal();
  const { user, userLoading, userHasPermission } = useCurrentUser();
  const { isDevTestResetEnabled, sharepointDocumentTemplateLibraryUrl, version } = useAppConfig();

  const [resetDataDialogIsVisible, setResetDataDialogIsVisible] = useState(false);

  const [tabIndex, setTabIndex] = useState<Tabs>(Tabs.Main);
  const [route, setRoute] = useState(history.location.pathname);

  const [reportsMenuOpen, setReportsMenuOpen] = useState<boolean>(false);
  const reportsMenuButton = React.useRef<any>();

  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState<HTMLElement | null>(null);

  const { inasUser, setInasUser } = useInasUser();

  const userQuery = useQuery<{ usersWhoseInasMayBeViewedByOthers: User[] }>(UsersWhoseInasMayBeViewedByOthersQuery);
  const usersWhoseInasCanBeViewed = userQuery.data?.usersWhoseInasMayBeViewedByOthers ?? [];

  const customReportsQuery = useQuery<{ customReports: CustomReport[] }>(FetchCustomReportsQuery);
  const customReports = customReportsQuery.data?.customReports || [];

  useEffect(() => {
    const deregisterListener = history.listen(() => {
      setRoute(history.location.pathname);
    });

    return () => {
      deregisterListener();
    };
  }, [history]);

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar className={classes.mainBar}>
        <RouterLink to="/" className={classes.homeLink}>
          <Box className={classes.logoBg} title={route !== AppRoute.INAs ? "Return to My INAs" : undefined}>
            <img src="/CPA_AB_4c_pro_condensed.svg" alt="CPAA logo" />
          </Box>
        </RouterLink>
        <div style={{ textAlign: "right" }}>
          <Typography className={classes.title} variant="h3" noWrap>
            Practice Review System
          </Typography>
          <Typography className={classes.versionIndicator} variant="subtitle2">
            v{version}
          </Typography>
        </div>
        <div className={classes.tabs}>
          <div className={cx(classes.tab, { selected: tabIndex === Tabs.Main })} onClick={() => setTabIndex(Tabs.Main)}>
            Main
          </div>
          {(userHasPermission(Permissions.AdminTasks) || userHasPermission(Permissions.AdminTasksPprp)) && (
            <div className={cx(classes.tab, { selected: tabIndex === Tabs.AdminTasks })} onClick={() => setTabIndex(Tabs.AdminTasks)}>
              Administrative Tasks
            </div>
          )}
          {userHasPermission(Permissions.ReportsView) && (
            <Button className={cx(classes.tab, classes.reportMenuButton)} onClick={() => setReportsMenuOpen(true)} ref={reportsMenuButton}>
              Reports
              <Box sx={{ ml: 1 }}>
                <FontAwesomeIcon icon={faCaretDown} />
              </Box>
            </Button>
          )}
        </div>
        <div className={classes.grow} />
        <div className={classes.goToPrField}>
          <GoToPrField />
        </div>
        {!userLoading && (
          <div>
            <IconButton onClick={(e) => setUserMenuAnchorElement(e.currentTarget)} size="large">
              <Tooltip title={user.name}>
                <Avatar className={classes.initialsAvatar}>
                  {user.firstName && user.lastName ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}` : user.name.slice(0, 1)}
                </Avatar>
              </Tooltip>
            </IconButton>
            <Menu
              anchorEl={userMenuAnchorElement}
              keepMounted
              open={!!userMenuAnchorElement}
              onClose={() => setUserMenuAnchorElement(null)}>
              <MenuItem onClick={() => msalInstance.logoutRedirect()}>Sign out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      {tabIndex === Tabs.Main && (
        <Stack direction="row" spacing={1} alignItems="center" className={classes.tabPanel} flexWrap="nowrap">
          <SiteNavItem title="INAs" path={AppRoute.INAs} className={classes.mainButton} />
          {userHasPermission(Permissions.InaViewOthers) && (
            <Route exact path={AppRoute.INAs}>
              <Autocomplete
                className={classes.viewOtherUsersInasSelector}
                options={usersWhoseInasCanBeViewed}
                getOptionLabel={(user: User | null) => (user === null ? "" : user.name)}
                renderInput={(params) => <TextField {...params} placeholder="View Other User's INAs" margin="none" />}
                value={inasUser}
                onChange={(e, newUser) => {
                  setInasUser(newUser);
                }}
              />
            </Route>
          )}
          <SiteNavItem title="Advanced PR Search" path={AppRoute.AdvancedPrSearch} className={classes.mainButton} />
          <SiteNavItem title="My Activity Log" path={AppRoute.MyActivityLog} className={classes.mainButton} />
        </Stack>
      )}
      {tabIndex === Tabs.AdminTasks && (
        <Stack direction="row" spacing={1} alignItems="center" className={classes.tabPanel} flexWrap="nowrap">
          {userHasPermission(Permissions.AdminTasks) && (
            <>
              <SiteNavCategory title="Upcoming Notices">
                <SiteNavItem title="Generate Notices" path={AppRoute.GenerateUpcomingReviewNotices} />
                <SiteNavItem title="Approve Notices" path={AppRoute.ApproveUpcomingReviewNotices} />
              </SiteNavCategory>
              <SiteNavCategory title="Committee Meetings">
                <SiteNavItem title="Meetings" path={AppRoute.CommitteeMeetings} />
                <SiteNavItem title="Enter Decisions" path={AppRoute.EnterCommitteeDecisions} />
                <SiteNavItem title="Review Final Reports" path={AppRoute.ReviewCommitteeDecisions} />
                <SiteNavItem title="Members" path={AppRoute.CommitteeMembers} />
              </SiteNavCategory>
              <SiteNavCategory title="Exemptions">
                <SiteNavItem title="Active Exemptions" path={AppRoute.Exemptions} />
                <SiteNavItem title="Review Exemption Letters" path={AppRoute.ReviewExemptionLetters} />
                <SiteNavItem title="Approve Exemption Letters" path={AppRoute.ApproveExemptionLetters} />
              </SiteNavCategory>
              <Button className={classes.navButton} size="large" component={RouterLink} to={AppRoute.ImportPrs}>
                Import Reviews
              </Button>
            </>
          )}

          {userHasPermission(Permissions.AdminTasksPprp) && (
            <SiteNavCategory title="Pre-approved Programs">
              <SiteNavItem title="Programs" path={AppRoute.PprpPrograms} />
              <SiteNavItem title="Program Tasks" path={AppRoute.PprpProgramTasks} />
              <SiteNavItem title="Generate Notices" path={AppRoute.GenerateUpcomingPprpReviewNotices} />
              <SiteNavItem title="Approve Notices" path={AppRoute.ApproveUpcomingPprpReviewNotices} />
              <SiteNavItem title="Review Exemption Letters" path={AppRoute.ReviewPprpExemptionLetters} />
              <SiteNavItem title="Approve Exemption Letters" path={AppRoute.ApprovePprpExemptionLetters} />
              <SiteNavItem title="Enter Decisions" path={AppRoute.EnterPprpCommitteeDecisions} />
              <SiteNavItem title="Review Final Reports" path={AppRoute.ReviewPprpCommitteeDecisions} />
              <SiteNavItem title="Registration Committee Members" path={AppRoute.PprpCommitteeMembers} />
            </SiteNavCategory>
          )}

          <SiteNavCategory title="Templates">
            <SiteNavItem title="Additional Paragraphs for Decisions" path={AppRoute.DecisionAdditionalParagraphs} />
            <SiteNavItem title="Adjustment Question Templates" path={AppRoute.AdjustmentQuestionTemplates} />
            <SiteNavItem title="Document Template Sandbox" path={AppRoute.DocumentTemplateSandbox} />
            <SiteNavItem
              title="Document Template Storage"
              path={sharepointDocumentTemplateLibraryUrl}
              condition={sharepointDocumentTemplateLibraryUrl}
              isHref={true}
            />
            <SiteNavItem title="Email Templates" path={AppRoute.EmailTemplates} />
            <SiteNavItem title="Engagement Types" path={AppRoute.EngagementTypeTemplates} />
            <SiteNavItem title="Master Checklist" path={AppRoute.MasterChecklist} />
            <SiteNavItem title="Motions" path={AppRoute.Motions} />
            <SiteNavItem title="Sign-off Checklist Items" path={AppRoute.SignOffChecklistItems} />
          </SiteNavCategory>

          <SiteNavCategory title="System">
            <SiteNavItem title="App Role Assignments" path={AppRoute.AppRoles} />
            <SiteNavItem title="Custom Reports" path={AppRoute.CustomReports} />
            <SiteNavItem title="Non-working Days" path={AppRoute.StatHolidays} />
            <SiteNavItem title="PD Courses" path={AppRoute.PdCourses} />
            <SiteNavItem title="Retention Policies" path={AppRoute.RetentionPolicies} />
            <SiteNavItem title="System Estimated Time Rules" path={AppRoute.SystemEstimatedTimeRules} />
            <SiteNavItem title="Time Conversion Formulas" path={AppRoute.TimeConversionFormulas} />
            <SiteNavItem title="Timed Event Margins" path={AppRoute.TimedEventMargins} />
            <SiteNavItem title="Users" path={AppRoute.Users} />
            <SiteNavItem title="Workflow INAs" path={AppRoute.WorkflowINAs} />
            {isDevTestResetEnabled && (
              <>
                <Divider />
                <MenuItem
                  className={classes.navButton}
                  onClick={() => {
                    setResetDataDialogIsVisible(true);
                  }}>
                  Reset Test Data
                </MenuItem>
              </>
            )}
          </SiteNavCategory>
        </Stack>
      )}

      <ResetTestDataDialog isOpen={resetDataDialogIsVisible} setIsOpen={setResetDataDialogIsVisible} />
      <NavSubMenu anchor={reportsMenuButton} open={reportsMenuOpen} onClose={() => setReportsMenuOpen(false)}>
        {_.orderBy(customReports, (r) => r.reportName).map((r) => {
          const normalizedUrl =
            r.url.toLocaleLowerCase().startsWith("https://") || r.url.toLocaleLowerCase().startsWith("http://")
              ? r.url
              : `https://${r.url}`;
          return (
            <MenuItem key={r.id} className={classes.navButton} onClick={() => window.open(normalizedUrl)}>
              {r.reportName}
            </MenuItem>
          );
        })}
      </NavSubMenu>
    </AppBar>
  );
};

export default SiteAppBar;
