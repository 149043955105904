import React, { useRef } from "react";
import { PracticeReview } from "practice-reviews";
import { Grid, Button, MenuItem, Box, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@mui/material";
import { Field as FormikField, Form as FormikForm, Formik, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { EngagementType, Reviewer, ReviewedClient, ReviewedClientInput } from "../models";
import { actionStyles } from "styles/common";
import _ from "lodash";
import { useCurrentUser } from "users";
import * as Yup from "yup";
import { Validations } from "common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";
import StackedStaticDataDisplay from "../../common/StackedStaticDataDisplay";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { DateTime } from "luxon";
import { FormikFormattedNumericField } from "./FormikFormattedNumericField";
import RichTextEditor from "../../common/RichTextEditor";
import { getHtmlForField } from "../../util/utilities";

const useStyles = makeStyles()((theme) => ({
  ...actionStyles(theme),
  reviewedClientDetailsActions: {
    marginTop: theme.spacing(2)
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  secondSectionHeader: {
    marginTop: theme.spacing(2)
  },
  hoursFeesAmountField: {
    width: "7em",
    margin: 0
  },
  engagementTypeName: {
    "li &": {
      paddingLeft: theme.spacing(3)
    }
  },
  currentValueEngagementType: {
    justifyContent: "space-between"
  },
  currentValueEngagementTypeCaption: {
    fontSize: "0.7em",
    fontStyle: "italic",
    fontWeight: "bold",
    marginLeft: theme.spacing(4),
    lineHeight: 1
  },
  currentValueWarningOnForm: {
    color: `${theme.palette.cpaAccentYellow.main}`
  },
  oldEngagementType: {
    textDecoration: "line-through",
    fontStyle: "italic",
    marginLeft: theme.spacing(2)
  },
  newEngagementType: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  leadMarker: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  }
}));

interface Props {
  practiceReview: PracticeReview;
  reviewedClientBeingEdited: ReviewedClient | null;
  saveReviewedClient: (reviewedClientInput: ReviewedClientInput) => Promise<boolean>;
  reviewers: Reviewer[];
  canEdit: boolean;
  engagementTypes: EngagementType[];
  unsavedChanges: () => void;
  loadingAdd: boolean;
  loadingEdit: boolean;
  loadingDelete: boolean;
  userCanDeleteReviewedClientBeingEdited: boolean;
  delete: (file: ReviewedClient) => void;
  cancel: (() => void) | null;
  pprpEngagementTypeId: number;
}

interface FormValues {
  reviewedByUserId: number;
  name: string;
  idNo: string;
  mentorName: string;
  perEffectiveDate: DateTime | null;
  monthsExperience: number | "";
  mentorReviews: number | "";
  requiredNoOfReviews: number | "";
  rotation1: string | "";
  rotation1Duration: number | "";
  rotation2: string | "";
  rotation2Duration: number | "";
  rotation3: string | "";
  rotation3Duration: number | "";
  rotation4: string | "";
  rotation4Duration: number | "";
  reasonableAuditChargeableHours: boolean;
  reasonableReviewChargeableHours: boolean;
  reasonableOtherAssuranceChargeableHours: boolean;
  reasonableCompilationChargeableHours: boolean;
  reasonableTaxationChargeableHours: boolean;
  reasonableOtherChargeableHours: boolean;
  additionalComments: string | null;
}

export const CandidateForm: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();
  const { user, userIsLeadReviewer } = useCurrentUser();

  const additionalCommentsContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  async function saveCandidate(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const reviewedClientInput: ReviewedClientInput = {
      id: props.reviewedClientBeingEdited?.id,
      practiceReviewId: props.practiceReview.id,
      engagementTypeId: props.pprpEngagementTypeId,
      name: values.name,
      partnerName: values.mentorName,
      reviewedByUserId: values.reviewedByUserId,
      refNum: props.reviewedClientBeingEdited?.refNum ?? "",
      candidate: {
        idNo: values.idNo,
        perEffectiveDate: values.perEffectiveDate ? values.perEffectiveDate.toISODate() : null,
        monthsExperience: values.monthsExperience !== "" ? values.monthsExperience : null,
        mentorReviews: values.mentorReviews !== "" ? values.mentorReviews : null,
        requiredNoOfReviews: values.requiredNoOfReviews !== "" ? values.requiredNoOfReviews : null,
        rotation1: values.rotation1.trim() !== "" ? values.rotation1 : null,
        rotation1Duration: values.rotation1Duration !== "" ? values.rotation1Duration : null,
        rotation2: values.rotation2.trim() !== "" ? values.rotation2 : null,
        rotation2Duration: values.rotation2Duration !== "" ? values.rotation2Duration : null,
        rotation3: values.rotation3.trim() !== "" ? values.rotation3 : null,
        rotation3Duration: values.rotation3Duration !== "" ? values.rotation3Duration : null,
        rotation4: values.rotation4.trim() !== "" ? values.rotation4 : null,
        rotation4Duration: values.rotation4Duration !== "" ? values.rotation4Duration : null,
        reasonableAuditChargeableHours: values.reasonableAuditChargeableHours,
        reasonableReviewChargeableHours: values.reasonableReviewChargeableHours,
        reasonableOtherAssuranceChargeableHours: values.reasonableOtherAssuranceChargeableHours,
        reasonableCompilationChargeableHours: values.reasonableCompilationChargeableHours,
        reasonableTaxationChargeableHours: values.reasonableTaxationChargeableHours,
        reasonableOtherChargeableHours: values.reasonableOtherChargeableHours,
        additionalComments: getHtmlForField(additionalCommentsContentRetriever)
      },
      clientFile: null
    };

    const successful = await props.saveReviewedClient(reviewedClientInput);

    if (successful) {
      formikHelpers.resetForm();
    }
  }

  const initialFormValues: FormValues = props.reviewedClientBeingEdited
    ? {
        reviewedByUserId: props.reviewedClientBeingEdited.reviewedByUserId,
        name: props.reviewedClientBeingEdited.name,
        idNo: props.reviewedClientBeingEdited.candidate!.idNo,
        mentorName: props.reviewedClientBeingEdited.partnerName,
        perEffectiveDate: props.reviewedClientBeingEdited.candidate!.perEffectiveDate
          ? DateTime.fromISO(props.reviewedClientBeingEdited.candidate!.perEffectiveDate)
          : null,
        monthsExperience: props.reviewedClientBeingEdited.candidate!.monthsExperience ?? "",
        mentorReviews: props.reviewedClientBeingEdited.candidate!.mentorReviews ?? "",
        requiredNoOfReviews: props.reviewedClientBeingEdited.candidate!.requiredNoOfReviews ?? "",
        rotation1: props.reviewedClientBeingEdited.candidate!.rotation1 ?? "",
        rotation1Duration: props.reviewedClientBeingEdited.candidate!.rotation1Duration ?? "",
        rotation2: props.reviewedClientBeingEdited.candidate!.rotation2 ?? "",
        rotation2Duration: props.reviewedClientBeingEdited.candidate!.rotation2Duration ?? "",
        rotation3: props.reviewedClientBeingEdited.candidate!.rotation3 ?? "",
        rotation3Duration: props.reviewedClientBeingEdited.candidate!.rotation3Duration ?? "",
        rotation4: props.reviewedClientBeingEdited.candidate!.rotation4 ?? "",
        rotation4Duration: props.reviewedClientBeingEdited.candidate!.rotation4Duration ?? "",
        reasonableAuditChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableAuditChargeableHours,
        reasonableReviewChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableReviewChargeableHours,
        reasonableOtherAssuranceChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableOtherAssuranceChargeableHours,
        reasonableCompilationChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableCompilationChargeableHours,
        reasonableTaxationChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableTaxationChargeableHours,
        reasonableOtherChargeableHours: props.reviewedClientBeingEdited.candidate!.reasonableOtherChargeableHours,
        additionalComments: props.reviewedClientBeingEdited.candidate!.additionalComments
      }
    : {
        reviewedByUserId: user.id,
        name: "",
        idNo: "",
        mentorName: "",
        perEffectiveDate: null,
        monthsExperience: "",
        mentorReviews: "",
        requiredNoOfReviews: "",
        rotation1: "",
        rotation1Duration: "",
        rotation2: "",
        rotation2Duration: "",
        rotation3: "",
        rotation3Duration: "",
        rotation4: "",
        rotation4Duration: "",
        reasonableAuditChargeableHours: false,
        reasonableReviewChargeableHours: false,
        reasonableOtherAssuranceChargeableHours: false,
        reasonableCompilationChargeableHours: false,
        reasonableTaxationChargeableHours: false,
        reasonableOtherChargeableHours: false,
        additionalComments: null
      };

  const validationSchema = Yup.object().shape({
    name: Validations.requiredText(),
    idNo: Validations.requiredText(),
    mentorName: Validations.requiredText()
  });

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} enableReinitialize onSubmit={saveCandidate}>
      {(formikProps) => (
        <FormikForm>
          <Grid container columnSpacing={2}>
            {props.reviewers.length > 1 && (
              <Grid item xs={12}>
                {userIsLeadReviewer(props.practiceReview) ? (
                  <FormikField
                    component={FmuiTextField}
                    name="reviewedByUserId"
                    label="Reviewer"
                    fullWidth
                    required
                    select
                    disabled={!userIsLeadReviewer(props.practiceReview)}
                    hidden={!props.canEdit}>
                    {props.reviewers.map((reviewer) => (
                      <MenuItem key={reviewer.user.id} value={reviewer.user.id}>
                        {reviewer.user.name}
                        {reviewer.isLead && <span className={classes.leadMarker}>Lead</span>}
                      </MenuItem>
                    ))}
                  </FormikField>
                ) : (
                  <Box sx={{ mb: 1 }}>
                    <StackedStaticDataDisplay
                      label="Reviewer"
                      value={props.reviewers.find((r) => r.user.id === formikProps.values.reviewedByUserId)?.user.name ?? ""}
                    />
                  </Box>
                )}
              </Grid>
            )}

            <Grid item xs={8}>
              <FormikField
                component={FmuiTextField}
                name="name"
                label={`Candidate Name`}
                hidden={!props.canEdit}
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("name", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <FormikField
                component={FmuiTextField}
                name="idNo"
                label={`ID No.`}
                hidden={!props.canEdit}
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("idNo", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormikField
                component={FmuiTextField}
                name="mentorName"
                label={`Mentor Name`}
                hidden={!props.canEdit}
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("mentorName", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={PrsDatePickerField}
                name="perEffectiveDate"
                label="PER Effective Date"
                allowNonWorkingDays
                fullWidth
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                hidden={!props.canEdit}
                onBlur={() => {
                  formikProps.setFieldTouched("perEffectiveDate", true);
                }}
                onChange={(newValue: DateTime) => {
                  formikProps.setFieldValue("perEffectiveDate", newValue);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="monthsExperience"
                hidden={!props.canEdit}
                label="Months of Experience"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="mentorReviews"
                hidden={!props.canEdit}
                label="No. of Mentor Reviews"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="requiredNoOfReviews"
                hidden={!props.canEdit}
                label="Required No. of Reviews"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ height: theme.spacing(3) }} />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiTextField}
                name="rotation1"
                label="Rotation 1"
                hidden={!props.canEdit}
                fullWidth
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("rotation1", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                months
                formikProps={formikProps}
                fieldName="rotation1Duration"
                hidden={!props.canEdit}
                label="Duration in Rotation 1"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiTextField}
                name="rotation2"
                label="Rotation 2"
                hidden={!props.canEdit}
                fullWidth
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("rotation2", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                months
                formikProps={formikProps}
                fieldName="rotation2Duration"
                hidden={!props.canEdit}
                label="Duration in Rotation 2"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiTextField}
                name="rotation3"
                label="Rotation 3"
                hidden={!props.canEdit}
                fullWidth
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("rotation3", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                months
                formikProps={formikProps}
                fieldName="rotation3Duration"
                hidden={!props.canEdit}
                label="Duration in Rotation 3"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiTextField}
                name="rotation4"
                label="Rotation 4"
                hidden={!props.canEdit}
                fullWidth
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("rotation4", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                months
                formikProps={formikProps}
                fieldName="rotation4Duration"
                hidden={!props.canEdit}
                label="Duration in Rotation 4"
                fullWidth
                reportUnsavedChanges
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ height: theme.spacing(3) }} />
            </Grid>

            <Grid item xs={12}>
              <Table size="small" sx={{ "& .MuiCheckbox-root": { padding: 0 } }}>
                <TableHead>
                  <TableRow sx={{ "& .MuiTableCell-head": { fontSize: theme.typography.body1.fontSize } }}>
                    <TableCell>Chargeable Hours</TableCell>
                    <TableCell align="center">Reasonable</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Audit</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableAuditChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue("reasonableAuditChargeableHours", !formikProps.values.reasonableAuditChargeableHours)
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Review</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableReviewChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue("reasonableReviewChargeableHours", !formikProps.values.reasonableReviewChargeableHours)
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other Assurance</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableOtherAssuranceChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue(
                            "reasonableOtherAssuranceChargeableHours",
                            !formikProps.values.reasonableOtherAssuranceChargeableHours
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Compilation</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableCompilationChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue(
                            "reasonableCompilationChargeableHours",
                            !formikProps.values.reasonableCompilationChargeableHours
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Taxation</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableTaxationChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue(
                            "reasonableTaxationChargeableHours",
                            !formikProps.values.reasonableTaxationChargeableHours
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={formikProps.values.reasonableOtherChargeableHours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formikProps.setFieldValue("reasonableOtherChargeableHours", !formikProps.values.reasonableOtherChargeableHours)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ height: theme.spacing(3) }} />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <RichTextEditor
                label="Additional Comments"
                minHeight="6em"
                html={formikProps.values.additionalComments}
                passContentRetriever={(getContentAsHtml) => {
                  additionalCommentsContentRetriever.current = { getContentAsHtml };
                }}
                reportUnsavedChanges
                hideToolbar
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <div className={cx(classes.actions, classes.reviewedClientDetailsActions)}>
              {props.reviewedClientBeingEdited && props.userCanDeleteReviewedClientBeingEdited && (
                <LoadingButton
                  color="error"
                  variant="text"
                  disabled={props.loadingAdd || props.loadingEdit}
                  hidden={!props.canEdit}
                  loading={props.loadingDelete}
                  onClick={() => props.delete(props.reviewedClientBeingEdited!)}>
                  Delete
                </LoadingButton>
              )}
            </div>
            <div className={cx(classes.actions, classes.reviewedClientDetailsActions)}>
              {props.cancel && (
                <Button hidden={!props.canEdit} variant="text" onClick={() => props.cancel!()}>
                  Cancel
                </Button>
              )}
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={props.loadingDelete}
                hidden={!props.canEdit}
                loading={props.loadingAdd || props.loadingEdit}
                onClick={() => formikProps.submitForm()}>
                Save
              </LoadingButton>
            </div>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};
