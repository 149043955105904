import { Theme } from "@mui/material/styles";
import { QuestionBase } from "../checklists/models";
import { theme } from "styles/theme";
import { CSSObject } from "tss-react";

export function answerButtonsStyles(theme: Theme) {
  return {
    buttonContainer: {
      display: "grid",
      gridColumnGap: theme.spacing(1)
    } as CSSObject,
    answerButton: {
      height: theme.spacing(6),
      width: theme.spacing(6),
      minWidth: theme.spacing(6)
    } as CSSObject,
    unselectedAnswerButton: {
      backgroundColor: theme.palette.common.white
    } as CSSObject,
    selectedAnswerButton: {
      color: theme.palette.common.white
    } as CSSObject,
    yes: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      }
    } as CSSObject,
    no: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      }
    } as CSSObject,
    rd: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark
      }
    } as CSSObject,
    nrd: {
      backgroundColor: theme.palette.warning.main,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark
      }
    } as CSSObject,
    na: {
      backgroundColor: theme.palette.cpaGrey.main,
      "&:hover": {
        backgroundColor: theme.palette.cpaGrey.dark
      }
    } as CSSObject
  };
}

export function tableStyles(theme: Theme) {
  return {
    category: {
      fontWeight: 800,
      borderBottom: "none",
      borderTop: `2px solid ${theme.palette.border}`
    } as CSSObject,
    topCategory: {
      borderTop: "none"
    } as CSSObject,
    total: {
      "& .MuiTableCell-root": {
        fontWeight: 500,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: "none"
      } as const
    } as CSSObject,
    spanningRow: {
      textAlign: "center"
    } as CSSObject,
    number: {
      fontVariantNumeric: "tabular-nums"
    }
  };
}

export function datagridStyles(theme: Theme, headerHeight: number = 56) {
  return {
    datagridRoot: {
      "& .MuiDataGrid-columnHeaders": {
        // Need to override these so that the header container is the correct height
        minHeight: `${headerHeight}px !important`,
        maxHeight: `${headerHeight}px !important`,
        lineHeight: `${headerHeight}px !important`
      },
      "& .MuiDataGrid-virtualScroller": {
        // Need to override these so that the header container is the correct height
        marginTop: `${headerHeight}px !important`
      },
      "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: theme.palette.highlight
      },
      "& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders": {
        // the default styling for pinned columns is bonkers heavy
        backgroundColor: "white",
        boxShadow: "inherit",
        borderRight: "1px solid #f5f5f5"
      }
    } as const,
    wrapHeader: {
      "& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-iconButtonContainer": {
        // These allow wrapped headers to look good
        lineHeight: "20px",
        whiteSpace: "normal !important" as any
      }
    } as const,
    tightSortIconHeader: {
      // gotta double up the classes to make it specific enough to change the padding
      "&.MuiDataGrid-columnHeader--sortable": {
        paddingRight: "0px",
        ".MuiDataGrid-columnHeaderTitleContainer": {
          justifyContent: "space-between",
          ".MuiIconButton-root": {
            padding: "5px 1px"
          }
        }
      }
    } as const,
    lastColumnHeader: {
      "& .MuiDataGrid-columnSeparator": {
        display: "none !important" as any
      }
    } as const,
    noDataMessage: {
      position: "absolute",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    } as const,
    hideBooleanXs: {
      "& .MuiDataGrid-cell svg[data-value=false]": {
        // Hide the X's in the boolean columns
        display: "none"
      }
    } as const,
    hideBooleanXsInCell: {
      "& svg[data-value=false]": {
        // Hide the X's in the boolean columns
        display: "none"
      }
    } as const,
    comply: {
      color: theme.palette.success.main
    } as const,
    nonComply: {
      color: theme.palette.error.main
    } as const
  };
}

export function actionStyles(theme: Theme) {
  return {
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(1)
      }
    }
  };
}

export function staticDataStyles(theme: Theme) {
  return {
    stackedContainer: {
      paddingLeft: theme.spacing(2)
    },
    label: {
      fontSize: "small",
      color: theme.palette.text.secondary
    },
    errorLabel: {
      color: theme.palette.error.main
    },
    inlineLabel: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1)
    },
    muted: {
      "& .MuiTypography-body1": {
        color: theme.palette.text.secondary
      }
    }
  };
}

export function expanderStyles(theme: Theme) {
  return {
    expander: {
      marginRight: theme.spacing(1)
    } as const,
    expandedIcon: {
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      }),
      "&.rotated": {
        transform: "rotate(180deg)"
      }
    } as const
  };
}

export function checklistStyles(theme: Theme) {
  return {
    numberedItemText: {
      marginRight: theme.spacing(3),
      paddingLeft: hangingIndentForNumberingWidth,
      textIndent: `-${hangingIndentForNumberingWidth}`,
      "&::before": {
        marginRight: theme.spacing(1),
        textAlign: "right",
        display: "inline-block",
        width: `calc(${hangingIndentForNumberingWidth} - ${theme.spacing(1)})`,
        flexShrink: 0
      }
    } as const,
    adornments: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0
    } as const
  };
}

export function checklistQuestionStyles<TProps extends { question: QuestionBase }>(theme: Theme, props: TProps) {
  return {
    numberedQuestionText: {
      flexGrow: 1,
      "&::before": {
        content: `counter(section-${props.question.parentId}, ${getCounterStyle(props.question.depth)}) '.'`
      }
    } as const,
    questionCounter: {
      counterIncrement: `section-${props.question.parentId}`
    } as const,
    questionBlock: {
      padding: questionPadding,
      paddingLeft: `calc((${checklistIndentSize} * ${props.question.depth - 1}) + ${checklistExpanderWidth})`,
      marginBottom: theme.spacing(2)
    } as const,
    questionTop: {
      display: "flex",
      alignItems: "flex-start"
    } as const,
    questionTextBody: {
      display: "inline",
      whiteSpace: "pre-wrap"
    } as const,
    standardParagraphTextBody: {
      whiteSpace: "pre-wrap"
    } as const,
    cpaRef: {
      width: cpaRefWidth,
      flexShrink: 0
    } as const
  };
}

export function documentListStyles(theme: Theme) {
  return {
    documentList: {
      marginTop: theme.spacing(3),
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridRowGap: theme.spacing(3),
      alignItems: "center",
      justifyContent: "flex-start"
    } as const,
    documentRow: {
      display: "contents",
      "& .removeButton": {
        visibility: "hidden"
      },
      "&:hover .removeButton": {
        visibility: "visible"
      }
    } as const,
    showAction: {
      visibility: "visible !important" as any
    } as const,
    documentName: {
      gridColumn: 1,
      overflowX: "hidden"
    } as const,
    documentAction: {
      gridColumn: 2,
      paddingLeft: theme.spacing(2),
      "& [class*='MuiButton-root']": {
        width: "100%"
      }
    } as const,
    missingForm: {
      color: theme.palette.text.secondary
    } as const
  };
}

export function summaryChecklistStyles(theme: Theme) {
  return {
    checklist: {
      display: "grid",
      gridTemplateColumns: "auto auto auto auto",
      alignItems: "center",
      justifyContent: "flex-start",
      gridRowGap: theme.spacing(0.5)
    } as const,
    checklistRow: {
      display: "contents",
      alignItems: "center",
      "& .removeAction": {
        visibility: "hidden"
      },
      "&:hover .removeAction": {
        visibility: "visible"
      }
    } as const,
    checklistDisabledRow: {
      display: "contents",
      alignItems: "center",
      color: theme.palette.cpaGrey.main
    } as const,
    checklistItemState: {
      gridColumn: 1,
      overflowX: "hidden",
      width: "1em",
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    } as const,
    checklistItem: {
      gridColumn: 2,
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      textTransform: "unset"
    } as const,
    checklistItemAction: {
      gridColumn: 3,
      paddingLeft: theme.spacing(3),
      "& [class*='MuiButton-root']": {
        width: "100%"
      }
    } as const,
    checklistItemDetail: {
      gridColumn: 3,
      paddingLeft: theme.spacing(4),
      fontWeight: 800,
      "& [class*='MuiButton-root']": {
        width: "100%"
      }
    } as const,
    checklistItemSecondAction: {
      gridColumn: 4,
      paddingLeft: theme.spacing(3)
    } as const
  };
}

export function meetingStyles(theme: Theme) {
  return {
    meetingCapacity: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2)
    },
    meetingOverCapacity: {
      color: theme.palette.warning.main
    }
  };
}

export function inaPanelStyles(theme: Theme) {
  return {
    defaultLeftBorder: {
      borderLeft: `6px solid transparent`
    } as const,
    new: {
      borderLeftColor: theme.palette.cpaAccentGreen.main
    } as const,
    semiNew: {
      borderLeftColor: theme.palette.cpaAccentYellow.main
    } as const,
    overdue: {
      borderLeftColor: theme.palette.error.main
    } as const,
    actionTextButton: {
      paddingTop: 0,
      paddingBottom: 0
    } as const,
    dangerCheck: {
      "& .MuiDataGrid-booleanCell[data-value='true']": {
        color: theme.palette.error.main
      }
    } as const
  };
}

export function fieldStyles(theme: Theme) {
  return {
    noNumberSpinners: {
      MozAppearance: "textfield",
      "& ::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      },
      "& ::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      }
    } as const
  };
}

export function versionIndicatorStyles(theme: Theme) {
  return {
    versionIndicator: {
      fontSize: "10px"
    }
  };
}

// note that if getCounterStyle ever changes, it needs to be kept semantically in sync with ChecklistNumbering.cs:
export const getCounterStyle = (depth: number) => ["decimal", "lower-alpha", "lower-roman"][(depth - 2) % 3];

export const checklistIndentSize = theme.spacing(2);
export const checklistExpanderWidth = "24px"; // Observed width of the expanded icon at a specific font size
export const hangingIndentForNumberingWidth = "1.75rem"; // Wide enough to allow three digit numbers
export const questionPadding = theme.spacing(1);
export const sectionHeaderPadding = theme.spacing(0.5);
export const cpaRefWidth = "8em";
