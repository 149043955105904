import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, IconButton, Typography } from "@mui/material";

import { ReviewedClient } from "practice-reviews";
import React from "react";
import { expanderStyles } from "styles/common";
import useLocalStorage from "util/useLocalStorage";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { ReviewedClientTile } from "./ReviewedClientTile";
import { makeStyles } from "makeStyles";

interface Props {
  pprp: boolean;
  partnerName: string;
  reviewedClients: ReviewedClient[];
  selectedReviewedClientId?: number;
  reviewedClientClicked: (reviewedClient: ReviewedClient) => void;
  markerClicked: (reviewedClient: ReviewedClient) => void;
  userCanViewReviewedClient: (reviewedClient: ReviewedClient) => boolean;
}

const useStyles = makeStyles()((theme) => ({
  ...expanderStyles(theme),
  header: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    "& h3": {
      fontWeight: 500
    }
  }
}));

export const PartnerGroup: React.FunctionComponent<Props> = (props) => {
  const [expanded, setExpanded] = useLocalStorage(`Review Files Grouped By Partner Name: ${props.partnerName}`, true);
  const { classes, cx } = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <IconButton size="small" onClick={() => setExpanded(!expanded)} className={classes.expander}>
          <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
        </IconButton>
        <Typography variant="h3">{props.partnerName}</Typography>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.reviewedClients.map((c) => (
          <ReviewedClientTile
            key={c.id}
            pprp={props.pprp}
            reviewedClient={c}
            isSelected={c.id === props.selectedReviewedClientId}
            reviewedClientClicked={props.reviewedClientClicked}
            markerClicked={props.markerClicked}
            userCanViewReviewedClient={props.userCanViewReviewedClient(c)}
          />
        ))}
      </Collapse>
    </div>
  );
};
