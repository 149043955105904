import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Question, Answer, AnswerType, StandardParagraph } from "checklists";
import {
  Button,
  Checkbox,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import { AnswerDisplay } from "./AnswerDisplay";
import {
  checklistStyles,
  checklistQuestionStyles,
  staticDataStyles,
  checklistIndentSize,
  hangingIndentForNumberingWidth
} from "styles/common";
import _ from "lodash";
import { ChecklistActionType } from "./checklistReducer";
import { PrChecklistDispatchContext } from "./PrChecklist";
import { makeStyles } from "../makeStyles";
import { useCurrentUser, Permissions } from "users";
import { PracticeReviewContext } from "../practice-reviews/PracticeReviewScreen";

const useStyles = makeStyles<Props>()((theme, props) => {
  return {
    ...staticDataStyles(theme),
    ...checklistStyles(theme),
    ...checklistQuestionStyles(theme, props),
    deficiencyIndent: {
      paddingLeft: `calc(${hangingIndentForNumberingWidth} + ${checklistIndentSize}px)`
    },
    questionWrapper: {
      borderRadius: theme.shape.borderRadius,
      scrollMargin: "100px"
    },
    currentQuestion: {
      backgroundColor: theme.palette.highlight
    },
    deficiencyForm: {
      marginLeft: hangingIndentForNumberingWidth,
      marginTop: theme.spacing(2),
      "& .MuiOutlinedInput-root, & .MuiButton-root": {
        backgroundColor: theme.palette.common.white
      }
    },
    standardParagraphContainer: {
      display: "flex",
      alignItems: "flex-start"
    },
    standardParagraphTitle: {
      flexGrow: 1
    },
    customizeButton: {
      marginLeft: theme.spacing(2),
      flexShrink: 0
    },
    paragraphAndCheckboxes: {
      display: "flex"
    },
    details: {
      flexGrow: 1,
      marginTop: theme.spacing(1),
      "& > *:not(:first-child)": {
        marginTop: theme.spacing(1)
      }
    },
    customizedDetails: {
      "& > :first-child": {
        marginTop: theme.spacing(1)
      },
      "& > *:not(:first-child)": {
        marginTop: theme.spacing(2)
      }
    },
    checkboxContainer: {
      marginLeft: theme.spacing(3),
      flexShrink: 0,
      alignSelf: "flex-end",
      "& > *": {
        display: "flex",
        margin: 0
      }
    },
    fileDetails: {
      marginTop: theme.spacing(2)
    }
  };
});

interface Props {
  question: Question;
  tabSectionId: number;
  isCurrentQuestion: boolean;
  findingInvalidAnswers: boolean;
  disabled?: boolean;
}

interface AnswerState {
  answerType: AnswerType | null;
  note: string | null;
  standardParagraphId: number | "" | "custom";
  customCpaRef: string;
  customParagraphText: string;
  customRecommendationText: string;
  requiresRemedialAction: boolean;
  isSignificant: boolean;
  fileDetails: string;
}

interface AnswerValidity {
  invalid: boolean;
  standardParagraph?: string;
  customCpaRef?: string;
  customParagraphText?: string;
  customRecommendationText?: string;
}

interface AnswerFieldsTouched {
  standardParagraph: boolean;
  customCpaRef: boolean;
  customParagraphText: boolean;
  customRecommendationText: boolean;
  fileDetails: boolean;
}

const PrQuestion: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles(props);
  const outerDivRef = useRef<HTMLDivElement | null>(null);
  const { practiceReview } = useContext(PracticeReviewContext);

  const { dispatch } = useContext(PrChecklistDispatchContext);

  const answer = props.question.answer;

  const { userHasPermission } = useCurrentUser();
  const canEdit = (practiceReview && !practiceReview.hasBeenReturned) || userHasPermission(Permissions.ReturnedPrUpdate);

  function getAnswerStateFromAnswer(): AnswerState {
    const answerType = getAnswerTypeFromAnswer(answer);

    return {
      answerType: answerType,
      note: answer?.note ?? null,
      standardParagraphId: answer?.standardParagraphId ? answer.standardParagraphId : answer?.hasCustomDeficiency ? "custom" : "",
      customCpaRef: answer?.customCpaRef ?? "",
      customParagraphText: answer?.customParagraphText ?? "",
      customRecommendationText: answer?.customRecommendationText ?? "",
      requiresRemedialAction: answer?.requiresRemedialAction ?? false,
      isSignificant: answer?.isSignificant ?? false,
      fileDetails: answer?.fileDetails ?? ""
    };
  }

  function getAnswerTypeFromAnswer(answer: Answer | undefined) {
    return !answer
      ? null
      : answer.isYes
      ? AnswerType.Yes
      : answer.isReportable
      ? AnswerType.Rd
      : answer.isNonReportable
      ? AnswerType.Nrd
      : answer.isNa
      ? AnswerType.Na
      : null;
  }

  const [answerState, setAnswerState] = useState<AnswerState>(getAnswerStateFromAnswer());
  const [customRequiresRemedialAction, setCustomRequiresRemedialAction] = useState(false); // We're tracking these explicitly for good behavior when swapping between standard and custom deficiences
  const [customIsSignificant, setCustomIsSignificant] = useState(false);
  useEffect(() => {
    setAnswerState(getAnswerStateFromAnswer());

    if (props.question.answer?.standardParagraphId === null) {
      setCustomRequiresRemedialAction(props.question.answer?.requiresRemedialAction);
      setCustomIsSignificant(props.question.answer?.isSignificant);
    }
  }, [props.question.answer]);

  const [validity, setValidity] = useState<AnswerValidity>({ invalid: false });
  useEffect(() => {
    validate();
  }, [props.findingInvalidAnswers]);

  const [fieldsTouched, setFieldsTouched] = useState<AnswerFieldsTouched>({
    standardParagraph: false,
    customCpaRef: false,
    customParagraphText: false,
    customRecommendationText: false,
    fileDetails: false
  });

  useEffect(() => {
    if (outerDivRef.current && props.isCurrentQuestion) {
      // Need to delay here until any expansion animations are done
      const animationBuffer = 10;
      setTimeout(
        () => outerDivRef.current!.scrollIntoView({ behavior: "smooth", block: "nearest" }),
        theme.transitions.duration.standard + animationBuffer
      );
    }
  }, [props.isCurrentQuestion]);

  useEffect(() => {
    // If the question has been answered, and then the user moves on to another question (i.e. isCurrentQuestion is no longer true),
    // set all fields to touched so that validation errors appear.
    if (
      props.question.answer?.isYes ||
      props.question.answer?.isReportable ||
      props.question.answer?.isNonReportable ||
      props.question.answer?.isNa
    ) {
      setFieldsTouched({
        standardParagraph: true,
        customCpaRef: true,
        customParagraphText: true,
        customRecommendationText: true,
        fileDetails: true
      });
    }
  }, [props.isCurrentQuestion]);

  useEffect(() => {
    if (!props.findingInvalidAnswers || !validity.invalid) return;

    // If the user attempts to save with an invalid question, touch all the fields and scroll the question into view.

    setFieldsTouched({
      standardParagraph: true,
      customCpaRef: true,
      customParagraphText: true,
      customRecommendationText: true,
      fileDetails: true
    });

    if (outerDivRef.current) {
      // Need to delay here until the expansion animations are done
      const animationBuffer = 10;
      setTimeout(
        () => outerDivRef.current!.scrollIntoView({ behavior: "smooth", block: "center" }),
        theme.transitions.duration.standard + animationBuffer
      );

      // After scheduling the scroll, reset the state so that this handler will run again next time, if necessary.
      dispatch({ type: ChecklistActionType.ResetFindInvalidAnswers, question: props.question });
    }
  }, [props.findingInvalidAnswers, validity.invalid]);

  const dispatchUpdatedAnswer = useCallback(
    (a: Answer) => {
      // We only want to automatically move to the next question if it is not a deficiency.
      const moveToNextQuestion =
        getAnswerTypeFromAnswer(props.question.answer) === null &&
        (getAnswerTypeFromAnswer(a) === AnswerType.Yes || getAnswerTypeFromAnswer(a) === AnswerType.Na);

      dispatch({
        type: ChecklistActionType.UpdateAnswer,
        question: props.question,
        answer: a,
        tabSectionId: props.tabSectionId,
        moveToNextQuestion
      });
    },
    [dispatch, props.question, props.tabSectionId]
  );

  useEffect(() => {
    const validity = validate();

    const updatedAnswer: Answer = {
      id: answer?.id,
      isYes: answerState.answerType === AnswerType.Yes,
      isReportable: answerState.answerType === AnswerType.Rd,
      isNonReportable: answerState.answerType === AnswerType.Nrd,
      isNa: answerState.answerType === AnswerType.Na,
      note: answerState.note && answerState.note.trim() !== "" ? answerState.note.trim() : null,
      standardParagraphId:
        answerState.standardParagraphId !== "custom" && answerState.standardParagraphId !== "" ? answerState.standardParagraphId : null,
      hasCustomDeficiency: answerState.standardParagraphId === "custom",
      customCpaRef: answerState.customCpaRef.trim() ? answerState.customCpaRef : null,
      customParagraphText: answerState.customParagraphText.trim() ? answerState.customParagraphText : null,
      customRecommendationText: answerState.customRecommendationText.trim() ? answerState.customRecommendationText : null,
      isSignificant: answerState.isSignificant,
      requiresRemedialAction: answerState.requiresRemedialAction,
      fileDetails: answerState.fileDetails.trim() ? answerState.fileDetails : null,

      modified: true,
      invalid: validity.invalid
    };

    const answerIsDifferentFromProps =
      updatedAnswer.isYes !== (props.question.answer?.isYes ?? false) ||
      updatedAnswer.isReportable !== (props.question.answer?.isReportable ?? false) ||
      updatedAnswer.isNonReportable !== (props.question.answer?.isNonReportable ?? false) ||
      updatedAnswer.isNa !== (props.question.answer?.isNa ?? false) ||
      updatedAnswer.note !== (props.question.answer?.note ?? null) ||
      updatedAnswer.standardParagraphId !== (props.question.answer?.standardParagraphId ?? null) ||
      updatedAnswer.customCpaRef !== (props.question.answer?.customCpaRef ?? null) ||
      updatedAnswer.customParagraphText !== (props.question.answer?.customParagraphText ?? null) ||
      updatedAnswer.customRecommendationText !== (props.question.answer?.customRecommendationText ?? null) ||
      updatedAnswer.isSignificant !== (props.question.answer?.isSignificant ?? false) ||
      updatedAnswer.requiresRemedialAction !== (props.question.answer?.requiresRemedialAction ?? false) ||
      updatedAnswer.fileDetails !== (props.question.answer?.fileDetails ?? null);

    if (answerIsDifferentFromProps) {
      dispatchUpdatedAnswer(updatedAnswer);
    }
  }, [answerState]);

  function validate() {
    const answerValidity: AnswerValidity = { invalid: false };

    if (answerState.answerType === AnswerType.Rd || answerState.answerType === AnswerType.Nrd) {
      if (answerState.standardParagraphId === "") {
        answerValidity.standardParagraph = "Select a deficiency.";
        answerValidity.invalid = true;
      }

      if (answerState.standardParagraphId === "custom") {
        if (!answerState.customParagraphText.trim()) {
          answerValidity.customParagraphText = "Enter a description.";
          answerValidity.invalid = true;
        }

        if (answerState.answerType === AnswerType.Rd && !answerState.customRecommendationText.trim()) {
          answerValidity.customRecommendationText = "Enter a recommendation.";
          answerValidity.invalid = true;
        }
      }
    }

    setValidity(answerValidity);

    return answerValidity;
  }

  const selectedStandardParagraph = props.question.standardParagraphs.find((sp) => sp.id === answerState.standardParagraphId);
  const deficiencySelectorLabel = answerState.answerType === AnswerType.Rd ? "Reportable Deficiency" : "Non-reportable Deficiency";

  function customize() {
    setAnswerState({
      ...answerState,
      standardParagraphId: "custom",
      customCpaRef: selectedStandardParagraph?.cpaRef ?? "",
      customParagraphText: selectedStandardParagraph?.text ?? "",
      customRecommendationText: selectedStandardParagraph?.recommendation ?? "",
      isSignificant: selectedStandardParagraph?.isSignificantByDefault ?? false,
      requiresRemedialAction: selectedStandardParagraph?.isRemedialByDefault ?? false
    });
  }
  const handleAnswerButtonClick = useCallback(
    (newAnswerType: AnswerType) => {
      // if the type is changing to RD and has a standard paragraph already selected, we need to reset the default flags
      const resetDefaultFlags =
        newAnswerType === AnswerType.Rd &&
        answerState.answerType !== AnswerType.Rd &&
        answerState.standardParagraphId &&
        answerState.standardParagraphId !== "custom";

      const newAnswerState = { ...answerState, answerType: newAnswerType !== answerState.answerType ? newAnswerType : null };

      if (resetDefaultFlags) {
        const standardParagraph = props.question.standardParagraphs.find((sp) => sp.id === answerState.standardParagraphId);

        if (standardParagraph) {
          newAnswerState.isSignificant = answerState.isSignificant || standardParagraph.isSignificantByDefault;
          newAnswerState.requiresRemedialAction = answerState.requiresRemedialAction || standardParagraph.isRemedialByDefault;
        }
      }

      setAnswerState(newAnswerState);
    },
    [answerState, props.question]
  );

  const handleSaveNote = useCallback(
    (newNote: string | null) => {
      if (answerState.note !== newNote) {
        setAnswerState({ ...answerState, note: newNote });
      }
    },
    [answerState]
  );

  async function onChangeDeficiency(newStandardParagraphId: number | "custom") {
    const newStandardParagraph = props.question.standardParagraphs.find((sp) => sp.id === newStandardParagraphId);

    setAnswerState({
      ...answerState,
      standardParagraphId: newStandardParagraphId,
      requiresRemedialAction:
        newStandardParagraphId !== "custom" ? newStandardParagraph!.isRemedialByDefault : customRequiresRemedialAction,
      isSignificant: newStandardParagraphId !== "custom" ? newStandardParagraph!.isSignificantByDefault : customIsSignificant
    });

    setFieldsTouched({
      ...fieldsTouched,
      standardParagraph: true
    });
  }

  const customized = answerState.standardParagraphId === "custom";

  return props.question.hidden ? (
    <div className={classes.questionCounter} />
  ) : (
    <div className={cx(classes.questionWrapper, { [classes.currentQuestion]: props.isCurrentQuestion })} ref={outerDivRef}>
      <div className={cx(classes.questionBlock, classes.questionCounter)}>
        <div className={classes.questionTop}>
          <div className={cx(classes.numberedItemText, classes.numberedQuestionText)}>
            <Typography variant="body1" className={classes.questionTextBody}>
              {props.question.text}
            </Typography>
          </div>
          <div className={classes.cpaRef}>
            <Typography variant="body1">{props.question.cpaRef}</Typography>
          </div>
          <AnswerDisplay
            readonly={!canEdit}
            answerType={answerState.answerType}
            note={answerState.note}
            answerQuestion={handleAnswerButtonClick}
            saveNote={handleSaveNote}
            hidden={false}
            disabled={props.disabled}
          />
        </div>
        {(answerState.answerType === AnswerType.Rd || answerState.answerType === AnswerType.Nrd) && (
          <>
            <div className={cx(classes.deficiencyForm, classes.deficiencyIndent)}>
              <div className={classes.standardParagraphContainer}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={cx(classes.standardParagraphTitle)}
                  error={fieldsTouched.standardParagraph && Boolean(validity.standardParagraph)}>
                  <InputLabel htmlFor="standard-paragraph-id">{deficiencySelectorLabel}</InputLabel>
                  <Select
                    label={deficiencySelectorLabel}
                    inputProps={{
                      id: "standard-paragraph-id"
                    }}
                    disabled={!canEdit || props.disabled}
                    value={answerState.standardParagraphId}
                    onChange={(e: SelectChangeEvent<any>) => onChangeDeficiency(e.target.value)}>
                    {props.question.standardParagraphs.map((sp) => (
                      <MenuItem value={sp.id} key={sp.id}>
                        {sp.title}
                      </MenuItem>
                    ))}
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                  {fieldsTouched.standardParagraph && <FormHelperText>{validity.standardParagraph}</FormHelperText>}
                </FormControl>
                <Button
                  variant="outlined"
                  className={classes.customizeButton}
                  onClick={() => customize()}
                  disabled={answerState.standardParagraphId === "custom" || !canEdit || props.disabled}>
                  Customize
                </Button>
              </div>

              {answerState.standardParagraphId && (
                <>
                  <div className={classes.paragraphAndCheckboxes}>
                    <div className={cx(classes.details, { [classes.customizedDetails]: customized })}>
                      {!customized ? (
                        <Typography variant="body2">{selectedStandardParagraph!.cpaRef}</Typography>
                      ) : (
                        <TextField
                          label="CPA Reference"
                          fullWidth
                          value={answerState.customCpaRef}
                          error={fieldsTouched.customCpaRef && Boolean(validity.customCpaRef)}
                          helperText={fieldsTouched.customCpaRef && validity.customCpaRef}
                          disabled={!canEdit || props.disabled}
                          onChange={(e: React.ChangeEvent<any>) => {
                            setAnswerState({ ...answerState, customCpaRef: e.target.value });
                          }}
                          onBlur={() => {
                            setFieldsTouched({ ...fieldsTouched, customCpaRef: true });
                          }}
                        />
                      )}
                      {!customized ? (
                        <Typography variant="body2" className={classes.standardParagraphTextBody}>
                          {selectedStandardParagraph!.text}
                        </Typography>
                      ) : (
                        <TextField
                          label="Description"
                          fullWidth
                          multiline
                          required
                          disabled={!canEdit || props.disabled}
                          value={answerState.customParagraphText}
                          error={fieldsTouched.customParagraphText && Boolean(validity.customParagraphText)}
                          helperText={fieldsTouched.customParagraphText && validity.customParagraphText}
                          onChange={(e: React.ChangeEvent<any>) => {
                            setAnswerState({ ...answerState, customParagraphText: e.target.value });
                          }}
                          onBlur={() => {
                            setFieldsTouched({ ...fieldsTouched, customParagraphText: true });
                          }}
                        />
                      )}
                      {!customized ? (
                        <div>
                          <Typography variant="body2" className={classes.label}>
                            Standard Recommendation
                          </Typography>
                          <Typography variant="body2" className={classes.standardParagraphTextBody}>
                            {selectedStandardParagraph!.recommendation}
                          </Typography>
                        </div>
                      ) : (
                        <TextField
                          label="Recommendation"
                          fullWidth
                          multiline
                          required={answerState.answerType === AnswerType.Rd}
                          disabled={!canEdit || props.disabled}
                          value={answerState.customRecommendationText}
                          error={fieldsTouched.customRecommendationText && Boolean(validity.customRecommendationText)}
                          helperText={fieldsTouched.customRecommendationText && validity.customRecommendationText}
                          onChange={(e: React.ChangeEvent<any>) => {
                            setAnswerState({ ...answerState, customRecommendationText: e.target.value });
                          }}
                          onBlur={() => {
                            setFieldsTouched({ ...fieldsTouched, customRecommendationText: true });
                          }}
                        />
                      )}
                    </div>
                    {answerState.answerType === AnswerType.Rd && (
                      <div className={classes.checkboxContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              title="Requires Remedial Action"
                              disabled={!canEdit || props.disabled}
                              checked={answerState.requiresRemedialAction}
                              onClick={() => {
                                if (answerState.standardParagraphId === "custom") {
                                  setCustomRequiresRemedialAction(!answerState.requiresRemedialAction);
                                }
                                setAnswerState({ ...answerState, requiresRemedialAction: !answerState.requiresRemedialAction });
                              }}
                            />
                          }
                          label="Requires Remedial Action"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              title="Significant"
                              disabled={!canEdit || props.disabled}
                              checked={answerState.isSignificant}
                              onClick={() => {
                                if (answerState.standardParagraphId === "custom") {
                                  setCustomIsSignificant(!answerState.isSignificant);
                                }
                                setAnswerState({ ...answerState, isSignificant: !answerState.isSignificant });
                              }}
                            />
                          }
                          label="Significant"
                        />
                      </div>
                    )}
                  </div>
                  <TextField
                    className={classes.fileDetails}
                    label="Specific File Details"
                    fullWidth
                    multiline
                    disabled={!canEdit || props.disabled}
                    value={answerState.fileDetails}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setAnswerState({ ...answerState, fileDetails: e.target.value });
                    }}
                    onBlur={() => {
                      setFieldsTouched({ ...fieldsTouched, fileDetails: true });
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MemoizedPrQuestion = memo(PrQuestion);
export { MemoizedPrQuestion as PrQuestion };
