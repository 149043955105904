import React, { useContext, useEffect } from "react";
import { ChecklistSection, QuestionHeader } from "checklists";
import { PrChecklistSection } from "./PrChecklistSection";
import { ChecklistActionType } from "./checklistReducer";
import { PrChecklistDispatchContext } from "./PrChecklist";
import { ChecklistNodeTreePath, isSection, QuestionContainer } from "./models";
import { makeStyles } from "../makeStyles";
import { PrQuestionHeader } from "./PrQuestionHeader";

const useStyles = makeStyles<Props>()((theme, props) => {
  return {
    root: {
      counterReset: `section-${props.tabSection.id}`
    }
  };
});

interface Props {
  tabSection: QuestionContainer;
  currentQuestionTreePath: ChecklistNodeTreePath | null;
  findingInvalidAnswers: boolean;
  isFiltered?: boolean;
  disabled?: boolean;
}

export const PrChecklistTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);

  const { dispatch } = useContext(PrChecklistDispatchContext);

  useEffect(() => {
    dispatch({ type: ChecklistActionType.MoveToFirstUnansweredQuestion, tabSectionId: props.tabSection.id });
    (document.activeElement as HTMLElement)?.blur(); // Enables keyboard entry as soon as the tab activates
  }, [dispatch, props.tabSection.id]);

  return (
    <div className={classes.root}>
      {props.tabSection.children.map((node) => {
        return (
          <>
            {isSection(node) ? (
              <PrChecklistSection
                key={node.id}
                section={node as ChecklistSection}
                tabSectionId={props.tabSection.id}
                currentQuestionTreePath={
                  props.currentQuestionTreePath && props.currentQuestionTreePath[node.depth].id === node.id
                    ? props.currentQuestionTreePath
                    : undefined
                }
                findingInvalidAnswers={props.findingInvalidAnswers}
                isFiltered={props.isFiltered}
                disabled={props.disabled}
              />
            ) : (
              <PrQuestionHeader
                key={node.id}
                questionHeader={node as QuestionHeader}
                tabSectionId={props.tabSection.id}
                currentQuestionTreePath={
                  props.currentQuestionTreePath && props.currentQuestionTreePath[node.depth].id === node.id
                    ? props.currentQuestionTreePath
                    : undefined
                }
                findingInvalidAnswers={props.findingInvalidAnswers}
                disabled={props.disabled}
              />
            )}
          </>
        );
      })}
    </div>
  );
};
