import { Decision } from ".";
import { PracticeReview } from "practice-reviews";
import { htmlHasContent } from "../util/utilities";
import { ComplianceZone, DecisionInput, DecisionTypeCode } from "./models";

export function getSignoffPreconditionsForReview(practiceReview: PracticeReview, decisionType: DecisionTypeCode) {
  const messages: string[] = [];

  if (decisionType === DecisionTypeCode.Reviewer) {
    if (practiceReview.firmChecklistCompletion.someQuestionsIncomplete) {
      messages.push("Complete the firm checklist.");
    }

    if (practiceReview.reviewedClients.some((rc) => rc.checklistCompletion.someQuestionsIncomplete)) {
      messages.push("Complete (or have other reviewers complete) all client checklists.");
    }

    if (
      practiceReview.otherReviewers.some(
        (r) => !r.reviewedClientsSubmitted && practiceReview.reviewedClients.filter((rc) => rc.reviewedByUserId === r.userId).length > 0
      )
    ) {
      messages.push("Have all other reviewers submit their client files.");
    }

    if (!Boolean(practiceReview.deficiencyReportUrl)) {
      messages.push("Generate the consolidated deficiency report.");
    }
  }

  if (decisionType === DecisionTypeCode.Manager || decisionType === DecisionTypeCode.Director) {
    const decision = practiceReview.decisions.find((d) => d.decisionType.typeCode === decisionType);

    if (!decision) {
      let decisionsFound = "";
      if (!practiceReview.decisions) {
        decisionsFound = "null";
      } else if (!practiceReview.decisions.length) {
        decisionsFound = "none";
      } else {
        decisionsFound = practiceReview.decisions.map((d) => d.decisionType?.typeCode).join(", ");
      }
      throw new Error(
        `No ${decisionType} decision has been loaded for review ${practiceReview?.prNumber}.\nIf it was just created, you may need to refresh your browser.\n(Decisions found: ${decisionsFound})`
      );
    }
    if (decision!.hasOutstandingDiscussChanges) {
      messages.push("Discuss the decision change with the reviewer and/or firm and complete the corresponding INA.");
    }
  }

  return messages;
}

export function getIncompleteDecisionMessages(
  decision: DecisionInput,
  decisionType: DecisionTypeCode | null,
  decisionComplianceZone: ComplianceZone | null,
  pprp = false
) {
  const messages: string[] = [];

  if ((decision?.isComply ?? null) === null) {
    messages.push("Select a decision (comply vs. non-comply).");
  }

  if (
    !pprp &&
    !(decision?.isComply ?? true) &&
    !decision?.nonComplyFinancial &&
    !decision?.nonComplyAudit &&
    !decision?.nonComplyReview &&
    !decision?.nonComplyQualityManagement &&
    !decision?.nonComplyTax &&
    !decision?.nonComplyCompilation &&
    !decision?.nonComplyOther
  ) {
    messages.push("Select at least one area of non-compliance.");
  }

  if (
    !pprp &&
    (decision === null || decision.cpabParticipating === null || decision.cpdCompliance === null || decision.pliCompliance === null)
  ) {
    messages.push("Answer each of the questions regarding CPAB, CPD, and PLI.");
  }

  if (pprp && (decision === null || decision.pprpContinuance === null)) {
    messages.push("Answer the question regarding PPRP continuance.");
  }

  if (decisionType === DecisionTypeCode.Reviewer) {
    if (!htmlHasContent(decision?.reviewerMemo)) {
      messages.push("Enter a memo to the director.");
    }
  }

  if (decisionType === DecisionTypeCode.Director) {
    const unsetZoneId = 0;
    if (decisionComplianceZone === null || decision!.complianceZoneId === unsetZoneId) {
      messages.push("Select a compliance zone.");
    }
  }

  if (decisionType === DecisionTypeCode.Director) {
    if (!pprp && decision!.motionStandardId === null) {
      messages.push("Select a standard motion.");
    }

    if (pprp && decision!.motionPprpId === null) {
      messages.push("Select a motion.");
    }

    if (
      !pprp &&
      decisionComplianceZone?.requiresResponse &&
      decision?.directedPdCourses.length === 0 &&
      !htmlHasContent(decision?.descriptionOfIntendedAction)
    ) {
      messages.push("Select at least one directed PD course, or enter a description of intended action.");
    }
  }

  if (decisionType === DecisionTypeCode.Director || decisionType === DecisionTypeCode.Committee) {
    if (!pprp && !decision.isComply && decision.followUpDate == null) {
      messages.push("Select a follow-up date.");
    }
    if (pprp && decision.monitoringRequired && !decision.monitoringCount) {
      messages.push("Enter the number of monitoring occurrences.");
    }
  }

  if (decisionType === DecisionTypeCode.Committee) {
    if (decision!.directedPdCourses.length > 0 && decision?.directedPdDueDate === null) {
      messages.push("Select the date that directed PD courses will be taken by.");
    }
  }

  return messages;
}
