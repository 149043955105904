export enum SignOffChecklistItemTypeCode {
  Reviewer = "Reviewer",
  Manager = "Manager",
  Director = "Director",
  AnnualProgramRenewal = "AnnualProgramRenewal"
}

export interface SignOffChecklistItemType {
  id: number;
  typeCode: SignOffChecklistItemTypeCode;
  name: string;
}

export interface SignOffChecklistItemTemplate {
  id: number;
  signOffChecklistItemType: SignOffChecklistItemType;
  questionText: string;
  sortOrder: number;
  __typename?: string;
}

export interface SignOffChecklistItemTemplateInput {
  id?: number;
  signOffChecklistItemTypeId?: number;
  questionText?: string;
  sortOrder?: number;
}
