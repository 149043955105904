import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import { Field as FormikField, Formik, FormikHelpers } from "formik";
import { DateTime } from "luxon";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { TextField as FmuiTextField, Autocomplete as FmuiAutocomplete } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import { Validations } from "../common/validations/common-yup-validations";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  AvailableMeetingsQuery,
  CommitteeMeeting,
  CommitteeMeetingInput,
  SaveCommitteeMeetingMutation,
  FetchCommitteeMeetingByIdQuery,
  getReasonBinderCannotBeGenerated,
  getReasonBinderCannotBeAssembled,
  getReasonMeetingPrsCannotBeReassigned,
  FetchCommitteeMeetingPrsQuery,
  BinderJob,
  FetchBinderJobByMeetingIdQuery,
  JobProgress
} from ".";
import { FetchCommitteeMembersQuery } from "committee-members";
import { CommitteeMember } from "../committee-members";
import { useNotifications } from "../notifications";
import FixedPlacesNumberFormat from "../util/FixedPlacesNumberFormat";
import { datagridStyles, tableStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { standardTimeFormat } from "../util/formats";
import { DataGridWithHeader } from "../common/DataGridWithHeader";
import { PracticeReview } from "../practice-reviews";
import { Skeleton } from "@mui/material";
import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid-pro";
import { useCurrentUser, Permissions } from "users";
import { getCommonMeetingFileColumns } from "./CommitteeMeetingsScreen";
import { useCalendar } from "../util/CalendarProvider";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ScreenHeader } from "../common/ScreenHeader";
import { Link as RouterLink } from "react-router-dom";
import { AppRoute } from "../Routes";
import ConfirmGenerateBinderDocumentsDialog from "./ConfirmGenerateBinderDocumentsDialog";
import ConfirmAssembleBinderDialog from "./ConfirmAssembleBinderDialog";
import { ChangeMeetingDialog } from "./ChangeMeetingDialog";
import BinderJobCard, { subscribeToDocumentGenerationProgress } from "./BinderJobCard";
import { useSignalR } from "../util/useSignalR";

const useStyles = makeStyles()((theme) => ({
  ...tableStyles(theme),
  ...datagridStyles(theme),
  subcommitteeMeetingContainer: {
    background: theme.palette.highlight,
    "& .MuiOutlinedInput-root": {
      background: theme.palette.common.white
    }
  }
}));

interface FormValues {
  meetingDate: DateTime | null;
  location: string;
  reviewQuota: number;
  status: "open" | "closed";

  recordingSecretary: string;
  observer1: string;
  observer2: string;
  observer3: string;
  observer4: string;

  subcommitteeDate: DateTime | null;
  subcommitteeMeetings: { id?: number; time: string; memberIds: number[] }[];
}

const CommitteeMeetingScreen = () => {
  const { classes, cx, theme } = useStyles();
  const notifications = useNotifications();
  const { userHasPermission } = useCurrentUser();
  const { configureForBusinessDaysMath } = useCalendar();
  const apolloClient = useApolloClient();

  const [selecting, setSelecting] = useState(false);
  const [selectedPrs, setSelectedPrs] = useState<PracticeReview[]>([]);
  const [reassigningPrs, setReassigningPrs] = useState<PracticeReview[] | null>(null);

  const [confirmingGenerateBinder, setConfirmingGenerateBinder] = useState(false);
  const [confirmingAssembleBinder, setConfirmingAssembleBinder] = useState(false);

  const routingParams = useParams<{ id: string }>();
  const meetingId = parseInt(routingParams.id);

  const committeeMembersQuery = useQuery<{ committeeMembers: CommitteeMember[] }, { includeInactive: boolean; includeDeleted: boolean }>(
    FetchCommitteeMembersQuery,
    { variables: { includeInactive: true, includeDeleted: true } }
  );
  const committeeMembers = committeeMembersQuery.data?.committeeMembers
    ? _.orderBy(committeeMembersQuery.data.committeeMembers, (cm) => cm.name)
    : [];
  const activeCommitteeMembers = committeeMembers.filter((cm) => cm.isActive && !cm.deleted);

  const meetingQuery = useQuery<{ committeeMeetingById: CommitteeMeeting }, { meetingId: number }>(FetchCommitteeMeetingByIdQuery, {
    variables: { meetingId: meetingId },
    fetchPolicy: "cache-and-network"
  });
  const meeting = meetingQuery.data?.committeeMeetingById;

  // These two queries are split because we need the meeting date to get the tabled-from-previous PRs.
  const meetingPrsQuery = useQuery<{ committeeMeetingById: CommitteeMeeting }, { meetingId: number; meetingDate: string }>(
    FetchCommitteeMeetingPrsQuery,
    { variables: { meetingId: meeting?.id ?? 0, meetingDate: meeting?.meetingDate ?? "" }, skip: !Boolean(meeting) }
  );
  const meetingPrs = meetingPrsQuery.data?.committeeMeetingById.assignedReviews ?? [];
  const sortedMeetingPrs = _.orderBy(
    meetingPrs ?? [],
    [
      (pr) => (pr.tabledFromPrevious ? pr.prNumber : 999999),
      (pr) => pr.mostAuthoritativeDecision?.isComply !== undefined,
      (pr) => pr.mostAuthoritativeDecision?.isComply ?? true,
      (pr) => pr.mostAuthoritativeDecision?.complianceZone?.zoneCode ?? "",
      (pr) => pr.reviewType === "Assurance",
      (pr) => pr.mostAuthoritativeDecision?.motionStandard?.sortOrder ?? 999,
      (pr) => pr.prNumber
    ],
    ["asc", "desc", "asc", "desc", "desc", "asc", "asc"]
  );

  const binderJobQuery = useQuery<{ binderJobByMeetingId: BinderJob | null }, { meetingId: number }>(FetchBinderJobByMeetingIdQuery, {
    variables: { meetingId: meeting?.id ?? 0 },
    fetchPolicy: "network-only",
    skip: !meeting
  });
  const binderJob = binderJobQuery.data?.binderJobByMeetingId ?? null;

  const signalrPubSub = useSignalR();
  useEffect(() => {
    subscribeToDocumentGenerationProgress(signalrPubSub, apolloClient, binderJobQuery.loading);
  }, [signalrPubSub, binderJobQuery]);

  useEffect(() => {
    // Refresh the meeting's reviews once the binder is generated so that the tab numbers appear.
    signalrPubSub.subscribe("documentGenerationProgress", async (messageContents) => {
      const jobProgress = JSON.parse(messageContents as any) as JobProgress;

      if (jobProgress.allStepsComplete) {
        await apolloClient.refetchQueries({
          updateCache(cache) {
            cache.modify({
              id: `CommitteeMeeting:${meetingId}`,
              fields: {
                assignedReviews(_, { INVALIDATE }) {
                  return INVALIDATE;
                },
                binderGenerated(_, { INVALIDATE }) {
                  return INVALIDATE;
                }
              }
            });
          }
        });
      }
    });
  }, [signalrPubSub]);

  const [saveMeetingMutate, saveMeetingMutation] = useMutation<
    { committeeMeeting: { edit: CommitteeMeeting } },
    { meeting: CommitteeMeetingInput }
  >(SaveCommitteeMeetingMutation, {
    refetchQueries: [{ query: AvailableMeetingsQuery }]
  });

  async function saveMeeting(values: FormValues, actions: FormikHelpers<FormValues>) {
    const meetingInput = getMeetingInputFromFormValues(values);

    const result = await saveMeetingMutate({
      variables: { meeting: meetingInput }
    });

    if (result.data?.committeeMeeting.edit.id) {
      notifications.success("Saved meeting.");
    }

    actions.setSubmitting(false);
  }

  function getMeetingInputFromFormValues(values: FormValues): CommitteeMeetingInput {
    return {
      id: meeting!.id,
      meetingDate: values.meetingDate!.toISODate(),
      location: values.location,
      isPprp: meeting!.isPprp,
      reviewQuota: Number(values.reviewQuota),
      isOpen: values.status === "open",

      recordingSecretary: values.recordingSecretary,
      observer1: values.observer1,
      observer2: values.observer2,
      observer3: values.observer3,
      observer4: values.observer4,

      subcommitteeDate: values.subcommitteeDate?.toISODate() ?? null,
      subcommitteeMeetings: values.subcommitteeMeetings
    };
  }

  const validationSchema = Yup.object({
    meetingDate: Validations.requiredDate(),
    location: Validations.requiredText(),
    reviewQuota: Validations.requiredPositiveInteger(),
    subcommitteeMeetings: Yup.array().of(
      Yup.object({
        time: Yup.string()
          .required("Enter the time of day.")
          .trim()
          .uppercase()
          .test({
            name: "isValidTimeOfDay",
            test: (timeString) => (timeString ? DateTime.fromFormat(timeString!, standardTimeFormat).isValid : false),
            message: "Times must be in the format H:mm AM."
          })
      })
    )
  });

  const initialValues: FormValues = {
    meetingDate: meeting ? DateTime.fromISO(meeting.meetingDate) : null,
    location: meeting?.location ?? "",
    reviewQuota: meeting?.reviewQuota ?? 0,
    status: meeting?.isOpen ? "open" : "closed",

    recordingSecretary: meeting?.recordingSecretary ?? "",
    observer1: meeting?.observer1 ?? "",
    observer2: meeting?.observer2 ?? "",
    observer3: meeting?.observer3 ?? "",
    observer4: meeting?.observer4 ?? "",

    subcommitteeDate: meeting?.subcommitteeDate ? DateTime.fromISO(meeting.subcommitteeDate) : null,
    subcommitteeMeetings: meeting
      ? _.orderBy(meeting.subcommitteeMeetings, (scm) => DateTime.fromFormat(scm.time, "h:mm a")).map((scm) => ({
          id: scm.id,
          time: scm.time,
          memberIds: scm.members.map((m) => m.id!)
        }))
      : []
  };

  const cannotGenerateBinderReason = getReasonBinderCannotBeGenerated(
    meeting,
    meetingPrs,
    userHasPermission(Permissions.MeetingBinderGenerate)
  );
  const cannotAssembleBinderReason = getReasonBinderCannotBeAssembled(meeting, userHasPermission(Permissions.MeetingBinderAssemble));
  const cannotReassignFilesReason = getReasonMeetingPrsCannotBeReassigned(
    meeting,
    meetingPrs,
    userHasPermission(Permissions.PrChangeMeeting)
  );

  const meetingDateIsPast = meeting && DateTime.fromISO(meeting.meetingDate) < DateTime.now().startOf("day");

  return !meeting ? (
    <Skeleton variant="rectangular" width="100%" height="40rem" />
  ) : (
    <div>
      <Helmet>
        <title>{`${meeting?.name ?? "Committee Meeting"} - PRS Online`}</title>
      </Helmet>

      <ScreenHeader
        title={meeting ? `Committee Meeting | ${meeting.name}` : "Committee Meeting"}
        navLink={
          <Link component={RouterLink} to={AppRoute.CommitteeMeetings}>
            Back to Committee Meetings
          </Link>
        }
      />

      <Stack spacing={3}>
        {binderJob && <BinderJobCard job={binderJob} />}

        <Paper sx={{ p: 3, flexGrow: 1 }}>
          <Formik initialValues={initialValues} onSubmit={saveMeeting} validationSchema={validationSchema}>
            {(formikProps) => (
              <>
                <Grid container columnSpacing={5} rowSpacing={3}>
                  <Grid item xs={12} lg={2}>
                    <FormikField
                      component={PrsDatePickerField}
                      name="meetingDate"
                      label="Date"
                      fullWidth
                      required
                      minDate={DateTime.local()}
                      onChange={(newDate: DateTime | null) => {
                        formikProps.setFieldValue("meetingDate", newDate);

                        if (newDate) {
                          formikProps.setFieldValue("subcommitteeDate", configureForBusinessDaysMath(newDate).minusBusiness({ days: 2 }));
                        }
                      }}
                    />
                    <FormikField component={FmuiTextField} name="location" label="Location" fullWidth required />

                    <Box sx={{ mt: 5 }}>
                      <Typography variant="h4">Personnel</Typography>
                      <FormikField component={FmuiTextField} name="recordingSecretary" label="Recording Secretary" fullWidth />
                      <FormikField component={FmuiTextField} name="observer1" label="Observer 1" fullWidth />
                      {formikProps.values.observer1.trim() && (
                        <FormikField component={FmuiTextField} name="observer2" label="Observer 2" fullWidth />
                      )}
                      {formikProps.values.observer2.trim() && (
                        <FormikField component={FmuiTextField} name="observer3" label="Observer 3" fullWidth />
                      )}
                      {formikProps.values.observer3.trim() && (
                        <FormikField component={FmuiTextField} name="observer4" label="Observer 4" fullWidth />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Box sx={{ mb: 5 }}>
                      <Typography variant="h4">Capacity</Typography>
                      <Table size="small">
                        <TableBody>
                          {!meeting.isPprp && (
                            <>
                              <TableRow>
                                <TableCell align="right">Assurance Files Assigned</TableCell>
                                <TableCell align="right">{meetingPrs.filter((r) => r.reviewType === "Assurance").length ?? 0}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">Non-assurance Files Assigned</TableCell>
                                <TableCell align="right">
                                  {meetingPrs.filter((r) => r.reviewType === "Non-assurance").length ?? 0}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          {meeting.isPprp && (
                            <>
                              <TableRow>
                                <TableCell align="right">PPRP Files Assigned</TableCell>
                                <TableCell align="right">{meetingPrs.filter((r) => r.reviewType === "PPRP").length ?? 0}</TableCell>
                              </TableRow>
                            </>
                          )}
                          <TableRow>
                            <TableCell align="right">Maximum to Assign*</TableCell>
                            <TableCell sx={{ pr: 0, width: "6em" }}>
                              <FormikField
                                component={FmuiTextField}
                                name="reviewQuota"
                                fullWidth
                                margin="none"
                                required
                                InputProps={{
                                  inputComponent: FixedPlacesNumberFormat,
                                  inputProps: { places: 0 },
                                  className: classes.number,
                                  placeholder: "0"
                                }}
                                disabled={saveMeetingMutation.loading || meetingDateIsPast}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right">Left to Assign</TableCell>
                            <TableCell align="right">{formikProps.values.reviewQuota - (meetingPrs.length ?? 0)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>

                    <Grid item xs={12} lg={6}>
                      <FormikField component={FmuiTextField} select name="status" label="Status" fullWidth>
                        <MenuItem value="open">{meeting.hasBeenClosed ? "Reopened" : "Open"}</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </FormikField>
                    </Grid>

                    <Box sx={{ mt: 2, mb: 2 }}>
                      <StackedStaticDataDisplay label="Meeting Type" value={meeting.isPprp ? "PPRP Reviews" : "Practice Reviews"} />
                    </Box>
                  </Grid>
                  {meeting.isPprp ? (
                    <Grid item xs={12} lg={5}></Grid>
                  ) : (
                    <Grid item xs={12} lg={5}>
                      <Card variant="outlined" className={classes.subcommitteeMeetingContainer}>
                        <CardContent>
                          <Typography variant="h3">Subcommittee Meeting</Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FormikField
                              component={PrsDatePickerField}
                              name="subcommitteeDate"
                              label="Date"
                              minDate={DateTime.local()}
                              disabled={saveMeetingMutation.loading}
                            />
                            <Button
                              variant="outlined"
                              sx={{ ml: "auto" }}
                              onClick={() =>
                                formikProps.setFieldValue(
                                  "subcommitteeMeetings",
                                  formikProps.values.subcommitteeMeetings.concat([{ time: "", memberIds: [] }])
                                )
                              }
                              disabled={saveMeetingMutation.loading}>
                              Add Time
                            </Button>
                          </Box>
                          <Table size="small" sx={{ "& .MuiTableCell-root": { verticalAlign: "top" } }}>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ width: "10em" }}>Time</TableCell>
                                <TableCell>Members</TableCell>
                                <TableCell sx={{ width: "3em" }} />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formikProps.values.subcommitteeMeetings.map((scm, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <FormikField
                                      name={`subcommitteeMeetings[${index}].time`}
                                      component={FmuiTextField}
                                      margin="none"
                                      disabled={formikProps.isSubmitting}
                                      value={formikProps.values.subcommitteeMeetings[index].time}
                                      onChange={(e: React.ChangeEvent<any>) => {
                                        const updatedSubcommitteeMeeting = {
                                          ...formikProps.values.subcommitteeMeetings[index],
                                          time: e.target.value
                                        };
                                        const updatedList = [...formikProps.values.subcommitteeMeetings];
                                        updatedList.splice(index, 1, updatedSubcommitteeMeeting);

                                        formikProps.setFieldValue("subcommitteeMeetings", updatedList);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormikField
                                      name={`subcommitteeMeetings[${index}].memberIds`}
                                      component={FmuiAutocomplete}
                                      value={formikProps.values.subcommitteeMeetings[index].memberIds}
                                      multiple
                                      disableClearable
                                      filterSelectedOptions
                                      getOptionDisabled={(memberId: number) =>
                                        formikProps.values.subcommitteeMeetings.some((m) => m.memberIds.indexOf(memberId) !== -1)
                                      }
                                      fullWidth
                                      options={activeCommitteeMembers.map((cm) => cm.id)}
                                      onChange={(e: React.ChangeEvent<{}>, newMemberIds: number[]) => {
                                        const updatedSubcommitteeMeeting = {
                                          ...formikProps.values.subcommitteeMeetings[index],
                                          memberIds: newMemberIds
                                        };
                                        const updatedList = [...formikProps.values.subcommitteeMeetings];
                                        updatedList.splice(index, 1, updatedSubcommitteeMeeting);

                                        formikProps.setFieldValue("subcommitteeMeetings", updatedList);
                                      }}
                                      getOptionLabel={(memberId: number) => {
                                        return committeeMembers.find((cm) => cm.id === memberId)?.name ?? "";
                                      }}
                                      renderInput={(params: any) => <TextField {...params} margin="none" />}
                                      disabled={saveMeetingMutation.loading}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        const updatedList = [...formikProps.values.subcommitteeMeetings];
                                        updatedList.splice(index, 1);

                                        formikProps.setFieldValue("subcommitteeMeetings", updatedList);
                                      }}
                                      disabled={saveMeetingMutation.loading}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}

                  <Grid item xs={12} lg={2}>
                    <Stack spacing={1} alignItems="flex-end">
                      <Tooltip title={cannotGenerateBinderReason ?? ""}>
                        <span>
                          <Button
                            variant="outlined"
                            disabled={(cannotGenerateBinderReason ?? "") !== ""}
                            onClick={() => setConfirmingGenerateBinder(true)}>
                            {meeting.binderGenerated ? "Regenerate Binder" : "Generate Binder"}
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip title={cannotAssembleBinderReason ?? ""}>
                        <span>
                          <Button
                            variant="outlined"
                            disabled={(cannotAssembleBinderReason ?? "") !== ""}
                            onClick={() => setConfirmingAssembleBinder(true)}>
                            {meeting.assembledBinders?.length ? "Reassemble Binder" : "Assemble Binder"}
                          </Button>
                        </span>
                      </Tooltip>
                      <Link href={meeting.sharePointUrl} target="_blank" sx={{ mr: "auto", ml: 5 }}>
                        Meeting SharePoint Folder
                      </Link>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <DataGridWithHeader
                      title="Assigned Files"
                      displayWithoutContainer
                      deemphasizeHeader
                      rows={sortedMeetingPrs}
                      noDataMessage="No files assigned."
                      loading={meetingQuery.loading}
                      checkboxSelection={selecting}
                      selectionModel={selectedPrs.map((pr) => pr.id)}
                      onSelectionModelChange={(newSelectionModel) =>
                        setSelectedPrs(sortedMeetingPrs.filter((pr) => newSelectionModel.indexOf(pr.id) !== -1))
                      }
                      disableSelectionOnClick
                      headerActions={
                        !userHasPermission(Permissions.PrChangeMeeting) ? undefined : selecting ? (
                          <>
                            <Button
                              onClick={() => {
                                setSelecting(false);
                                setSelectedPrs([]);
                              }}
                              size="small"
                              variant="outlined">
                              Cancel
                            </Button>

                            <Button
                              onClick={() => {
                                setSelectedPrs(sortedMeetingPrs.filter((pr) => !pr.committeeMeetingIsConfirmed));
                              }}
                              size="small"
                              color="primary"
                              variant="outlined">
                              Select Unconfirmed
                            </Button>

                            <Button
                              onClick={() => {
                                setReassigningPrs(selectedPrs);
                                setSelecting(false);
                                setSelectedPrs([]);
                              }}
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={selectedPrs.length === 0}>
                              Reassign
                            </Button>
                          </>
                        ) : (
                          <Tooltip title={cannotReassignFilesReason ?? ""}>
                            <span>
                              <Button
                                onClick={() => setSelecting(true)}
                                variant="outlined"
                                size="small"
                                disabled={Boolean(cannotReassignFilesReason)}>
                                Select for Reassignment
                              </Button>
                            </span>
                          </Tooltip>
                        )
                      }
                      columns={[
                        {
                          field: "committeeTab",
                          headerName: "Tab",
                          width: 50
                        },
                        ...getCommonMeetingFileColumns(classes, cx),
                        {
                          field: "tabledFromPrevious",
                          headerName: "Tabled from Previous",
                          headerClassName: classes.wrapHeader,
                          type: "boolean",
                          width: 100,
                          renderCell: (params) => (
                            <Tooltip title={(params.row as PracticeReview).tabledFromPrevious?.reason ?? ""}>
                              {(params.row as PracticeReview).tabledFromPrevious ? (
                                <GridCheckIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
                              ) : (
                                <GridCloseIcon fontSize="small" sx={{ color: theme.palette.text.disabled }} />
                              )}
                            </Tooltip>
                          )
                        }
                      ]}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    color="primary"
                    variant="outlined"
                    loading={saveMeetingMutation.loading}
                    onClick={() => formikProps.submitForm()}>
                    Save
                  </LoadingButton>
                </Box>
              </>
            )}
          </Formik>
        </Paper>
      </Stack>

      {reassigningPrs && <ChangeMeetingDialog practiceReviews={reassigningPrs} onClose={() => setReassigningPrs(null)} />}

      {confirmingGenerateBinder && (
        <ConfirmGenerateBinderDocumentsDialog committeeMeeting={meeting} onClose={() => setConfirmingGenerateBinder(false)} />
      )}

      {confirmingAssembleBinder && (
        <ConfirmAssembleBinderDialog committeeMeeting={meeting} onClose={() => setConfirmingAssembleBinder(false)} />
      )}
    </div>
  );
};

export default CommitteeMeetingScreen;
