import React from "react";
import { PracticeReview } from "practice-reviews";
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { YesNoIcon } from "../../common/YesNoIcon";
import { makeStyles } from "makeStyles";
import { getOpenableUrl } from "../../util/utilities";

interface Props {
  practiceReview: PracticeReview;
}

export const QuestionnaireList: React.FunctionComponent<Props> = ({ practiceReview }) => {
  const useStyles = makeStyles()((theme) => ({
    root: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(1)
      }
    },
    check: {
      color: theme.palette.success.main
    }
  }));

  const { classes } = useStyles();

  function getListItem(questionnaireName: string, questionnaireUrl: string | null, isMissing: boolean) {
    return (
      <ListItem disableGutters>
        <ListItemIcon>
          <YesNoIcon yes={!isMissing} />
        </ListItemIcon>
        <ListItemText
          primary={
            isMissing ? (
              questionnaireName
            ) : (
              <Link href={getOpenableUrl(questionnaireUrl!)} target="_blank">
                {questionnaireName}
              </Link>
            )
          }
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3">Questionnaires and Forms</Typography>
      <List disablePadding>
        {practiceReview.requiresSchedulingQuestionnaire &&
          getListItem(
            "Scheduling Questionnaire",
            practiceReview.schedulingQuestionnaireUrl,
            practiceReview.isMissingSchedulingQuestionnaire
          )}
        {practiceReview.requiresTaxQuestionnaire &&
          getListItem("Tax Questionnaire", practiceReview.taxQuestionnaireUrl, practiceReview.isMissingTaxQuestionnaire)}
        {(practiceReview.requiresConfidentialClientList ||
          (!practiceReview.isMissingConfidentialClientList && practiceReview.confidentialClientListUrl)) &&
          getListItem("Confidential Client List", practiceReview.confidentialClientListUrl, practiceReview.isMissingConfidentialClientList)}
        {practiceReview.requiresSelfEvaluationQuestionnaire &&
          getListItem(
            "PPRP Self-Evaluation Questionnaire",
            practiceReview.selfEvaluationQuestionnaireUrl,
            practiceReview.isMissingSelfEvaluationQuestionnaire
          )}
      </List>
    </div>
  );
};
