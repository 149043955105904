import { gql } from "@apollo/client";

const EngagementTypeFields = gql`
  fragment EngagementTypeFields on EngagementTypeTemplate {
    id
    name
    engagementGroup {
      id
      name
    }
    isPublic
    isActive
    questionContainerTemplates {
      id
      parentId
      isPaf
      isPprp
    }
  }
`;

export const FetchEngagementTypeTemplatesQuery = gql`
  ${EngagementTypeFields}
  query FetchEngagementTypeTemplates {
    engagementTypeTemplates {
      ...EngagementTypeFields
    }
  }
`;

export const FetchMasterChecklistTabsQuery = gql`
  query FetchMasterChecklistTabs {
    masterChecklistQuestionContainers(filterFirmTabs: true) {
      id
      parentId
      sortOrder
      text
      ... on ChecklistSectionTemplate {
        shortDescription
        isPaf
        isPprp
      }
    }
  }
`;

export const FetchEngagementGroupsQuery = gql`
  query FetchEngagementGroupsQuery {
    engagementGroups {
      id
      name
    }
  }
`;

export const AddEngagementTypeTemplateMutation = gql`
  ${EngagementTypeFields}
  mutation AddEngagementTypeTemplates($engagementTypeTemplate: EngagementTypeTemplateInput) {
    engagementTypeTemplates {
      add(engagementTypeTemplate: $engagementTypeTemplate) {
        ...EngagementTypeFields
      }
    }
  }
`;

export const EditEngagementTypeTemplateMutation = gql`
  ${EngagementTypeFields}
  mutation EditEngagementTypeTemplate($engagementTypeTemplate: EngagementTypeTemplateInput) {
    engagementTypeTemplates {
      update(engagementTypeTemplate: $engagementTypeTemplate) {
        ...EngagementTypeFields
      }
    }
  }
`;

export const DeleteEngagementTypeTemplateMutation = gql`
  ${EngagementTypeFields}
  mutation DeleteEngagementTypeTemplates($id: Int) {
    engagementTypeTemplates {
      delete(id: $id) {
        ...EngagementTypeFields
      }
    }
  }
`;
