import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { HighlightRowType, Ina, InaTypeCode } from "./models";
import _ from "lodash";
import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import { PracticeReviewInaPanel } from "./PracticeReviewInaPanel";
import { PprpProgramTaskInaPanel } from "./PprpProgramTaskInaPanel";
import { AppRoute } from "Routes";
import { Skeleton } from "@mui/material";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { datagridStyles, tableStyles, inaPanelStyles } from "styles/common";
import { dataGridDateValueFormatter, formatDate, formatDateTime, standardDateFormat } from "util/formats";
import { Helmet } from "react-helmet";
import { GetInasForUserQuery } from ".";
import { makeStyles } from "makeStyles";
import { useInasUser } from "InasUserProvider";
import { ScreenHeader } from "common/ScreenHeader";
import { useCurrentUser, Permissions } from "users";
import { DecisionTypeCode } from "decisions";
import { GridColDef, GridRowId, GridSortCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { MeetingInaPanel } from "./MeetingInaPanel";
import { CommitteeMeeting } from "committee-meetings";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { DocType, PracticeReview } from "../practice-reviews";
import { EndExemptionDialog } from "../exemptions/EndExemptionDialog";
import { DateTime } from "luxon";
import InlineStaticDataDisplay from "common/InlineStaticDataDisplay";
import { PprpProgramTask, PprpProgramTaskTypeCode, SendPprpProgramAnnualRenewalNoticesMutation } from "../pprp";
import { useNotifications } from "../notifications";
import { LoadingButton } from "@mui/lab";
import { CollapsibleProvider, useCollapsibleGrids } from "util/CollapsibleProvider";
import useLocalStorage from "util/useLocalStorage";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme),
  inaPanel: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(5)
    }
  },
  rowDetails: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(1)
    },
    "& svg": {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary
    },
    "& label": {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1)
    }
  },
  detailRow: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    position: "relative",
    top: "-1px", // Shift row up 1px to cover bottom border of parent row
    display: "flex",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(5)
    }
  }
}));

const ButtonStack = () => {
  const { collapseAll, expandAll } = useCollapsibleGrids();

  return (
    <>
      <Button onClick={collapseAll}>Collapse All</Button>
      <Button onClick={expandAll}>Expand All</Button>
    </>
  );
};

export const InaScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const { inasUser } = useInasUser();
  const { user, userHasPermission } = useCurrentUser();
  const notifications = useNotifications();
  const [lastClickedRow, setLastClickedRow, clearLastClickedRow] = useLocalStorage<HighlightRowType | null>("HighLightRow", null);

  const history = useHistory();

  const [endingExemptionForPr, setEndingExemptionForPr] = useState<PracticeReview | null>(null);
  const [sendingRegistrarPackageForTaskId, setSendingRegistrarPackageForTaskId] = useState<number | null>(null);

  const inaQuery = useQuery<{ inas: Ina[] }>(GetInasForUserQuery, {
    variables: {
      userId: inasUser?.id
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 300000 // refresh the INAs every 5 minutes
  });
  const inas = inaQuery.data?.inas ?? [];
  const inasByType = _.orderBy(
    _.groupBy(inas, (ina) => ina.type.id),
    (g) => g[0].type.friendlyName
  );

  const clearHighlightedRow = () => {
    if (lastClickedRow) {
      clearLastClickedRow();
    }
  };

  const navigateTo = (event: React.MouseEvent<HTMLElement>, ina: Ina, id: GridRowId, location: string) => {
    // Stop clearHighlightedRow() event propagation
    event.stopPropagation();

    // Save the highlighted PR type and id
    const savedPr: HighlightRowType = {
      rowId: id,
      hasNavigated: true,
      rowTypeCode: ina.type.typeCode
    };
    // Save to store
    setLastClickedRow(savedPr);
    history.push(location);
  };

  function buildPrInaPanel(
    panelInas: Ina[],
    getNavigationRouteForIna?: (ina: Ina) => string,
    options?: {
      groupByMeeting?: boolean;
      meetingActions?: React.ReactNode;
      meetingPrActions?: React.ReactNode;
      meetingPrActionsWidth?: number;
      completable?: boolean;
      showOverdue?: boolean;
      showScheduledColumn?: boolean;
      showReviewLocationColumn?: boolean;
      showMissingFormsColumn?: boolean;
      showSystemEstimateColumn?: boolean;
      showFinalEstimateColumn?: boolean;
      showLeadReviewerColumn?: boolean;
      showOtherReviewersColumn?: boolean;
      showPhaseColumn?: boolean;
      showIncreasedRiskColumn?: boolean;
      showDecisionColumn?: boolean;
      showMeetingColumn?: boolean;
      showRequiresResponseColumn?: boolean;
      showExemptionStartDateColumn?: boolean;
      isPerformReview?: boolean;
      extraColumns?: GridColDef[];
      headerActions?: (selectedInas: Ina[]) => React.ReactElement;
      extraActions?: (ina: Ina) => React.ReactElement;
      actionsWidth?: number;
      getRowDetails?: (ina: Ina) => React.ReactElement;
      getDetailPanelHeight?: (ina: Ina) => number;
      hideInaTable?: boolean;
      getFixedNavigationRoute?: () => string;
      getNavigationRouteForMeeting?: (meeting: CommitteeMeeting) => string;
      defaultSortOrder?: GridSortModel;
    }
  ) {
    options = options || {};
    const sampleIna = panelInas[0];

    return options.groupByMeeting ? (
      <MeetingInaPanel
        key={`meeting-${sampleIna.type.id}`}
        className={classes.inaPanel}
        title={sampleIna.type.friendlyName}
        inas={options.hideInaTable ? [] : panelInas}
        getNavigationRouteForIna={getNavigationRouteForIna}
        getNavigationRouteForMeeting={options.getNavigationRouteForMeeting}
        completable={options.completable}
        meetingActions={options.meetingActions}
        prActions={options.meetingPrActions}
        prActionsWidth={options.meetingPrActionsWidth}
        getLastClickedRow={lastClickedRow}
        navigateTo={navigateTo}
      />
    ) : (
      <PracticeReviewInaPanel
        key={`${sampleIna.type.id}`}
        className={classes.inaPanel}
        title={sampleIna.type.friendlyName}
        inas={options.hideInaTable ? [] : panelInas}
        getNavigationRoute={getNavigationRouteForIna}
        getFixedNavigationRoute={options.getFixedNavigationRoute}
        completable={options.completable}
        showOverdue={options.showOverdue}
        showScheduledColumn={options.showScheduledColumn}
        showReviewLocationColumn={options.showReviewLocationColumn}
        showMissingFormsColumn={options.showMissingFormsColumn}
        showSystemEstimateColumn={options.showSystemEstimateColumn}
        showFinalEstimateColumn={options.showFinalEstimateColumn}
        showLeadReviewerColumn={options.showLeadReviewerColumn}
        showOtherReviewersColumn={options.showOtherReviewersColumn}
        showPhaseColumn={options.showPhaseColumn}
        showIncreasedRiskColumn={options.showIncreasedRiskColumn}
        showDecisionColumn={options.showDecisionColumn}
        showMeetingColumn={options.showMeetingColumn}
        showRequiresResponseColumn={options.showRequiresResponseColumn}
        showExemptionStartDateColumn={options.showExemptionStartDateColumn}
        isPerformReview={options.isPerformReview}
        extraColumns={options.extraColumns}
        headerActions={options.headerActions}
        extraActions={options.extraActions}
        actionsWidth={options.actionsWidth}
        getRowDetails={options.getRowDetails ? (ina) => options!.getRowDetails!(ina) : undefined}
        getDetailPanelHeight={options.getDetailPanelHeight}
        getLastClickedRow={lastClickedRow}
        navigateTo={navigateTo}
        defaultSortModel={options.defaultSortOrder}
      />
    );
  }

  function buildPprpProgramTaskInaPanel(
    panelInas: Ina[],
    options?: {
      completable?: boolean;
      hideSubmissionDateColumn?: boolean;
      hideSubmittedByColumn?: boolean;
      showProgramLocationColumn?: boolean;
      getCompletionBlockingReason?: (ina: Ina) => string | null;
      extraActions?: (ina: Ina) => React.ReactElement;
      extraActionsWidth?: number;
      headerActions?: (selectedInas: Ina[]) => React.ReactElement;
    }
  ) {
    options = options || {};
    const sampleIna = panelInas[0];

    return (
      <PprpProgramTaskInaPanel
        key={`program-task-${sampleIna.type.id}`}
        className={classes.inaPanel}
        title={sampleIna.type.friendlyName}
        inas={panelInas}
        completable={options.completable}
        hideSubmissionDateColumn={options.hideSubmissionDateColumn}
        hideSubmittedByColumn={options.hideSubmittedByColumn}
        showProgramLocationColumn={options.showProgramLocationColumn}
        getCompletionBlockingReason={options.getCompletionBlockingReason}
        extraActions={options.extraActions}
        extraActionsWidth={options.extraActionsWidth}
        headerActions={options.headerActions}
        showProgramEntityNumber={sampleIna.type.typeCode === InaTypeCode.PprpSendAnnualProgramRenewalNotice}
      />
    );
  }

  function renderPrContactInfo(ina: Ina) {
    const address1 = ina.practiceReview!.pprpProgram?.address1 ?? ina.practiceReview!.firm.address1;
    const address2 = ina.practiceReview!.pprpProgram?.address2 ?? ina.practiceReview!.firm.address2;
    const city = ina.practiceReview!.pprpProgram?.city ?? ina.practiceReview!.firm.city;

    return (
      <Stack direction="row" className={classes.detailRow} alignItems="baseline">
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faUserTie} />
          <Typography variant="body1">{ina.practiceReview!.contactName}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faPhone} />
          <Typography variant="body1">{ina.practiceReview!.contactPhone}</Typography>
        </Stack>
        <a href={ina.practiceReview!.contactEmailUri}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FontAwesomeIcon icon={faEnvelope} />
            <Typography variant="body1">{ina.practiceReview!.contactEmail}</Typography>
          </Stack>
        </a>
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <Typography variant="body1">{`${address1}, ${address2 ? `${address2}, ` : ""}${city}`}</Typography>
        </Stack>
      </Stack>
    );
  }

  function getExtraActionsForPendingExemptionIna(ina: Ina) {
    return userHasPermission(Permissions.ExemptionConfirm) ? (
      <>
        <Button
          variant="outlined"
          size="small"
          className={classes.actionTextButton}
          onClick={() => setEndingExemptionForPr(ina.practiceReview)}>
          End Exemption
        </Button>
      </>
    ) : (
      <></>
    );
  }

  const [sendPprpProgramAnnualRenewalNoticesMutate, sendPprpProgramAnnualRenewalNoticesMutation] = useMutation<
    { pprpProgramTask: { sendAnnualRenewalNotices: PprpProgramTask[] } },
    { taskIds: number[] }
  >(SendPprpProgramAnnualRenewalNoticesMutation);

  async function sendPprpProgramAnnualRenewalNotices(taskIds: number[]) {
    const result = await sendPprpProgramAnnualRenewalNoticesMutate({
      variables: {
        taskIds
      },
      refetchQueries: [{ query: GetInasForUserQuery }]
    });

    if (!result.errors || result.errors.length === 0) {
      notifications.success("Sent renewal notices.");
    }
  }

  const actionsWidthForNoncompletableExemptionInas = 220;
  const actionsWidthForCompletableExemptionInas = 290;

  return (
    <CollapsibleProvider screen="inas">
      <Helmet>
        <title>INAs - PRS Online</title>
      </Helmet>
      <div onClick={clearHighlightedRow}>
        {inasUser && <ScreenHeader title={`${inasUser.name}'s INAs`} />}
        <Stack direction="row" sx={{ mb: 1 }}>
          {inaQuery.data ? <ButtonStack /> : <Skeleton width={200} height="3rem" />}
        </Stack>
        {inaQuery.loading && !inaQuery.data ? (
          <Grid container direction="column" spacing={3}>
            {[...Array(4)].map((x, index) => (
              <Grid item key={index} xs={12}>
                <Skeleton variant="rectangular" width="100%" height="10rem" />
              </Grid>
            ))}
          </Grid>
        ) : inas.length > 0 ? (
          _.map(inasByType, (inaGroup) => {
            const sampleIna = inaGroup[0];
            switch (sampleIna.type.typeCode) {
              case InaTypeCode.GenerateNotices:
                return buildPrInaPanel(inaGroup, (ina) =>
                  ina.practiceReview?.isPprpReview ? AppRoute.GenerateUpcomingPprpReviewNotices : AppRoute.GenerateUpcomingReviewNotices
                );

              case InaTypeCode.ApproveNotices:
                return buildPrInaPanel(inaGroup, (ina) =>
                  ina.practiceReview?.isPprpReview ? AppRoute.ApproveUpcomingPprpReviewNotices : AppRoute.ApproveUpcomingReviewNotices
                );

              case InaTypeCode.WaitingForForms:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    showMissingFormsColumn: true,
                    completable: true
                  }
                );

              case InaTypeCode.ReviewForms:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    showMissingFormsColumn: true,
                    completable: true
                  }
                );

              case InaTypeCode.EstimateTime:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.EstimateTime), {
                  showReviewLocationColumn: true,
                  showSystemEstimateColumn: true
                });

              case InaTypeCode.ScheduleReview:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.SchedulingReviewers),
                  {
                    showScheduledColumn: true,
                    showFinalEstimateColumn: !inaGroup.every((ina) => ina.practiceReview?.isPprpReview),
                    showIncreasedRiskColumn: true,
                    getRowDetails: (ina) => renderPrContactInfo(ina)
                  }
                );

              case InaTypeCode.PerformReview:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Summary), {
                  showLeadReviewerColumn: true,
                  showOtherReviewersColumn: true,
                  showOverdue: true,
                  isPerformReview: true,
                  extraColumns: [
                    {
                      field: "practiceReview.dueDate",
                      headerName: "Due Date",
                      width: 100,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.dueDate ? DateTime.fromISO(params.row.dueDate).toFormat(standardDateFormat) : "--"
                    }
                  ],
                  getRowDetails: (ina) => (
                    <>
                      {renderPrContactInfo(ina)}
                      <Stack direction="row" className={classes.detailRow} alignItems="baseline">
                        <InlineStaticDataDisplay label="Review Ends" value={formatDate(ina.practiceReview!.endDate)} />
                        <InlineStaticDataDisplay label="INA Started" value={formatDate(ina.createdDate)} />
                      </Stack>
                    </>
                  ),
                  getDetailPanelHeight: () => 112
                });

              case InaTypeCode.WaitingForReviewPeriod:
                return buildPrInaPanel(inaGroup, undefined, { showLeadReviewerColumn: true });

              case InaTypeCode.SendClientFileRequestNotification:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                  showScheduledColumn: true,
                  completable: true
                });

              case InaTypeCode.ClientFilesNotReceived:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                  showScheduledColumn: true,
                  completable: true
                });

              case InaTypeCode.RescheduleReview:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.SchedulingReviewers),
                  {
                    showScheduledColumn: true,
                    showFinalEstimateColumn: !inaGroup.every((ina) => ina.practiceReview?.isPprpReview),
                    showIncreasedRiskColumn: true,
                    getRowDetails: (ina) => (
                      <>
                        {renderPrContactInfo(ina)}
                        <div className={classes.detailRow}>
                          <Typography variant="body1">
                            <label>Rescheduling Reason:</label>
                            {ina.practiceReview!.mostRecentRescheduleReason}
                          </Typography>
                        </div>
                      </>
                    ),
                    getDetailPanelHeight: (ina) => 112
                  }
                );

              case InaTypeCode.ReviewSubmittedFiles:
                const prInas = inaGroup.filter((ina) => Boolean(ina.practiceReview));
                return (
                  <React.Fragment key="review-submitted-files">
                    {prInas.length > 0 &&
                      buildPrInaPanel(
                        prInas,
                        (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                        {
                          completable: true,
                          showPhaseColumn: true
                        }
                      )}
                  </React.Fragment>
                );

              case InaTypeCode.ReviewSubmittedTaskFiles:
                const programTaskInas = inaGroup.filter((ina) => Boolean(ina.programTask));
                return (
                  <React.Fragment key="review-submitted-task-files">
                    {programTaskInas.length > 0 && buildPprpProgramTaskInaPanel(programTaskInas, { completable: true })}
                  </React.Fragment>
                );

              case InaTypeCode.ReviewSubmittedPdDocuments:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.DirectedPD), {
                  extraColumns: [
                    {
                      field: "proofOfAttendance",
                      headerName: "Proof of Attendance",
                      width: 150,
                      valueGetter: (params: { row: Ina }) => {
                        const submittedProofsOfAttendanceCount = params.row.practiceReview?.attachedDocuments.filter(
                          (doc) => doc.type === DocType.SignedPDProofOfAttendance
                        ).length;
                        const directedPdCoursesCount = params.row.practiceReview?.mostAuthoritativeDecision?.assignedPdCourses.filter(
                          (course) => course.isDirected
                        ).length;
                        return `${submittedProofsOfAttendanceCount} of ${directedPdCoursesCount}`;
                      }
                    },
                    {
                      field: "cpdDeclaration",
                      headerName: "CPD Declaration",
                      width: 150,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview?.attachedDocuments.some((doc) => doc.type === DocType.SignedPDDeclaration) ? "Yes" : "No"
                    },
                    {
                      field: "practiceReview.mostAuthoritativeDecision.directedPdDueDate",
                      headerName: "PD Due Date",
                      width: 100,
                      valueGetter: (params: { row: Ina }) => {
                        const dueDate = params.row.practiceReview?.mostAuthoritativeDecision?.directedPdDueDate;
                        return dueDate ? DateTime.fromISO(dueDate).toFormat(standardDateFormat) : "--";
                      }
                    }
                  ],
                  completable: true
                });

              case InaTypeCode.PRNotReturned:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Summary), {
                  showLeadReviewerColumn: true
                });

              case InaTypeCode.SendReviewerEvaluation:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    completable: true
                  }
                );

              case InaTypeCode.PrepareDraftReport:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    completable: true,
                    showDecisionColumn: true,
                    showLeadReviewerColumn: true,
                    showMeetingColumn: true
                  }
                );

              case InaTypeCode.CreatePendingInvoice:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.TimeEntry), {
                  showLeadReviewerColumn: true
                });

              case InaTypeCode.EnterManagerDecision:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Manager),
                  {
                    showLeadReviewerColumn: true,
                    showDecisionColumn: true,
                    showMeetingColumn: true,
                    showReviewLocationColumn: true
                  }
                );

              case InaTypeCode.EnterDirectorDecision:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Director),
                  {
                    showLeadReviewerColumn: true,
                    showDecisionColumn: true,
                    showMeetingColumn: true,
                    showReviewLocationColumn: true
                  }
                );

              case InaTypeCode.DiscussDecisionChangeWithReviewer:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Reviewer),
                  {
                    showLeadReviewerColumn: true,
                    completable: true
                  }
                );

              case InaTypeCode.WaitingForFirmResponse:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                  showLeadReviewerColumn: true,
                  showMeetingColumn: true,
                  showRequiresResponseColumn: true,
                  completable: true
                });

              case InaTypeCode.RedactFirmResponse:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                  showMeetingColumn: true,
                  showRequiresResponseColumn: true,
                  completable: true
                });

              case InaTypeCode.ReviewFirmResponse:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                  showMeetingColumn: true,
                  showRequiresResponseColumn: true,
                  completable: true
                });

              case InaTypeCode.ReviewOnHold:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                  showScheduledColumn: true,
                  extraColumns: [
                    {
                      field: "practiceReview.held.heldBy",
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.held?.heldBy,
                      headerName: "Held by",
                      width: 140
                    }
                  ],
                  getRowDetails: (ina) => (
                    <div className={classes.detailRow}>
                      <Typography variant="body1">
                        <label>Reason Held:</label>
                        {ina.practiceReview!.held?.reason}
                      </Typography>
                      <Typography variant="body1">
                        <label>Date Held:</label>
                        {formatDateTime(ina.practiceReview!.held?.date)}
                      </Typography>
                    </div>
                  )
                });

              case InaTypeCode.FirmStatusChanged:
              case InaTypeCode.FirmAddressChanged:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmDetails), {
                  showPhaseColumn: true,
                  completable: true
                });

              case InaTypeCode.WaitingForCommitteeMeeting:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    groupByMeeting: true,
                    getNavigationRouteForMeeting: (meeting) => AppRoute.CommitteeMeetings
                  }
                );

              case InaTypeCode.WaitingToEnterCommitteeDecisions:
              case InaTypeCode.EnterCommitteeDecision:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision), {
                  groupByMeeting: true,
                  getNavigationRouteForMeeting: (meeting) =>
                    `${meeting.isPprp ? AppRoute.EnterPprpCommitteeDecisions : AppRoute.EnterCommitteeDecisions}?meetingId=${meeting.id}`
                });

              case InaTypeCode.ReviewFinalReports:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    groupByMeeting: true,
                    getNavigationRouteForMeeting: (meeting) =>
                      `${meeting.isPprp ? AppRoute.ReviewPprpCommitteeDecisions : AppRoute.ReviewCommitteeDecisions}?meetingId=${
                        meeting.id
                      }`
                  }
                );

              case InaTypeCode.ReviewDraftExemptionLetter:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                  showExemptionStartDateColumn: true,
                  showLeadReviewerColumn: true,
                  headerActions: () =>
                    userHasPermission(Permissions.ExemptionReviewLetter) ? (
                      <Button size="small" variant="outlined" component={RouterLink} to={AppRoute.ReviewExemptionLetters}>
                        Review Letters
                      </Button>
                    ) : (
                      <></>
                    ),
                  extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                  actionsWidth: actionsWidthForNoncompletableExemptionInas
                });

              case InaTypeCode.ApproveExemptionLetterAndSendNotification:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                  showExemptionStartDateColumn: true,
                  showLeadReviewerColumn: true,
                  headerActions: () =>
                    userHasPermission(Permissions.ExemptionConfirm) ? (
                      <Button size="small" variant="outlined" component={RouterLink} to={AppRoute.ApproveExemptionLetters}>
                        Approve Letters
                      </Button>
                    ) : (
                      <></>
                    ),
                  extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                  actionsWidth: actionsWidthForNoncompletableExemptionInas
                });

              case InaTypeCode.ConfirmAcknowledgedExemption:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                  showExemptionStartDateColumn: true,
                  showLeadReviewerColumn: true,
                  extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                  actionsWidth: actionsWidthForNoncompletableExemptionInas
                });

              case InaTypeCode.WaitingForExemptionResponse:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                  showExemptionStartDateColumn: true,
                  showLeadReviewerColumn: true,
                  completable: true,
                  extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                  actionsWidth: actionsWidthForCompletableExemptionInas
                });

              case InaTypeCode.ReviewPrWithActiveExemption:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                  showExemptionStartDateColumn: true,
                  completable: true,
                  extraColumns: [
                    {
                      field: "newPrForExemptionFirm",
                      headerName: "New PR",
                      width: 70,
                      renderCell: (params: { row: Ina }) => (
                        <Link
                          to={getRouteForPracticeReview(
                            params.row.practiceReview!.exemption?.newPracticeReviewForFirm ?? null,
                            PracticeReviewTabs.Overview
                          )}
                          component={RouterLink}>
                          {params.row.practiceReview!.exemption?.newPracticeReviewForFirm?.prNumber ?? ""}
                        </Link>
                      ),
                      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
                        param1.api
                          .getRow(param1.id)!
                          .practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
                    }
                  ],
                  extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                  actionsWidth: actionsWidthForCompletableExemptionInas
                });

              case InaTypeCode.PDWaitingForDeclaration:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.DirectedPD), {
                  extraColumns: [
                    {
                      field: "pdDeclarationDueDate",
                      headerName: "PD Due Date",
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.mostAuthoritativeDecision?.directedPdDueDate ?? "--"
                    }
                  ],
                  getRowDetails: (ina) => {
                    const decision = ina.practiceReview!.mostAuthoritativeDecision!;
                    const assignedPdCoursesText = decision?.assignedPdCourses
                      .filter((c) => c.isDirected && !c.isCompleted && !c.isExempt)!
                      .map((c) => c.name ?? c.pdCourse.name)
                      .join(", ");
                    return (
                      <div className={classes.detailRow}>
                        <InlineStaticDataDisplay label="Incomplete Courses" value={assignedPdCoursesText} />
                      </div>
                    );
                  }
                });

              case InaTypeCode.SendPDCompletionNotification:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.DirectedPD), {
                  extraColumns: [
                    {
                      field: "pdDeclarationDueDate",
                      headerName: "PD Due Date",
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.mostAuthoritativeDecision?.directedPdDueDate ?? "--"
                    }
                  ],
                  completable: true
                });

              case InaTypeCode.PprpMonitoringRequired:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Monitoring), {
                  defaultSortOrder: [{ field: "monitoringDueDate", sort: "asc" }],
                  extraColumns: [
                    {
                      field: "monitoringStartDate",
                      headerName: "Monitoring Started",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.completedDate ?? "--"
                    },
                    {
                      field: "monitoringDueDate",
                      headerName: "Monitoring Due Date",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.latestMonitoring?.monitoringDate ?? "--"
                    },
                    {
                      field: "monitoringEndDate",
                      headerName: "Monitoring End",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview!.latestMonitoring
                          ? DateTime.fromISO(params.row.practiceReview!.latestMonitoring.monitoringDate).plus({
                              days:
                                params.row.practiceReview!.monitoringFrequencyInDays! *
                                params.row.practiceReview!.monitoringOccurrencesRemaining!
                            })
                          : "--"
                    }
                  ],
                  completable: true
                });

              case InaTypeCode.PprpMonitoringCompleted:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Monitoring), {
                  extraColumns: [
                    {
                      field: "monitoringPassFail",
                      headerName: "Pass/Fail",
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview!.lastCompletedMonitoring?.isPass ? "Pass" : "Fail" ?? "--"
                    },
                    {
                      field: "monitoringCompletedOn",
                      headerName: "Completed On",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.lastCompletedMonitoring?.completedDate ?? "--"
                    },
                    {
                      field: "monitoringCompletedBy",
                      headerName: "Completed By",
                      headerClassName: classes.wrapHeader,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview!.lastCompletedMonitoring?.completedByUser?.name ?? "--"
                    },
                    {
                      field: "monitoringStartDate",
                      headerName: "Monitoring Started",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.completedDate ?? "--"
                    },
                    {
                      field: "monitoringEndDate",
                      headerName: "Monitoring End",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview!.latestMonitoring
                          ? DateTime.fromISO(params.row.practiceReview!.latestMonitoring.monitoringDate).plus({
                              days:
                                params.row.practiceReview!.monitoringFrequencyInDays! *
                                params.row.practiceReview!.monitoringOccurrencesRemaining!
                            })
                          : "--"
                    }
                  ],
                  completable: true
                });

              case InaTypeCode.PprpSendBackToCommittee:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Monitoring), {
                  extraColumns: [
                    {
                      field: "monitoringFailedOn",
                      headerName: "Monitoring Failed On",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.lastCompletedMonitoring?.completedDate ?? "--"
                    },
                    {
                      field: "monitoringFailedBy",
                      headerName: "Monitoring Failed By",
                      headerClassName: classes.wrapHeader,
                      valueGetter: (params: { row: Ina }) =>
                        params.row.practiceReview!.lastCompletedMonitoring?.completedByUser?.name ?? "--"
                    },
                    {
                      field: "monitoringStartDate",
                      headerName: "Monitoring Started",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.completedDate ?? "--"
                    },
                    {
                      field: "monitoringEndDate",
                      headerName: "Monitoring End",
                      headerClassName: classes.wrapHeader,
                      valueFormatter: dataGridDateValueFormatter,
                      valueGetter: (params: { row: Ina }) => params.row.practiceReview!.latestMonitoring?.monitoringDate ?? "--"
                    }
                  ],
                  completable: true
                });

              case InaTypeCode.CustomActivity:
                return buildPrInaPanel(
                  inaGroup,
                  (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                  {
                    showLeadReviewerColumn: true,
                    showPhaseColumn: true,
                    completable: true,
                    extraColumns: [
                      {
                        field: "activity.activityName",
                        headerName: "Activity",
                        width: 200,
                        valueGetter: (params: { row: Ina }) => params.row.activity?.activityName ?? "--"
                      },
                      {
                        field: "activity.assignedByUser",
                        headerName: "Assigned By",
                        width: 160,
                        valueGetter: (params: { row: Ina }) => params.row.activity?.assignedByUser?.name ?? "--"
                      }
                    ],
                    getRowDetails: (ina) => (
                      <div className={classes.detailRow}>
                        <InlineStaticDataDisplay label="Comments" value={ina.activity?.outcomeOrComments ?? "--"} />
                      </div>
                    )
                  }
                );

              case InaTypeCode.PprpReviewApplication:
                return buildPprpProgramTaskInaPanel(inaGroup, {
                  completable: true,
                  getCompletionBlockingReason: (ina) => {
                    const task = ina.programTask!;

                    if (
                      task.type.typeCode === PprpProgramTaskTypeCode.ApplyForProgram &&
                      (!task.applyForProgramLeaderFirstName || !task.applyForProgramLeaderLastName || !task.applyForProgramLeaderEmail)
                    ) {
                      return "The name and/or email for the new program's leader have not been entered.";
                    }

                    if (!task.type.skipRegistrar) return null;

                    return !task.registrarDecision
                      ? "The decision has not been entered."
                      : !task.registrarDecisionDate
                      ? "The decision date has not been entered."
                      : null;
                  }
                });

              case InaTypeCode.PprpWaitingForRegistrarDecision:
                return buildPprpProgramTaskInaPanel(inaGroup);

              case InaTypeCode.PprpEnterProgramTaskDecision:
                return buildPprpProgramTaskInaPanel(inaGroup, {
                  completable: true,
                  getCompletionBlockingReason: (ina) => {
                    const task = ina.programTask!;
                    const decisionLetter = task.attachedDocuments.filter((ad) => ad.type === DocType.PprpProgramTaskDecisionLetter)[0];

                    return !task.registrarDecision
                      ? "The decision has not been entered."
                      : !task.registrarDecisionDate
                      ? "The registrar decision date has not been entered."
                      : !decisionLetter
                      ? "The decision letter has not been attached."
                      : null;
                  }
                });

              case InaTypeCode.PprpWaitingForSignedDecisionLetter:
                return buildPprpProgramTaskInaPanel(inaGroup, { completable: true });

              case InaTypeCode.PprpSignedDecisionLetterReceived:
                return buildPprpProgramTaskInaPanel(inaGroup, { completable: true });

              case InaTypeCode.PprpSendAnnualProgramRenewalNotice:
                return buildPprpProgramTaskInaPanel(inaGroup, {
                  hideSubmissionDateColumn: true,
                  hideSubmittedByColumn: true,
                  showProgramLocationColumn: true,
                  headerActions: (selectedInas) => (
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      onClick={() => sendPprpProgramAnnualRenewalNotices(selectedInas.map((ina) => ina.programTask!.id))}
                      loading={sendPprpProgramAnnualRenewalNoticesMutation.loading}
                      disabled={selectedInas.length === 0}>
                      Send Notices
                    </LoadingButton>
                  )
                });

              case InaTypeCode.PprpWaitingForProgramRenewalResponse:
                return buildPprpProgramTaskInaPanel(inaGroup, { completable: true });

              default:
                return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview));
            }
          })
        ) : (
          <Typography variant="body1">{`${!inasUser || user.id === inasUser.id ? "You" : "They"} have no outstanding INAs.`}</Typography>
        )}
        {endingExemptionForPr && <EndExemptionDialog practiceReview={endingExemptionForPr} onClose={() => setEndingExemptionForPr(null)} />}
      </div>
    </CollapsibleProvider>
  );
};
