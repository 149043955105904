import { gql } from "@apollo/client";

export const SaveEstimateMutation = gql`
  mutation SaveEstimate($practiceReviewId: Int!, $overrideEstimate: Decimal, $notes: String, $finalize: Boolean!) {
    estimate {
      save(practiceReviewId: $practiceReviewId, overrideEstimate: $overrideEstimate, notes: $notes, finalize: $finalize) {
        id
        isFinalized
        overrideEstimate
        notes
      }
    }
  }
`;

export const SaveScheduleMutation = gql`
  mutation SaveSchedule(
    $practiceReviewId: Int!
    $startDate: DateTime
    $endDate: Date
    $leadReviewer: Int
    $otherReviewers: [Int]
    $schedulingNotes: String
    $finalize: Boolean!
    $hasPaperFiles: Boolean!
    $hasScannableFiles: Boolean!
    $hasElectronicFiles: Boolean!
    $hasTaxOnlyFiles: Boolean!
  ) {
    practiceReview {
      saveSchedule(
        practiceReviewId: $practiceReviewId
        startDate: $startDate
        endDate: $endDate
        leadReviewerId: $leadReviewer
        otherReviewerIds: $otherReviewers
        schedulingNotes: $schedulingNotes
        finalize: $finalize
        hasPaperFiles: $hasPaperFiles
        hasScannableFiles: $hasScannableFiles
        hasElectronicFiles: $hasElectronicFiles
        hasTaxOnlyFiles: $hasTaxOnlyFiles
      ) {
        id
        startDate
        endDate
        datesHaveBeenConfirmed
        leadReviewer {
          userId
          practiceReviewId
          user {
            id
            name
          }
        }
        otherReviewers {
          userId
          practiceReviewId
          user {
            id
            name
          }
        }
        notesFromSchedulingPhase
        hasPaperFiles
        hasScannableFiles
        hasElectronicFiles
        hasTaxOnlyFiles
      }
    }
  }
`;
