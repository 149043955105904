import { gql } from "@apollo/client";

export const SavePprpProgramDetailsMutation = gql`
  mutation SavePprpProgramDetails($practiceReviewId: Int!, $programDetails: PprpProgramDetailsInput!) {
    practiceReview {
      savePprpProgramDetails(practiceReviewId: $practiceReviewId, programDetails: $programDetails) {
        id
        pprpProgramDetails {
          technicalCompetencyApprovedDepth
          technicalCompetencyApprovedCore
          approvalLimitComments
          firmProfileCurrentApprovalLimit
          firmProfileCurrentStudentsInProgram
          firmProfileMaxStudentSupportBySch
        }
      }
    }
  }
`;

export const SavePprpProgramTaskMutation = gql`
  mutation SavePprpProgramTask($task: PprpProgramTaskInput!) {
    pprpProgramTask {
      save(task: $task) {
        id
        internalNotes
        notesToRegistrar
        registrarDecision
        registrarDecisionDate
        registrarNotes
        applyForProgramLeaderFirstName
        applyForProgramLeaderLastName
        applyForProgramLeaderEmail
        applyForNewProviderFirmName
        applyForNewProviderPprpName
        applyForNewProviderLeaderFirstName
        applyForNewProviderLeaderLastName
        applyForNewProviderLeaderEmail
      }
    }
  }
`;

export const CreateNewProviderApplicationTaskMutation = gql`
  mutation CreateNewProviderApplication($task: PprpProgramTaskInput!) {
    pprpProgramTask {
      createNewProviderApplication(task: $task) {
        id
        internalNotes
        applyForNewProviderFirmName
        applyForNewProviderPprpName
        applyForNewProviderLeaderFirstName
        applyForNewProviderLeaderLastName
        applyForNewProviderLeaderEmail
      }
    }
  }
`;

export const CancelPprpProgramTaskMutation = gql`
  mutation CancelPprpProgramTask($taskId: Int!) {
    pprpProgramTask {
      cancel(taskId: $taskId) {
        id
      }
    }
  }
`;

export const FetchPprpProgramsQuery = gql`
  query FetchPprpPrograms($includeInactive: Boolean!) {
    pprpPrograms(includeInactive: $includeInactive) {
      id
      programEntityNumber
      name
      approvalDate
      programPath
      firm {
        id
        entityNumber
        name
        entityType {
          typeCode
          typeShortName
        }
        firmStatus
        subStatus
      }
    }
  }
`;

export const FetchPprpProgramTasksQuery = gql`
  query FetchPprpProgramTasks($includeComplete: Boolean) {
    pprpProgramTasks(includeComplete: $includeComplete) {
      id
      type {
        typeCode
        typeName
      }
      submissionDate
      status
      lastStatusChange
      registrarDecision
      applyForNewProviderFirmName
      applyForNewProviderPprpName
      pprpProgram {
        id
        name
        programPath
        members {
          id
          name
          isPrimaryProgramLeader
        }
        firm {
          id
          entityNumber
          name
        }
      }
      firm {
        id
        entityNumber
        name
      }
      inas {
        id
        isComplete
        type {
          id
          typeCode
          friendlyName
        }
      }
    }
  }
`;

export const FetchPprpProgramByIdQuery = gql`
  query FetchPprpProgramById($id: Int!) {
    pprpProgramById(id: $id) {
      id
      programEntityNumber
      name
      programPath
      approvalDate
      restrictions
      conditions
      address1
      address2
      city
      province
      postalCode
      firm {
        id
        entityNumber
        name
        firmStatus
        subStatus
        entityType {
          typeCode
          typeShortName
        }
        address1
        address2
        city
        province
        postalCode
        zone
        practiceLocale
        phoneNumber
        faxNumber
      }
      pertCandidateTotal
      pertCandidateTotalPerPert
      pertCandidateApprovalLimit
      pertCandidateCountPerLocation
      members {
        id
        memberEntityNumber
        name
        firstName
        lastName
        designation
        title
        email
        isPrimaryProgramLeader
        isSecondaryProgramLeader
        isProgramManager
      }
      attachedDocuments {
        id
        type
        typeFriendlyName
        fileName
        url
      }
    }
  }
`;
export const FetchPprpProgramTaskByIdQuery = gql`
  query FetchPprpProgramTaskById($id: Int!) {
    pprpProgramTaskById(id: $id) {
      id
      type {
        typeCode
        typeName
        skipRegistrar
      }
      submittedBy
      submissionDate
      status
      lastStatusChange
      internalNotes
      notesToRegistrar
      registrarDecision
      registrarDecisionDate
      registrarNotes
      annualRenewalResponseReceived
      applyForProgramLeaderFirstName
      applyForProgramLeaderLastName
      applyForProgramLeaderEmail
      applyForNewProviderFirmName
      applyForNewProviderPprpName
      applyForNewProviderLeaderFirstName
      applyForNewProviderLeaderLastName
      applyForNewProviderLeaderEmail
      pprpProgram {
        id
        name
        programEntityNumber
        programPath
        members {
          id
          name
          isPrimaryProgramLeader
          isSecondaryProgramLeader
        }
        firm {
          id
          entityNumber
          name
        }
      }
      firm {
        id
        entityNumber
        name
      }
      inas {
        id
        isComplete
        type {
          id
          typeCode
          friendlyName
        }
      }
      attachedDocuments {
        id
        type
        typeFriendlyName
        fileName
        url
      }
      primaryLeaderChange {
        current {
          id
          name
        }
        resigning
        replacement {
          id
          name
        }
      }
      secondaryLeaderChange {
        current {
          id
          name
        }
        resigning
        replacement {
          id
          name
        }
      }
      renewalChecklistItems {
        id
        questionText
        sortOrder
        isChecked
      }
    }
  }
`;

export const GeneratePprpProgramTaskRegistrarPackageMutation = gql`
  mutation GeneratePprpProgramTaskRegistrarPackage($taskId: Int!) {
    pprpProgramTask {
      generateRegistrarPackage(taskId: $taskId) {
        id
      }
    }
  }
`;

export const SendPprpProgramAnnualRenewalNoticesMutation = gql`
  mutation SendPprpProgramAnnualRenewalNotices($taskIds: [Int!]!) {
    pprpProgramTask {
      sendAnnualRenewalNotices(taskIds: $taskIds) {
        id
      }
    }
  }
`;

export const RemoveAttachedDocumentFromPprpProgramMutation = gql`
  mutation RemoveAttachedDocumentFromPprpProgram($programId: Int!, $attachedDocumentId: Int!) {
    pprpProgram {
      removeAttachedDocument(programId: $programId, attachedDocumentId: $attachedDocumentId) {
        id
        attachedDocuments {
          id
          type
          typeFriendlyName
          fileName
          url
        }
      }
    }
  }
`;

export const RemoveAttachedDocumentFromPprpProgramTaskMutation = gql`
  mutation RemoveAttachedDocumentFromPprpProgramTask($taskId: Int!, $attachedDocumentId: Int!) {
    pprpProgramTask {
      removeAttachedDocument(taskId: $taskId, attachedDocumentId: $attachedDocumentId) {
        id
      }
    }
  }
`;
