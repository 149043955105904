import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import NotificationProvider from "notifications/NotificationProvider";
import { Routes } from "./Routes";
import Layout from "./chrome/Layout";
import { AppConfigProvider } from "util/AppConfig";
import { ApolloProvider } from "util/ApolloProvider";
import { MsalProvider } from "auth/MsalProvider";
import { AuthenticationProvider } from "auth/AuthenticationProvider";
import { AppInsightsProvider } from "util/AppInsightsProvider";
import { CalendarProvider } from "util/CalendarProvider";
import { UnsavedChangesProvider } from "UnsavedChangesProvider";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { InasUserProvider } from "./InasUserProvider";
import { AxiosProvider } from "auth/SecureAxios";
import { SignalRProvider } from "util/useSignalR";
import { ThemeProviderByEnvironment } from "ThemeProviderByEnvironment";
import { WarningIcon } from "./assets/icons/ErrorIcon";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()({
  errorSnackDisplay: {
    "& .SnackbarItem-variantError": {
      flexWrap: "wrap-reverse",

      "& .SnackbarItem-message": {
        alignItems: "start",
        maxHeight: "90vh",
        maxWidth: "97vw",
        overflowY: "auto"
      }
    }
  }
});

const muiCache = createCache({
  key: "mui",
  prepend: true
});

const App: React.FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <CacheProvider value={muiCache}>
      <CssBaseline />
      <AppConfigProvider>
        <ThemeProviderByEnvironment>
          <AppInsightsProvider>
            <MsalProvider>
              <SnackbarProvider
                classes={{ root: classes.errorSnackDisplay }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                iconVariant={{
                  error: <WarningIcon />
                }}>
                <NotificationProvider>
                  <AuthenticationProvider>
                    <ApolloProvider>
                      <SignalRProvider>
                        <AxiosProvider>
                          <CalendarProvider>
                            <BrowserRouter>
                              <UnsavedChangesProvider>
                                <InasUserProvider>
                                  <Layout>
                                    <Routes />
                                  </Layout>
                                </InasUserProvider>
                              </UnsavedChangesProvider>
                            </BrowserRouter>
                          </CalendarProvider>
                        </AxiosProvider>
                      </SignalRProvider>
                    </ApolloProvider>
                  </AuthenticationProvider>
                </NotificationProvider>
              </SnackbarProvider>
            </MsalProvider>
          </AppInsightsProvider>
        </ThemeProviderByEnvironment>
      </AppConfigProvider>
    </CacheProvider>
  );
};

export default App;
